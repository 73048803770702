import { DatePicker as EnvDatePicker } from '@envestnet/envreact-component-library';
import { DatePickerVariant } from '@envestnet/envreact-component-library/dist/components/enums';
import moment from 'moment';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps, Label } from './Label';

interface IProps
    extends ILabelProps,
        Omit<ComponentPropsWithoutRef<typeof EnvDatePicker>, 'className' | 'label' | 'onChange'> {
    className?: string;
    min?: string;
    max?: string;
    onChange: (value: string | null) => void;
    value: string | undefined;
}

const DateInput = ({ className, label, labelClassName, min, max, onChange, value, ...rest }: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    const isoDateFormat = 'YYYY-MM-DD';
    const parseDate = (isoDateString: string | undefined): Date | undefined =>
        isoDateString ? moment(isoDateString, isoDateFormat).toDate() : undefined;
    const formatDate = (date: Date | null | undefined): string | null =>
        date ? moment(date).format(isoDateFormat) : null;

    return (
        <div className={className}>
            <Label label={label} labelClassName={labelClassName} />
            <EnvDatePicker
                className="w-full"
                minDate={parseDate(min)}
                maxDate={parseDate(max)}
                onChangeDate={(date?: Date | null) => onChange(formatDate(date))}
                selectedDate={parseDate(value)}
                variant={DatePickerVariant.Single}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            />
        </div>
    );
};

export { DateInput };
