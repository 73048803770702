import { IBizEquityBusiness } from '@api';
import { faFlagUsa, faMapMarked } from '@fortawesome/pro-light-svg-icons';
import ValueRankMetric from './ValueRankMetric';

interface IProps {
    bizEquityBusiness: IBizEquityBusiness;
}

const ValueRank = ({ bizEquityBusiness }: IProps): JSX.Element => (
    <div className="flex flex-col px-7 py-5 pr-10">
        <div className="font-bold text-lg">Value Rank</div>
        <div className="flex-1 flex flex-col justify-evenly">
            <ValueRankMetric
                icon={faMapMarked}
                name="Locally"
                rank={bizEquityBusiness.postcodeRank?.order}
                total={bizEquityBusiness.postcodeRank?.total}
            />
            <ValueRankMetric
                icon={faFlagUsa}
                name="in the Country"
                rank={bizEquityBusiness.countryRank?.order}
                total={bizEquityBusiness.countryRank?.total}
            />
        </div>
    </div>
);

export default ValueRank;
