import { IBusiness } from '@api';
import Card from '../Card';
import Header from '../Header';
import CompensationCardContent from './CompensationCardContent';

interface IProps {
    business: IBusiness;
}

const CompensationCard = ({ business }: IProps): JSX.Element => {
    return (
        <Card padding>
            <Header text="Compensation" />
            <CompensationCardContent business={business} />
        </Card>
    );
};

export default CompensationCard;
