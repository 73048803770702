import { IChartPoint, IChartSeries } from './chartModels';
import RadarChartAxes, {
    axisIconHeight,
    axisIconMargin,
    axisIconWidth,
    axisLabelHeight,
    axisLabelMargin,
    axisLabelWidth,
    IRadarChartAxis,
} from './RadarChartAxes';
import RadarChartLevels from './RadarChartLevels';

interface IProps {
    axesIconsOnly?: boolean;
    className?: string;
    data: IChartSeries[];
    dataMax: number;
    dataMin: number;
    height: number;
    levels: number;
    width: number;
}

const threeHundredSixtyDegreesInRadians = 2 * Math.PI;

const getPointsString = (points: IChartPoint[], radians: number, radius: number, range: number): string => {
    const pointCoordinates = points.map((p, i) => [
        radius * (1 - (p.value / range) * Math.sin((i * radians) / points.length)),
        radius * (1 - (p.value / range) * Math.cos((i * radians) / points.length)),
    ]);

    return pointCoordinates.reduce((acc, current) => `${acc} ${current[0]},${current[1]}`, '');
};

const RadarChart = ({
    axesIconsOnly = false,
    className,
    data,
    dataMax,
    dataMin,
    height,
    levels,
    width,
}: IProps): JSX.Element => {
    const axisMargin = axesIconsOnly ? axisIconMargin : axisLabelMargin;
    const axisWidth = axesIconsOnly ? axisIconWidth : axisLabelWidth;
    const axisHeight = axesIconsOnly ? axisIconHeight : axisLabelHeight;
    const diameter = Math.min(height - 2 * axisHeight - 2 * axisMargin, width - 2 * axisWidth - 2 * axisMargin);
    const range = dataMax - dataMin;
    const radius = diameter / 2;
    const axes = data[0].points.map<IRadarChartAxis>(d => ({
        icon: d.icon,
        label: d.name,
    }));

    return (
        <svg
            className={className}
            height={diameter + 2 * axisHeight + 2 * axisMargin}
            width={diameter + 2 * axisWidth + 2 * axisMargin}
        >
            <defs>
                {data.map((s, seriesIndex) => (
                    <radialGradient key={seriesIndex} id={`gradient-${seriesIndex}`}>
                        {s.gradients.map((g, gradientIndex) => (
                            <stop key={gradientIndex} offset={gradientIndex} stopColor={g} />
                        ))}
                    </radialGradient>
                ))}
            </defs>
            <g transform={`translate(${axisWidth + axisMargin}, ${axisHeight + axisMargin})`}>
                <>
                    <RadarChartLevels
                        levels={levels}
                        numberOfAxes={axes.length}
                        radians={threeHundredSixtyDegreesInRadians}
                        radius={radius}
                    />
                    <RadarChartAxes
                        axes={axes}
                        iconsOnly={axesIconsOnly}
                        radians={threeHundredSixtyDegreesInRadians}
                        radius={radius}
                    />
                    {data.map((s, i) => (
                        <polygon
                            key={i}
                            fill={`url(#gradient-${i})`}
                            points={getPointsString(s.points, threeHundredSixtyDegreesInRadians, radius, range)}
                        />
                    ))}
                </>
            </g>
        </svg>
    );
};

export { RadarChart };
