import { CircularProgress as EnvCircularProgress } from '@envestnet/envreact-component-library';
import { CircularProgressSizes } from '@envestnet/envreact-component-library/dist/components/enums';
import { faCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from '~/components';
import { useAddDocument } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { closeModal, ModalType } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useParams } from '~/routing';
import { errorParser } from '~/utils/errorParser';
import { defaultErrorMessage } from '~/utils/errorUtils';
import DragAndDropUploadHandler from '../DragAndDropUploadHandler';
import { getIconFromExtension } from '../icons/iconMap';
import { getColorFromIcon } from '../utils/colorMap';

enum Status {
    None,
    IsSaving,
    DidSaveSuccessful,
    Error,
}

interface IProps {
    filesToAdd?: FileList;
    targetFolderId: string;
}

const AddFilesModal = ({ filesToAdd, targetFolderId }: IProps): JSX.Element => {
    const [status, setStatus] = useState(Status.None);
    const [files, setFiles] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { businessId } = useParams<BusinessParams>();
    const addDocument = useAddDocument(businessId);
    const fileDialog = useRef<HTMLInputElement>(null);
    const showProgress = status === Status.IsSaving || status === Status.DidSaveSuccessful;
    const didSaveSuccessful = status === Status.DidSaveSuccessful;
    const { selectedModal } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    console.log('trying to open!');

    const handleAddFiles = useCallback(
        async (fileList?: FileList | null) => {
            const newFiles = Array.from(fileList || []);
            if (!newFiles.length) {
                return;
            }

            setStatus(Status.IsSaving);

            try {
                setFiles(newFiles);
                await Promise.all(
                    newFiles.map(async file => {
                        await addDocument.mutateAsync({ file, parentFolderId: targetFolderId });
                    })
                );
                setStatus(Status.DidSaveSuccessful);
            } catch (err: unknown) {
                if (axios.isAxiosError(err) && err.response?.status === 400) {
                    const errors = errorParser.getFieldValidationErrors(err);
                    if (errors && Object.keys(errors).length > 0) {
                        setErrorMessage(['Failed to upload file(s).', ...Object.values(errors)].join(' '));
                    } else {
                        setErrorMessage(`Failed to upload file(s). ${defaultErrorMessage}`);
                    }
                } else {
                    setErrorMessage(`Failed to upload file(s). ${defaultErrorMessage}`);
                }
                setStatus(Status.Error);
                throw err;
            }
        },
        [addDocument, targetFolderId]
    );

    useEffect(() => {
        if (status === Status.None) {
            handleAddFiles(filesToAdd);
        }
    }, [filesToAdd, handleAddFiles, status]);

    return (
        <Modal
            maxWidth="xl"
            open={selectedModal.type === ModalType.AddFiles}
            setOpen={() => dispatch(closeModal())}
            title="Add Files"
            disableBackdropClickClose={showProgress}
        >
            <div className="p-6">
                <div className="space-y-4 mt-5">
                    {showProgress && (
                        <div>
                            <p className="font-bold">
                                <span className="mr-3">
                                    {didSaveSuccessful ? 'Added' : 'Adding'} {files.length} files
                                </span>
                                {didSaveSuccessful ? (
                                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                                ) : (
                                    <EnvCircularProgress size={CircularProgressSizes.Small} />
                                )}
                            </p>
                            {files.map((file, index) => {
                                const icon = getIconFromExtension(file.name);
                                const color = getColorFromIcon(icon.iconName);
                                return (
                                    <div key={index} className="flex items-center my-2">
                                        <span className="fa-stack fa-sm flex items-center justify-center mr-2">
                                            <FontAwesomeIcon icon={icon} className={color} size="lg" />
                                        </span>
                                        <span className="truncate" title={file.name}>
                                            {file.name}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!showProgress && (
                        <DragAndDropUploadHandler folderId={targetFolderId} onDrop={handleAddFiles}>
                            <div
                                className="text-center p-4 m-4 cursor-pointer bg-background2"
                                onClick={() => fileDialog.current?.click()}
                            >
                                <input
                                    onChange={e => handleAddFiles(e.currentTarget.files)}
                                    ref={fileDialog}
                                    type="file"
                                    multiple
                                    className="hidden"
                                />
                                Click or drag-and-drop here to add new files
                            </div>
                        </DragAndDropUploadHandler>
                    )}
                    {status === Status.Error && (
                        <p className="text-error">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                            {errorMessage ?? defaultErrorMessage}
                        </p>
                    )}
                    <div className="space-x-3 flex justify-end">
                        <Button color="primary" text="Close" onClick={() => dispatch(closeModal())} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AddFilesModal;
