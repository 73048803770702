import { Toggle as EnvToggle } from '@envestnet/envreact-component-library';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';

interface IProps extends Omit<ComponentPropsWithoutRef<typeof EnvToggle>, 'onClick'> {
    id: string;
    onChange: () => void;
}

const Toggle = ({ id, onChange, ...rest }: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return <EnvToggle id={id} onClick={onChange} {...rest} disabled={fieldSetContext.disabled || rest.disabled} />;
};

export { Toggle };
