interface IProps {
    levels: number;
    numberOfAxes: number;
    radians: number;
    radius: number;
}
const RadarChartLevels = ({ levels, numberOfAxes, radians, radius }: IProps): JSX.Element => {
    return (
        <>
            {[...Array(levels).keys()].map(l => {
                const levelFactor = radius * ((l + 1) / levels);

                return [...Array(numberOfAxes).keys()].map(i => (
                    <line
                        key={i}
                        x1={levelFactor * (1 - Math.sin((i * radians) / numberOfAxes))}
                        y1={levelFactor * (1 - Math.cos((i * radians) / numberOfAxes))}
                        x2={levelFactor * (1 - Math.sin(((i + 1) * radians) / numberOfAxes))}
                        y2={levelFactor * (1 - Math.cos(((i + 1) * radians) / numberOfAxes))}
                        className="stroke-current text-divider"
                        strokeWidth=".5px"
                        transform={`translate(${radius - levelFactor},${radius - levelFactor})`}
                    ></line>
                ));
            })}
        </>
    );
};

export default RadarChartLevels;
