import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    outcome: string;
    steps: string[];
}

const ProcessInfo = ({ outcome, steps }: IProps): JSX.Element => (
    <div className="space-y-6">
        <div className="font-bold">Our Process</div>
        {steps.map((s, i) => (
            <div className="flex flex-row items-center space-x-8" key={`${i}`}>
                <div>
                    <div className="bg-information flex h-8 items-center justify-center rounded-full w-8 ring-8 ring-highlight">
                        <div>{i + 1}</div>
                    </div>
                </div>
                <div>{s}</div>
            </div>
        ))}
        {outcome && (
            <div className="bg-background2 flex items-center p-5 rounded-xl space-x-6">
                <div className="flex flex-col items-center justify-center space-y-4">
                    <div>Outcome</div>
                    <div>
                        <FontAwesomeIcon size={'3x'} fontWeight={300} icon={faFileInvoiceDollar} />
                    </div>
                </div>
                <div>{outcome}</div>
            </div>
        )}
    </div>
);

export default ProcessInfo;
