import { IBizEquityBusiness, ITodo, ITodoSystemActivityType } from '@api';
import { faCircle, faClock, faKeyboard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, LoadingIndicator } from '~/components';
import { useBusinessTeamMembers, useInviteBizEquityBusinessDataEntryDelegate, useTodos } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import background from '../background.jpg';
import bizEquityLogo from '../bizEquityLogo.svg';
import DelegateModal from './DelegateModal';
import ManualEntryModal from './ManualEntryModal';

interface IProps {
    bizEquityBusiness: IBizEquityBusiness;
    businessId: string;
}

const getLastDataEntryTodo = (todos?: ITodo[]): ITodo | undefined => {
    if (!todos) return undefined;

    return todos.find(t => t.systemActivity === ITodoSystemActivityType.BizEquityBusinessDataEntry);
};

const ValuationOptions = ({ bizEquityBusiness, businessId }: IProps): JSX.Element => {
    const { data: todos, isLoading: isTodosLoading } = useTodos(businessId);
    const { data: teamMembers } = useBusinessTeamMembers(businessId);
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const [delegateModalOpen, setDelegateModalOpen] = useState<boolean>(false);
    const [manualEntryModalOpen, setManualEntryModalOpen] = useState<boolean>(false);
    const [isResendingEmail, setIsResendingEmail] = useState(false);
    const inviteBizEquityBusinessDataEntryDelegate = useInviteBizEquityBusinessDataEntryDelegate(businessId);

    if (isTodosLoading) return <LoadingIndicator />;

    const dataEntryTodo = getLastDataEntryTodo(todos);
    const dataEntryDelegateEmail =
        teamMembers && teamMembers.find(m => m.id === dataEntryTodo?.assigneeId)?.emailAddress;

    return (
        <>
            <div className="grid xl:grid-cols-2 h-full">
                <div className="flex flex-col items-center justify-center max-w-xl mx-auto py-4 space-y-8 w-full">
                    <img className="mx-auto w-80 white-logo" src={bizEquityLogo} alt="Biz Equity Logo" />
                    {dataEntryTodo ? (
                        <div className="text-center">
                            <span className="fa-layers fa-fw fa-8x mb-5">
                                <FontAwesomeIcon className="text-background2" icon={faCircle} />
                                <FontAwesomeIcon className="text-background" icon={faClock} transform="shrink-6" />
                            </span>
                            <div>
                                {`Waiting on data entry. An email has been sent ${
                                    dataEntryDelegateEmail ? `to ${dataEntryDelegateEmail}` : ''
                                } with instructions on how to enter this data.`}
                            </div>
                            <div className="flex space-x-4 justify-center mt-4">
                                {dataEntryDelegateEmail && (
                                    <Button
                                        loading={isResendingEmail}
                                        text="Resend Email"
                                        onClick={() => {
                                            setIsResendingEmail(true);
                                            inviteBizEquityBusinessDataEntryDelegate
                                                .mutateAsync({ emailAddress: dataEntryDelegateEmail })
                                                .catch(() => showError())
                                                .finally(() => setIsResendingEmail(false));
                                        }}
                                    />
                                )}
                                <Button
                                    disabled={isResendingEmail}
                                    text="Manually Add Data"
                                    onClick={() => setManualEntryModalOpen(true)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 gap-6 w-full">
                            <div className="bg-background1 flex flex-col px-6 py-5 rounded-lg space-y-4">
                                <div className="flex justify-between">
                                    <div className="font-semibold ">Manually Add Data</div>
                                    <FontAwesomeIcon className="text-divider text-2xl" icon={faKeyboard} size="1x" />
                                </div>
                                <div className="flex-1">Manually fill out the valuation form.</div>
                                <div className="justify-self-end text-center">
                                    <Button
                                        color="primary"
                                        text="Manually Add Data"
                                        onClick={() => setManualEntryModalOpen(true)}
                                    />
                                </div>
                            </div>
                            {/* <div className="bg-background1 flex flex-col px-6 py-5 rounded-lg space-y-4">
                                <div className="flex justify-between">
                                    <div className="font-semibold text-lg">Delegate</div>
                                    <FontAwesomeIcon className="text-divider text-2xl" icon={faPaperPlane} size="1x" />
                                </div>
                                <div className="flex-1">
                                    Send an email to someone else to fill out the valuation form.
                                </div>
                                <div className="text-center">
                                    <Button
                                        color="primary"
                                        onClick={() => setDelegateModalOpen(true)}
                                        text="Delegate"
                                    />
                                </div>
                            </div> */}
                        </div>
                    )}
                </div>
                <div
                    className="bg-center bg-cover bg-no-repeat hidden xl:block"
                    style={{ backgroundImage: `url(${background})` }}
                />
            </div>
            <DelegateModal
                businessId={businessId}
                isOpen={delegateModalOpen}
                onClose={() => setDelegateModalOpen(false)}
            />
            <ManualEntryModal
                bizEquityBusiness={bizEquityBusiness}
                businessId={businessId}
                isOpen={manualEntryModalOpen}
                onClose={() => setManualEntryModalOpen(false)}
            />
        </>
    );
};

export default ValuationOptions;
