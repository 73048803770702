import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { useRef } from 'react';
import { useEmployeeAvatar } from '~/hooks';
import { Avatar, AvatarProps } from '.';

interface IProps {
    businessId: string;
    employeeId: string;
}

const AvatarEmployeeEditable = ({
    businessId,
    className,
    employeeId,
    placeholderIcon = faUser,
    rounded = 'full',
    size = 10,
    src,
    ...rest
}: IProps & AvatarProps): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null);
    const setNewAvatar = useEmployeeAvatar(businessId).mutateAsync;

    const handleFileSelect = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        const avatar = evt.target.files?.[0];
        if (avatar) {
            setNewAvatar({ avatar, employeeId });
        }
    };

    const onClick = () => {
        inputRef?.current?.click();
    };

    return (
        <button className="inline-block cursor-pointer" onClick={onClick}>
            <Avatar
                className={className}
                placeholderIcon={placeholderIcon}
                rounded={rounded}
                size={size}
                src={src}
                {...rest}
            />
            <input
                accept="image/jpeg, image/png"
                className="hidden"
                onChange={handleFileSelect}
                type="file"
                ref={inputRef}
            />
        </button>
    );
};

export { AvatarEmployeeEditable };
