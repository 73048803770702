import { ErrorPage, LoadingIndicator } from '~/components';
import { useAdvisorFirm } from '~/hooks';
import PresentationSettings from './PresentationSettings';

interface IProps {
    advisorFirmId: string;
}

const MyFirmPresentationSettings = ({ advisorFirmId }: IProps): JSX.Element => {
    const { isError, isLoading, data: myFirm } = useAdvisorFirm(advisorFirmId);

    if (isLoading) return <LoadingIndicator />;
    if (isError || !myFirm) return <ErrorPage />;

    return <PresentationSettings advisorFirm={myFirm} />;
};

export default MyFirmPresentationSettings;
