import { IEmployee } from '@api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useBoundingClientRect } from '~/hooks';
import CategoryModal from '../rating/CategoryModal';
import EmployeeSearch from '../rating/EmployeeSearch';
import {
    categoryDescription,
    categoryIcon,
    categoryTitle,
    ITeamRating,
    modelKey,
    ratingCategories,
    RatingCategory,
} from '../rating/models';
import RatingLegend from '../rating/RatingLegend';
import { formatRating, hasRating } from '../rating/utils';
import TeamPanelChart from '../TeamPanelChart';

interface IProps {
    employees: IEmployee[];
}

const aggregateRating = (
    result: Record<RatingCategory, ITeamRating>,
    employee: IEmployee,
    ratingCategory: RatingCategory
): ITeamRating => {
    const employeeRating = employee[modelKey[ratingCategory]];
    const hasRating = !!employeeRating;
    const currentAggregate = result[ratingCategory];
    const ratedEmployeeCount = (currentAggregate?.ratedEmployeeCount ?? 0) + (hasRating ? 1 : 0);
    const ratingSum = (currentAggregate?.ratingSum ?? 0) + (employeeRating ?? 0);
    return {
        ratedEmployeeCount,
        ratingAverage: ratingSum / (ratedEmployeeCount || 1),
        ratingCategory,
        ratingSum,
    };
};

export const getTeamRatings = (employees: IEmployee[]): ITeamRating[] => {
    const categoryMap = employees.reduce<Record<RatingCategory, ITeamRating>>((result, employee) => {
        for (const ratingCategory of ratingCategories) {
            result[ratingCategory] = aggregateRating(result, employee, ratingCategory);
        }
        return result;
    }, {} as Record<RatingCategory, ITeamRating>);
    return Object.values(categoryMap);
};

const Team = ({ employees }: IProps): JSX.Element => {
    const [activeCategory, setActiveCategory] = useState<RatingCategory>(RatingCategory.Growth);
    const [containerRect, containerRef] = useBoundingClientRect();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [comparisonEmployeeId, setComparisonEmployeeId] = useState<string>('');
    const fullTeamRatings = getTeamRatings(employees);

    return (
        <>
            <div className="border-t border-background2 pt-3"></div>

            <div className="grid xl:grid-cols-3 grid-cols-1">
                <div className="col-span-1">
                    <RatingLegend />
                    {fullTeamRatings.map(({ ratingCategory, ratingAverage }) => (
                        <div
                            key={ratingCategory}
                            className="px-8 py-4 rounded-md flex items-center justify-between bg-background1 my-4 cursor-pointer hover:bg-background2 shadow"
                            onClick={() => {
                                setActiveCategory(ratingCategory);
                                setModalIsOpen(true);
                            }}
                        >
                            <div>
                                <div className="flex items-center">
                                    <FontAwesomeIcon
                                        icon={categoryIcon[ratingCategory]}
                                        className="hidden 2xl:inline-block mr-6"
                                        fixedWidth
                                        size="lg"
                                    />
                                    <div>
                                        <div className="text-lg font-bold">
                                            <FontAwesomeIcon
                                                icon={categoryIcon[ratingCategory]}
                                                className="mr-2 2xl:hidden"
                                                fixedWidth
                                            />
                                            {categoryTitle[ratingCategory]}
                                        </div>
                                        <div className="text-sm 2xl:text-base">
                                            {categoryDescription[ratingCategory]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-l border-background2 pl-7 flex flex-col text-center">
                                <div className="whitespace-nowrap">Average</div>
                                <div className="font-bold text-2xl 2xl:text-5xl">{formatRating(ratingAverage)}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="col-span-2 xl:border-l border-background2 pl-5 ml-5 mt-3">
                    <div className="flex mb-4">
                        <div style={{ maxWidth: '15rem' }} className="mr-3">
                            <EmployeeSearch
                                employees={employees.filter(e => hasRating(e))}
                                onSelect={id => setComparisonEmployeeId(id)}
                                selectedEmployee={comparisonEmployeeId}
                            />
                        </div>
                    </div>
                    <div ref={containerRef} style={{ height: '80vh' }}>
                        <TeamPanelChart
                            containerRect={containerRect}
                            fullTeamRatings={fullTeamRatings}
                            selectedEmployee={employees.find(employee => employee.id === comparisonEmployeeId)}
                        />
                    </div>
                </div>
                <CategoryModal
                    employees={employees.filter(e => !!e[modelKey[activeCategory]])}
                    onClose={() => setModalIsOpen(false)}
                    open={modalIsOpen}
                    ratingCategory={activeCategory}
                />
            </div>
        </>
    );
};

export default Team;
