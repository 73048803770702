import { IBusinessValuation, IBusinessValuationType } from '@api';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyFormatter from '~/utils/currencyFormatter';

interface IProps {
    businessValuation: IBusinessValuation;
}

const BusinessValuationNumber = ({ businessValuation }: IProps): JSX.Element => {
    return (
        <>
            {CurrencyFormatter.format(businessValuation.value)}
            {businessValuation.source === IBusinessValuationType.BizEquity && (
                <sup>
                    <FontAwesomeIcon
                        className="ml-1"
                        icon={faInfoCircle}
                        size="sm"
                        title="This fair market value conclusion from BizEquity is the value of the company available to its owners or shareholders and incorporates all of the assets included in the “asset value” plus the firm's liquid financial assets (cash, A/R, deposits, etc.) and minus its liabilities (ST and LT)."
                    />
                </sup>
            )}
        </>
    );
};

export default BusinessValuationNumber;
