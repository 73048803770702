import { IEmployeeCreateRequest, IEmployeeUpdateRequest } from '@api';
import React from 'react';
import * as Yup from 'yup';
import { AvatarInput, FieldSet, FileUpload, TextInput } from '~/components';
import { trimString } from '~/utils/yupUtils';

export const mapFormDataToApi = (
    formData: IPrimaryEmployeeFormData
): IEmployeeCreateRequest | IEmployeeUpdateRequest => ({
    ...formData,
    emailAddress: formData.emailAddress?.length ? formData.emailAddress : undefined,
});

export type IPrimaryEmployeeFormData = Pick<
    IEmployeeCreateRequest,
    'emailAddress' | 'firstName' | 'lastName' | 'title'
>;

export const schemaValidation = Yup.object().shape({
    emailAddress: Yup.string().label('Email').email(),
    firstName: Yup.string().transform(trimString).label('First Name').required(),
    lastName: Yup.string().transform(trimString).label('Last Name').required(),
});

interface IProps {
    autoFocus?: boolean;
    avatar: string | null | undefined;
    disabled: boolean;
    errors: { [key: string]: string };
    formData: IPrimaryEmployeeFormData;
    onChange: (formData: IPrimaryEmployeeFormData) => void;
    onChangeAvatar: React.Dispatch<React.SetStateAction<FileUpload | undefined>>;
}

const PrimaryEmployeeForm = ({
    autoFocus,
    avatar,
    disabled,
    errors,
    formData,
    onChange,
    onChangeAvatar,
}: IProps): JSX.Element => {
    return (
        <FieldSet className="grid grid-cols-6 gap-6" disabled={disabled}>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    autoFocus={autoFocus}
                    errorMessage={errors.firstName}
                    label="First Name"
                    value={formData.firstName}
                    onChange={firstName => onChange({ ...formData, firstName })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.lastName}
                    label="Last Name"
                    value={formData.lastName}
                    onChange={lastName => onChange({ ...formData, lastName })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.emailAddress}
                    label="Email"
                    value={formData.emailAddress || ''}
                    onChange={emailAddress => onChange({ ...formData, emailAddress })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3 relative">
                <TextInput
                    errorMessage={errors.title}
                    label="Title"
                    value={formData.title || ''}
                    onChange={title => onChange({ ...formData, title })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <AvatarInput errorMessage={errors.avatar} label="Photo" onChange={onChangeAvatar} src={avatar} />
            </div>
        </FieldSet>
    );
};

export default PrimaryEmployeeForm;
