import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export enum VerticalNavStepStatus {
    Complete,
    Current,
    Upcoming,
}

export interface IVerticalNavStep {
    description: string;
    disabled: boolean;
    name: string;
    onClick: () => void;
    onSave: () => Promise<boolean>;
    onValidate?: () => boolean;
    status: VerticalNavStepStatus;
}

interface IProps {
    steps: IVerticalNavStep[];
}

const commonProgressLineClasses = '-ml-px absolute mt-0.5 top-4 left-8 md:left-4 w-8 md:w-px h-px md:h-full';

const VerticalNav = ({ steps }: IProps): JSX.Element => {
    const handleClick = (step: IVerticalNavStep) => () => {
        if (!step.disabled) {
            step.onClick();
        }
    };

    return (
        <nav aria-label="Progress">
            <div className="md:hidden mb-2 text-center text-sm">
                <strong>{steps.find(s => s.status === VerticalNavStepStatus.Current)?.name}</strong>
                <br />
                {steps.find(s => s.status === VerticalNavStepStatus.Current)?.description}
            </div>
            <ol className="flex justify-center md:justify-start md:block space-x-8 md:space-x-0 overflow-hidden">
                {steps.map((step, stepIdx) => (
                    <li
                        key={step.name}
                        className={classNames(
                            stepIdx !== steps.length - 1 ? 'md:pb-10' : '',
                            step.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                            'relative'
                        )}
                        onClick={handleClick(step)}
                    >
                        {step.status === VerticalNavStepStatus.Complete ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        className={classNames(commonProgressLineClasses, 'bg-information')}
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex items-start group">
                                    <span className="h-9 flex items-center">
                                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-information rounded-full">
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                className="w-5 h-5 text-textDefault"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </span>
                                    <span className="hidden ml-4 min-w-0 md:flex flex-col">
                                        <span className="text-xs font-semibold tracking-wide uppercase text-textDisabled group-hover:text-textSubtle">
                                            {step.name}
                                        </span>
                                        <span className="text-sm">{step.description}</span>
                                    </span>
                                </div>
                            </>
                        ) : step.status === VerticalNavStepStatus.Current ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        className={classNames(commonProgressLineClasses, 'bg-background2')}
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex items-start group" aria-current="step">
                                    <span className="h-9 flex items-center" aria-hidden="true">
                                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-textDefault border-2 border-information rounded-full">
                                            <span className="h-2.5 w-2.5 bg-information rounded-full" />
                                        </span>
                                    </span>
                                    <span className="hidden ml-4 min-w-0 md:flex flex-col">
                                        <span className="text-xs font-semibold tracking-wide uppercase">
                                            {step.name}
                                        </span>
                                        <span className="text-sm">{step.description}</span>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        className={classNames(commonProgressLineClasses, 'bg-background2')}
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex items-start group">
                                    <span className="h-9 flex items-center" aria-hidden="true">
                                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-background1 border-2 border-background2 rounded-full group-hover:border-highlight">
                                            <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-background2" />
                                        </span>
                                    </span>
                                    <span className="hidden ml-4 min-w-0 md:flex flex-col">
                                        <span className="text-xs font-semibold tracking-wide uppercase text-textDisabled group-hover:text-textSubtle">
                                            {step.name}
                                        </span>
                                        <span className="text-sm">{step.description}</span>
                                    </span>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default VerticalNav;
