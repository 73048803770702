import { INote, INoteType, IUserEntitlementType } from '@api';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button, Can } from '~/components';
import { isEven, isOdd } from '~/utils/numberUtils';
import Note from './Note';
import NotesEmptyState from './NotesEmptyState';

interface IProps {
    businessName: string;
    notes: INote[];
    noteType: INoteType;
    onAddClick: () => void;
    onEditClick: (note: INote) => void;
}

const noteTypeTitle = {
    [INoteType.Objective]: 'Objectives',
    [INoteType.Observation]: 'Observations',
};

const Notes = ({ businessName, notes, noteType, onAddClick, onEditClick }: IProps): JSX.Element => {
    const sortedNotes = notes.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));

    return (
        <div className="mx-auto max-w-4xl p-6">
            <div className="text-center text-5xl font-extrabold pb-3">{noteTypeTitle[noteType]}</div>
            <div className="text-center text-2xl text-textSubtle mb-10 relative">
                {`${noteTypeTitle[noteType]} for ${businessName}`}
                {sortedNotes.length !== 0 && (
                    <Can hasEntitlement={IUserEntitlementType.NoteCreate}>
                        <div className="xl:inline-block xl:absolute xl:right-0 mt-2 xl:mt-0">
                            <Button color="primary" iconLeft={faPlusCircle} text="Add New" onClick={onAddClick} />
                        </div>
                    </Can>
                )}
            </div>
            {sortedNotes.length === 0 ? (
                <NotesEmptyState noteType={noteType} onAddClick={onAddClick} />
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                    <div>
                        {sortedNotes.map((n, i) =>
                            isEven(i) ? <Note key={n.id} note={n} onEditClick={() => onEditClick(n)} /> : null
                        )}
                    </div>
                    <div>
                        {sortedNotes.map((n, i) =>
                            isOdd(i) ? <Note key={n.id} note={n} onEditClick={() => onEditClick(n)} /> : null
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notes;
