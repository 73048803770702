import {
    combineThemeDescription,
    generateTheme,
    Theme,
    ThemeProvider as EnvThemeProvider,
} from '@envestnet/envreact-component-library';
import React, { useEffect } from 'react';
import { useStorageState } from 'react-storage-hooks';
import { transformInfoColorKey } from '~/utils/colorUtils';
import { getStorageKey } from '~/utils/storageKeys';

export enum ColorScheme {
    contrast = 'contrast',
    dark = 'dark',
    light = 'light',
}

const STORAGE_KEY = getStorageKey('colorScheme');

export const ThemeProvider = ({ children }: React.PropsWithChildren<unknown>): JSX.Element => {
    const [colorScheme, setColorScheme] = useStorageState<ColorScheme>(localStorage, STORAGE_KEY, ColorScheme.dark);
    const theme = generateTheme(combineThemeDescription(), colorScheme);

    useEffect(() => {
        const root = document.getElementById('root');
        root?.classList.remove(ColorScheme.contrast, ColorScheme.dark, ColorScheme.light);
        root?.classList.add(colorScheme);
    }, [colorScheme]);

    useEffect(() => {
        const onKeyUp = (e: KeyboardEvent) => {
            if (e && e.ctrlKey && e.key === 't') {
                switch (colorScheme) {
                    case ColorScheme.contrast:
                        setColorScheme(ColorScheme.dark);
                        break;
                    case ColorScheme.dark:
                        setColorScheme(ColorScheme.light);
                        break;
                    case ColorScheme.light:
                        setColorScheme(ColorScheme.contrast);
                        break;
                }
            }
        };
        document.addEventListener('keyup', onKeyUp, false);
        return () => document.removeEventListener('keyup', onKeyUp);
    }, [colorScheme, setColorScheme]);

    return (
        <EnvThemeProvider theme={theme}>
            <CssVariableSetter theme={theme} />
            {children}
        </EnvThemeProvider>
    );
};

interface CssVariableSetterProps {
    theme: Theme;
}

const CssVariableSetter = ({ theme }: CssVariableSetterProps): null => {
    useEffect(() => {
        const root = document.documentElement;
        Object.entries(theme.colors).forEach(([key, value]) => {
            root.style.setProperty(`--color-${key}`, value);
        });
        Object.entries(theme.infoColors).forEach(([key, value]) => {
            root.style.setProperty(`--color-info-${transformInfoColorKey(key)}`, value);
        });
    });

    return null;
};
