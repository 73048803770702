import { IInnerZoneLoginStatus } from '@api';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InnerZoneLoginForm from './InnerZoneLoginForm';

interface IProps {
    loginStatus: IInnerZoneLoginStatus;
}

const InnerZoneSettingLoginStatus = ({ loginStatus }: IProps): JSX.Element => {
    return (
        <>
            <div className="mt-6 mb-8">
                Evaluate Senior Management and other top employees on their communication and collaboration skills.
            </div>
            {loginStatus.isLoggedIn ? (
                <div className="text-center">
                    <FontAwesomeIcon className="text-positive" icon={faCheckCircle} size="5x" />
                    <div className="mt-4">{`Logged in as ${loginStatus.loggedInAs}`}</div>
                </div>
            ) : (
                <InnerZoneLoginForm className="mb-6" username={loginStatus.loggedInAs || ''} />
            )}
        </>
    );
};

export default InnerZoneSettingLoginStatus;
