import { IEmployee, IFamilyLimitedPartnershipCreateRequest } from '@api';
import { useState } from 'react';
import { Button } from '~/components';
import { useAddFamilyLimitedPartnership } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { useParams } from '~/routing';
import { lineStrokeWidth } from '../constants';
import { addDataLineHeight } from './constants';
import PartnershipForm from './PartnershipForm';

interface IProps {
    owner: IEmployee;
}

const AddPartnership = ({ owner }: IProps): JSX.Element => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const { businessId } = useParams<BusinessParams>();
    const addPartnership = useAddFamilyLimitedPartnership(businessId);
    const onSave = async (request: IFamilyLimitedPartnershipCreateRequest) => {
        await addPartnership.mutateAsync(request);
        setIsFormOpen(false);
    };

    return (
        <>
            <div className="flex flex-col items-center">
                <div>
                    <Button color="primary" text="Add Family Limited Partnership" onClick={() => setIsFormOpen(true)} />
                </div>
                <svg height={addDataLineHeight} width={lineStrokeWidth}>
                    <line
                        className="stroke-current text-highlight"
                        strokeWidth={lineStrokeWidth}
                        y1={0}
                        y2={addDataLineHeight}
                    />
                </svg>
            </div>
            <PartnershipForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} onSave={onSave} owner={owner} />
        </>
    );
};

export default AddPartnership;
