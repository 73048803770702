import { IBusiness, IVisionLinkContactRecord, IVisionLinkContactUserRecord } from '@api';
import moment from 'moment';
import { Button, ErrorPage, LoadingIndicator } from '~/components';
import { useVisionLinkContactRecord } from '~/hooks/visionLinkContact';
import visionLinkLogo from '../VisionLinkLogo.png';

interface IProps {
    business: IBusiness;
    setSubmitRequestAgain: () => void;
}

const personOrPeople = (records: IVisionLinkContactUserRecord[]) => (records.length > 1 ? 'people' : 'person');

const getMessage = (record: IVisionLinkContactRecord) => `On ${moment(record.emailSentAt).format(
    'MMMM DD'
)}, you submitted a request for a consultation with
VisionLink. A representative from VisionLink will reach out to the
${personOrPeople(record.usersToContact)} below.`;

const TestTakenHasContacted = ({ business, setSubmitRequestAgain }: IProps): JSX.Element => {
    const { data: record, isError, isLoading } = useVisionLinkContactRecord(business.id);

    if (isError) return <ErrorPage />;
    if (isLoading || !record) return <LoadingIndicator />;

    return (
        <div className="max-w-md my-auto py-2 px-4 sm:px-0 space-y-4 sm:space-y-8">
            <img className="h-10 sm:h-auto mx-auto white-logo" alt="VisionLink Logo" src={visionLinkLogo} />
            <p className="text-xl">Contact Request Submitted</p>
            <p>{getMessage(record)}</p>
            <div className="flex-grow">
                <div className="bg-background border border-background2 p-8 rounded-xl space-y-8">
                    {record.usersToContact.map(u => (
                        <div key={u.logId}>
                            <div>{u.displayName}</div>
                            <div>{u.emailAddress}</div>
                        </div>
                    ))}
                </div>
            </div>
            <Button color="primary" text="Submit Request Again" onClick={setSubmitRequestAgain} />
        </div>
    );
};

export default TestTakenHasContacted;
