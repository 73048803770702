import { IDocument, IDocumentList } from '@api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStorageKey } from '~/utils/storageKeys';

const STORAGE_KEY = getStorageKey('documentCenterDisplayType');

export enum DisplayType {
    Grid = 'Grid',
    Table = 'Table',
}

export enum ModalType {
    AddFiles,
    AddFolder,
    Delete,
    Move,
    None,
    Rename,
    Share,
}

type SelectedModal =
    | { type: ModalType.AddFiles; files?: FileList; folderId: string }
    | { type: ModalType.AddFolder }
    | { type: ModalType.Delete }
    | { type: ModalType.Move }
    | { type: ModalType.Rename }
    | { type: ModalType.Share }
    | { type: ModalType.None };

export interface DocumentCenterState {
    currentFolderId: string;
    rootFolder: IDocumentList | undefined;
    selectedDisplayType: DisplayType;
    selectedItems: Record<string, IDocument>;
    selectedModal: SelectedModal;
}

const storedDisplayType = localStorage.getItem(STORAGE_KEY);
const initialDisplayType = storedDisplayType ? (storedDisplayType as DisplayType) : DisplayType.Grid;

const initialState: DocumentCenterState = {
    currentFolderId: '',
    rootFolder: undefined,
    selectedDisplayType: initialDisplayType,
    selectedItems: {},
    selectedModal: { type: ModalType.None },
};

export const documentCenterSlice = createSlice({
    name: 'documentCenter',
    initialState,
    reducers: {
        clearSelectedItems: state => {
            state.selectedItems = {};
        },
        closeModal: state => {
            state.selectedModal = { type: ModalType.None };
        },
        setCurrentFolder: (state, action: PayloadAction<string>) => {
            state.currentFolderId = action.payload;
        },
        setRootFolder: (state, action: PayloadAction<IDocumentList>) => {
            state.rootFolder = action.payload;
        },
        setSelectedDisplayType: (state, action: PayloadAction<DisplayType>) => {
            window.localStorage.setItem(STORAGE_KEY, `${action.payload}`);
            state.selectedDisplayType = action.payload;
        },
        setSelectedModal: (state, action: PayloadAction<SelectedModal>) => {
            state.selectedModal = action.payload;
        },
        toggleSelectedItem: (state, action: PayloadAction<IDocument>) => {
            const item = action.payload;

            if (state.selectedItems[item.id] !== undefined) {
                delete state.selectedItems[item.id];
            } else {
                state.selectedItems[item.id] = item;
            }
        },
    },
});

export const {
    clearSelectedItems,
    closeModal,
    setCurrentFolder,
    setSelectedDisplayType,
    setRootFolder,
    setSelectedModal,
    toggleSelectedItem,
} = documentCenterSlice.actions;

export default documentCenterSlice.reducer;
