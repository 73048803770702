import { IBusiness, IBusinessPageType } from '@api';
import classNames from 'classnames';
import CommunicationCard from './communication/CommunicationCard';
import CompensationCard from './compensation/CompensationCard';
import TasksCard from './tasks/TasksCard';
import TeamValuesCard from './teamValues/TeamValuesCard';
import ValuationCard from './valuation/ValuationCard';

interface IProps {
    business: IBusiness;
}

const maxCards = 5;

const getCardCount = (pages: IBusinessPageType[]) => {
    let count = 2;
    if (pages.includes(IBusinessPageType.BonusRight)) count++;
    if (pages.includes(IBusinessPageType.InnerZone)) count++;
    if (pages.includes(IBusinessPageType.TeamValues)) count++;
    return count;
};

const Scorecard = ({ business }: IProps): JSX.Element => {
    const pages = business.pages || [];
    const cardCount = getCardCount(pages);
    const fullWidthValuation = cardCount < maxCards;
    const fullWidthTasks = cardCount < maxCards - 1;

    return (
        <div className="p-10">
            <div
                className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
                style={{ gridAutoRows: 'minmax(20rem, min-content)' }}
            >
                <div className={classNames('md:col-span-2', { 'xl:col-span-3': fullWidthValuation })}>
                    <ValuationCard businessId={business.id} fullWidth={fullWidthValuation} />
                </div>
                <div className={classNames({ 'md:col-span-2': fullWidthTasks })}>
                    <TasksCard businessId={business.id} fullWidth={fullWidthTasks} />
                </div>
                {pages.includes(IBusinessPageType.BonusRight) && <CompensationCard business={business} />}
                {pages.includes(IBusinessPageType.InnerZone) && <CommunicationCard business={business} />}
                {pages.includes(IBusinessPageType.TeamValues) && <TeamValuesCard business={business} />}
            </div>
        </div>
    );
};

export default Scorecard;
