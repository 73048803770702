import { IEmployee, IRecapitalizationEmployee } from '@api';

export enum CorporateStrategyType {
    Esop = 'Esop',
    FamilyLimitedPartnership = 'FamilyLimitedPartnership',
    Recapitalization = 'Recapitalization',
}

export interface IOwner {
    employeeId: string;
    equityPercentage: number;
    isVotingShares: boolean;
    requiresInvestment: boolean;
    votingSharesPercentage: number;
}

export type IOwnerFormItem = Pick<IRecapitalizationEmployee, 'employeeId' | 'percentage'>;

export const employeeToOwner = (
    employee: IEmployee,
    equityPercentageByEmployeeId: Record<string, number>,
    votingSharesPercentage: number
): IOwner => ({
    employeeId: employee.id,
    equityPercentage: equityPercentageByEmployeeId[employee.id],
    isVotingShares: employee.isOwner ?? false,
    requiresInvestment: false,
    votingSharesPercentage: employee.isOwner ? equityPercentageByEmployeeId[employee.id] / votingSharesPercentage : 0,
});

export const employeeToOwnerFormItem = (employee: IEmployee, equityPercentage: number): IOwnerFormItem => ({
    employeeId: employee.id,
    percentage: equityPercentage * 100,
});

export const recapitalizationEmployeeToOwner = (ownerFormItem: IRecapitalizationEmployee): IOwner => ({
    employeeId: ownerFormItem.employeeId,
    equityPercentage: ownerFormItem.percentage,
    isVotingShares: false,
    requiresInvestment: true,
    votingSharesPercentage: 0,
});

const roundToNearestHundredth = (x: number): number => Math.round(100 * x) / 100;

export const getAdjustedEquity = (targetOwner: IOwner, newEquity: number): number => {
    const percentageToSubstract = newEquity * targetOwner.equityPercentage;
    const adjustedEquity = targetOwner.equityPercentage - percentageToSubstract;
    return roundToNearestHundredth(adjustedEquity);
};

export const getAdjustedEquityForRecapitalization = (
    targetOwner: IOwner,
    newOwners: IRecapitalizationEmployee[]
): number => {
    const totalNewEquityPercentage = newOwners.reduce((total, o) => {
        total += o.percentage;
        return total;
    }, 0);
    const percentageToSubstract = totalNewEquityPercentage * targetOwner.equityPercentage;
    const adjustedEquity = targetOwner.equityPercentage - percentageToSubstract;
    return roundToNearestHundredth(adjustedEquity);
};
