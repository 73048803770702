import { IDocument } from '@api';
import classnames from 'classnames';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { SyntheticEvent } from 'react';
import { IconButton } from '~/components';
import { useDocumentDownload } from '~/hooks';
import { toggleSelectedItem } from '~/redux/documentCenter';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { getIconFromExtension } from './icons/iconMap';
import SelectionIcon from './icons/SelectionIcon';
import ItemDropdown from './ItemDropdown';
import { getColorFromIcon } from './utils/colorMap';

interface IProps {
    item: IDocument;
}

const ItemFileTableView = ({ item }: IProps): JSX.Element => {
    const icon = getIconFromExtension(item.name);
    const color = getColorFromIcon(icon.iconName);
    const getDocumentDownload = useDocumentDownload();
    const { selectedItems } = useAppSelector(s => s.documentCenter);
    const isSelected = selectedItems[item.id] !== undefined;
    const dispatch = useAppDispatch();

    const handleDownload = async (e: SyntheticEvent) => {
        try {
            e.stopPropagation();
            const documentDownload = await getDocumentDownload(item.id);
            saveAs(documentDownload.url, item.name);
        } catch (err: unknown) {
            dispatch(showErrorModal(`Failed to download ${item.name}. ${defaultErrorMessage}`));
        }
    };

    return (
        <tr
            className={classnames(
                'hover:bg-background2',
                { 'bg-background2': isSelected },
                { 'odd:bg-background1': !isSelected }
            )}
            onClick={() => dispatch(toggleSelectedItem(item))}
        >
            <td className="text-center">
                <SelectionIcon isSelected={isSelected} />
            </td>
            <td className="flex items-center py-2 px-6 max-w-screen-md">
                <IconButton
                    iconFixedWidth
                    className="flex items-center justify-center px-2"
                    icon={icon}
                    iconClassName={`${color} text-xl`}
                    onClick={handleDownload}
                />
                <button className="text-base truncate ml-2" color="link" onClick={handleDownload} title={item.name}>
                    <span className="max-w-full truncate">{item.name}</span>
                </button>
            </td>
            <td />
            <td className="align-middle py-2 px-6">{moment(item.hierarchyUpdatedAt).fromNow()}</td>
            <td className="text-right p-2">
                <ItemDropdown item={item} />
            </td>
        </tr>
    );
};

export default ItemFileTableView;
