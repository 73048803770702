import { IEmployee } from '@api';
import { TeamValueCard } from './TeamValueCard';

interface IHeadingProps {
    title: string;
}

const Heading = ({ title }: IHeadingProps): JSX.Element => (
    <div className="flex flex-row justify-between mb-3 mt-12">
        <h3 className="font-medium text-lg">{title}</h3>
    </div>
);

interface IProps {
    employees: IEmployee[];
    onClickEmployee: (employee: IEmployee) => void;
    renderValue: (employee: IEmployee) => React.ReactNode;
}

export const GridView = ({ employees, onClickEmployee, renderValue }: IProps): JSX.Element => {
    const owners = employees.filter(e => e.isOwner);
    const otherEmployees = employees.filter(e => !e.isOwner);

    return (
        <>
            {owners.length > 0 && (
                <>
                    <Heading title="Owners" />
                    <div className="grid grid-cols-1 gap-3 gap-y-7 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                        {owners.map(employee => (
                            <TeamValueCard
                                key={employee.id}
                                employee={employee}
                                onClick={onClickEmployee}
                                renderValue={renderValue}
                            />
                        ))}
                    </div>
                </>
            )}
            <>
                <Heading title="Employees" />
                <div className="grid grid-cols-1 gap-3 gap-y-7 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                    {otherEmployees.length === 0 && <div className="text-lg">No employees added yet.</div>}
                    {otherEmployees.map(employee => (
                        <TeamValueCard
                            key={employee.id}
                            employee={employee}
                            onClick={onClickEmployee}
                            renderValue={renderValue}
                        />
                    ))}
                </div>
            </>
        </>
    );
};
