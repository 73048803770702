import { useState } from 'react';
import { Button, Modal } from '~/components';
import ConnectToBonusRightForm from './ConnectToBonusRightForm';

interface IProps {
    businessId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ConnectToBonusRightModal = ({ businessId, open, setOpen }: IProps): JSX.Element => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    return (
        <Modal
            disableBackdropClickClose={isLoggingIn}
            maxWidth="sm"
            open={open}
            setOpen={setOpen}
            title="Link Accounts"
        >
            <ConnectToBonusRightForm
                businessId={businessId}
                className="px-6"
                isLoggingIn={isLoggingIn}
                onConnected={() => setOpen(false)}
                setIsLoggingIn={setIsLoggingIn}
            >
                <div className="space-x-3 flex justify-end">
                    <Button color="secondary" text="Cancel" onClick={() => setOpen(false)} />
                    <Button color="primary" text="Save" type="submit" />
                </div>
            </ConnectToBonusRightForm>
        </Modal>
    );
};

export default ConnectToBonusRightModal;
