import { BizEquityPlacesApi, IBizEquityPlace, IProblemDetails } from '@api';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBizEquityCurrentUserQueryKey } from './bizEquityUser';

const bizEquityApi = new BizEquityPlacesApi(undefined, config.api.baseUrl);

const getBizEquityPlaceSearchQueryKey = (...keyPieces: string[]): QueryKey =>
    getBizEquityCurrentUserQueryKey('placeSearch', ...keyPieces);

export interface Bounds {
    northEastLat: number;
    northEastLong: number;
    southWestLat: number;
    southWestLong: number;
}

export const useSearchBizEquityPlaces = (
    industryId: string,
    bounds?: Bounds
): UseQueryResult<IBizEquityPlace[], IProblemDetails> => {
    const { northEastLat, northEastLong, southWestLat, southWestLong } = bounds || {};
    return useQuery(
        getBizEquityPlaceSearchQueryKey(
            industryId,
            `${northEastLat},${northEastLong},${southWestLat},${southWestLong}`
        ),
        () =>
            bizEquityApi
                .searchBizEquityPlaces(industryId, northEastLat, northEastLong, southWestLat, southWestLong)
                .then(response => response.data),
        { enabled: !!industryId && !!bounds }
    );
};
