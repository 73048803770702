import { CircularProgress as EnvCircularProgress } from '@envestnet/envreact-component-library';
import { CircularProgressSizes } from '@envestnet/envreact-component-library/dist/components/enums';
import { faCircle, faCloudUpload, faDatabase } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { parse as papaParse, ParseResult } from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import { bool, date, number, object, string, ValidationError } from 'yup';
import { Button, ErrorPage, LoadingIndicator } from '~/components';
import { useEmployees } from '~/hooks';
import { setPendingUploads } from '~/redux/employeeUpload';
import { useAppDispatch } from '~/redux/hooks';
import { downloadCsv, IEmployeeWithOptionalId } from '~/utils/employeeUtils';
import { nameofFactory } from '~/utils/nameof';
import { stringToBoolean, stringToNumber } from '~/utils/stringUtils';
import { transformEmptyStringToNull } from '~/utils/yupUtils';
import { IEmployeeCsvRow, IEmployeeDataRow } from './model';
import { UploadPreview } from './UploadPreview';

interface IProps {
    businessId: string;
    onReset?: () => void;
}

const employeeSchemaValidation = object({
    dateOfBirth: date().typeError('must be a valid date').nullable().transform(transformEmptyStringToNull),
    department: string().nullable(),
    disabilityInsuranceCoverage: number()
        .nullable()
        .transform((_curr, orig: string) => stringToNumber(orig)),
    emailAddress: string().nullable().email(),
    firstName: string().required(),
    id: string().nullable(),
    isOwner: bool()
        .nullable()
        .transform(curr => stringToBoolean(curr)),
    lastName: string().required(),
    lifeInsuranceCoverage: number()
        .nullable()
        .transform((_curr, orig: string) => stringToNumber(orig)),
    salary: number()
        .nullable()
        .transform((_curr, orig: string) => stringToNumber(orig)),
    shares: number()
        .nullable()
        .transform((_curr, orig: string) => stringToNumber(orig)),
    title: string().nullable(),
});
const nameof = nameofFactory<IEmployeeCsvRow>();
const supportedHeaders = [
    nameof('firstName'),
    nameof('dateOfBirth'),
    nameof('department'),
    nameof('disabilityInsuranceCoverage'),
    nameof('emailAddress'),
    nameof('id'),
    nameof('isOwner'),
    nameof('lastName'),
    nameof('lifeInsuranceCoverage'),
    nameof('salary'),
    nameof('shares'),
    nameof('title'),
];

const isCsvFileType = (file: File) =>
    file.type === 'application/vnd.ms-excel' || file.type === 'text/csv' || file.name.endsWith('.csv');

const sampleEmployees: IEmployeeWithOptionalId[] = [
    {
        firstName: 'John',
        lastName: 'Smith',
        dateOfBirth: '1961-08-31',
        emailAddress: 'john.smith@example.com',
        disabilityInsuranceCoverage: 200000,
        department: 'Leadership',
        isOwner: true,
        lifeInsuranceCoverage: 200000,
        salary: 250000,
        shares: 1000,
        title: 'CEO',
    },
];

interface ICsvUploadPreviewProps {
    onReset: () => void;
    parseResult: ParseResult<IEmployeeCsvRow>;
}

const validateRow = (row: IEmployeeCsvRow): { [field: string]: string[] } => {
    const errors: { [field: string]: string[] } = {};
    try {
        employeeSchemaValidation.validateSync(row, { abortEarly: false });
    } catch (error: unknown) {
        if (ValidationError.isError(error)) {
            error.inner.forEach(err => (errors[err.path ?? ''] = err.errors));
        }
    }
    return errors;
};

const CsvUploadPreview = ({ parseResult, onReset }: ICsvUploadPreviewProps) => {
    const dispatch = useAppDispatch();
    const invalidHeaders = parseResult?.meta.fields?.filter(h => !supportedHeaders.includes(h));

    useEffect(() => {
        if (parseResult.data) {
            dispatch(
                setPendingUploads(
                    parseResult.data.map<IEmployeeDataRow>((data, index) => ({
                        data: { ...data, isCsv: true },
                        errors: validateRow(data),
                        rowNumber: index + 1,
                    }))
                )
            );
        }
    }, [dispatch, parseResult.data]);

    if (invalidHeaders && invalidHeaders?.length > 0) {
        return (
            <>
                <span className="fa-layers fa-fw fa-10x">
                    <FontAwesomeIcon className="text-caution" icon={faCircle} />
                    <FontAwesomeIcon className="text-background" icon={faDatabase} transform="shrink-8" />
                </span>
                <h3 className="text-lg">We've detected one or more unknown headers.</h3>
                <ul className="list-disc list-inside">
                    {invalidHeaders.map((h, i) => (
                        <li key={`header_${i}`}>"{h}"</li>
                    ))}
                </ul>
                <div>
                    For a list of supported headers download our{' '}
                    <Button
                        color="link"
                        onClick={() => downloadCsv(sampleEmployees, 'employees-template.csv')}
                        text="Employee CSV Template"
                    />
                    .
                </div>
                <div className="flex items-center">
                    <Button color="primary" text="Reset" onClick={onReset} />
                </div>
            </>
        );
    }
    return <UploadPreview onReset={onReset} />;
};

const EmployeesUpload = ({ businessId, onReset }: IProps): JSX.Element => {
    // Need to keep a count because of how the event bubbles to child elements.
    // Technique described at https://stackoverflow.com/a/21002544
    const [dragCounter, setDragCounter] = useState(0);
    const fileDialog = useRef<HTMLInputElement>(null);
    const [isCsvLoading, setIsCsvLoading] = useState(false);
    const [parseResult, setParseResult] = useState<ParseResult<IEmployeeCsvRow> | undefined>(undefined);
    const { isError, isLoading, data: employees } = useEmployees(businessId);

    if (isLoading) return <LoadingIndicator />;
    if (isError || !employees) return <ErrorPage />;

    const handleDrop = (fileList: FileList | null) => {
        const files = Array.from(fileList || []);
        if (files.length !== 1) {
            return;
        }

        const file = files[0];
        if (!isCsvFileType(file)) {
            return;
        }
        setIsCsvLoading(true);
        papaParse<IEmployeeCsvRow>(file, {
            complete: results => {
                setParseResult(results);
                setIsCsvLoading(false);
            },
            header: true,
            skipEmptyLines: true,
            transformHeader: (header, _index) => header.trim(),
        });
    };
    const handleReset = () => {
        if (fileDialog.current != null) {
            fileDialog.current.value = '';
        }
        setIsCsvLoading(false);
        setParseResult(undefined);
        onReset?.();
    };
    const hasEmployees = employees.length > 0;

    return (
        <div className="space-y-3">
            {parseResult ? (
                <>{parseResult && <CsvUploadPreview onReset={handleReset} parseResult={parseResult} />}</>
            ) : (
                <>
                    {!isCsvLoading && (
                        <div className="flex flex-col items-center max-w-60 space-y-6">
                            <div
                                className={classNames(
                                    'group w-full sm:w-1/2 p-8 rounded-lg border-4 border-dashed border-divider dark:border-background2 flex items-center cursor-pointer hover:bg-background2',
                                    {
                                        'bg-background2': dragCounter > 0,
                                    }
                                )}
                                onClick={() => fileDialog.current?.click()}
                                onDragEnter={() => setDragCounter(dragCounter + 1)}
                                onDragLeave={() => {
                                    setDragCounter(dragCounter - 1);
                                }}
                                onDragOver={e => e.preventDefault()}
                                onDrop={event => {
                                    event.preventDefault();
                                    setDragCounter(dragCounter - 1);
                                    handleDrop(event.dataTransfer?.files);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCloudUpload}
                                    size="4x"
                                    className={classNames('mr-5 group-hover:text-textDisabled', {
                                        'text-textDisabled': dragCounter > 0,
                                        'text-divider dark:text-background2': dragCounter === 0,
                                    })}
                                />
                                <span>
                                    Drag and drop to upload an Employee CSV file, or{' '}
                                    <Button
                                        className="text-textLink text-base"
                                        color="link"
                                        size="lg"
                                        text="select a file on disk"
                                    />
                                    .
                                </span>
                                <input
                                    accept=".csv"
                                    className="hidden"
                                    onChange={e => handleDrop(e.currentTarget.files)}
                                    ref={fileDialog}
                                    type="file"
                                />
                            </div>
                            {hasEmployees && (
                                <>
                                    <p>Looking to update existing employees?</p>
                                    <Button
                                        color="secondary"
                                        onClick={() => downloadCsv(employees, `employees-${businessId}.csv`)}
                                        text="Export Employees CSV"
                                    />
                                </>
                            )}
                            {!hasEmployees && (
                                <>
                                    <p>Looking for a template?</p>
                                    <Button
                                        color="secondary"
                                        onClick={() => downloadCsv(sampleEmployees, 'employees-template.csv')}
                                        text="Download Employees Template CSV"
                                    />
                                </>
                            )}
                        </div>
                    )}
                    {isCsvLoading && (
                        <div className="flex items-center">
                            <h3 className="text-lg">
                                Loading Employees from CSV file...
                                <EnvCircularProgress className="ml-3" size={CircularProgressSizes.Small} />
                            </h3>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default EmployeesUpload;
