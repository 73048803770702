import { IBusiness, IBusinessValuation, IEsop, IEsopUpdateRequest } from '@api';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Avatar, Button, EntitySelect, FormActions, PercentageInput, SlideOver, SlideOverSizeType } from '~/components';
import BusinessValuationNumber from '~/components/BusinessValuationNumber';
import { useEmployees } from '~/hooks';
import { getEmployeesById } from '~/utils/businessUtils';
import NameFormatter from '~/utils/nameFormatter';
import { transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    esop?: IEsop;
    isOpen: boolean;
    onClose: () => void;
    onSave: (employeeIds: string[], percentage: number) => void;
}

type IEsopFormData = Pick<IEsopUpdateRequest, 'employees'> & {
    percentage: number | null | undefined;
};

const getInitialValues = (esop: IEsop | undefined): IEsopFormData => ({
    employees: esop?.employees || [],
    percentage: esop?.percentage ? esop?.percentage * 100 : undefined,
});

const schemaValidation = Yup.object().shape({
    employees: Yup.array().min(1).required().label('Employees'),
    percentage: Yup.number()
        .positive()
        .max(100)
        .transform(transformEmptyStringToNull)
        .nullable()
        .required()
        .label('Percentage'),
});

const EsopForm = ({ business, businessValuation, esop, isOpen, onClose, onSave }: IProps): JSX.Element => {
    const { data: employees = [] } = useEmployees(business.id);
    const employeesById = useMemo(() => getEmployeesById(employees), [employees]);
    const [formData, setFormData] = useState<IEsopFormData>(getInitialValues(esop));
    const esopEmployees = formData.employees.map(id => employeesById[id]) || [];
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const nonOwners = employees.filter(e => (e.shares ?? 0) === 0);
    const onAddAll = () => {
        setFormData({ ...formData, employees: nonOwners.map(e => e.id) });
    };
    const onAddEmployee = (employeeId: string) => {
        setFormData({ ...formData, employees: [...formData.employees, employeeId] });
    };
    const onRemoveEmployee = (employeeId: string) => {
        setFormData({ ...formData, employees: formData.employees.filter(id => id !== employeeId) });
    };
    const onSaveInternal = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        const percentage = (formData.percentage ?? 0) / 100;
        onSave(formData.employees, percentage);
    };

    useEffect(() => {
        if (isOpen) {
            setFormData(getInitialValues(esop));
        }
    }, [employeesById, esop, isOpen]);

    return (
        <SlideOver
            isOpen={isOpen}
            size={SlideOverSizeType.lg}
            stickyFooter={
                <FormActions onCancel={onClose} onSave={onSaveInternal} setValidationErrors={setValidationErrors} />
            }
            title="Create ESOP"
            onClose={onClose}
        >
            <div>
                <div className="flex">
                    {business.avatar && <Avatar className="mr-3" size={16} src={business.avatar} />}
                    <div className="flex items-center">
                        <div className="p-2">
                            <div className="text-xl leading-7 font-semibold">{business.name}</div>
                            <p className="text-lg leading-6">
                                <BusinessValuationNumber businessValuation={businessValuation} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <PercentageInput
                    errorMessage={validationErrors.percentage}
                    label="What percentage of owner shares will the ESOP purchase?"
                    onChange={percentage => setFormData({ ...formData, percentage })}
                    value={formData.percentage ?? undefined}
                />
            </div>
            <table className="w-full">
                <thead>
                    <tr>
                        <th className="text-left pr-2 py-2 whitespace-nowrap border-b border-highlight">
                            Employees Participating in ESOP
                        </th>
                        <th className="border-b border-highlight text-right">
                            <Button color="primaryLink" text="Add All" onClick={onAddAll} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {esopEmployees.map(e => (
                        <tr key={e.id}>
                            <td className="pr-2 py-2 whitespace-nowrap">
                                <div>
                                    <Avatar className="mr-3" size={10} src={e.avatar || undefined} />
                                    <span>{NameFormatter.getName(e)}</span>
                                </div>
                            </td>
                            <td className="text-right">
                                <button onClick={() => onRemoveEmployee(e.id)}>
                                    <FontAwesomeIcon className="text-error" icon={faTrashAlt} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <EntitySelect
                errorMessage={validationErrors.employees}
                options={nonOwners
                    .filter(e => !esopEmployees.some(esopEmployee => esopEmployee.id === e.id))
                    .sort((a, b) => (b.salary || 0) - (a.salary || 0))
                    .map(e => ({
                        avatar: e.avatar || undefined,
                        label: NameFormatter.getLastNameFirst(e),
                        name: NameFormatter.getLastNameFirst(e),
                        description: e.title || undefined,
                        showAddButton: true,
                        value: e.id,
                    }))}
                placeholder="Add Employees"
                onEntityOptionSelected={e => onAddEmployee(e.value)}
            />
        </SlideOver>
    );
};

export default EsopForm;
