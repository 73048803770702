import { OktaAuth, urlParamsToObject } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { ReactNode, useEffect, useState } from 'react';
import config from '../config';
import AxiosConfigurationProvider from './AxiosConfigurationProvider';
import LoginRedirect from './LoginRedirect';
import MagicLinkWrapper from './MagicLinkWrapper';

interface IProps {
    children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { state } = urlParamsToObject(window.location.search) as any;
const oktaAuth = new OktaAuth({
    clientId: config.okta.clientId,
    issuer: `https://${config.okta.domain}/oauth2/${config.okta.authServer}`,
    redirectUri: `${window.location.origin}/`,
    scopes: ['openid', 'profile', 'email', config.okta.scope],
    pkce: true,
    state: state,
});

const restoreOriginalUri = async () => {
    // Intentionally empty.
};

const Okta = ({ children }: IProps) => {
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (oktaAuth.isLoginRedirect()) {
            try {
                oktaAuth.handleLoginRedirect().then(() => {
                    setInitialized(true);
                });
            } catch (e) {
                // log or display error details
            }
        } else {
            setInitialized(true);
        }
    }, []);

    return initialized ? (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    ) : null;
};

const AuthWrapper = ({ children }: IProps): JSX.Element => (
    <Okta>
        <MagicLinkWrapper>
            <LoginRedirect>
                <AxiosConfigurationProvider>{children}</AxiosConfigurationProvider>
            </LoginRedirect>
        </MagicLinkWrapper>
    </Okta>
);

export default AuthWrapper;
