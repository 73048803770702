import { IBusiness, IBusinessValuation, IEmployee } from '@api';
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '~/components';
import { getEquityAmount } from '~/utils/businessUtils';
import NameFormatter from '~/utils/nameFormatter';
import CurrencyTableCell from '../../CurrencyTableCell';
import { getExposure } from './getExposure';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    employees: IEmployee[];
}

const GroupLifeTable = ({ business, businessValuation, employees }: IProps): JSX.Element => {
    return (
        <div className="max-w-7xl">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Title</TableHeaderCell>
                        <TableHeaderCell>Salary</TableHeaderCell>
                        <TableHeaderCell>Value of Equity</TableHeaderCell>
                        <TableHeaderCell>Coverage</TableHeaderCell>
                        <TableHeaderCell>Exposure</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employees.map(employee => (
                        <TableRow key={employee.id}>
                            <TableCell>{NameFormatter.getLastNameFirst(employee)}</TableCell>
                            <TableCell>{employee.title}</TableCell>
                            <CurrencyTableCell value={employee.salary} />
                            <CurrencyTableCell value={getEquityAmount(business, businessValuation.value, employee)} />
                            <CurrencyTableCell value={employee.lifeInsuranceCoverage} />
                            <CurrencyTableCell
                                background="bg-background1"
                                value={getExposure(
                                    employee.lifeInsuranceCoverage,
                                    getEquityAmount(business, businessValuation.value, employee)
                                )}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default GroupLifeTable;
