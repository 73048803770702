import classNames from 'classnames';
import { ComponentPropsWithoutRef, ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

interface ITableProps extends ComponentPropsWithRef<'table'> {
    hasStickyFirstColumn?: boolean;
}

const TableComponent = (props: ITableProps, ref: ForwardedRef<HTMLTableElement>) => {
    const { className, hasStickyFirstColumn = true, ...rest } = props;

    return (
        <div
            className={classNames('max-w-full shadow border border-highlight sm:rounded-lg', {
                'overflow-x-auto': hasStickyFirstColumn,
            })}
        >
            <table
                className={classNames(
                    'min-w-full divide-y divide-background1',
                    { 'sticky-first-column': hasStickyFirstColumn },
                    className
                )}
                ref={ref}
                {...rest}
            ></table>
        </div>
    );
};

export const Table = forwardRef<HTMLTableElement, ITableProps>(TableComponent);

export const TableHead = ({ ...rest }: ComponentPropsWithoutRef<'thead'>): JSX.Element => {
    return <thead {...rest} />;
};

export const TableBody = ({ className, ...rest }: ComponentPropsWithoutRef<'tbody'>): JSX.Element => {
    return <tbody className={classNames('divide-y divide-background1', className)} {...rest} />;
};

export const TableFooter = ({ ...rest }: ComponentPropsWithoutRef<'tfoot'>): JSX.Element => {
    return <tfoot {...rest} />;
};

export const TableRow = ({ ...rest }: ComponentPropsWithoutRef<'tr'>): JSX.Element => {
    return <tr {...rest} />;
};

interface ITableCell {
    background?: string;
}

export const TableCell = ({
    background = 'bg-background',
    className,
    ...rest
}: ComponentPropsWithoutRef<'td'> & ITableCell): JSX.Element => {
    return <td className={classNames('px-6 py-4 whitespace-nowrap', background, className)} {...rest} />;
};

export const TableHeaderCell = ({
    background = 'bg-background1',
    className,
    ...rest
}: ComponentPropsWithoutRef<'th'> & ITableCell): JSX.Element => {
    return (
        <th
            scope="col"
            className={classNames(
                'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                background,
                className
            )}
            {...rest}
        />
    );
};
