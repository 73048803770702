import { IBizEquityBusinessCreateRequest } from '@api';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button, FieldSet, TextInput } from '~/components';
import { yupToFormErrors } from '~/utils/yupUtils';
import IndustrySelect from './IndustrySelect';

interface IProps {
    businessName: string;
    isCreatingBusiness: boolean;
    onCreateBizEquityBusiness: (createRequest: IBizEquityBusinessCreateRequest) => void;
}

type ICreateBizEquityBusinessFormFormData = Strings<IBizEquityBusinessCreateRequest>;

export const schemaValidation = Yup.object().shape({
    industry: Yup.string().required(),
    zip: Yup.string()
        .required()
        .test('len', 'zip must be exactly 5 characters', val => (val?.length ?? 0) === 5)
        .test('dig', 'zip must be all numbers', val => /^\d+$/.test(val ?? '')),
});

const getInitialValues = (): ICreateBizEquityBusinessFormFormData => ({
    industry: '',
    zip: '',
});

const CreateBusinessForm = ({ businessName, isCreatingBusiness, onCreateBizEquityBusiness }: IProps): JSX.Element => {
    const [formData, setFormData] = useState<ICreateBizEquityBusinessFormFormData>(getInitialValues());
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const onCreateBusiness = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        onCreateBizEquityBusiness(formData);
    };

    return (
        <form
            className="w-full"
            onSubmit={e => {
                e.preventDefault();
                onCreateBusiness();
            }}
        >
            <FieldSet disabled={isCreatingBusiness}>
                <div className="space-y-6">
                    <TextInput disabled label="Name" value={businessName} onChange={() => null} />
                    <TextInput
                        errorMessage={validationErrors.zip}
                        label="Zip"
                        maxLength={5}
                        value={formData.zip}
                        onChange={zip => setFormData({ ...formData, zip })}
                    />
                    <div>
                        <IndustrySelect
                            errorMessage={validationErrors.industry}
                            industryId={formData.industry}
                            onChange={industry => setFormData({ ...formData, industry: industry || '' })}
                        />
                    </div>
                    <Button fullWidth color="primary" text="Create" type="submit" />
                </div>
            </FieldSet>
        </form>
    );
};

export default CreateBusinessForm;
