import { IAdvisorFirmSummary } from '@api';
import { VerticalTabs as Tabs, VerticalTabs } from '~/components';
import visionLinkLogo from '../../VisionLinkLogo.png';
import DisconnectButton from './DisconnectButton';
import {
    AssessingHRReadiness,
    BuildingPayGradeStructure,
    DesigningIncentivePlans,
    EnhancingExecutiveBenefits,
    ValidatingMarketCompetitiveness,
} from './index';

interface IProps {
    advisorFirm: IAdvisorFirmSummary;
    businessId: string;
    isConnected: boolean;
}

const ServicesSection = ({ advisorFirm, businessId, isConnected }: IProps): JSX.Element => {
    const ValidatingMarketCompetitivenessTab = ValidatingMarketCompetitiveness(advisorFirm);
    const BuildingPayGradeStructureTab = BuildingPayGradeStructure(advisorFirm);
    const DesigningIncentivePlansTab = DesigningIncentivePlans(advisorFirm);
    const EnhancingExecutiveBenefitsTab = EnhancingExecutiveBenefits(advisorFirm);
    const AssessingHRReadinessTab = AssessingHRReadiness(advisorFirm);

    return (
        <section className="order-4 lg:col-start-2 lg:col-span-2 h-full">
            <Tabs activeTabId={EnhancingExecutiveBenefitsTab.Id}>
                {EnhancingExecutiveBenefitsTab.Tab}
                {EnhancingExecutiveBenefitsTab.Panel}
                {BuildingPayGradeStructureTab.Tab}
                {BuildingPayGradeStructureTab.Panel}
                {DesigningIncentivePlansTab.Tab}
                {DesigningIncentivePlansTab.Panel}
                {ValidatingMarketCompetitivenessTab.Tab}
                {ValidatingMarketCompetitivenessTab.Panel}
                {AssessingHRReadinessTab.Tab}
                {AssessingHRReadinessTab.Panel}
                <VerticalTabs.TabFlowElement className="flex flex-col items-center pt-16 pb-12 space-y-4">
                    <img alt="Vision Link Logo" className="h-10 white-logo" src={visionLinkLogo} />
                    {isConnected && (
                        <div>
                            <DisconnectButton businessId={businessId} />
                        </div>
                    )}
                </VerticalTabs.TabFlowElement>
            </Tabs>
        </section>
    );
};

export default ServicesSection;
