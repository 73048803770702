import Card from '../Card';
import Header from '../Header';
import TasksCardContent from './TasksCardContent';

interface IProps {
    businessId: string;
    fullWidth?: boolean;
}

const TasksCard = ({ businessId, fullWidth }: IProps): JSX.Element => {
    return (
        <Card className="h-full" padding>
            <Header text="Tasks" />
            <TasksCardContent businessId={businessId} fullWidth={fullWidth} />
        </Card>
    );
};

export default TasksCard;
