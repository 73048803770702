import { IEmployee } from '@api';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { Button, EmptyStateIcon, ErrorPage, LoadingIndicator } from '~/components';
import { useEmployeeFamilies } from '~/hooks';
import { Link, useLocation } from '~/routing';
import { TeamValueCard } from '../TeamValueCard';

interface IProps {
    businessId: string;
    employees: IEmployee[];
    onEditEmployee: (employee: IEmployee) => void;
}

const Family = ({ businessId, employees, onEditEmployee }: IProps): JSX.Element => {
    const teamValuesState = { backUrl: useLocation().pathname, label: 'Team Values' };
    const { data: families, isError, isLoading } = useEmployeeFamilies(businessId);
    const employeesById = employees.reduce((result, e) => {
        result[e.id] = e;
        return result;
    }, {} as Record<string, IEmployee>);

    if (isLoading) return <LoadingIndicator />;
    if (isError || !families) return <ErrorPage />;

    return (
        <>
            <div className="border-t border-background2 pt-3"></div>
            {families.length === 0 ? (
                <div className="mt-12 p-3 p-md-5 flex flex-col items-center text-center">
                    <EmptyStateIcon icon={faUsers} />
                    <p className="my-4 text-lg">No families added yet.</p>
                    <Link to={{ pathname: `/${businessId}/Edit/Employees`, state: teamValuesState }}>
                        <Button color="primary" text="Edit Employees" />
                    </Link>
                </div>
            ) : (
                families.map(family => (
                    <div className="mb-8 mt-12" key={family.employeeIds[0]}>
                        <h3 className="text-lg font-medium mb-3">{family.name}</h3>
                        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                            {family.employeeIds.map(id => (
                                <TeamValueCard
                                    key={id}
                                    employee={employeesById[id]}
                                    onClick={() => onEditEmployee(employeesById[id])}
                                />
                            ))}
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

export default Family;
