import { BizEquitySettingsApi, IBizEquityWhiteLabelUpdateRequest, IProblemDetails } from '@api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import config from '~/config';
import { getBizEquityLoginStatusQueryKey } from './bizEquityUser';

const bizEquitySettingsApi = new BizEquitySettingsApi(undefined, config.api.baseUrl);

export const useUpdateBizEquityWhiteLabel = (): UseMutationResult<
    void,
    IProblemDetails,
    IBizEquityWhiteLabelUpdateRequest
> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBizEquityWhiteLabelUpdateRequest>(
        request => bizEquitySettingsApi.updateWhiteLabel(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBizEquityLoginStatusQueryKey());
            },
        }
    );

    return mutation;
};
