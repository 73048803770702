import { IMe, IUserEntitlementType, IUserRoleType } from '@api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook,
    faArrowLeft,
    faBuilding,
    faKey,
    faPresentation,
    faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useBusiness, useCurrentUser } from '~/hooks';
import { Link, NavLink, Redirect, Route, Switch, useLocation, useParams } from '~/routing';
import { BusinessParams } from '..';
import { ILocationState } from '../../ILocationState';
import BusinessPageHeader from '../header/BusinessPageHeader';
import BusinessInfo from './businessInfo/BusinessInfo';
import EmployeeList from './employeeList/EmployeeList';
import PortalAccess from './portalAccess/PortalAccess';
import PresentationSettings from './presentationSettings/PresentationSettings';
import PrimaryEmployee from './primaryContact/PrimaryEmployee';

export enum IEditPageType {
    Employees = 'Employees',
    Info = 'Info',
    PresentationSettings = 'PresentationSettings',
    PrimaryContact = 'PrimaryContact',
    PortalAccess = 'PortalAccess',
}

const pageDisplay: Record<IEditPageType, string> = {
    Employees: 'Employee List',
    Info: 'Business Info',
    PresentationSettings: 'Presentation Settings',
    PrimaryContact: 'Primary Contact',
    PortalAccess: 'Portal Access',
};

const pageIcon: Record<IEditPageType, IconProp> = {
    Employees: faAddressBook,
    Info: faBuilding,
    PresentationSettings: faPresentation,
    PrimaryContact: faUserTie,
    PortalAccess: faKey,
};

const getPages = (currentUser: IMe): IEditPageType[] => {
    if (currentUser.role === IUserRoleType.BusinessOwner) {
        return [IEditPageType.Info, IEditPageType.Employees, IEditPageType.PrimaryContact];
    }
    return [
        IEditPageType.Info,
        IEditPageType.Employees,
        IEditPageType.PrimaryContact,
        IEditPageType.PresentationSettings,
        IEditPageType.PortalAccess,
    ];
};

const hasEntitlement = (currentUser: IMe, entitlement: IUserEntitlementType) =>
    currentUser.entitlements.some(e => e === entitlement);

const filterEntitlements = (page: IEditPageType, currentUser: IMe) => {
    if (page === IEditPageType.PortalAccess) {
        return hasEntitlement(currentUser, IUserEntitlementType.BusinessOwnerRead);
    }
    return true;
};

const BusinessEdit = (): JSX.Element | null => {
    const { businessId } = useParams<BusinessParams>();
    const businessQuery = useBusiness(businessId);
    const currentUserQuery = useCurrentUser();
    const [businessSettingsLocationState, setBusinessSettingsLocationState] = useState<ILocationState>();
    const { state: locationState } = useLocation<ILocationState>();
    const isError = isTrueForAnyQuery('isError', businessQuery, currentUserQuery);
    const isLoading = isTrueForAnyQuery('isLoading', businessQuery, currentUserQuery);

    useEffect(() => {
        if (locationState) {
            setBusinessSettingsLocationState(locationState);
        }
    }, [locationState]);

    if (isLoading) return <LoadingIndicator />;
    if (isError || !businessQuery.data || !currentUserQuery.data) return <ErrorPage />;

    const { data: business } = businessQuery;
    const { data: currentUser } = currentUserQuery;
    const pages = getPages(currentUser).filter(p => filterEntitlements(p, currentUser));

    return (
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <BusinessPageHeader business={business} />
            <main className="flex h-full max-h-full overflow-y-auto focus:outline-none" tabIndex={0}>
                <div className="max-w-7xl w-full mx-auto sm:px-6 lg:px-8">
                    <div className="mb-12 pt-10 pb-6 bg-background border-b border-background2">
                        <Link className="text-sm text-textLink" to={businessSettingsLocationState?.backUrl ?? '/'}>
                            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                            Back to {businessSettingsLocationState?.label ?? 'Presentation'}
                        </Link>
                        <h2 className="text-2xl leading-none self-center mt-2">{business.name} Settings</h2>
                    </div>
                    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                            <nav className="space-y-1">
                                {pages.map(p => (
                                    <NavLink
                                        key={p}
                                        activeClassName="bg-background1 text-textDefault shadow-sm"
                                        className="group flex items-center px-2 py-2 rounded-md hover:bg-background1 hover:shadow-sm"
                                        to={`/${businessId}/Edit/${p}`}
                                    >
                                        <FontAwesomeIcon fixedWidth className="mr-4" icon={pageIcon[p]} />
                                        {pageDisplay[p]}
                                    </NavLink>
                                ))}
                            </nav>
                        </aside>
                        <div className="sm:px-6 lg:px-0 sm:pb-6 lg:pb-8 lg:col-span-9">
                            <Switch>
                                {pages.includes(IEditPageType.Employees) && (
                                    <Route path={`/:businessId/Edit/${IEditPageType.Employees}`}>
                                        <EmployeeList businessId={businessId} />
                                    </Route>
                                )}
                                {pages.includes(IEditPageType.Info) && (
                                    <Route path={`/:businessId/Edit/${IEditPageType.Info}`}>
                                        <BusinessInfo business={business} />
                                    </Route>
                                )}
                                {pages.includes(IEditPageType.PrimaryContact) && (
                                    <Route path={`/:businessId/Edit/${IEditPageType.PrimaryContact}`}>
                                        <PrimaryEmployee business={business} />
                                    </Route>
                                )}
                                {pages.includes(IEditPageType.PresentationSettings) && (
                                    <Route path={`/:businessId/Edit/${IEditPageType.PresentationSettings}`}>
                                        <PresentationSettings business={business} />
                                    </Route>
                                )}
                                {pages.includes(IEditPageType.PortalAccess) && (
                                    <Route path={`/:businessId/Edit/${IEditPageType.PortalAccess}`}>
                                        <PortalAccess business={business} />
                                    </Route>
                                )}
                                <Redirect to={`/:businessId/Edit/${pages[0]}`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default BusinessEdit;
