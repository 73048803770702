import { IBusiness } from '@api';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { useStorageState } from 'react-storage-hooks';
import { Button, EmptyStateIcon, ErrorPage, LoadingIndicator, RadioGroup } from '~/components';
import { isTrueForAnyQuery, useBusinessValuation, useEmployees } from '~/hooks';
import { ILocationState } from '~/pages/ILocationState';
import { Link, useLocation } from '~/routing';
import { getStorageKey } from '~/utils/storageKeys';
import { IEditPageType } from '../../edit';
import Esop from './esop/Esop';
import FamilyLimitedPartnership from './familyLimitedPartnership/FamilyLimitedPartnership';
import { CorporateStrategyType } from './model/models';
import Recapitalization from './recapitalization/Recapitalization';
import SharesWarning from './SharesWarning';

interface IProps {
    business: IBusiness;
}

const strategyTypeLabel: Record<CorporateStrategyType, string> = {
    Esop: 'ESOP',
    FamilyLimitedPartnership: 'Family Limited Partnership',
    Recapitalization: 'Recapitalization',
};

const CorporateStrategies = (props: IProps): JSX.Element => {
    const { business } = props;
    const businessValuationQuery = useBusinessValuation(business.id);
    const employeesQuery = useEmployees(business.id);
    const [selectedStrategy, setSelectedStrategy] = useStorageState<CorporateStrategyType>(
        localStorage,
        getStorageKey(`${business.id}.corporateStrategyType`),
        CorporateStrategyType.Recapitalization
    );
    const businessSettingsState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Corporate Strategies',
    };

    const isError = isTrueForAnyQuery('isError', businessValuationQuery, employeesQuery);
    const isLoading = isTrueForAnyQuery('isLoading', businessValuationQuery, employeesQuery);
    if (isError || !businessValuationQuery.data) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    const { data: businessValuation } = businessValuationQuery;
    const { data: employees = [] } = employeesQuery;
    const owners = employees.filter(e => e.isOwner).sort(e => (e.id === business.primaryEmployeeId ? -1 : 1));
    if (owners.length === 0) {
        return (
            <div className="flex flex-col h-full p-10">
                <h2 className="text-xl">Corporate Strategies</h2>
                <div className="p-3 p-md-5 flex flex-col items-center text-center">
                    <EmptyStateIcon icon={faUsers} />
                    <p className="my-4 text-lg">No owners added yet.</p>
                    <Link
                        to={{
                            pathname: `/${business.id}/Edit/${IEditPageType.Employees}`,
                            state: businessSettingsState,
                        }}
                    >
                        <Button color="primary" text="Add Owners" />
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-full p-10">
            <div className="flex flex-row justify-between">
                <h2 className="mb-5 text-xl">Corporate Strategies</h2>
                <SharesWarning business={business} employees={employees} />
            </div>
            <div className="mb-6">
                <RadioGroup
                    value={selectedStrategy.toString()}
                    options={[
                        CorporateStrategyType.Recapitalization,
                        CorporateStrategyType.Esop,
                        CorporateStrategyType.FamilyLimitedPartnership,
                    ].map(s => ({
                        label: strategyTypeLabel[s],
                        value: s,
                    }))}
                    onChange={e => setSelectedStrategy(e as CorporateStrategyType)}
                />
            </div>
            {
                {
                    [CorporateStrategyType.Esop]: <Esop businessValuation={businessValuation} {...props} />,
                    [CorporateStrategyType.FamilyLimitedPartnership]: (
                        <FamilyLimitedPartnership businessValuation={businessValuation} {...props} />
                    ),
                    [CorporateStrategyType.Recapitalization]: (
                        <Recapitalization businessValuation={businessValuation} {...props} />
                    ),
                }[selectedStrategy]
            }
        </div>
    );
};

export default CorporateStrategies;
