import { Popover } from '@envestnet/envreact-component-library';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';

const InfoTooltip = ({ children }: PropsWithChildren<React.ReactNode>): JSX.Element => {
    return (
        <Popover
            backgroundColor="var(--color-bg-700)"
            content={children}
            className="w-56 text-center inline-flex"
            placement="top"
            style={{ zIndex: 1500 }}
            triggers={['click', 'hover', 'focus']}
        >
            <FontAwesomeIcon className="mr-2" icon={faInfoCircle} tabIndex={0} />
        </Popover>
    );
};

export default InfoTooltip;
