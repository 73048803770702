import { IBusiness, IEmployee } from '@api';
import { ProgressChart } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import { useBonusRightTotalRewardsAssessment } from '~/hooks/bonusRightEmployeeRewards';
import { getBonusRightSampleEmployeeRewards } from '~/sampleData';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { toEmployeeRewardsSummaryStatViewModels, toEmployeeRewardsViewModel } from '../../bonusRight/employeeRewards';
import { colors } from '../../bonusRight/employeeRewards/EmployeeRewardsStats';

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
}

const CompensationChart = ({ business, employees }: IProps): JSX.Element => {
    const {
        isError,
        isLoading,
        data: tra = getBonusRightSampleEmployeeRewards(employees, business.isSampleCase ?? false),
    } = useBonusRightTotalRewardsAssessment(business.id, !business.isSampleCase);

    if (isLoading) return <div>Loading</div>;
    if (isError) return <div>Error</div>;

    const employeeRewardsViewModelList = tra.map(toEmployeeRewardsViewModel);
    const stats = toEmployeeRewardsSummaryStatViewModels(employeeRewardsViewModelList).sort(
        (a, b) => b.value - a.value
    );
    const points = stats.map<IChartPoint>((r, i) => ({
        name: r.shortName || r.name,
        color: colors[i],
        value: r.value,
    }));
    const total = points.reduce((acc, current) => (acc += current.value), 0);

    return (
        <div>
            <ProgressChart hideLabels points={points} />
            <div className="mt-8">
                {points.map(p => (
                    <div className="flex justify-between items-center py-4 border-t border-background2" key={p.name}>
                        <div className="flex items-center truncate mr-2">
                            <div className={`bg-${p.color} flex-shrink-0 rounded-full self-center h-2 w-2 mr-4`} />
                            <div className="truncate">{p.name}</div>
                        </div>
                        <div>{CurrencyFormatter.format(p.value)}</div>
                    </div>
                ))}
                <div className="flex justify-between items-center py-4 border-t border-background2 font-bold">
                    <div className="truncate ml-6 mr-2">Total Compensation</div>
                    <div>{CurrencyFormatter.format(total)}</div>
                </div>
            </div>
        </div>
    );
};

export default CompensationChart;
