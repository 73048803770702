import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { useHistory } from '~/routing';
import { Button } from '.';

interface IProps {
    className?: string;
    relative?: boolean;
}

const BackLink = ({ relative, className }: IProps): JSX.Element | null => {
    const history = useHistory();
    if (!history.length) {
        return null;
    }

    return (
        <Button
            color="primaryLink"
            className={classNames(className, { 'absolute left-0': relative })}
            iconLeft={faArrowAltCircleLeft}
            text="Go Back"
            onClick={history.goBack}
        />
    );
};

export { BackLink };
