import { PercentageInput as EnvPercentageInput } from '@envestnet/envreact-component-library';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps, Label } from './Label';

interface IProps
    extends ILabelProps,
        Omit<
            ComponentPropsWithoutRef<typeof EnvPercentageInput>,
            'className' | 'label' | 'maxPercentage' | 'nFractional' | 'percentage'
        > {
    className?: string;
    precision?: number;
}

const PercentageInput = ({ className, label, labelClassName, precision = 0, value, ...rest }: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return (
        <div className={className}>
            <Label label={label} labelClassName={labelClassName} />
            <EnvPercentageInput
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                autoComplete="off"
                alignText="right"
                allowEmpty={true}
                nFractional={precision}
                percentage={value ?? undefined}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            />
        </div>
    );
};

export { PercentageInput };
