import { IBusiness, IBusinessValuation, IRecapitalizationUpdateRequest } from '@api';
import { useState } from 'react';
import { Avatar, Button, ErrorPage, LoadingIndicator } from '~/components';
import BusinessValuationNumber from '~/components/BusinessValuationNumber';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import {
    useAddRecapitalization,
    useDeleteRecapitalization,
    useRecapitalizationForBusiness,
    useUpdateRecapitalization,
} from '~/hooks/recapitalizations';
import { getEmployeesById } from '~/utils/businessUtils';
import NameFormatter from '~/utils/nameFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import EntityCard from '../../successionPlan/EntityCard';
import { entityCardWidth } from '../constants';
import CurrentOwnersAndLines from '../CurrentOwnersAndLines';
import getCorporateStrategyOwners from '../model/getCorporateStrategyOwners';
import ResetButton from '../ResetButton';
import { getAdjustedEquityForRecapitalization, recapitalizationEmployeeToOwner } from '../model/models';
import RecapitalizationForm from './RecapitalizationForm';
import RecapitalizedOwnersAndLines from './RecapitalizedOwnersAndLines';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const Recapitalization = (props: IProps): JSX.Element => {
    const { business, businessValuation } = props;
    const employeesQuery = useEmployees(business.id);
    const recapitalizationQuery = useRecapitalizationForBusiness(business.id);
    const addRecapitalization = useAddRecapitalization(business.id);
    const deleteRecapitalization = useDeleteRecapitalization(business.id);
    const updateRecapitalization = useUpdateRecapitalization(business.id);
    const [isFormOpen, setIsFormOpen] = useState(false);

    const isError = isTrueForAnyQuery('isError', employeesQuery, recapitalizationQuery);
    const isLoading = isTrueForAnyQuery('isLoading', employeesQuery, recapitalizationQuery);
    if (isError && recapitalizationQuery.error?.response?.status !== 404) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    const { data: employees = [] } = employeesQuery;
    const employeesById = getEmployeesById(employees);
    const owners = getCorporateStrategyOwners(business, employees);
    const { data: recapitalization } = recapitalizationQuery;
    const updatedOwners = recapitalization
        ? [
              ...owners.map(o => ({
                  ...o,
                  equityPercentage: getAdjustedEquityForRecapitalization(o, recapitalization.employees),
              })),
              ...recapitalization.employees.map(recapitalizationEmployeeToOwner),
          ]
        : [];
    const onSave = async (request: IRecapitalizationUpdateRequest) => {
        if (recapitalization) {
            await updateRecapitalization.mutateAsync({ request, recapitalizationId: recapitalization.id });
        } else {
            await addRecapitalization.mutateAsync({ ...request, businessId: business.id });
        }
        setIsFormOpen(false);
    };

    return (
        <>
            <div className="mx-auto max-w-full flex flex-col">
                <CurrentOwnersAndLines ownerIds={owners.map(o => o.employeeId)} showLines>
                    {owners.map(o => {
                        const employee = employeesById[o.employeeId];
                        return (
                            <EntityCard
                                key={o.employeeId}
                                avatar={employee.avatar || undefined}
                                className={entityCardWidth}
                                description={employee.title || undefined}
                                name={NameFormatter.getName(employee)}
                                value={formatPercentage(o.equityPercentage)}
                            />
                        );
                    })}
                </CurrentOwnersAndLines>
                <div className="flex justify-center">
                    <button
                        className="text-left bg-background1 px-5 py-4 rounded-md hover:bg-background2 shadow"
                        onClick={() => setIsFormOpen(true)}
                    >
                        <div className="flex">
                            {business.avatar && <Avatar className="mr-3" size={16} src={business.avatar} />}
                            <div className="flex items-center">
                                <div className="p-2">
                                    <div className="text-xl leading-7 font-semibold">{business.name}</div>
                                    <p className="text-lg leading-6 font-normal">
                                        <BusinessValuationNumber businessValuation={businessValuation} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </button>
                </div>
                {updatedOwners.length ? (
                    <RecapitalizedOwnersAndLines
                        employeesById={employeesById}
                        onClick={() => setIsFormOpen(true)}
                        owners={updatedOwners}
                        {...props}
                    />
                ) : (
                    <div className="flex justify-center mt-4">
                        <Button color="primary" text="Recapitalize" onClick={() => setIsFormOpen(true)} />
                    </div>
                )}
            </div>
            <RecapitalizationForm
                isOpen={isFormOpen}
                owners={owners}
                onClose={() => setIsFormOpen(false)}
                onSave={onSave}
                recapitalization={recapitalization}
                {...props}
            />
            {recapitalization && (
                <ResetButton
                    modelTitle="Recapitalization"
                    onConfirm={() => deleteRecapitalization.mutateAsync(recapitalization.id)}
                />
            )}
        </>
    );
};

export default Recapitalization;
