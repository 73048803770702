import {
    IBusiness,
    IBusinessValuation,
    IEmployee,
    IFamilyLimitedPartnership,
    IFamilyLimitedPartnershipCreateRequest,
} from '@api';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Avatar } from '~/components';
import { useBoundingClientRect, useDeleteFamilyLimitedPartnership, useUpdateFamilyLimitedPartnership } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { getEquityAmount } from '~/utils/businessUtils';
import CurrencyFormatter from '~/utils/currencyFormatter';
import NumberFormatter from '~/utils/numberFormatter';
import DownArrow from '../../successionPlan/DownArrow';
import { lineStrokeWidth } from '../constants';
import { generalPartnerColor, limitedPartnerColor } from './constants';
import PartnershipForm from './PartnershipForm';

const transferLineGap = 20;
const lineHeight = 80;
interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    owner: IEmployee;
    partnership: IFamilyLimitedPartnership;
}

const PartnershipCard = ({ business, businessValuation, owner, partnership }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const [cardContainerRect, cardContainerRef] = useBoundingClientRect();
    const containerWidth = cardContainerRect?.width || 0;
    const [isFormOpen, setIsFormOpen] = useState(false);
    const updatePartnership = useUpdateFamilyLimitedPartnership(business.id, partnership.id);
    const deletePartnership = useDeleteFamilyLimitedPartnership(business.id);
    const equityAmount = getEquityAmount(business, businessValuation.value, partnership);

    const onSave = async (request: IFamilyLimitedPartnershipCreateRequest) => {
        await updatePartnership.mutateAsync(request);
        setIsFormOpen(false);
    };

    const onDelete = async () => {
        try {
            await deletePartnership.mutateAsync(partnership.id);
        } catch {
            showError();
        }
    };

    return (
        <>
            <div ref={cardContainerRef}>
                <button
                    className="text-lg p-5 w-96 flex flex-col items-stretch bg-background1 hover:bg-background2 rounded-md shadow"
                    onClick={() => setIsFormOpen(true)}
                >
                    <div className="flex items-center mb-4">
                        <Avatar src={business.avatar || ''} size={12} />
                        <span className="font-bold ml-3 leading-tight text-left">
                            {owner.lastName} Family Limited Partnership
                        </span>
                    </div>
                    <div className="flex justify-between my-2">
                        <div className="flex items-center pl-2">
                            <FontAwesomeIcon icon={faCircle} size="sm" className={`${generalPartnerColor} mr-2`} />
                            <span className="font-light">General Partner Interest</span>
                        </div>
                        <div className="font-bold">
                            {NumberFormatter.formatNumber(partnership.generalPartnerInterest)}%
                        </div>
                    </div>
                    <div className="flex justify-between my-2 pl-2">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faCircle} size="sm" className={`${limitedPartnerColor} mr-2`} />
                            <span className="font-light">Limited Partner Interest</span>
                        </div>
                        <div className="font-bold">
                            {NumberFormatter.formatNumber(partnership.limitedPartnerInterest)}%
                        </div>
                    </div>
                    <div className="flex justify-between mt-2">
                        <span className="font-light">Value</span>
                        <span className="font-bold">{CurrencyFormatter.format(equityAmount)}</span>
                    </div>
                </button>
            </div>
            <div className={`relative pr-${transferLineGap}`}>
                <DownArrow
                    colorClassName={generalPartnerColor}
                    containerHeight={lineHeight}
                    containerWidth={containerWidth}
                    markerId="gpArrow"
                    strokeWidth={lineStrokeWidth}
                />
                <div className={`absolute top-0 left-${transferLineGap}`}>
                    <DownArrow
                        colorClassName={limitedPartnerColor}
                        containerHeight={lineHeight}
                        containerWidth={containerWidth}
                        markerId="lpArrow"
                        strokeWidth={lineStrokeWidth}
                    />
                </div>
                <div className="absolute top-1/3 w-full right-2/3 text-right text-lg">
                    General Partner Interest&nbsp;
                    <span className={`${generalPartnerColor} text-xl font-bold ml-1`}>
                        {partnership?.generalPartnerInterest}%
                    </span>
                </div>
                <div className="absolute top-1/3 w-full left-2/3 text-lg">
                    <span className={`${limitedPartnerColor} text-xl mr-1 font-bold`}>
                        {partnership?.limitedPartnerInterest}%
                    </span>
                    &nbsp;Limited Partner Interest
                </div>
            </div>
            <PartnershipForm
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onDelete={onDelete}
                onSave={onSave}
                owner={owner}
                partnership={partnership}
            />
        </>
    );
};

export default PartnershipCard;
