import { IAdvisorFirmSummary } from '@api';
import { VerticalTabs as Tabs } from '~/components';
import IPanel from './IPanel';
import PanelContainer from './PanelContainer';
import ProcessInfo from './ProcessInfo';

const TabId = 'ValidatingMarketCompetitiveness';

const ValidatingMarketCompetitiveness = (advisorFirm: IAdvisorFirmSummary | undefined): IPanel => ({
    Id: TabId,
    Tab: <Tabs.Tab tabId={TabId}>Validating Market Competitiveness</Tabs.Tab>,
    Panel: (
        <Tabs.Panel tabId={TabId}>
            <PanelContainer>
                <h2 className="font-bold">Validate Market Competitiveness</h2>
                <p>
                    Prudent business leaders regularly compare pay levels within their organizations with market
                    standards. Compensation experts recommend these studies be conducted at least once every two years,
                    especially for key positions.This practice helps the company remain competitive in its recruiting
                    efforts while ensuring it never over or under pays for key roles.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} partners with The VisionLink Advisory Group in offering market
                    pay services to our business clients. Visionlink is a national compensation design firm that has
                    been providing compensation solutions for businesses since 1996. The professionals at VisionLink can
                    provide the market data you need, help you interpret its meaning, and offer recommendations on the
                    actions you should take as a result.
                </p>
                <ProcessInfo
                    outcome="A clear, up-to-date picture of where your salary and total cash compensation levels match up with current market standards."
                    steps={[
                        'Organize a list of positions that are in need of salary and/or incentive validation.  (A template will be provided)',
                        'Review or draft basic job descriptions for each position. (VisionLink can assist)',
                        'VisionLink obtains viable market data for salary and total cash compensation for as many positions as possible. ',
                        'VisionLink analyzes the data and organizes it into tables (a report) to present to you.',
                        'Upon presentation of the report, your feedback allows VisionLink to refine the market intelligence to fit your circumstances.',
                        'Some positions will lack viable market matches. VisionLink will coach you on how to set appropriate pay levels for these roles by using the principle of "internal equity."',
                    ]}
                />
            </PanelContainer>
        </Tabs.Panel>
    ),
});

export { ValidatingMarketCompetitiveness };
