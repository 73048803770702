export interface IEmployeeCsvRow {
    dateOfBirth?: string | null;
    department?: string | null;
    disabilityInsuranceCoverage?: string | null;
    emailAddress?: string | null;
    firstName: string;
    id?: string | null;
    isCsv: true;
    isOwner?: string | null;
    lastName: string;
    lifeInsuranceCoverage?: string | null;
    salary?: string | null;
    shares?: string | null;
    title?: string | null;
}

export type IEmployeeCsvExport = Omit<IEmployeeCsvRow, 'isCsv'>;

export interface IEmployeeDataRow {
    data: IEmployeeCsvRow;
    errors: { [field: string]: string[] };
    rowNumber: number;
}

export const isIEmployeeCsvRow = (props: IEmployeeCsvRow): props is IEmployeeCsvRow =>
    !!(props as IEmployeeCsvRow).isCsv;
