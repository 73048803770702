import { IBizEquityBusiness, IBusiness } from '@api';
import { faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact, { BootstrapURLKeys, MapOptions } from 'google-map-react';
import { useState } from 'react';
import { ErrorPage } from '~/components';
import { Bounds, useSearchBizEquityPlaces } from '~/hooks/bizEquityPlaces';
import LocationMissing from './LocationMissing';
import PlaceMarker from './PlaceMarker';

const apiKey = 'AIzaSyBo7Rg-qGlMo_i2ssCxihoJcg5QUVw67zk';
const mapId = 'ccdb9858e62441d0';

const keys: BootstrapURLKeys & { mapIds: string[] } = {
    key: apiKey,
    mapIds: [mapId],
};

const options: MapOptions & { mapId: string } = {
    clickableIcons: false,
    disableDoubleClickZoom: true,
    draggable: false,
    fullscreenControl: false,
    keyboardShortcuts: false,
    mapId,
    panControl: false,
    scrollwheel: false,
    zoomControl: false,
};

interface IProps {
    business: IBusiness;
    bizEquityBusiness: IBizEquityBusiness;
}

const Map = ({ business, bizEquityBusiness }: IProps): JSX.Element => {
    const latitude = bizEquityBusiness.latitude || business.latitude || 0;
    const longitude = bizEquityBusiness.longitude || business.longitude || 0;

    return !latitude || !longitude ? (
        <div className="relative">
            <LocationMissing businessId={business.id} />
        </div>
    ) : (
        <InternalMap
            business={business}
            industry={bizEquityBusiness.industry || ''}
            latitude={latitude}
            longitude={longitude}
        />
    );
};

const MapLoading = () => (
    <div className="flex items-center justify-center bg-background2 h-full">
        <FontAwesomeIcon className="text-divider animate-pulse" icon={faMapMarkedAlt} size="10x" />
    </div>
);

interface IInternalMap {
    business: IBusiness;
    industry: string;
    latitude: number;
    longitude: number;
}

const InternalMap = ({ business, industry, latitude, longitude }: IInternalMap): JSX.Element => {
    const [bounds, setBounds] = useState<Bounds>();
    const { data: places, isError, isFetching } = useSearchBizEquityPlaces(industry, bounds);
    const [googleError, setGoogleError] = useState<boolean>(false);
    const isLoading = !bounds || isFetching || !places;

    if (isError || googleError || !industry) {
        return <ErrorPage title={industry ? '' : 'Industry must be set to show map'} hideActions={!industry} />;
    }

    const handleApiLoaded = (map: google.maps.Map) => {
        const bounds = map.getBounds();
        const northEast = bounds?.getNorthEast();
        const northEastLat = northEast?.lat();
        const northEastLong = northEast?.lng();
        const southWest = bounds?.getSouthWest();
        const southWestLat = southWest?.lat();
        const southWestLong = southWest?.lng();
        if (
            northEastLat === undefined ||
            northEastLong === undefined ||
            southWestLat === undefined ||
            southWestLong === undefined
        ) {
            setGoogleError(true);
        } else {
            setBounds({ northEastLat, northEastLong, southWestLat, southWestLong });
        }
    };

    return (
        <div className="h-full w-full relative">
            <GoogleMapReact
                bootstrapURLKeys={keys}
                defaultCenter={{ lat: latitude, lng: longitude }}
                defaultZoom={13}
                onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
                options={options}
                yesIWantToUseGoogleMapApiInternals
            >
                {places &&
                    places.map(p => (
                        <PlaceMarker key={p.id} className="opacity-100" place={p} lat={p.latitude} lng={p.longitude} />
                    ))}
                <PlaceMarker
                    className="opacity-100"
                    iconColorClassName="text-success"
                    lat={latitude}
                    lng={longitude}
                    place={{
                        ...business,
                        latitude,
                        longitude,
                    }}
                />
            </GoogleMapReact>
            {isLoading && (
                <div className={`absolute text-center z-10 top-0 left-0 h-full w-full`}>
                    <MapLoading />
                </div>
            )}
        </div>
    );
};

export default Map;
