import { IBizEquityBusiness } from '@api';
import { Modal } from '~/components';
import UpdateBizEquityBusinessWizard from './updateBusinessWizard/UpdateBizEquityBusinessWizard';

interface IProps {
    bizEquityBusiness: IBizEquityBusiness;
    businessId: string;
    canSkip?: boolean;
    isOpen: boolean;
    onClose: () => void;
}

const ManualEntryModal = ({ bizEquityBusiness, businessId, canSkip = false, isOpen, onClose }: IProps): JSX.Element => {
    return (
        <Modal disableBackdropClickClose={!canSkip} hideHeader maxWidth="7xl" open={isOpen} setOpen={onClose}>
            <UpdateBizEquityBusinessWizard
                bizEquityBusiness={bizEquityBusiness}
                businessId={businessId}
                canSkip={canSkip}
                onClose={onClose}
            />
        </Modal>
    );
};

export default ManualEntryModal;
