import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useEffect, useRef, useState } from 'react';
import { Avatar, Button } from '~/components';
import { ILabelProps, Label } from './Label';

interface IProps extends ILabelProps {
    className?: string;
    errorMessage?: string;
    onChange: React.Dispatch<React.SetStateAction<FileUpload | undefined>>;
    placeholderIcon?: IconDefinition;
    src?: string | null;
}

export interface FileUpload {
    file: File;
    url: string | null;
}

export const useAvatarInput = (
    shouldReset: boolean | unknown
): [FileUpload | undefined, React.Dispatch<React.SetStateAction<FileUpload | undefined>>] => {
    const [newPhoto, setNewPhoto] = useState<FileUpload>();

    useEffect(() => {
        if (shouldReset) {
            setNewPhoto(undefined);
        }
    }, [shouldReset]);

    return [newPhoto, setNewPhoto];
};

export const AvatarInput = ({
    className,
    errorMessage,
    label,
    labelClassName,
    onChange,
    placeholderIcon,
    src,
}: IProps): JSX.Element => {
    const photoRef = useRef<HTMLInputElement>(null);

    const handleFileSelect = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
        const file = evt.target.files?.[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = e =>
                onChange({
                    file,
                    url: e.target?.result as string,
                });
            fileReader.readAsDataURL(file);
        }
    };

    return (
        <div className={className}>
            <Label label={label} labelClassName={labelClassName} />
            <div className="flex items-center">
                <Avatar size={12} src={src || ''} placeholderIcon={placeholderIcon} />
                <input
                    accept="image/jpeg, image/png"
                    className="hidden"
                    onChange={handleFileSelect}
                    type="file"
                    ref={photoRef}
                />
                <Button className="ml-3" text={src ? 'Change' : 'Upload'} onClick={() => photoRef?.current?.click()} />
            </div>
            {errorMessage && <p className="mt-2 text-sm text-error">{errorMessage}</p>}
        </div>
    );
};
