import { IAdvisorFirm, IBusiness, IBusinessPageType } from '@api';
import {
    faCheckCircle,
    faCog,
    faExclamationTriangle,
    faGripVertical,
    faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Button,
    DeleteConfirmationModal,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Toggle,
} from '~/components';
import { useAdvisorFirm, useBusinessValuation, useUpdateBusiness } from '~/hooks';
import { EditAdvisorPageType } from '~/pages/advisorEdit';
import { ILocationState } from '~/pages/ILocationState';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import { Link, useLocation } from '~/routing';
import { businessPageDisplay, businessPageIcon, businessPageIconColor, getArrayOfEnum } from '~/utils/enumUtils';
import PageSettingsSlideOver from './pageSettings';

export interface IPageSetting {
    enabled: boolean;
    index: number;
    pageType: IBusinessPageType;
}

interface IProps {
    business: IBusiness;
}

const settingsMap: Record<IBusinessPageType, boolean> = {
    BizEquity: false,
    BonusRight: false,
    BusinessForecast: false,
    CorporateStrategies: false,
    DocumentCenter: false,
    Education: true,
    ExecutivePrograms: false,
    InnerZone: false,
    Observations: false,
    Purpose: false,
    ScoreCard: false,
    SuccessionPlan: false,
    TeamValues: false,
};

export const getInitialBusinessPageSettings = (
    pages: IBusinessPageType[],
    pagesExcluded: IBusinessPageType[]
): IPageSetting[] => {
    let disabledCount = 0;

    return getArrayOfEnum(IBusinessPageType)
        .filter(pageType => !pagesExcluded.includes(pageType))
        .map(pageType => {
            const enabled = pages.includes(pageType);
            const index = enabled ? pages.indexOf(pageType) : pages.length + disabledCount;

            if (!enabled) {
                disabledCount++;
            }

            return {
                enabled,
                index,
                pageType,
            };
        })
        .sort((a, b) => a.index - b.index);
};

export const togglePage = (pageSettings: IPageSetting[], pageType: IBusinessPageType): IPageSetting[] =>
    pageSettings.map(pageSetting => {
        if (pageSetting.pageType === pageType) {
            return {
                ...pageSetting,
                enabled: !pageSetting.enabled,
            };
        }

        return pageSetting;
    });

export const reorder = (pageSettings: IPageSetting[], sourceIndex: number, destinationIndex: number): IPageSetting[] =>
    pageSettings
        .map(ps => {
            if (ps.index === sourceIndex) {
                return {
                    ...ps,
                    index: destinationIndex,
                };
            }
            if (ps.index < sourceIndex) {
                if (ps.index >= destinationIndex) {
                    return {
                        ...ps,
                        index: ps.index + 1,
                    };
                }
            }
            if (ps.index > sourceIndex) {
                if (ps.index <= destinationIndex) {
                    return {
                        ...ps,
                        index: ps.index - 1,
                    };
                }
            }

            return ps;
        })
        .sort((a, b) => a.index - b.index);

const getIsEqualToFirmDefault = (
    firmPages: IBusinessPageType[] | null | undefined,
    businessPages: IBusinessPageType[] | null | undefined
): boolean => {
    if (!firmPages || !businessPages) {
        return false;
    }
    if (businessPages.length !== firmPages.length) {
        return false;
    }
    return businessPages.every((page, i) => firmPages[i] === page);
};

const PresentationSettings = ({ business }: IProps): JSX.Element => {
    const {
        isError: isErrorBusinessValuation,
        isLoading: isLoadingBusinessValuation,
        data: businessValuation,
    } = useBusinessValuation(business.id);
    const [isSaving, setIsSaving] = useState(false);
    const isDisabled = isSaving || isLoadingBusinessValuation || isErrorBusinessValuation;
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const [pageSettings, setPageSettings] = useState<IPageSetting[]>([]);
    const isEqualToFirmDefault = (advisorFirm: IAdvisorFirm) =>
        getIsEqualToFirmDefault(advisorFirm.pages, business.pages);
    const [pageSettingsSlideOver, setPageSettingsSlideOver] = useState<{
        isOpen: boolean;
        pageType?: IBusinessPageType;
    }>({ isOpen: false });
    const locationState: ILocationState = {
        backUrl: useLocation().pathname,
        label: `${business.name} Presentation Settings`,
    };
    const [resetModal, setResetModal] = useState<{
        isOpen: boolean;
        isSaving?: boolean;
    }>({ isOpen: false });
    const updateBusiness = useUpdateBusiness(business.id);
    const { isError, isLoading, data: advisorFirm } = useAdvisorFirm(business.advisorFirmId);
    useEffect(() => {
        if (advisorFirm) {
            setPageSettings(getInitialBusinessPageSettings(business.pages, advisorFirm.pagesExcluded));
        }
    }, [advisorFirm, business.pages]);
    if (isError) return <ErrorPage />;
    if (isLoading || !advisorFirm) return <LoadingIndicator />;

    const handleSave = async (pages: IPageSetting[]) => {
        try {
            setIsSaving(true);
            await updateBusiness.mutateAsync({
                ...business,
                pages: pages.filter(ps => ps.enabled).map(ps => ps.pageType),
                value: businessValuation?.value,
            });
            showSuccess();
        } catch {
            showError();
        } finally {
            setIsSaving(false);
        }
    };
    const onChange = async (pageType: IBusinessPageType) => {
        const originalPageSettings = [...pageSettings];
        try {
            const updatedPageSettings = togglePage(originalPageSettings, pageType);
            setPageSettings(updatedPageSettings);
            await handleSave(updatedPageSettings);
        } catch {
            setPageSettings(originalPageSettings);
        }
    };
    const onReorder = async (sourceIndex: number, destinationIndex: number) => {
        const originalPageSettings = [...pageSettings];
        try {
            const updatedPageSettings = reorder(originalPageSettings, sourceIndex, destinationIndex);
            setPageSettings(updatedPageSettings);
            await handleSave(updatedPageSettings);
        } catch {
            setPageSettings(originalPageSettings);
        }
    };
    const onClickReset = () => setResetModal({ isOpen: true });
    const onConfirmReset = async () => {
        const firmDefaultPageSettings = getInitialBusinessPageSettings(advisorFirm.pages, advisorFirm.pagesExcluded);
        setResetModal({ ...resetModal, isSaving: true });
        try {
            await handleSave(firmDefaultPageSettings);
            setPageSettings(firmDefaultPageSettings);
        } finally {
            setResetModal({ isOpen: false });
        }
    };

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Presentation Settings</h3>
            <p className="mt-1 text-sm leading-5 font-normal text-textSubtle">
                Turn pages on or off in your presentation for this business. Click and grab to change the order of pages
                in your presentation.
            </p>
            <div className="my-4">
                {isErrorBusinessValuation || !businessValuation ? (
                    <div>
                        <FontAwesomeIcon className="mr-2 text-caution" icon={faExclamationTriangle} size="1x" />
                        An unknown error occurred retrieving the business valuation.
                    </div>
                ) : (
                    <div className="text-sm flex items-center">
                        {isEqualToFirmDefault(advisorFirm) ? (
                            <FontAwesomeIcon icon={faCheckCircle} className="text-affirmation mr-2" />
                        ) : (
                            <FontAwesomeIcon icon={faInfoCircle} className="text-information mr-2" />
                        )}
                        <span className="text-textDisabled">
                            {isEqualToFirmDefault(advisorFirm)
                                ? `These settings are the same as your firm's default.`
                                : `These settings differ from your firm's default.`}
                        </span>
                        <Button
                            className="ml-2"
                            disabled={isEqualToFirmDefault(advisorFirm)}
                            size="sm"
                            text="Reset to Default"
                            onClick={onClickReset}
                        />
                        <Link
                            className="ml-2"
                            to={{
                                pathname: `/settings/Me/${EditAdvisorPageType.FirmPresentationSettings}`,
                                state: locationState,
                            }}
                        >
                            <Button size="sm" text="Edit Default" />
                        </Link>
                    </div>
                )}
            </div>
            <DragDropContext
                onDragEnd={result => result.destination && onReorder(result.source.index, result.destination.index)}
            >
                <Droppable droppableId="droppable">
                    {provided => (
                        <Table ref={provided.innerRef}>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell>Presentation</TableHeaderCell>
                                    <TableHeaderCell>On/Off</TableHeaderCell>
                                    <TableHeaderCell></TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageSettings.map((pageSetting, index) => (
                                    <Draggable
                                        key={pageSetting.pageType}
                                        draggableId={pageSetting.pageType}
                                        index={index}
                                        isDragDisabled={isDisabled}
                                    >
                                        {provided => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <TableCell>
                                                    <FontAwesomeIcon className="text-divider" icon={faGripVertical} />
                                                </TableCell>
                                                <TableCell className="w-full text-sm leading-5">
                                                    <FontAwesomeIcon
                                                        fixedWidth
                                                        className={`mr-4 text-${
                                                            businessPageIconColor[pageSetting.pageType]
                                                        }`}
                                                        icon={businessPageIcon[pageSetting.pageType]}
                                                    />
                                                    {businessPageDisplay[pageSetting.pageType]}
                                                </TableCell>
                                                <TableCell>
                                                    <Toggle
                                                        checked={pageSetting.enabled}
                                                        disabled={isDisabled}
                                                        id={pageSetting.pageType}
                                                        onChange={() => onChange(pageSetting.pageType)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {settingsMap[pageSetting.pageType] && (
                                                        <Button
                                                            color="primaryLink"
                                                            disabled={isDisabled}
                                                            iconLeft={faCog}
                                                            text="Settings"
                                                            onClick={() =>
                                                                setPageSettingsSlideOver({
                                                                    isOpen: true,
                                                                    pageType: pageSetting.pageType,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </TableCell>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        </Table>
                    )}
                </Droppable>
            </DragDropContext>
            <DeleteConfirmationModal
                confirmButtonText="Reset"
                onClose={() => setResetModal({ ...resetModal, isOpen: false })}
                onConfirm={onConfirmReset}
                title="Reset Presentation Settings"
                {...resetModal}
            >
                Are you sure you want to reset presentation settings? This will take effect immediately. Individual page
                settings such as education resource selections will remain unchanged.
            </DeleteConfirmationModal>
            <PageSettingsSlideOver
                {...pageSettingsSlideOver}
                business={business}
                onClose={() => setPageSettingsSlideOver({ ...pageSettingsSlideOver, isOpen: false })}
            />
        </>
    );
};

export default PresentationSettings;
