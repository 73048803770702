import classNames from 'classnames';
import moment from 'moment';
import {
    Button,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import { useEmployees } from '~/hooks';
import { useOnConfirm } from '~/hooks/employeeUpload';
import { useAppSelector } from '~/redux/hooks';
import { useParams } from '~/routing';
import { nameof as nameOf } from '~/utils/nameof';
import { BusinessParams } from '..';
import { IEmployeeCsvRow, isIEmployeeCsvRow } from './model';

const formatDateString = (dateString: string | null | undefined): string | null | undefined => {
    const date = moment(dateString, false);
    if (!date.isValid()) return dateString;
    return date.format('YYYY-MM-DD');
};

interface IProps {
    onReset: () => void;
}

const nameof = (name: keyof IEmployeeCsvRow) => nameOf<IEmployeeCsvRow>(name);

export const UploadPreview = ({ onReset }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const { pendingUploads, isUploading, hideConfirmButton } = useAppSelector(s => s.employeeUpload);
    const onConfirm = useOnConfirm(businessId);
    const { error, data: employees, isLoading } = useEmployees(businessId, !isUploading);
    if (error) return <ErrorPage />;
    if (isLoading || !employees) return <LoadingIndicator />;

    if (!pendingUploads || pendingUploads.length === 0) {
        return (
            <>
                <h3 className="text-lg">No employees found to upload.</h3>
                <Button text="Reset" onClick={onReset} />
            </>
        );
    }
    const isCsvImport = isIEmployeeCsvRow(pendingUploads[0].data);

    return (
        <>
            <div className="w-full text-right space-x-3">
                <Button text="Reset" onClick={onReset} />
                {!hideConfirmButton && (
                    <Button color="primary" loading={isUploading} text="Confirm" onClick={onConfirm} />
                )}
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>#</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('firstName')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('lastName')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('dateOfBirth')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('department')}</TableHeaderCell>
                        {isCsvImport && (
                            <TableHeaderCell className="normal-case">
                                {nameof('disabilityInsuranceCoverage')}
                            </TableHeaderCell>
                        )}
                        <TableHeaderCell className="normal-case">{nameof('emailAddress')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('isOwner')}</TableHeaderCell>
                        {isCsvImport && (
                            <TableHeaderCell className="normal-case">{nameof('lifeInsuranceCoverage')}</TableHeaderCell>
                        )}
                        <TableHeaderCell className="normal-case">{nameof('salary')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('shares')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('title')}</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pendingUploads.map(({ data, errors, rowNumber }) => {
                        const hasError = (field: string): boolean => errors[field]?.length > 0;
                        const getErrorMessage = (field: string): string => errors[field]?.join('&#10;');

                        return (
                            <TableRow key={`BodyRow_${rowNumber}`}>
                                <TableCell className="text-xs">{rowNumber}</TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('firstName')),
                                    })}
                                    title={getErrorMessage(nameof('firstName'))}
                                >
                                    {data.firstName}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('lastName')),
                                    })}
                                    title={getErrorMessage(nameof('lastName'))}
                                >
                                    {data.lastName}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('dateOfBirth')),
                                    })}
                                    title={getErrorMessage(nameof('dateOfBirth'))}
                                >
                                    {formatDateString(data.dateOfBirth)}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('department')),
                                    })}
                                    title={getErrorMessage(nameof('department'))}
                                >
                                    {data.department}
                                </TableCell>
                                {isCsvImport && (
                                    <TableCell
                                        className={classNames('text-xs', {
                                            'bg-error cursor-not-allowed': hasError(
                                                nameof('disabilityInsuranceCoverage')
                                            ),
                                        })}
                                        title={getErrorMessage(nameof('disabilityInsuranceCoverage'))}
                                    >
                                        {(data as IEmployeeCsvRow).disabilityInsuranceCoverage}
                                    </TableCell>
                                )}
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('emailAddress')),
                                    })}
                                    title={getErrorMessage(nameof('emailAddress'))}
                                >
                                    {data.emailAddress}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('isOwner')),
                                    })}
                                    title={getErrorMessage(nameof('isOwner'))}
                                >
                                    {data.isOwner}
                                </TableCell>
                                {isCsvImport && (
                                    <TableCell
                                        className={classNames('text-xs', {
                                            'bg-error cursor-not-allowed': hasError(nameof('lifeInsuranceCoverage')),
                                        })}
                                        title={getErrorMessage(nameof('lifeInsuranceCoverage'))}
                                    >
                                        {(data as IEmployeeCsvRow).lifeInsuranceCoverage}
                                    </TableCell>
                                )}
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('salary')),
                                    })}
                                    title={getErrorMessage(nameof('salary'))}
                                >
                                    {data.salary}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('shares')),
                                    })}
                                    title={getErrorMessage(nameof('shares'))}
                                >
                                    {data.shares}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-error cursor-not-allowed': hasError(nameof('title')),
                                    })}
                                    title={getErrorMessage(nameof('title'))}
                                >
                                    {data.title}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};
