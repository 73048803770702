import { FullScreenOverlay } from '@envestnet/envreact-component-library';
import { ReactNode } from 'react';

export enum SlideOverSizeType {
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    '2xl' = '2xl',
    '3xl' = '3xl',
    '4xl' = '4xl',
    '5xl' = '5xl',
    '6xl' = '6xl',
    '7xl' = '7xl',
}

interface IProps {
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    size?: SlideOverSizeType;
    stickyFooter?: ReactNode;
    title: ReactNode;
}

const sizeClassNameMap: Record<SlideOverSizeType, string> = {
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    '7xl': 'max-w-7xl',
};

export const SlideOver = ({
    children,
    isOpen,
    onClose,
    size = SlideOverSizeType.md,
    stickyFooter,
    title,
}: IProps): JSX.Element => {
    return (
        <FullScreenOverlay
            anchorSide="right"
            contentContainerStyle={{ height: '100%' }}
            open={isOpen}
            onClose={onClose}
            showCloseButton
        >
            <div className={`w-screen h-full text-textDefault ${sizeClassNameMap[size]}`}>
                <div className="h-full flex flex-col bg-background shadow-xl overflow-hidden">
                    <div className="min-h-0 flex-1 flex flex-col">
                        <div className="px-4 sm:px-6 py-4 sm:py-6 bg-background1 shadow">
                            <div className="flex items-start justify-between">
                                <h2 className="text-lg font-medium">{title}</h2>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col justify-between overflow-y-auto">
                            <div className="px-4 sm:px-6">
                                <div className="space-y-6 pt-6 pb-5">{children}</div>
                            </div>
                        </div>
                    </div>
                    {stickyFooter && (
                        <div className="flex-shrink-0 px-4 py-4 flex justify-end bg-background1 shadow">
                            {stickyFooter}
                        </div>
                    )}
                </div>
            </div>
        </FullScreenOverlay>
    );
};
