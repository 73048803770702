import { IBusiness } from '@api';
import classNames from 'classnames';
import { useState } from 'react';
import ServicesModal from './teaser/panels/ServicesModal';
import visionLinkLogo from './VisionLinkLogo.png';

interface IProps {
    business: IBusiness;
    className?: string;
    showServicesOnClick?: boolean;
}

const PoweredBy = ({ business, className = '', showServicesOnClick = false }: IProps): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const onClick = showServicesOnClick ? () => setShowModal(!showModal) : undefined;

    return (
        <>
            <div className={classNames('flex flex-col space-y-2', className)}>
                <h5>Powered by</h5>
                <img
                    alt="Vision Link Logo"
                    className={classNames('h-10 white-logo', {
                        'cursor-pointer': showServicesOnClick,
                    })}
                    onClick={onClick}
                    src={visionLinkLogo}
                />
            </div>
            {showServicesOnClick && (
                <ServicesModal
                    business={business}
                    isConnected={true}
                    open={showModal}
                    setOpen={() => setShowModal(false)}
                />
            )}
        </>
    );
};

export default PoweredBy;
