import { IBusiness, IEmployee } from '@api';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormatter from '~/utils/numberFormatter';
import { getAllocationState, ShareAllocationState } from '~/utils/sharesUtils';

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
}

const SharesWarning = ({ business, employees }: IProps): JSX.Element => {
    const {
        outstandingShares,
        sharesAllocatedToEmployees,
        state: allocationState,
    } = getAllocationState(business, employees);

    return {
        [ShareAllocationState.NoSharesOutstanding]: (
            <div className="border border-caution p-2 text-caution">
                <FontAwesomeIcon icon={faWarning} /> Business does not have outstanding shares set.
            </div>
        ),
        [ShareAllocationState.TooManySharesAllocated]: (
            <div className="border border-caution p-2 text-caution">
                <FontAwesomeIcon icon={faWarning} />
                {`${NumberFormatter.formatNumber(
                    sharesAllocatedToEmployees
                )} shares have been allocated to employees, but only ${NumberFormatter.formatNumber(
                    outstandingShares
                )} are outstanding.`}
            </div>
        ),
        [ShareAllocationState.Valid]: <></>,
    }[allocationState];
};

export default SharesWarning;
