import { IProblemDetails } from '@api';
import { ErrorPage } from '~/components';
import InnerZoneSetting from '~/pages/advisorSettings/innerZone/InnerZoneSetting';

interface IProps {
    error?: IProblemDetails;
}

const InnerZoneError = ({ error }: IProps): JSX.Element => {
    const isForbidden = error?.response?.status === 403;

    if (isForbidden) {
        return (
            <div className="flex justify-center">
                <div className="max-w-lg">
                    <InnerZoneSetting />
                </div>
            </div>
        );
    }

    return <ErrorPage hideActions iconColor="divider" />;
};

export default InnerZoneError;
