import { DropdownItem as EnvDropdownItem } from '@envestnet/envreact-component-library';
import { faPlus, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ComponentPropsWithoutRef } from 'react';
import { Avatar } from './Avatar';
import { ILabelProps } from './Label';
import { ISelectOption, Select } from './Select';

export interface IEntitySelectOption extends ISelectOption {
    avatar?: string;
    description?: string;
    disabled?: boolean;
    name: string;
    showAddButton?: boolean;
    showLoading?: boolean;
}

const EntitySelectOptionComponent = (props: IEntitySelectOption) => {
    if (props.isCreatableOption) {
        return (
            <EnvDropdownItem.Basic className="group" value={props} key="creatable">
                <div className="flex items-center">
                    <Avatar />
                    <div className="text-base ml-2">Add New</div>
                </div>
            </EnvDropdownItem.Basic>
        );
    }
    return (
        <EnvDropdownItem.Basic value={props} className="group" key={props.value}>
            <div className="flex items-center">
                <Avatar src={props.avatar || undefined} />
                <div className="truncate flex-1 ml-2">
                    <div className="truncate text-base">{props.name}</div>
                    <div className="text-textDisabled truncate text-sm">{props.description}</div>
                </div>
                {props.showAddButton && (
                    <div className="flex flex-shrink-0 justify-center items-center rounded-full h-8 w-8 bg-information mx-3">
                        <FontAwesomeIcon icon={faPlus} aria-hidden />
                    </div>
                )}
                {props.showLoading && (
                    <div className="flex flex-shrink-0 justify-center items-center rounded-full h-8 w-8 mx-3">
                        <FontAwesomeIcon icon={faSpinner} size="lg" spin aria-hidden />
                    </div>
                )}
            </div>
        </EnvDropdownItem.Basic>
    );
};

interface IProps
    extends ILabelProps,
        Omit<
            ComponentPropsWithoutRef<typeof Select>,
            'onChange' | 'onCreateOption' | 'optionComponent' | 'options' | 'value'
        > {
    options: IEntitySelectOption[];
    value?: string;
    onEntityOptionSelected?: (option: IEntitySelectOption) => void;
    onNewSelected?: () => void;
}

const EntitySelect = ({ onEntityOptionSelected, onNewSelected, ...rest }: IProps): JSX.Element => {
    const onChange = (option: IEntitySelectOption | null) => {
        if (onEntityOptionSelected) {
            const entityOption = option as IEntitySelectOption;

            if (entityOption && !entityOption.disabled) {
                return onEntityOptionSelected(entityOption);
            }
        }
    };

    return (
        <Select
            onChange={onChange}
            onCreateOption={onNewSelected}
            optionComponent={EntitySelectOptionComponent}
            showCreateWhenEmpty
            showSearchIcon
            {...rest}
        />
    );
};

export { EntitySelect };
