import { CurrencyInput as EnvCurrencyInput } from '@envestnet/envreact-component-library';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps, Label } from './Label';

interface IProps
    extends ILabelProps,
        Omit<
            ComponentPropsWithoutRef<typeof EnvCurrencyInput>,
            'amount' | 'className' | 'currencySymbol' | 'label' | 'maxAmount'
        > {
    className?: string;
    value: number | undefined;
}

const CurrencyInput = ({ className, label, labelClassName, value, ...rest }: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return (
        <div className={className}>
            <Label label={label} labelClassName={labelClassName} />
            <EnvCurrencyInput
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                autoComplete="off"
                allowEmpty={true}
                amount={value}
                currencySymbol="$"
                maxAmount={999999999}
                nFractional={0}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            />
        </div>
    );
};

export { CurrencyInput };
