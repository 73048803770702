import { IAdvisorFirmSummary } from '@api';
import { VerticalTabs as Tabs } from '~/components';
import IPanel from './IPanel';
import PanelContainer from './PanelContainer';

const TabId = 'AssessingHRReadiness';

const AssessingHRReadiness = (advisorFirm: IAdvisorFirmSummary | undefined): IPanel => ({
    Id: TabId,
    Tab: <Tabs.Tab tabId={TabId}>Assessing HR Readiness</Tabs.Tab>,
    Panel: (
        <Tabs.Panel tabId={TabId}>
            <PanelContainer>
                <h2 className="font-bold">Assessing HR Readiness</h2>
                <p>
                    Human Resource teams in many organizations lack the specialization they need to maintain quality
                    services for employees--let alone stay up-to-date with compliance responsibilities or set a Human
                    Resources strategy that aligns with the organization&apos;s growth strategy. Most companies find it
                    easier, cheaper and more effective to engage outsource teams to round out important HR functions.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} partners with The VisionLink Advisory Group in offering Human
                    Resource support services to our business clients. Visionlink is a national compensation design firm
                    that has been providing compensation solutions for businesses since 1996. The professionals at
                    VisionLink can work with your HR team and assist in a wide variety of both strategic and narrow
                    responsibilities.
                </p>
                <div className="space-y-6">
                    <div className="font-bold">Sample Services</div>
                    {[
                        'General Human Resources: HR Policy & Handbook Review, Compliance Audits, HR Strategy Creation, General HR Questions and M&A Integration',
                        'Benefit Programs: Provider search/selection, Plan Design, Renewal Negotiations, Integration, Administration, and Employee Communication/Enrollment',
                        'Retirement Programs: Provider assessment and selection, Annual Plan Audits, Administration and Employee Communication',
                        'Talent Management: Job Descriptions, Job Structure, Performance Management, Succession Planning and Employee Engagement Culture Surveys',
                        'HRIS/HCM: Provider search/selection, Implementation and Streamline HR processes',
                    ].map((s, i) => (
                        <div className="flex flex-row items-center space-x-8" key={`${i}`}>
                            <div>
                                <div className="bg-information flex h-8 items-center justify-center rounded-full w-8 ring-8 ring-highlight">
                                    <div>{i + 1}</div>
                                </div>
                            </div>
                            <div>{s}</div>
                        </div>
                    ))}
                </div>
            </PanelContainer>
        </Tabs.Panel>
    ),
});

export { AssessingHRReadiness };
