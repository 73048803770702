import { ITodo, ITodoPriorityType } from '@api';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faTasks } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmptyStateIcon, ErrorPage } from '~/components';
import { useTodos } from '~/hooks';
import { TodoItem } from '~/pages/business/header/todos/TodoItemSet';
import CardSkeleton from '../CardSkeleton';

interface IProps {
    businessId: string;
    fullWidth?: boolean;
}

const TaskProgress = ({ todos }: { todos: ITodo[] }) => {
    const completed = todos.filter(t => t.completed).length;
    const total = todos.length;

    return (
        <div>
            <div>
                <span className="text-information font-bold">{completed}</span> completed out of{' '}
                <span className="text-information font-bold">{total}</span> tasks
            </div>
            <div className="mt-1 h-3 rounded overflow-hidden bg-highlight">
                <div className="bg-information h-full" style={{ width: total && `${(completed / total) * 100}%` }} />
            </div>
        </div>
    );
};

export const getSortedIncompleteTasks = (tasks: ITodo[]): ITodo[] =>
    tasks
        .filter(t => !t.completed)
        .sort((a, b) => {
            if (a.priority === b.priority) return 0;
            if (a.priority === ITodoPriorityType.High) return -1;
            if (b.priority === ITodoPriorityType.High) return 1;
            if (a.priority === ITodoPriorityType.Medium) return -1;
            return 1;
        });

const TasksCardContent = ({ businessId, fullWidth }: IProps): JSX.Element => {
    const { data: todos, isError, isLoading } = useTodos(businessId, true);

    if (isError) return <ErrorPage hideActions iconColor="divider" />;
    if (isLoading || !todos) return <CardSkeleton />;
    if (todos.length === 0)
        return (
            <div className="flex flex-col flex-grow items-center justify-center">
                <EmptyStateIcon icon={faTasks} />
                <p className="my-4 text-lg">No tasks added yet.</p>
            </div>
        );

    const sortedTasks = getSortedIncompleteTasks(todos);
    return (
        <div className="flex flex-col flex-grow">
            <TaskProgress todos={todos} />
            {sortedTasks.length === 0 ? (
                <div className="flex flex-col flex-grow items-center justify-center">
                    <span className="fa-layers fa-fw fa-6x">
                        <FontAwesomeIcon className="text-positive" icon={faCircle} />
                        <FontAwesomeIcon className="text-positive" icon={faCheck} transform="shrink-8" />
                    </span>
                    <p className="my-4 text-lg">All tasks are complete.</p>
                </div>
            ) : (
                <div className={fullWidth ? 'grid grid-cols-1 md:grid-cols-2 gap-x-4' : 'flex flex-col flex-grow mt-2'}>
                    {sortedTasks.slice(0, fullWidth ? 6 : 3).map(todo => (
                        <TodoItem key={todo.id} todo={todo} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TasksCardContent;
