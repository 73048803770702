import { IBizEquityRequiredFields, IBizEquityYearlyStatementUpdate } from '@api';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HorizontalTabs as Tabs } from '~/components';
import FinancialRow from './FinancialRow';
import { IRowData } from './models';

interface IProps {
    formData: { [key: string]: IBizEquityYearlyStatementUpdate };
    requiredFields: IBizEquityRequiredFields;
    rows: IRowData[];
    setFormData: (formData: { [key: string]: IBizEquityYearlyStatementUpdate }) => void;
    validationErrors?: Record<string, string>;
}

const currentYearAsString = new Date().getFullYear().toString();

const isRequiredField = (
    field: keyof IBizEquityYearlyStatementUpdate,
    requiredFields: IBizEquityRequiredFields
): boolean => {
    return requiredFields.requiredFields.includes(field);
};

const FinancialGrid = ({ formData, requiredFields, rows, setFormData, validationErrors }: IProps): JSX.Element => {
    const years = Object.keys(formData).reverse();

    return (
        <>
            <div className="hidden xl:flex flex-col p-4 space-y-2">
                <div className="grid grid-cols-4 gap-x-4 gap-y-6">
                    <div />
                    {years.map(year => (
                        <div key={year} className="flex space-x-1 items-baseline">
                            <span className="font-bold text-2xl">{year}</span>
                            {year === currentYearAsString && <span>(Projected)</span>}
                        </div>
                    ))}
                </div>
                <div className="grid grid-cols-4 gap-x-4 gap-y-6">
                    {rows.map((r, i) => (
                        <FinancialRow
                            key={`${r.key}-row-${i}`}
                            formData={formData}
                            infoContent={r.infoContent}
                            isRequired={isRequiredField(r.key, requiredFields)}
                            name={r.displayName}
                            propertyName={r.key}
                            validationErrors={validationErrors}
                            years={years}
                            setFormData={setFormData}
                        />
                    ))}
                </div>
            </div>
            <div className="flex flex-col xl:hidden">
                <Tabs activeTabId={years[0]}>
                    {years.map((year, index) => (
                        <>
                            <Tabs.Tab tabId={year} key={year}>
                                <div className="flex space-x-1 items-baseline">
                                    <span className="font-bold text-lg">{year}</span>
                                    {year === currentYearAsString && <span>(Projected)</span>}
                                </div>
                            </Tabs.Tab>
                            <Tabs.Panel tabId={year} key={`${year}_panel`}>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 md:gap-y-6 md:mt-6">
                                    {rows.map((r, i) => (
                                        <FinancialRow
                                            key={`${r.key}-row-${i}`}
                                            formData={formData}
                                            infoContent={r.infoContent}
                                            isRequired={index === 0 && isRequiredField(r.key, requiredFields)}
                                            name={r.displayName}
                                            propertyName={r.key}
                                            validationErrors={validationErrors}
                                            years={[year]}
                                            setFormData={setFormData}
                                        />
                                    ))}
                                </div>
                            </Tabs.Panel>
                        </>
                    ))}
                </Tabs>
            </div>
            <div className="mt-auto pt-8 flex items-center">
                <FontAwesomeIcon icon={faAsterisk} className="text-error mr-2" size="xs" /> Indicates Required Fields
            </div>
        </>
    );
};

export default FinancialGrid;
