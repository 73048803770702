import { INote, IUserEntitlementType } from '@api';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { Button, Can } from '~/components';

interface IProps {
    note: INote;
    onEditClick: () => void;
}

const Note = ({ note, onEditClick }: IProps): JSX.Element => {
    return (
        <div className="p-4 mb-4 rounded bg-background1 shadow">
            <div className="truncate" title={note.text}>
                {note.text}
            </div>
            <div className="flex justify-between text-sm mt-3">
                <div>{moment(note.updatedAt).format('L')}</div>
                <Can hasEntitlement={IUserEntitlementType.NoteUpdate}>
                    <Button color="tertiary" iconLeft={faEdit} size="sm" text="Edit" onClick={onEditClick} />
                </Can>
            </div>
        </div>
    );
};

export default Note;
