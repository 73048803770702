import { IBonusRightEmployeeRewards, IBusiness, IEmployee } from '@api';
import { useState } from 'react';
import { LoadingIndicator } from '~/components';
import { useBonusRightTotalRewardsAssessment } from '~/hooks/bonusRightEmployeeRewards';
import { getBonusRightSampleEmployeeRewards } from '~/sampleData';
import BonusRightErrorPage from '../BonusRightErrorPage';
import PoweredBy from '../PoweredBy';
import EmployeeRewardsStats from './EmployeeRewardsStats';
import EmployeeRewardsTable from './EmployeeRewardsTable';
import EmployeeValueStatementModal from './employeeValueStatement/EmployeeValueStatementModal';
import { IEmployeeRewardsSummaryStatViewModel, IEmployeeRewardsViewModel } from './models';

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
}

export const toEmployeeRewardsViewModel = (
    bonusRightEmployeeRewards: IBonusRightEmployeeRewards
): IEmployeeRewardsViewModel => ({
    ...bonusRightEmployeeRewards,
    grade: bonusRightEmployeeRewards.grade,
    salary: bonusRightEmployeeRewards.salary,
    totalDirectRewards:
        (bonusRightEmployeeRewards.longTermIncentives || 0) +
        bonusRightEmployeeRewards.salary +
        (bonusRightEmployeeRewards.shortTermIncentives || 0),
    totalIndirectRewards: (bonusRightEmployeeRewards.benefits || 0) + (bonusRightEmployeeRewards.retirement || 0),
    totalRewards:
        (bonusRightEmployeeRewards.benefits || 0) +
        (bonusRightEmployeeRewards.longTermIncentives || 0) +
        (bonusRightEmployeeRewards.retirement || 0) +
        bonusRightEmployeeRewards.salary +
        (bonusRightEmployeeRewards.shortTermIncentives || 0),
});

export const toEmployeeRewardsSummaryStatViewModels = (
    employeeRewardsViewModelList: IEmployeeRewardsViewModel[]
): IEmployeeRewardsSummaryStatViewModel[] => {
    return employeeRewardsViewModelList.reduce(
        (acc, current) => {
            acc[0].value += current.benefits || 0;
            acc[1].value += current.equity || 0;
            acc[2].value += current.longTermIncentives || 0;
            acc[3].value += current.retirement || 0;
            acc[4].value += current.salary || 0;
            acc[5].value += current.shortTermIncentives || 0;

            return acc;
        },
        [
            { name: 'Benefits', value: 0 },
            { name: 'Equity', value: 0 },
            { name: 'Long-Term Incentives', shortName: 'LTIP', value: 0 },
            { name: 'Retirement', value: 0 },
            { name: 'Salary', value: 0 },
            { name: 'Short-Term Incentives', shortName: 'STIP', value: 0 },
        ]
    );
};

const EmployeeRewards = ({ business, employees }: IProps): JSX.Element | null => {
    const {
        isError,
        isLoading,
        data: tra = getBonusRightSampleEmployeeRewards(employees, business.isSampleCase ?? false),
    } = useBonusRightTotalRewardsAssessment(business.id, !business.isSampleCase);
    const [selectedBonusRightEmployeeId, setSelectedBonusRightEmployeeId] = useState<number | undefined>(undefined);
    if (isError) return <BonusRightErrorPage business={business} reportName="Total Rewards Assessment" />;
    if (isLoading) return <LoadingIndicator />;

    const employeeRewardsViewModelList = tra.map(toEmployeeRewardsViewModel);
    const stats = toEmployeeRewardsSummaryStatViewModels(employeeRewardsViewModelList).sort(
        (a, b) => b.value - a.value
    );

    return (
        <div className="p-10">
            <div className="mb-6 flex justify-between">
                <h3 className="text-lg leading-6 font-medium mr-2">Employee Rewards Summary</h3>
                <PoweredBy business={business} showServicesOnClick={true} />
            </div>
            <h3 className="mb-4 text-lg leading-6 font-medium">Rewards Allocation</h3>
            <div className="mb-4">
                <EmployeeRewardsStats stats={stats} />
            </div>
            <h3 className="mb-3 text-lg leading-6 font-medium">Rewards Overview By Employee</h3>
            <EmployeeRewardsTable
                employeeRewardsList={employeeRewardsViewModelList}
                onClickEmployee={setSelectedBonusRightEmployeeId}
            />
            {selectedBonusRightEmployeeId && (
                <EmployeeValueStatementModal
                    bonusRightEmployeeId={selectedBonusRightEmployeeId}
                    business={business}
                    employees={employees}
                    onClose={() => setSelectedBonusRightEmployeeId(undefined)}
                />
            )}
        </div>
    );
};

export default EmployeeRewards;
