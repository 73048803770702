import { IEmployee, IEmployeeCreateRequest, IEmployeeUpdateRequest } from '@api';
import { useDeleteEmployee, useEmployeeAvatar, useUpdateEmployee } from '~/hooks';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import EmployeeForm from './EmployeeForm';

interface IProps {
    businessId: string;
    employee?: IEmployee;
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: (id: string) => void;
}

const EditEmployeeForm = (props: IProps): JSX.Element => {
    const { businessId, employee, onSuccess, onClose } = props;
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const employeeId = employee?.id ?? '';
    const deleteEmployee = useDeleteEmployee(businessId);
    const updateEmployee = useUpdateEmployee(businessId);
    const employeeAvatar = useEmployeeAvatar(businessId);
    const onDelete = async () => {
        await deleteEmployee.mutateAsync(employeeId);
        showSuccess();
        onSuccess?.(employeeId);
        onClose();
    };
    const onSave = async (request: IEmployeeCreateRequest | IEmployeeUpdateRequest, avatar?: File) => {
        await updateEmployee.mutateAsync({
            ...(employee ?? {}),
            ...request,
            employeeId: employee?.id ?? '',
        });
        if (avatar) {
            await employeeAvatar.mutateAsync({
                avatar,
                employeeId,
            });
        }
        showSuccess();
        onSuccess?.(employeeId);
        onClose();
    };

    return <EmployeeForm onDelete={onDelete} onSave={onSave} title="Edit Employee" {...props} />;
};

export default EditEmployeeForm;
