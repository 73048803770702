import Highcharts, { PlotOptions, SeriesOptionsType, XAxisOptions, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CurrencyFormatter from '~/utils/currencyFormatter';

interface IProps {
    height: number;
    plotOptions?: PlotOptions;
    series: Array<SeriesOptionsType>;
    xAxis?: XAxisOptions;
    yAxis?: YAxisOptions;
}

const AreaChart = ({ height, plotOptions, series, xAxis, yAxis }: IProps): JSX.Element => {
    const options: Highcharts.Options = {
        chart: {
            backgroundColor: 'transparent',
            height,
        },
        credits: {
            enabled: false,
        },
        legend: { enabled: false },
        plotOptions: {
            ...plotOptions,
            area: {
                ...plotOptions?.area,
                marker: {
                    enabled: false,
                    ...plotOptions?.area?.marker,
                },
            },
            series: {
                animation: false,
                ...plotOptions?.series,
            },
        },
        series,
        title: {
            text: '',
        },
        tooltip: {
            backgroundColor: 'var(--color-background)',
            borderRadius: 8,
            formatter: function () {
                return (
                    '<span class="text-textDefault fill-current">' +
                    this.x +
                    '</span><br /><b class="text-textDefault fill-current">' +
                    CurrencyFormatter.format(this.y) +
                    '</b>'
                );
            },
        },
        xAxis: {
            ...xAxis,
            labels: {
                ...xAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '0.875rem',
                    ...xAxis?.labels?.style,
                },
            },
            lineColor: 'var(--color-highlight)',
            tickLength: 0,
        },
        yAxis: {
            ...yAxis,
            gridLineColor: 'var(--color-background2)',
            labels: {
                ...yAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '0.875rem',
                    ...yAxis?.labels?.style,
                },
            },
            title: {
                text: null,
                ...yAxis?.title,
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export { AreaChart };
