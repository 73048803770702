import {
    DropdownItem as EnvDropdownItem,
    SearchableDropdown as EnvSearchableDropdown,
} from '@envestnet/envreact-component-library';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import deepEqual from 'deep-equal';
import React, { ComponentPropsWithoutRef, useContext, useMemo, useState } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps, Label } from './Label';

export interface ISelectOption {
    isCreatableOption?: true;
    label: string;
    value: string;
}

export interface ISelectProps<T extends ISelectOption>
    extends ILabelProps,
        Omit<
            ComponentPropsWithoutRef<typeof EnvSearchableDropdown>,
            'getValueName' | 'onChange' | 'options' | 'renderOption' | 'searchFilter' | 'value'
        > {
    errorMessage?: string;
    isCreatable?: boolean;
    menuIsOpen?: boolean;
    onChange?: (option: T | null) => void;
    onCreateOption?: (inputValue: string) => void;
    optionComponent?: (option: T) => React.ReactElement;
    options?: Array<T>;
    showCreateWhenEmpty?: boolean;
    showSearchIcon?: boolean;
    value?: string | T;
    onSearchAsync?: (term: string) => void;
}

const isISelectOption = (toBeDetermined: string | ISelectOption | undefined): toBeDetermined is ISelectOption => {
    if ((toBeDetermined as ISelectOption)?.label) {
        return true;
    }
    return false;
};

const creatableOption: ISelectOption = {
    // included so "new" option is still shown when search is empty
    isCreatableOption: true,
    label: '',
    value: '',
};

const getOptions = <T extends ISelectOption>(options: T[], showCreateOption: boolean): T[] => [
    ...options,
    ...(showCreateOption ? [creatableOption as T] : []),
];

const defaultRenderOption =
    (searchTerm: string) =>
    <T extends ISelectOption>(option: T): JSX.Element => {
        if (option.isCreatableOption) {
            return (
                <EnvDropdownItem.Basic key="creatable" value={option}>
                    Add '{searchTerm || ''}'
                </EnvDropdownItem.Basic>
            );
        }
        return (
            <EnvDropdownItem.Basic key={option.value} value={option}>
                {option.label}
            </EnvDropdownItem.Basic>
        );
    };

const renderSelectedValue = <T extends ISelectOption>(option: T) => option.label;

const Select = <T extends ISelectOption>({
    errorMessage,
    isCreatable = false,
    label,
    labelClassName,
    menuIsOpen,
    onChange = () => ({}),
    onClose,
    onCreateOption,
    optionComponent,
    options,
    placeholder,
    showCreateWhenEmpty = false,
    showSearchIcon,
    value,
    onSearchAsync,
    ...rest
}: ISelectProps<T>): JSX.Element => {
    const valueIsSet = typeof value !== 'undefined' && value !== null;
    const foundValue =
        (valueIsSet &&
            options?.find(option =>
                isISelectOption(value)
                    ? deepEqual(option.value, value?.value) || deepEqual(option.value, value?.label)
                    : deepEqual(option.value, value)
            )) ||
        '';
    const [searchTerm, setSearchTerm] = useState<string>('');
    const showCreateOption = isCreatable && (showCreateWhenEmpty || !!searchTerm);
    const memoizedOptions = useMemo(() => getOptions(options || [], showCreateOption), [options, showCreateOption]);
    const renderOption = optionComponent ? optionComponent : defaultRenderOption(searchTerm);
    const fieldSetContext = useContext(FieldSetContext) || {};

    const handleChange = (value: T) => {
        if (value.isCreatableOption) {
            return onCreateOption?.(searchTerm);
        }
        return onChange(value);
    };

    return (
        <>
            <Label label={label} labelClassName={labelClassName} />
            <EnvSearchableDropdown
                getValueName={renderSelectedValue}
                icon={showSearchIcon ? faSearch : undefined}
                iconClass="text-divider"
                maxHeight={450}
                onChange={handleChange}
                onClose={() => {
                    setSearchTerm('');
                    onClose?.();
                }}
                options={memoizedOptions}
                placeholder={placeholder || 'Select One'}
                renderOption={renderOption}
                searchFilter={(options: T[], term: string) => {
                    if (onSearchAsync) {
                        onSearchAsync(term);
                        return [];
                    }
                    setSearchTerm(term);
                    return options.filter(
                        o => o.isCreatableOption || o.label.toLowerCase().includes(term.toLowerCase())
                    );
                }}
                showMenuOnMount={menuIsOpen}
                value={foundValue}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            />
            {errorMessage && <p className="mt-2 text-sm text-error">{errorMessage}</p>}
        </>
    );
};

export { Select };
