import { IBizEquityBusiness, IBizEquityYearlyStatement } from '@api';

const currentYear = new Date().getFullYear();
const isNullOrUndefined = (dataPoint: number | null | undefined) => dataPoint === null || dataPoint === undefined;
type GraphKey = keyof Pick<
    IBizEquityYearlyStatement,
    'accountsReceivables' | 'fixedAssets' | 'income' | 'inventory' | 'revenue'
>;

const totalDebt = (statement: IBizEquityYearlyStatement | undefined): number | null => {
    if (!statement) {
        return null;
    }

    if (
        isNullOrUndefined(statement.accountsPayables) &&
        isNullOrUndefined(statement.otherShortTermLiabilities) &&
        isNullOrUndefined(statement.bankLoans) &&
        isNullOrUndefined(statement.otherLongTermLiabilities)
    ) {
        return null;
    }

    return (
        (statement.accountsPayables || 0) +
        (statement.otherShortTermLiabilities || 0) +
        (statement.bankLoans || 0) +
        (statement.otherLongTermLiabilities || 0)
    );
};

export const getTotalDebtOverTime = (statements: IBizEquityBusiness['yearlyStatements']): number[] | null => {
    const twoYearsAgo = totalDebt(statements?.[currentYear - 2]);
    const oneYearAgo = totalDebt(statements?.[currentYear - 1]);
    const thisYear = totalDebt(statements?.[currentYear]);

    if (isNullOrUndefined(twoYearsAgo) || isNullOrUndefined(oneYearAgo) || isNullOrUndefined(thisYear)) {
        return null;
    }

    return [twoYearsAgo as number, oneYearAgo as number, thisYear as number];
};

export const getDataOverTime = (
    statements: IBizEquityBusiness['yearlyStatements'],
    dataKey: GraphKey
): number[] | null => {
    const twoYearsAgo = statements?.[currentYear - 2]?.[dataKey];
    const oneYearAgo = statements?.[currentYear - 1]?.[dataKey];
    const thisYear = statements?.[currentYear]?.[dataKey];

    if (isNullOrUndefined(twoYearsAgo) || isNullOrUndefined(oneYearAgo) || isNullOrUndefined(thisYear)) {
        return null;
    }

    return [twoYearsAgo as number, oneYearAgo as number, thisYear as number];
};
