import { IBusiness } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useBizEquityBusiness, useBusinessValuation } from '~/hooks';
import { getBizEquityBusiness } from '~/sampleData';
import ValuationOptions from './valuation/ValuationOptions';
import BizEquityViews from './views';

interface IProps {
    business: IBusiness;
}

const ConnectedPage = ({ business }: IProps): JSX.Element => {
    const businessValuationQuery = useBusinessValuation(business.id, business.isSampleCase);
    const bizEquityBusinessQuery = useBizEquityBusiness(business.id, !business.isSampleCase);
    const isError = isTrueForAnyQuery('isError', businessValuationQuery, bizEquityBusinessQuery);
    const isLoading = isTrueForAnyQuery('isLoading', businessValuationQuery, bizEquityBusinessQuery);
    const {
        data: bizEquityBusiness = businessValuationQuery.data?.value
            ? getBizEquityBusiness(businessValuationQuery.data.value)
            : undefined,
    } = bizEquityBusinessQuery;

    if (isError) return <ErrorPage />;
    if (isLoading || !bizEquityBusiness) return <LoadingIndicator />;

    return bizEquityBusiness.hasCompletedDataEntry ? (
        <BizEquityViews bizEquityBusiness={bizEquityBusiness} business={business} />
    ) : (
        <ValuationOptions bizEquityBusiness={bizEquityBusiness} businessId={business.id} />
    );
};

export default ConnectedPage;
