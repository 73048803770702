import { IBusiness, IEmployee } from '@api';
import { tabContainerClassName } from '~/components';
import { Redirect, Route, Switch, useHistory, useParams } from '~/routing';
import NavTab from './NavTab';
import FamilyPanel from './panels/Family';
import Income from './panels/Income';
import Shares from './panels/Shares';
import Team from './panels/Team';
import Values from './panels/Values';

export interface IEditState {
    isOpen: boolean;
    employee?: IEmployee;
}

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
    onEditEmployee: (employee: IEmployee) => void;
}

interface IParams {
    tabId?: string;
}

export enum TabId {
    Family = 'Family',
    Income = 'Income',
    Shares = 'Shares',
    Team = 'Team',
    Values = 'Values',
}

const sanitizeTabId = (tabId: string | undefined): TabId => {
    if (typeof tabId === undefined) return TabId.Values;
    return TabId[tabId as keyof typeof TabId] ?? TabId.Values;
};

interface ITeamValueTabOptions {
    business: IBusiness;
}

const TeamValueTabOptions = ({ business }: ITeamValueTabOptions) => {
    const tabId = sanitizeTabId(useParams<IParams>().tabId);
    const history = useHistory();
    const navigate = (tabId: TabId) => () => history.push(`/${business.id}/Present/TeamValues/${tabId}`);
    return (
        <nav aria-label="Tabs" className={tabContainerClassName}>
            <NavTab isActive={tabId === TabId.Values} onClick={navigate(TabId.Values)}>
                Values
            </NavTab>
            <NavTab isActive={tabId === TabId.Shares} onClick={navigate(TabId.Shares)}>
                Shares
            </NavTab>
            <NavTab isActive={tabId === TabId.Income} onClick={navigate(TabId.Income)}>
                Income
            </NavTab>
            <NavTab isActive={tabId === TabId.Family} onClick={navigate(TabId.Family)}>
                Family
            </NavTab>
            <NavTab isActive={tabId === TabId.Team} onClick={navigate(TabId.Team)}>
                Team
            </NavTab>
        </nav>
    );
};

const TeamValueTabContent = ({ business, employees, onEditEmployee }: IProps) => {
    return (
        <Switch>
            <Route path={`/:businessId/Present/TeamValues/${TabId.Values}`}>
                <Values employees={employees} onEditEmployee={onEditEmployee} />
            </Route>
            <Route path={`/:businessId/Present/TeamValues/${TabId.Shares}`}>
                <Shares business={business} employees={employees} onEditEmployee={onEditEmployee} />
            </Route>
            <Route path={`/:businessId/Present/TeamValues/${TabId.Income}`}>
                <Income employees={employees} onEditEmployee={onEditEmployee} />
            </Route>
            <Route path={`/:businessId/Present/TeamValues/${TabId.Family}`}>
                <FamilyPanel businessId={business.id} employees={employees} onEditEmployee={onEditEmployee} />
            </Route>
            <Route path={`/:businessId/Present/TeamValues/${TabId.Team}`}>
                <Team employees={employees} />
            </Route>
            <Redirect to={`/${business.id}/Present/TeamValues/${TabId.Values}`} />
        </Switch>
    );
};

const TeamValuesTabs = (props: IProps): JSX.Element => {
    return (
        <>
            <Route path="/:businessId/Present/TeamValues/:tabId?">
                <TeamValueTabOptions business={props.business} />
            </Route>
            <TeamValueTabContent {...props} />
        </>
    );
};

export default TeamValuesTabs;
