import { IDocumentList, IDocumentPathSegment } from '@api';
import { Fragment } from 'react';
import { useAppSelector } from '~/redux/hooks';
import { Link } from '~/routing';

import { getRootFolderName } from './utils/rootFolder';
import { getFolderRoute } from './utils/routes';

interface IProps {
    folder: IDocumentList;
    segments: IDocumentPathSegment[];
}

const Breadcrumbs = ({ folder, segments }: IProps): JSX.Element => {
    const { rootFolder } = useAppSelector(s => s.documentCenter);
    if (!rootFolder) return <></>;
    const isRootFolder = folder.folderId === rootFolder.folderId;
    const rootFolderName = getRootFolderName(rootFolder);

    return (
        <h3 className="text-2xl font-medium flex mb-0 leading-none items-center">
            {segments
                .filter(s => s.id !== folder.folderId)
                .map((s, index) => (
                    <Fragment key={s.id}>
                        <Link to={getFolderRoute(s.id)} className="mr-2 text-information" draggable={false}>
                            {index === 0 ? rootFolderName : s.name}
                        </Link>
                        <div className="mr-2">/</div>
                    </Fragment>
                ))}
            <div>{isRootFolder ? rootFolderName : folder.name}</div>
            {/* <Can hasEntitlement={IUserEntitlementType.DocumentShare} condition={!isRootFolder}>
                <ShareCurrentFolderButton folder={folder} />
            </Can> */}
        </h3>
    );
};

export default Breadcrumbs;
