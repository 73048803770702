import { Checkbox as EnvCheckbox } from '@envestnet/envreact-component-library';
import { ComponentState } from '@envestnet/envreact-component-library/dist/components/enums';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps } from './Label';

interface IProps
    extends Omit<ILabelProps, 'label'>,
        Omit<
            ComponentPropsWithoutRef<typeof EnvCheckbox>,
            'checked' | 'className' | 'disabled' | 'labelClass' | 'onClick' | 'reversed' | 'state' | 'value'
        > {
    checked?: boolean;
    className?: string;
    disabled?: boolean;
    errorMessage?: string;
    onChange?: (value: string, isChecked: boolean) => void;
    value: string;
}

const Checkbox = ({
    checked = false,
    disabled = false,
    className,
    errorMessage,
    label,
    labelClassName,
    onChange,
    value,
    ...rest
}: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return (
        <div className={className}>
            <EnvCheckbox
                checked={checked}
                disabled={fieldSetContext.disabled || disabled}
                error={errorMessage}
                label={label}
                labelClass={labelClassName}
                onClick={(value, isChecked) => (onChange ? onChange(value, isChecked) : true)}
                state={ComponentState.Normal}
                value={value}
                {...rest}
            />
        </div>
    );
};

export { Checkbox };
