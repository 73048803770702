import { IBusiness } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBizEquityLoginStatus } from '~/hooks';
import Authenticated from './Authenticated';
import UnauthenticatedPage from './Unauthenticated';

interface IProps {
    business: IBusiness;
}

const BizEquityPage = ({ business }: IProps): JSX.Element => {
    const {
        data: loginStatus = business.isSampleCase ? { isLoggedIn: business.isSampleCase } : null,
        isError,
        isLoading,
    } = useBizEquityLoginStatus(!business.isSampleCase);

    if (isError) return <ErrorPage />;
    if (isLoading || !loginStatus) return <LoadingIndicator />;

    return loginStatus.isLoggedIn ? (
        <Authenticated business={business} />
    ) : (
        <UnauthenticatedPage loginStatus={loginStatus} />
    );
};

export default BizEquityPage;
