import { IEmployee } from '@api';
import NumberFormatter from '~/utils/numberFormatter';
import { GridView } from '../GridView';

interface IProps {
    employees: IEmployee[];
    onEditEmployee: (employee: IEmployee) => void;
}

const Income = ({ employees, onEditEmployee }: IProps): JSX.Element => {
    const employeesSortedByIncome = [...employees];
    employeesSortedByIncome.sort((a, b) => (b.salary ?? 0) - (a.salary ?? 0));

    return (
        <>
            <div className="border-t border-background2 pt-3"></div>
            <GridView
                employees={employeesSortedByIncome}
                onClickEmployee={onEditEmployee}
                renderValue={(employee: IEmployee) => (
                    <div className="pr-3">
                        {employee.salary ? `$${NumberFormatter.formatNumber(employee.salary)}` : '--'}
                    </div>
                )}
            />
        </>
    );
};

export default Income;
