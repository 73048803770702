import { useBoundingClientRect } from '~/hooks';
import { lineStrokeWidth } from './constants';

const svgHeight = 50;
const horizontalLineY = 30;

interface IProps {
    ownerIds: string[];
    showLines?: boolean;
}

const CurrentOwnersAndLines = ({ children, ownerIds, showLines }: React.PropsWithChildren<IProps>): JSX.Element => {
    const [containerGridRect, containerGridRef] = useBoundingClientRect();
    const containerSvgWidth = containerGridRect?.width || 0;
    const [ownersGridRect, ownersGridRef] = useBoundingClientRect();
    const ownerLinesSvgWidth = ownersGridRect?.width || 0;
    const ownerEntityCardWidth = ownerLinesSvgWidth / ownerIds.length;

    return (
        <>
            <div className="w-full" ref={containerGridRef}></div>
            <div className="flex justify-center mx-auto">
                <div
                    className={`grid gap-3`}
                    ref={ownersGridRef}
                    style={{ gridTemplateColumns: `repeat(${ownerIds.length}, 1fr)` }}
                >
                    {children}
                </div>
            </div>
            {showLines && (
                <>
                    <svg className="mx-auto" height={svgHeight} width={ownerLinesSvgWidth}>
                        {ownerIds.map((id, i) => (
                            <line
                                key={id}
                                className="stroke-current text-textDisabled"
                                strokeWidth={lineStrokeWidth}
                                x1={ownerEntityCardWidth * (i + 1) - ownerEntityCardWidth / 2}
                                y1={0}
                                x2={ownerEntityCardWidth * (i + 1) - ownerEntityCardWidth / 2}
                                y2={svgHeight}
                            />
                        ))}
                        <line
                            className="stroke-current text-textDisabled"
                            strokeWidth={lineStrokeWidth}
                            x1={ownerEntityCardWidth / 2}
                            y1={svgHeight - lineStrokeWidth / 2}
                            x2={ownerLinesSvgWidth - ownerEntityCardWidth / 2}
                            y2={svgHeight - lineStrokeWidth / 2}
                        />
                    </svg>
                    <svg className="mx-auto" height={horizontalLineY} width={containerSvgWidth}>
                        <line
                            className="stroke-current text-textDisabled"
                            strokeWidth={lineStrokeWidth}
                            x1={containerSvgWidth / 2}
                            y1={0}
                            x2={containerSvgWidth / 2}
                            y2={horizontalLineY}
                        />
                    </svg>
                </>
            )}
        </>
    );
};

export default CurrentOwnersAndLines;
