import { IEmployee } from '@api';
import { GridView } from '../GridView';
import EmployeeRating from '../rating/EmployeeRating';
import RatingLegend from '../rating/RatingLegend';
import { getAverageRatingForEmployee } from '../rating/utils';

interface IProps {
    employees: IEmployee[];
    onEditEmployee: (employee: IEmployee) => void;
}

const Values = ({ employees, onEditEmployee }: IProps): JSX.Element => {
    const employeesSortedByRating = [...employees];
    employeesSortedByRating.sort((a, b) => {
        const aRating = getAverageRatingForEmployee(a);
        const bRating = getAverageRatingForEmployee(b);
        return bRating - aRating;
    });

    return (
        <>
            <div className="border-t border-background2 pt-3"></div>
            <RatingLegend />
            <GridView
                employees={employeesSortedByRating}
                onClickEmployee={onEditEmployee}
                renderValue={(employee: IEmployee) => (
                    <EmployeeRating className="w-10 h-10" rating={getAverageRatingForEmployee(employee)} />
                )}
            />
        </>
    );
};

export default Values;
