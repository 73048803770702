import { IInnerZoneEmployeeLinkType, IInnerZoneEmployeeProfileType } from '@api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBolt, faLightbulb, faNetworkWired, faUserCheck } from '@fortawesome/pro-light-svg-icons';

export const linkTypeColor: Record<IInnerZoneEmployeeLinkType, string> = {
    Moderate: 'caution',
    Strongest: 'positive',
    Weakest: 'negative',
};

export const profileTypeColor: Record<IInnerZoneEmployeeProfileType, string> = {
    BottomLiner: 'info-F',
    BridgeBuilder: 'info-E',
    Energizer: 'info-D',
    Thinker: 'info-A',
};

export const profileTypeDisplay: Record<IInnerZoneEmployeeProfileType, string> = {
    BottomLiner: 'Bottom Liner',
    BridgeBuilder: 'Bridge Builder',
    Energizer: 'Energizer',
    Thinker: 'Thinker',
};

export const profileTypeIcon: Record<IInnerZoneEmployeeProfileType, IconProp> = {
    BottomLiner: faUserCheck,
    BridgeBuilder: faNetworkWired,
    Energizer: faBolt,
    Thinker: faLightbulb,
};
