import { IEmployee } from '@api';
import { modelKey, ratingCategories, RatingCategory, RatingColor } from './models';

export const formatRating = (n?: number | null): string => {
    if (n === null || n === undefined) {
        return '--';
    }
    return Math.round(n).toFixed(0);
};

export const getAverageRatingForEmployee = (employee: IEmployee): number => {
    return (
        ((employee.ratingGrowth ?? 0) +
            (employee.ratingOperations ?? 0) +
            (employee.ratingProduct ?? 0) +
            (employee.ratingSales ?? 0) +
            (employee.ratingTeam ?? 0) +
            (employee.ratingTechnology ?? 0)) /
        6
    );
};

export const hasRating = (employee?: IEmployee): boolean => !!employee && getAverageRatingForEmployee(employee) > 0;

export const getTeamAverage = (employees: IEmployee[]): Record<RatingCategory, number> => {
    const employeesWithScore = employees.filter(hasRating);
    const total = {} as Record<RatingCategory, number>;
    return employeesWithScore.reduce((result, employee) => {
        for (const category of ratingCategories) {
            total[category] = (total[category] ?? 0) + (employee[modelKey[category]] ?? 0);
            result[category] = total[category] / employeesWithScore.length;
        }
        return result;
    }, {} as Record<RatingCategory, number>);
};

const ratingMap: Record<number, RatingColor> = {
    1: RatingColor.Lowest,
    2: RatingColor.Low,
    3: RatingColor.Average,
    4: RatingColor.High,
    5: RatingColor.Highest,
};

export const getRatingColor = (rating?: number | null): RatingColor => {
    if (!rating) {
        return RatingColor.Highest;
    }

    const cappedRating = Math.min(5, Math.max(1, rating));
    return ratingMap[Math.round(cappedRating)];
};
