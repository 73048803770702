import { IEmployee, IEmployeeCreateRequest, IEmployeeUpdateRequest } from '@api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useAddEmployee, useEmployees, useUpdateEmployee } from '~/hooks';
import { IEmployeeCsvRow, isIEmployeeCsvRow } from '~/pages/business/employees/model';
import { setIsUploading, setPendingUploads } from '~/redux/employeeUpload';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { stringToBoolean, stringToNumber } from '~/utils/stringUtils';

const formatDateString = (dateString: string | null | undefined): string | undefined => {
    const date = moment(dateString, false);
    if (!date.isValid()) return undefined;
    return date.format('YYYY-MM-DD');
};

const mapCsvToApi = (employee: IEmployeeCsvRow): IEmployeeCreateRequest | IEmployeeUpdateRequest => ({
    dateOfBirth: formatDateString(employee.dateOfBirth),
    department: employee.department,
    disabilityInsuranceCoverage: stringToNumber(employee.disabilityInsuranceCoverage),
    emailAddress: employee.emailAddress ? employee.emailAddress : undefined,
    firstName: employee.firstName,
    isOwner: stringToBoolean(employee.isOwner),
    lastName: employee.lastName,
    lifeInsuranceCoverage: stringToNumber(employee.lifeInsuranceCoverage),
    salary: stringToNumber(employee.salary),
    shares: stringToNumber(employee.shares),
    title: employee.title,
});

const findEmployee = (employee: IEmployeeCsvRow, employees: IEmployee[]) => {
    if (employee.id) {
        return employees.find(e => e.id === employee.id);
    }
    if (employee.emailAddress) {
        return employees.find(e => e.emailAddress === employee.emailAddress);
    }
    return employees.find(
        e =>
            e.firstName?.toUpperCase() === employee.firstName?.toUpperCase() &&
            e.lastName?.toUpperCase() === employee.lastName?.toUpperCase()
    );
};

type OnConfirmReturn = () => Promise<void>;

export const useOnConfirm = (businessId: string | undefined): OnConfirmReturn => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { pendingUploads } = useAppSelector(s => s.employeeUpload);

    const { data: employees } = useEmployees(businessId ?? '', businessId !== undefined);
    const addEmployee = useAddEmployee(businessId ?? '');
    const updateEmployee = useUpdateEmployee(businessId ?? '');

    if (businessId === undefined || employees === undefined) {
        return async () => {
            /* Empty */
        };
    }
    return async () => {
        if (!pendingUploads) {
            return;
        }

        dispatch(setIsUploading(true));

        for (let index = 0; index < pendingUploads.length; index++) {
            const { data, errors, rowNumber } = pendingUploads[index];
            if (Object.keys(errors)?.length > 0) {
                continue;
            }
            const existingEmployee = findEmployee(data, employees);
            try {
                const mapped = isIEmployeeCsvRow(data) ? mapCsvToApi(data) : data;
                let employee = existingEmployee;
                if (employee) {
                    await updateEmployee.mutateAsync({
                        ...employee,
                        employeeId: employee.id,
                        ...mapped,
                    });
                } else {
                    employee = await addEmployee.mutateAsync({
                        businessId,
                        ...mapped,
                    });
                }
            } catch (error) {
                console.error(`Error uploading employee row ${rowNumber}`, error);
            }
        }
        dispatch(setIsUploading(false));
        dispatch(setPendingUploads(undefined));

        const newLocation = history.location.pathname.replace('/Upload', '');
        history.push(newLocation);
    };
};
