import { useState } from 'react';
import { History, Navigation, Pagination, Swiper as SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Carousel.scss';
import { CarouselNavButton } from './CarouselNavButton';

interface IProps {
    children: React.ReactNode[];
    historyKey: string;
    slideNavs?: string[];
}

SwiperCore.use([Navigation, Pagination, History]);

const paginationSettings = (slideNavs?: string[]) => {
    if (slideNavs) {
        return {
            clickable: true,
            el: '.swiper-pager',
            bulletActiveClass: 'text-action border-action',
            bulletInactiveClass: '',
            renderBullet: (index: number, className: string) => {
                return `<div class="${className} hover:text-textSubtle hover:border-textSubtle border-transparent group inline-flex items-center p-4 border-t-2 font-medium text-sm cursor-pointer">${slideNavs[index]}</div>`;
            },
        };
    }
    return false;
};

const Carousel = ({ children, historyKey, slideNavs }: IProps): JSX.Element => {
    const [slideIndex, setSlideIndex] = useState(0);

    return (
        <>
            <div className="h-full overflow-y-auto p-10">
                <Swiper
                    autoHeight={true}
                    className="flex flex-col min-h-full"
                    defaultValue={slideIndex}
                    history={{
                        key: historyKey,
                    }}
                    navigation={{
                        nextEl: '.swiper-next-btn',
                        prevEl: '.swiper-previous-btn',
                    }}
                    pagination={paginationSettings(slideNavs)}
                    onSlideChange={swiper => setSlideIndex(swiper.activeIndex)}
                    spaceBetween={250}
                >
                    {children.map((c, i) => (
                        <SwiperSlide data-history={`${i + 1}`} key={i}>
                            {c}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {slideNavs && (
                <div className="flex justify-between bottom-0 left-0 right-0 bg-background z-10">
                    <CarouselNavButton className="swiper-previous-btn" invisible={slideIndex === 0} type="previous" />
                    <div className="flex-1 border-t border-background2" />
                    <div className="border-t border-background2">
                        <nav className="swiper-pager flex" style={{ marginTop: '-2px' }}></nav>
                    </div>
                    <div className="flex-1 border-t border-background2" />
                    <CarouselNavButton
                        className="swiper-next-btn float-right"
                        invisible={slideIndex === children.length - 1}
                        type="next"
                    />
                </div>
            )}
        </>
    );
};

export { Carousel };
