import { NumericInput as EnvNumericInput } from '@envestnet/envreact-component-library';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps, Label } from './Label';

export interface INumericInputProps
    extends ILabelProps,
        Omit<ComponentPropsWithoutRef<typeof EnvNumericInput>, 'className' | 'label' | 'nFractional'> {
    className?: string;
    precision?: number;
}

const NumericInput = ({
    className,
    label,
    labelClassName,
    precision = 0,
    ...rest
}: INumericInputProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return (
        <div className={className}>
            <Label label={label} labelClassName={labelClassName} />
            <EnvNumericInput
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                autoComplete="off"
                allowEmpty={true}
                nFractional={precision}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            />
        </div>
    );
};

export { NumericInput };
