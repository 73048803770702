import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { useState } from 'react';
import { EmptyStateIcon, ErrorPage } from '~/components';
import { useBusinessValuation } from '~/hooks';
import Card from '../Card';
import CardSkeleton from '../CardSkeleton';
import Header from '../Header';
import NetSaleAnalysis from './NetSaleAnalysis';
import ValuationProjection from './ValuationProjection';

interface IProps {
    businessId: string;
    fullWidth?: boolean;
}

const ValuationCard = ({ businessId, fullWidth }: IProps): JSX.Element => {
    const [growthRate, setGrowthRate] = useState(0.1);
    const { data: businessValuation, isError, isLoading } = useBusinessValuation(businessId);

    if (isError || isLoading) {
        return (
            <Card className="h-full" padding>
                <Header text="Projected Valuation" />
                {isError ? <ErrorPage hideActions iconColor="divider" /> : <CardSkeleton />}
            </Card>
        );
    }

    return (
        <Card className="h-full" padding>
            {businessValuation && businessValuation.value > 0 ? (
                <div className={classNames('grid grid-cols-1 md:grid-cols-2 gap-8', { 'xl:grid-cols-3': fullWidth })}>
                    <div className={fullWidth ? 'xl:col-span-2' : ''}>
                        <ValuationProjection
                            currentValuation={businessValuation.value}
                            growthRate={growthRate}
                            onSetGrowthRate={setGrowthRate}
                        />
                    </div>
                    <NetSaleAnalysis currentValuation={businessValuation.value} growthRate={growthRate} />
                </div>
            ) : (
                <div className="flex flex-col h-full">
                    <Header text="Projected Valuation" />
                    <div className="flex flex-col items-center justify-center flex-1">
                        <EmptyStateIcon icon={faBuilding} />
                        <p className="my-4 text-lg">No valuation added yet.</p>
                    </div>
                </div>
            )}
        </Card>
    );
};

export default ValuationCard;
