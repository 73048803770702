import { IBusiness, IEmployee, IFamilyLimitedPartnership } from '@api';

export type IHaveShares = Pick<IEmployee, 'shares'>;

export const getEmployeesById = (employees: IEmployee[]): Record<string, IEmployee> =>
    employees.reduce((map, employee) => {
        map[employee.id] = employee;
        return map;
    }, {} as Record<string, IEmployee>);

export const getEquityAmountByEmployeeId = (
    business: IBusiness,
    businessValue: number,
    employees: IEmployee[]
): Record<string, number> =>
    employees.reduce((map, employee) => {
        map[employee.id] = businessValue ? businessValue * getEquityPercentage(business, employee) : 0;
        return map;
    }, {} as Record<string, number>);

export const getEquityAmount = (business: IBusiness, businessValue: number, owner: IHaveShares): number =>
    businessValue ? businessValue * getEquityPercentage(business, owner) : 0;

export const getEquityPercentage = (business: Pick<IBusiness, 'sharesOutstanding'>, owner: IHaveShares): number => {
    if (!business.sharesOutstanding) return 0;
    if (!owner.shares) return 0;
    return owner.shares / business.sharesOutstanding;
};

export const getEquityPercentageByEmployeeId = (
    business: Pick<IBusiness, 'sharesOutstanding'>,
    employees: IEmployee[]
): Record<string, number> =>
    employees.reduce((map, employee) => {
        map[employee.id] = getEquityPercentage(business, employee);
        return map;
    }, {} as Record<string, number>);

export const getHeadquarters = (business: IBusiness): string | null => {
    if (!business.city?.length) {
        if (!business.stateOrProvince?.length) {
            return null;
        }

        return business.stateOrProvince;
    }
    if (!business.stateOrProvince?.length) {
        return business.city;
    }

    return `${business.city}, ${business.stateOrProvince}`;
};

export const getLimitedInterestAmount = (
    business: IBusiness,
    businessValue: number,
    partnership: IFamilyLimitedPartnership
): number => {
    const totalPartnershipValue = getEquityAmount(business, businessValue, partnership);
    const limitedInterestAmount = (partnership.limitedPartnerInterest / 100) * totalPartnershipValue;
    return limitedInterestAmount;
};
