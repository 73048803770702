import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    icon: IconDefinition;
    name: string;
    rank?: number;
    total?: number;
}

const ValueRankMetric = ({ icon, name, rank, total }: IProps): JSX.Element => (
    <div className="flex items-center justify-between">
        <div>
            <div className="text-sm">{`Value Rank ${name}`}</div>
            <div>
                <span className="font-semibold text-3xl">{rank ? `${rank}/` : '--'}</span>
                {total && <span>{total}</span>}
            </div>
        </div>
        <div className="text-5xl text-divider">
            <FontAwesomeIcon icon={icon} />
        </div>
    </div>
);

export default ValueRankMetric;
