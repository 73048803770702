import classNames from 'classnames';
import { TableCell } from '~/components';
import { formatPercentage } from '~/utils/percentageFormatter';

interface ICurrencyTableCellProps {
    background?: string;
    className?: string;
    value?: number | null;
}

const PercentageTableCell = ({
    background = 'bg-background',
    className,
    value,
}: ICurrencyTableCellProps): JSX.Element => (
    <TableCell background={background} className={classNames(className, 'text-right')}>
        {(value && formatPercentage(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })) || '--'}
    </TableCell>
);

export default PercentageTableCell;
