import { IBusiness, IBusinessPageType, IMe, IUserRoleType } from '@api';
import { useState } from 'react';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useBusiness, useCurrentUser } from '~/hooks';
import { Redirect, Route, Switch, useParams } from '~/routing';
import { BusinessParams } from '..';
import BusinessPageHeader from '../header/BusinessPageHeader';
import BizEquityPage from './bizEquity';
import BusinessForecast from './bonusRight/BusinessForecastPage';
import EmployeeRewards from './bonusRight/EmployeeRewardsPage';
import ExecutivePrograms from './bonusRight/ExecutiveProgramsPage';
import BusinessPageSideBar from './BusinessPageSideBar';
import CorporateStrategies from './corporateStrategies';
import DocumentCenter from './documentCenter';
import Education from './education';
import InnerZone from './innerZone';
import Observations from './observations';
import Purpose from './purpose';
import Scorecard from './scorecard/Scorecard';
import SuccessionPlan from './successionPlan';
import TeamValues from './teamValues';

const getPages = (currentUser: IMe, business: IBusiness): IBusinessPageType[] => {
    if (currentUser.role === IUserRoleType.BusinessOwner) {
        return business.pages.filter(p => p !== IBusinessPageType.Purpose);
    }
    return business.pages;
};

const BusinessPresent = (): JSX.Element => {
    const [showNav, setShowNav] = useState(false);
    const { businessId } = useParams<BusinessParams>();
    const businessQuery = useBusiness(businessId);
    const currentUserQuery = useCurrentUser();
    const isError = isTrueForAnyQuery('isError', businessQuery, currentUserQuery);
    const isLoading = isTrueForAnyQuery('isLoading', businessQuery, currentUserQuery);
    if (isLoading) return <LoadingIndicator />;
    if (isError || !businessQuery.data || !currentUserQuery.data) return <ErrorPage />;

    const { data: business } = businessQuery;
    const { data: currentUser } = currentUserQuery;
    const pages = getPages(currentUser, business);

    return (
        <>
            <div className={`lg:hidden ${showNav ? '' : 'hidden'}`} onClick={() => setShowNav(false)}>
                <div className="fixed inset-0 flex z-40">
                    <div className="fixed inset-0" aria-hidden="true">
                        <div className="absolute inset-0 bg-highlight opacity-75" />
                    </div>
                    <BusinessPageSideBar className="relative flex-1 max-w-s w-full" business={business} pages={pages} />
                </div>
            </div>
            <div className="hidden lg:flex lg:flex-shrink-0 z-10">
                <div className="flex flex-col w-64">
                    <BusinessPageSideBar
                        className="flex-grow overflow-y-auto border-r border-background2"
                        business={business}
                        pages={pages}
                    />
                </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <BusinessPageHeader business={business} onOpenNav={() => setShowNav(true)} />
                <main
                    className="flex flex-col justify-between flex-1 h-full max-h-full overflow-y-auto focus:outline-none"
                    tabIndex={0}
                >
                    <Switch>
                        {pages.includes(IBusinessPageType.BizEquity) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.BizEquity}`}>
                                <BizEquityPage business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.BonusRight) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.BonusRight}`}>
                                <EmployeeRewards business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.BusinessForecast) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.BusinessForecast}`}>
                                <BusinessForecast business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.ExecutivePrograms) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.ExecutivePrograms}`}>
                                <ExecutivePrograms business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.CorporateStrategies) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.CorporateStrategies}`}>
                                <CorporateStrategies business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.DocumentCenter) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.DocumentCenter}`}>
                                <DocumentCenter />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.Education) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.Education}`}>
                                <Education />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.InnerZone) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.InnerZone}`}>
                                <InnerZone business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.Observations) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.Observations}/:slideId?`}>
                                <Observations business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.Purpose) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.Purpose}/:slideId?`}>
                                <Purpose business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.ScoreCard) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.ScoreCard}`}>
                                <Scorecard business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.SuccessionPlan) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.SuccessionPlan}`}>
                                <SuccessionPlan business={business} />
                            </Route>
                        )}
                        {pages.includes(IBusinessPageType.TeamValues) && (
                            <Route path={`/:businessId/Present/${IBusinessPageType.TeamValues}`}>
                                <TeamValues business={business} />
                            </Route>
                        )}
                        <Redirect to={`/:businessId/Present/${pages[0] || IBusinessPageType.Purpose}/1`} />
                    </Switch>
                </main>
            </div>
        </>
    );
};

export default BusinessPresent;
