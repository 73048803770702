import { IUserEntitlementType } from '@api';
import { faListUl, faTh, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';
import { Button, Can, DropdownMenu, IconButton } from '~/components';
import { DisplayType, ModalType, setSelectedDisplayType, setSelectedModal } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import AddButton from './AddButton';

interface IDisplayTypeButtonProps {
    icon: IconDefinition;
    displayType: DisplayType;
}

const DisplayTypeButton: React.FC<IDisplayTypeButtonProps> = ({ icon, displayType }: IDisplayTypeButtonProps) => {
    const { selectedDisplayType } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    return (
        <IconButton
            className={classnames(
                'p-2',
                { 'text-textLink': selectedDisplayType === displayType },
                { 'text-textDisabled dark:text-highlight': selectedDisplayType !== displayType }
            )}
            icon={icon}
            iconSize="lg"
            onClick={() => dispatch(setSelectedDisplayType(displayType))}
        />
    );
};

const MoreActionsButton = () => {
    const { selectedItems } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    const canRename = Object.keys(selectedItems).length === 1;
    // const canShare = Object.keys(selectedItems).length === 1 && Object.values(selectedItems)[0].isFolder;

    return (
        <DropdownMenu position="right">
            <DropdownMenu.Trigger
                as={Button}
                className="mr-3"
                color="secondary"
                refName="buttonRef"
                size="sm"
                text="More"
            />
            <DropdownMenu.Item onClick={() => dispatch(setSelectedModal({ type: ModalType.Delete }))}>
                Delete
            </DropdownMenu.Item>
            <Can hasEntitlement={IUserEntitlementType.DocumentUpdate}>
                <DropdownMenu.Item onClick={() => dispatch(setSelectedModal({ type: ModalType.Move }))}>
                    Move
                </DropdownMenu.Item>
                <DropdownMenu.Item
                    disabled={!canRename}
                    title={canRename ? '' : 'Must have only 1 item selected to rename.'}
                    onClick={() => dispatch(setSelectedModal({ type: ModalType.Rename }))}
                >
                    Rename
                </DropdownMenu.Item>
            </Can>
            {/* <Can hasEntitlement={IUserEntitlementType.DocumentShare}>
                <DropdownMenu.Item
                    disabled={!canShare}
                    title={canShare ? '' : 'Must have only 1 folder selected to share.'}
                    onClick={() => dispatch(setSelectedModal({ type: ModalType.Share }))}
                >
                    Share
                </DropdownMenu.Item>
            </Can> */}
        </DropdownMenu>
    );
};

const Header = ({ children }: ComponentPropsWithoutRef<'div'>): JSX.Element => {
    const { selectedItems } = useAppSelector(s => s.documentCenter);
    return (
        <>
            <div className="flex justify-between items-center flex-wrap" style={{ minHeight: '2.1rem' }}>
                {children}
                <div className="flex items-center">
                    {Object.keys(selectedItems).length > 0 && <MoreActionsButton />}
                    <DisplayTypeButton displayType={DisplayType.Grid} icon={faTh} />
                    <DisplayTypeButton displayType={DisplayType.Table} icon={faListUl} />
                    <AddButton />
                </div>
            </div>
            <hr className="my-3 w-full border-t border-background2" />
        </>
    );
};

export default Header;
