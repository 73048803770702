import { createContext } from 'react';

type IFieldSetContext = Pick<JSX.IntrinsicElements['fieldset'], 'disabled'>;

export const FieldSetContext = createContext<IFieldSetContext>({});

const FieldSet = ({
    children,
    disabled,
    ...rest
}: React.PropsWithoutRef<JSX.IntrinsicElements['fieldset']>): JSX.Element => {
    return (
        <FieldSetContext.Provider value={{ disabled }}>
            <fieldset {...rest} disabled={disabled}>
                {children}
            </fieldset>
        </FieldSetContext.Provider>
    );
};

export { FieldSet };
