import { IBizEquityBusinessSearchResultItem, IProblemDetails } from '@api';
import {
    CircularProgress as EnvCircularProgress,
    DropdownItem as EnvDropdownItem,
} from '@envestnet/envreact-component-library';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { ISelectOption, Select } from '~/components';
import { useSearchBizEquityBusinesses } from '~/hooks';

interface IProps {
    bizEquityBusinessId?: string;
    className?: string;
    disabled?: boolean;
    onChange: (bizEquityBusinessId: string | undefined) => void;
}

interface IBusinessSelectOption extends ISelectOption {
    description?: string;
}

const errorOptionValue = 'ERROR_OPTION';
const loadingOptionValue = 'LOADING_OPTION';

const BusinessSelectOptionComponent = (props: IBusinessSelectOption) => {
    if (props.value === loadingOptionValue) {
        return (
            <EnvDropdownItem.Basic disabled key={loadingOptionValue} value={props}>
                <div className="flex items-start">
                    <EnvCircularProgress size={16} />
                    <div className="ml-2">Loading...</div>
                </div>
            </EnvDropdownItem.Basic>
        );
    }

    return (
        <EnvDropdownItem.Basic
            key={props.value}
            description={props.description}
            disabled={props.value === errorOptionValue}
            value={props}
        >
            {props.label}
        </EnvDropdownItem.Basic>
    );
};

const errorOption: IBusinessSelectOption = {
    label: 'Sorry, there was an error loading Existing Businesses. Please try another search.',
    value: errorOptionValue,
};

const loadingOption: IBusinessSelectOption = {
    label: '',
    value: loadingOptionValue,
};

const getOptions = (
    queryResult: UseQueryResult<IBizEquityBusinessSearchResultItem[], IProblemDetails>
): IBusinessSelectOption[] => {
    if (queryResult.isError) return [errorOption];
    if (queryResult.isLoading) return [loadingOption];
    if (!queryResult.data) return [];

    return queryResult.data.map<IBusinessSelectOption>(b => ({
        description: b.location || undefined,
        label: b.name,
        value: b.id,
    }));
};

const BusinessSelect = ({ bizEquityBusinessId, className, disabled, onChange }: IProps): JSX.Element => {
    const [search, setSearch] = useState('');
    const queryResult = useSearchBizEquityBusinesses(search);

    return (
        <Select
            className={className}
            disabled={disabled}
            optionComponent={BusinessSelectOptionComponent}
            options={getOptions(queryResult)}
            placeholder="Select Existing Business"
            searchFieldPlaceholder="Search for Existing Businesses"
            value={bizEquityBusinessId}
            onChange={e => onChange(e?.value)}
            onSearchAsync={term => setSearch(term)}
        />
    );
};

export default BusinessSelect;
