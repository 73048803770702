import { IBizEquityIndustry, IProblemDetails } from '@api';
import {
    CircularProgress as EnvCircularProgress,
    DropdownItem as EnvDropdownItem,
} from '@envestnet/envreact-component-library';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { ISelectOption, Select } from '~/components';
import { useSearchBizEquityIndustries } from '~/hooks';

interface IProps {
    errorMessage: string;
    industryId?: string;
    onChange: (industry: string | undefined) => void;
}

interface IIndustrySelectOption extends ISelectOption {
    code?: string;
}

const errorOptionValue = 'ERROR_OPTION';
const loadingOptionValue = 'LOADING_OPTION';

const IndustrySelectOptionComponent = (props: IIndustrySelectOption) => {
    if (props.value === loadingOptionValue) {
        return (
            <EnvDropdownItem.Basic disabled key={loadingOptionValue} value={props}>
                <div className="flex items-start">
                    <EnvCircularProgress size={16} />
                    <div className="ml-2">Loading...</div>
                </div>
            </EnvDropdownItem.Basic>
        );
    }

    return (
        <EnvDropdownItem.Basic key={props.value} disabled={props.value === errorOptionValue} value={props}>
            <span className="mr-3">{props.code}</span>
            {props.label}
        </EnvDropdownItem.Basic>
    );
};

const errorOption: IIndustrySelectOption = {
    label: 'Sorry, there was an error loading Industries. Please try another search.',
    value: errorOptionValue,
};

const loadingOption: IIndustrySelectOption = {
    label: '',
    value: loadingOptionValue,
};

const getOptions = (queryResult: UseQueryResult<IBizEquityIndustry[], IProblemDetails>): IIndustrySelectOption[] => {
    if (queryResult.isError) return [errorOption];
    if (queryResult.isLoading) return [loadingOption];
    if (!queryResult.data) return [];

    return queryResult.data.map<IIndustrySelectOption>(i => ({
        code: i.code || undefined,
        label: i.name,
        value: i.id,
    }));
};

const IndustrySelect = ({ errorMessage, industryId, onChange }: IProps): JSX.Element => {
    const [search, setSearch] = useState('');
    const queryResult = useSearchBizEquityIndustries(search);

    return (
        <Select
            errorMessage={errorMessage}
            label="Industry"
            optionComponent={IndustrySelectOptionComponent}
            options={getOptions(queryResult)}
            searchFieldPlaceholder="Search for Industries"
            value={industryId}
            onChange={e => onChange(e?.value)}
            onSearchAsync={term => setSearch(term)}
        />
    );
};

export default IndustrySelect;
