import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft, faRedo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { Button } from './Button';

interface IProps {
    className?: string;
    fullScreen?: boolean;
    hideActions?: boolean;
    iconColor?: string;
    title?: string;
}

const ErrorPage = (props: IProps): JSX.Element => {
    const { fullScreen = false, title = defaultErrorMessage, hideActions = false, iconColor = 'background2' } = props;
    return (
        <div
            className={classNames(
                'flex flex-col justify-center mx-auto',
                props.className,
                fullScreen ? 'h-screen' : 'h-full'
            )}
        >
            <div className="text-center">
                <FontAwesomeIcon className={`text-${iconColor} mb-3`} icon={faExclamationTriangle} size="3x" />
                <p>{title}</p>
                {!hideActions && (
                    <div className="mt-6">
                        {window.history.length > 1 && (
                            <Button
                                color="link"
                                iconLeft={faArrowLeft}
                                size="lg"
                                text="Go Back"
                                onClick={() => window.history.back()}
                            />
                        )}
                        <Button
                            className="ml-6"
                            color="link"
                            iconLeft={faRedo}
                            size="lg"
                            text="Reload"
                            onClick={() => window.location.reload()}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export { ErrorPage };
