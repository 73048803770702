import { getArrayOfEnum } from '~/utils/enumUtils';
import { RatingCategory } from '../../../teamValues/rating/models';

const middleValue = 3;

export const getInitialValues = (initialValue?: number): Record<RatingCategory, number> =>
    getArrayOfEnum(RatingCategory).reduce(
        (result, key) => ({ ...result, [key]: initialValue ?? middleValue }),
        {} as Record<RatingCategory, number>
    );

const step = 0.4 / 24;

export const getModifier = (modifiers: Record<RatingCategory, number>): number => {
    return 1 + Object.values(modifiers).reduce((total, modifier) => total + modifier - middleValue, 0) * step;
};
