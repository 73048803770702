import CurrencyFormatter from '~/utils/currencyFormatter';

interface IProps {
    baseValue: number;
    currentModifier: number;
}

const varianceUpOrDown = 0.2;
const gutterWidth = (1 / 12) * 100;
const bodyWidth = 100 - 2 * gutterWidth;

const EstimatedValueLine = ({ baseValue, currentModifier }: IProps): JSX.Element => {
    const min = baseValue * (1 - varianceUpOrDown);
    const max = baseValue * (1 + varianceUpOrDown);
    const leftWidth = ((currentModifier - 1) / varianceUpOrDown / 2 + 0.5) * bodyWidth;
    const rightWidth = bodyWidth - leftWidth;
    const leftMargin = gutterWidth + leftWidth;
    return (
        <div className="flex flex-col">
            <div className="overflow-visible w-0" style={{ marginLeft: `${leftMargin}%` }}>
                <div className="flex flex-col items-center">
                    <div className="font-medium text-sm">Potential&nbsp;Value</div>
                    <div className="font-semibold text-3xl">
                        {CurrencyFormatter.format(baseValue * currentModifier)}
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="border-r border-textDefault h-4 w-1/12" />
                <div className="border-r border-textDefault h-4" style={{ width: `${leftWidth}%` }} />
                <div className="h-4" style={{ width: `${rightWidth}%` }} />
                <div className="border-l border-textDefault h-4 w-1/12" />
            </div>
            <div className="flex">
                <div className="bg-background2 border-r border-textDefault h-2 w-1/12" />
                <div className="bg-negative h-2 w-5/12 relative">
                    <div className="absolute -left-8 top-4 text-center text-2xl font-bold">
                        {CurrencyFormatter.abbreviate(min)}
                    </div>
                </div>
                <div className="bg-affirmation h-2 w-5/12 relative">
                    <div className="absolute -right-8 top-4 text-center text-2xl font-bold">
                        {CurrencyFormatter.abbreviate(max)}
                    </div>
                </div>
                <div className="bg-background2 border-l border-textDefault h-2 w-1/12" />
            </div>
            <div className="flex justify-between mt-12">
                <div>
                    Venture Capital / Private Equity
                    <br />
                    will try to get 20%, because of weakness
                </div>
                <div className="text-right">
                    We believe you have 20%
                    <br />
                    Opportunity w/ areas build up
                </div>
            </div>
        </div>
    );
};

export default EstimatedValueLine;
