import { IDocument } from '@api';
import classnames from 'classnames';
import moment from 'moment';
import { toggleSelectedItem } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { Link } from '~/routing';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';
import FolderIconGridView from './icons/FolderIconGridView';
import SelectionIcon from './icons/SelectionIcon';
import ItemDropdown from './ItemDropdown';
import { getFolderRoute } from './utils/routes';

interface IProps {
    item: IDocument;
}

const ItemFolderGridView = ({ item }: IProps): JSX.Element => {
    const { selectedItems } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();
    const isSelected = selectedItems[item.id] !== undefined;

    return (
        <div
            className={classnames(
                'h-60 w-60 hover:bg-background2 rounded-lg mb-4 mr-4 shadow',
                isSelected ? 'bg-background2' : 'bg-background1'
            )}
            onClick={() => dispatch(toggleSelectedItem(item))}
        >
            <DragAndDropUploadHandler
                className="flex flex-col justify-between items-center rounded-lg"
                folderId={item.id}
            >
                <div className="w-full pl-3 mt-3 flex flex-row justify-between">
                    <SelectionIcon isSelected={isSelected} />
                    <ItemDropdown item={item} />
                </div>
                <div className="text-center w-full">
                    <Link
                        to={getFolderRoute(item.id)}
                        className="relative w-24 mx-auto flex flex-col justify-center items-center cursor-pointer bg-center bg-no-repeat bg-contain"
                        draggable={false}
                        onClick={e => e.stopPropagation()}
                    >
                        <FolderIconGridView icon={item.icon} color={item.color} />
                    </Link>
                    <div className="mt-3">{item.name}</div>
                </div>
                <div className="text-sm w-full px-3 mb-3 flex flex-row justify-between">
                    <div>
                        {item.fileCount} {item.fileCount === 1 ? 'File' : 'Files'}
                    </div>
                    <div>{moment(item.hierarchyUpdatedAt).fromNow()}</div>
                </div>
            </DragAndDropUploadHandler>
        </div>
    );
};

export default ItemFolderGridView;
