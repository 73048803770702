import { BizEquityUserApi, IBizEquityLoginStatus, IBizEquityPostOAuthCodeRequest, IProblemDetails } from '@api';
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getCurrentUserQueryKey } from './user';

const bizEquityUserApi = new BizEquityUserApi(undefined, config.api.baseUrl);
const bizEquityQueryKey = 'bizEquity';

export const getBizEquityCurrentUserQueryKey = (...keyPieces: string[]): QueryKey =>
    getCurrentUserQueryKey(bizEquityQueryKey, ...(keyPieces || []));

export const getBizEquityLoginStatusQueryKey = (): QueryKey => getBizEquityCurrentUserQueryKey('loginStatus');

export const useBizEquityLoginStatus = (enabled = true): UseQueryResult<IBizEquityLoginStatus, IProblemDetails> =>
    useQuery(
        getBizEquityLoginStatusQueryKey(),
        () => bizEquityUserApi.getBizEquityLoginStatus().then(response => response.data),
        {
            enabled,
        }
    );

export const usePostBizEquityAuthorizationCode = (): UseMutationResult<
    void,
    IProblemDetails,
    IBizEquityPostOAuthCodeRequest
> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBizEquityPostOAuthCodeRequest>(
        request => bizEquityUserApi.postBizEquityOAuthCode(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBizEquityCurrentUserQueryKey());
            },
        }
    );

    return mutation;
};
