import { IAdvisor, IUserEntitlementType } from '@api';
import { useState } from 'react';
import { ValidationError } from 'yup';
import { Button, Can, DeleteConfirmationModal, useAvatarInput } from '~/components';
import { useAdvisorAvatar, useDeleteAdvisor, useUpdateAdvisor } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import { useHistory } from '~/routing';
import { errorParser } from '~/utils/errorParser';
import { yupToFormErrors } from '~/utils/yupUtils';
import AdvisorInfoForm, {
    getInitialFormData,
    IAdvisorInfoFormData,
    mapFormDataToApi,
    schemaValidation,
} from './AdvisorInfoForm';

interface IProps {
    advisor: IAdvisor;
}

const AdvisorInfo = ({ advisor }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));

    const [deleteState, setDeleteState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const [formData, setFormData] = useState<IAdvisorInfoFormData>(getInitialFormData(advisor));
    const [isDisabled, setIsDisabled] = useState(false);
    const [newAvatar, setNewAvatar] = useAvatarInput(advisor);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const deleteAdvisor = useDeleteAdvisor(advisor.advisorFirmId);
    const history = useHistory();
    const updateAdvisor = useUpdateAdvisor(advisor.advisorFirmId, advisor.id);
    const updateAdvisorAvatar = useAdvisorAvatar(advisor.advisorFirmId);
    const handleDelete = async () => {
        try {
            await deleteAdvisor.mutateAsync(advisor.id);
            history.push(`/${advisor.advisorFirmId}/Advisors`);
            showSuccess();
        } catch {
            showError();
        } finally {
            setIsDisabled(false);
        }
    };
    const handleSave = async () => {
        try {
            try {
                schemaValidation.validateSync(formData, { abortEarly: false });
            } catch (err: unknown) {
                if (ValidationError.isError(err)) {
                    setValidationErrors(yupToFormErrors(err));
                }
                return;
            }
            setIsDisabled(true);
            await updateAdvisor.mutateAsync({
                ...advisor,
                ...mapFormDataToApi(formData),
            });
            if (newAvatar?.file) {
                await updateAdvisorAvatar.mutateAsync({ avatar: newAvatar.file, advisorId: advisor.id });
            }
            showSuccess();
        } catch (error) {
            const fieldValidationErrors = errorParser.getFieldValidationErrors(error);

            if (fieldValidationErrors) {
                setValidationErrors(fieldValidationErrors);
                return;
            }

            showError();
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Advisor Information</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-textSubtle">
                Enter general information about the advisor.
            </p>
            <div className="space-y-3">
                <AdvisorInfoForm
                    avatar={newAvatar?.url || advisor?.avatar}
                    disabled={isDisabled}
                    emailDisabled={true}
                    errors={validationErrors}
                    formData={formData}
                    onChange={setFormData}
                    onChangeAvatar={setNewAvatar}
                />
                <div className="py-3 border-t border-background2 flex flex-row-reverse justify-between sm:px-0">
                    <Button color="primary" disabled={isDisabled} text="Save" onClick={handleSave} />
                    <Can hasEntitlement={IUserEntitlementType.AdvisorDelete}>
                        <Button
                            color="secondaryDanger"
                            disabled={isDisabled}
                            text="Delete Advisor"
                            onClick={() => setDeleteState({ isOpen: true })}
                        />
                    </Can>
                </div>
            </div>
            <DeleteConfirmationModal
                onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                onConfirm={handleDelete}
                title="Delete Advisor"
                {...deleteState}
            >
                Are you sure you want to delete this advisor? All of his/her data will be permanently removed from our
                servers forever. This action cannot be undone.
            </DeleteConfirmationModal>
        </>
    );
};

export default AdvisorInfo;
