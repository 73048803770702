import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEmployeeDataRow } from '~/pages/business/employees/model';

export interface EmployeeUploadState {
    hideConfirmButton: boolean;
    importerBusinessId?: string;
    importerTokenId?: string;
    isUploading: boolean;
    pendingUploads: IEmployeeDataRow[] | undefined;
}

const initialState: EmployeeUploadState = {
    hideConfirmButton: false,
    importerBusinessId: undefined,
    importerTokenId: undefined,
    isUploading: false,
    pendingUploads: undefined,
};

export const employeeUploadSlice = createSlice({
    name: 'employeeUpload',
    initialState,
    reducers: {
        setHideConfirmationButton: (state, action: PayloadAction<boolean>) => {
            state.hideConfirmButton = action.payload;
        },
        setImporterBusinessId: (state, { payload }: PayloadAction<string>) => {
            state.importerBusinessId = payload;
        },
        setImporterTokenId: (state, { payload }: PayloadAction<string>) => {
            state.importerTokenId = payload;
        },
        setIsUploading: (state, { payload }: PayloadAction<boolean>) => {
            state.isUploading = payload;
        },
        setPendingUploads: (state, { payload }: PayloadAction<IEmployeeDataRow[] | undefined>) => {
            state.pendingUploads = payload;
        },
    },
});

export const {
    setHideConfirmationButton,
    setImporterBusinessId,
    setImporterTokenId,
    setIsUploading,
    setPendingUploads,
} = employeeUploadSlice.actions;

export default employeeUploadSlice.reducer;
