import { IInnerZoneEmployeeUser, IInnerZoneSurveyStatus } from '@api';
import { ComponentPropsWithoutRef } from 'react';

interface IProps {
    employeeUsers: IInnerZoneEmployeeUser[];
}

const UserSurveyProgress = ({ employeeUsers, ...rest }: IProps & ComponentPropsWithoutRef<'div'>): JSX.Element => {
    const completedSurveyCount = employeeUsers.filter(
        u => u.surveyStatus === IInnerZoneSurveyStatus.SurveyCompleted
    ).length;
    const totaluserCount = employeeUsers.length;

    return (
        <div {...rest}>
            <div>
                <span className="text-textLink font-bold">{completedSurveyCount}</span> completed out of{' '}
                <span className="text-textLink font-bold">{totaluserCount}</span> surveys
            </div>
            <div className="mt-1 h-3 rounded overflow-hidden bg-background2">
                <div
                    className="bg-information h-full"
                    style={{ width: totaluserCount && `${(completedSurveyCount / totaluserCount) * 100}%` }}
                />
            </div>
        </div>
    );
};

export default UserSurveyProgress;
