import { IBizEquityBusinessUpdateRequest } from '@api';
import { Label, RadioGroup } from '~/components';
import InfoTooltip from './InfoTooltip';
import PercentageSlider from './PercentageSlider';

interface IProps {
    formData: IBizEquityBusinessUpdateRequest;
    setFormData: (formData: IBizEquityBusinessUpdateRequest) => void;
}

const GrowthForm = ({ formData, setFormData }: IProps): JSX.Element => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 lg:gap-y-6">
            <div className="flex flex-nowrap md:col-span-2 lg:col-span-1">
                <InfoTooltip>
                    <b>Projected Revenue Growth:</b> This slider reflects the company's intermediate/long term rate of
                    growth in terms of "topline" revenues{' '}
                    <i>
                        specifically the projected 5 year "compound annual growth rate" <b>(CAGR)</b>
                    </i>
                    . The higher the projected revenue growth, the higher the future profits and current business value.
                    The combination of revenue growth rate and long term EBITDA margin will impact the discounted cash
                    flow paradigm which is particularly important for valuing high growth, high profit companies.
                </InfoTooltip>
                Projected Revenue Growth
            </div>
            <div className="col-span-1 md:col-span-2 mb-6 lg:mb-0">
                <PercentageSlider
                    onChange={e => setFormData({ ...formData, revenueGrowth: e / 100 })}
                    value={formData.revenueGrowth}
                />
            </div>
            <div className="flex flex-nowrap md:col-span-2 lg:col-span-1">
                <InfoTooltip>
                    <b>Long Term EBITDA Margin:</b> This slider represents expectations as to the long run operating
                    cash flow (EBITDA) margin in future periods once the firm is in "steady state" mode, e.g. year five
                    in the company forecast. EBITDA is a measure of a company's operating profitability and equals
                    earnings before interest, tax, depreciation and amortization. EBITDA margin is defined as EBITDA
                    divided by total revenues. The combination of revenue growth rate and long term EBITDA margin will
                    impact the discounted cash flow paradigm which is particularly important for valuing high growth,
                    high profit companies.
                </InfoTooltip>
                Long Term EBITDA Margin
            </div>
            <div className="col-span-1 md:col-span-2 mb-6 lg:mb-0">
                <PercentageSlider
                    onChange={e => setFormData({ ...formData, ebitdaMarginGrowth: e / 100 })}
                    value={formData.ebitdaMarginGrowth}
                />
            </div>
            <div className="flex flex-nowrap md:col-span-2 lg:col-span-1">
                <InfoTooltip>
                    <b>Percent of Business Recurring:</b> This indicator is a key risk determinant in that building a
                    client base which returns year after year will reduce the riskiness of future cash flows (lower
                    risk, higher value). Repeat customers are easier to work with and tend to spend more money and refer
                    new customers, enhancing their value.
                </InfoTooltip>
                Percent of Business Recurring
            </div>
            <div className="col-span-1 md:col-span-2 mb-6 lg:mb-0">
                <PercentageSlider
                    onChange={e => setFormData({ ...formData, recurringRevenue: e / 100 })}
                    value={formData.recurringRevenue}
                />
            </div>
            <div className="flex col-span-1 lg:col-span-2 xl:col-span-3 flex-col lg:flex-row">
                <div className="flex flex-nowrap">
                    <InfoTooltip>
                        <b>Intellectual Property:</b> Does the business have any intellectual property or goodwill that
                        should be taken into account? The effect of IP on the final multiple is relatively minimal, and
                        this question is largely included for data gathering purposes.
                    </InfoTooltip>
                    <Label
                        labelClassName="mr-4"
                        label="Do you have Intellectual Property, including Trade Secrets or Patents?"
                    />
                </div>
                <RadioGroup
                    className="flex-shrink-0"
                    options={[
                        {
                            label: 'Yes',
                            value: 'true',
                        },
                        {
                            label: 'No',
                            value: 'false',
                        },
                    ]}
                    onChange={e => setFormData({ ...formData, intellectualProperty: e === 'true' })}
                    value={formData.intellectualProperty ? 'true' : 'false'}
                />
            </div>
        </div>
    );
};

export default GrowthForm;
