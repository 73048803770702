import { IEmployee, IFamilyLimitedPartnership, IFamilyLimitedPartnershipCreateRequest } from '@api';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormActions, PercentageInput, SlideOver, SlideOverSizeType } from '~/components';
import { NumericInput } from '~/components/NumericInput';
import { transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';
import { generalPartnerColor, limitedPartnerColor } from './constants';
import InterestGraph from './InterestGraph';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onDelete?: () => Promise<void>;
    onSave: (formData: IFamilyLimitedPartnershipCreateRequest) => void;
    owner: IEmployee;
    partnership?: IFamilyLimitedPartnership;
}

type FlpFormData = Strings<Omit<IFamilyLimitedPartnershipCreateRequest, 'partners' | 'shares'>> &
    Pick<IFamilyLimitedPartnershipCreateRequest, 'shares'> & {
        maxShares: number;
    };

const testInterestSum = (interest1 = 0, interest2 = 0): boolean => interest1 + interest2 === 100;

const schemaValidation = Yup.object().shape({
    discountForControl: Yup.number().min(0).max(99).transform(transformEmptyStringToNull).nullable().label('Discount'),
    discountForMarketability: Yup.number()
        .min(0)
        .max(99)
        .transform(transformEmptyStringToNull)
        .nullable()
        .label('Discount'),
    generalPartnerInterest: Yup.number()
        .min(0.01)
        .max(99.99)
        .transform(transformEmptyStringToNull)
        .required()
        .test('sum', 'Total must equal 100', function (interest) {
            return testInterestSum(interest, this.parent.limitedPartnerInterest);
        })
        .label('General Partner Interest'),
    limitedPartnerInterest: Yup.number()
        .min(0.01)
        .max(99.99)
        .transform(transformEmptyStringToNull)
        .required()
        .test('sum', 'Total must equal 100', function (interest) {
            return testInterestSum(interest, this.parent.generalPartnerInterest);
        })
        .label('Limited Partner Interest'),
    shares: Yup.number()
        .positive()
        .transform(transformEmptyStringToNull)
        .required()
        .test('max', "Shares cannot exceed owner's shares", function (shares = 0) {
            return shares <= this.parent.maxShares;
        })
        .label('Shares'),
});

const getInitialValues = (owner: IEmployee, partnership?: IFamilyLimitedPartnership): FlpFormData => {
    return {
        discountForControl: partnership?.discountForControl?.toString() || '0',
        discountForMarketability: partnership?.discountForMarketability?.toString() || '0',
        employeeId: partnership?.employeeId || owner.id,
        generalPartnerInterest: partnership?.generalPartnerInterest?.toString() || '1',
        limitedPartnerInterest: partnership?.limitedPartnerInterest?.toString() || '99',
        maxShares: owner.shares || 0,
        shares: partnership?.shares || owner.shares || 0,
    };
};

const mapFormDataToApi = (
    formData: FlpFormData,
    partnership?: IFamilyLimitedPartnership
): IFamilyLimitedPartnershipCreateRequest => ({
    ...formData,
    discountForControl: formData.discountForControl?.length ? parseFloat(formData.discountForControl) : 0,
    discountForMarketability: formData.discountForMarketability?.length
        ? parseFloat(formData.discountForMarketability)
        : 0,
    generalPartnerInterest: formData.generalPartnerInterest?.length ? parseFloat(formData.generalPartnerInterest) : 0,
    limitedPartnerInterest: formData.limitedPartnerInterest?.length ? parseFloat(formData.limitedPartnerInterest) : 0,
    partners: partnership?.partners || [],
});

interface InternalInputProps {
    errors: { [key: string]: string };
    formData: FlpFormData;
    label: React.ReactNode;
    propKey: keyof FlpFormData;
    setFormData: React.Dispatch<React.SetStateAction<FlpFormData>>;
}
const InternalPercentageInput = ({ errors, formData, label, propKey, setFormData }: InternalInputProps) => (
    <div className="flex justify-between items-center">
        <div>{label}</div>
        <div className="w-24">
            <PercentageInput
                errorMessage={errors[propKey]}
                label=""
                onChange={data => setFormData({ ...formData, [propKey]: data?.toString() })}
                value={formData[propKey] as number}
            />
        </div>
    </div>
);

const PartnershipForm = ({ isOpen, onClose, onDelete, onSave, owner, partnership }: IProps): JSX.Element => {
    const [formData, setFormData] = useState<FlpFormData>(getInitialValues(owner, partnership));
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const commonInputProps = { errors: validationErrors, formData, setFormData };

    const handleSave = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        const request = mapFormDataToApi(formData, partnership);
        return onSave(request);
    };
    const resetForm = (owner: IEmployee, partnership?: IFamilyLimitedPartnership) => {
        setValidationErrors({});
        setFormData(getInitialValues(owner, partnership));
    };

    useEffect(() => {
        if (isOpen) {
            resetForm(owner, partnership);
        }
    }, [owner, partnership, isOpen]);

    return (
        <SlideOver
            isOpen={isOpen}
            size={SlideOverSizeType.lg}
            stickyFooter={
                <FormActions
                    onCancel={onClose}
                    onDelete={onDelete}
                    onSave={handleSave}
                    setValidationErrors={setValidationErrors}
                />
            }
            title="Family Limited Partnership"
            onClose={onClose}
        >
            <InterestGraph generalPartnerInterest={formData.generalPartnerInterest} />
            <InternalPercentageInput
                {...commonInputProps}
                label={
                    <div>
                        <FontAwesomeIcon icon={faCircle} className={`${generalPartnerColor} mr-2`} />
                        General Partner Interest
                    </div>
                }
                propKey="generalPartnerInterest"
            />
            <InternalPercentageInput
                {...commonInputProps}
                label={
                    <div>
                        <FontAwesomeIcon icon={faCircle} className={`${limitedPartnerColor} mr-2`} />
                        Limited Partner Interest
                    </div>
                }
                propKey="limitedPartnerInterest"
            />
            <hr className="my-4 border-highlight" />
            <InternalPercentageInput
                {...commonInputProps}
                label="Discount due to lack of marketability"
                propKey="discountForMarketability"
            />
            <InternalPercentageInput
                {...commonInputProps}
                label="Discount due to lack of control"
                propKey="discountForControl"
            />
            <div className="flex justify-between items-center">
                <div>
                    <div>{owner.firstName}'s shares to transfer</div>
                    <div className="text-textDisabled text-sm">
                        <span className="font-bold">{owner.shares}</span>
                        &nbsp;shares owned
                    </div>
                </div>
                <div className="w-24">
                    <NumericInput
                        errorMessage={validationErrors.shares}
                        label=""
                        onChange={shares => setFormData({ ...formData, shares: shares ?? 0 })}
                        value={formData.shares}
                    />
                </div>
            </div>
        </SlideOver>
    );
};

export default PartnershipForm;
