import { faTombstoneAlt, faWheelchair, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Tab } from './index';

interface IProps {
    selectedTab: Tab;
    setSelectedTab: (tab: Tab) => void;
}

const TableSelector = ({ selectedTab, setSelectedTab }: IProps): JSX.Element => {
    return (
        <div className="flex space-x-4">
            <Button
                eventName="Loss of Life"
                icon={faTombstoneAlt}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tab={Tab.LossOfLife}
            />
            <Button
                eventName="Illness or Injury"
                icon={faWheelchair}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tab={Tab.IllnessOrInjury}
            />
        </div>
    );
};

interface ButtonProps {
    eventName: string;
    icon: IconDefinition;
    tab: Tab;
}

const Button = ({ eventName, icon, selectedTab, setSelectedTab, tab }: ButtonProps & IProps): JSX.Element => (
    <div
        className={classNames('bg-background1 flex p-5 rounded-xl space-x-4', { 'ring-2': selectedTab === tab })}
        onClick={() => setSelectedTab(tab)}
        role="button"
    >
        <div className="flex items-center">
            <FontAwesomeIcon icon={icon} />
        </div>
        <div className="flex flex-col">
            <div>In the event of</div>
            <div>{eventName}</div>
        </div>
    </div>
);

export default TableSelector;
