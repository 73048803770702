import { Button } from '~/components';
import { lineStrokeWidth } from '../constants';
import { addDataLineHeight } from './constants';

interface IProps {
    onClickAdd: () => void;
}

const AddLimitedPartners = ({ onClickAdd }: IProps): JSX.Element => {
    return (
        <div className="flex flex-col items-center">
            <svg height={addDataLineHeight} width={lineStrokeWidth}>
                <line
                    className="stroke-current text-highlight"
                    strokeWidth={lineStrokeWidth}
                    y1={0}
                    y2={addDataLineHeight}
                />
            </svg>
            <div>
                <Button color="primary" text="Add Limited Partners" onClick={onClickAdd} />
            </div>
        </div>
    );
};

export default AddLimitedPartners;
