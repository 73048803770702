import { Slider as EnvSlider } from '@envestnet/envreact-component-library';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';

interface IProps extends Omit<ComponentPropsWithoutRef<typeof EnvSlider>, 'onChange'> {
    onChange?: (value: number) => void;
}

const Slider = ({ onChange, ...rest }: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = onChange ? (_: any, value: number) => onChange(value) : undefined;

    return (
        <div>
            <EnvSlider
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
                onChange={handleChange}
                maxLabel=" "
                minLabel=" "
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                valueLabelDisplay="auto"
            />
            <div className="flex justify-between">
                <span>{rest.minLabel ?? rest.min}</span>
                <span>{rest.maxLabel ?? rest.max}</span>
            </div>
        </div>
    );
};

export { Slider };
