import { IBusiness, IEmployee } from '@api';
import { LoadingIndicator, Table, TableHead, TableRow } from '~/components';
import { useBonusRightAggregatePaySummary } from '~/hooks/bonusRightEmployeeRewards';
import { getBonusRightSampleAggregatePaySummary } from '~/sampleData';
import BonusRightErrorPage from '../BonusRightErrorPage';
import PoweredBy from '../PoweredBy';
import AllocationSummary from './tableSections/AllocationSummary';
import CompanyPerformance from './tableSections/CompanyPerformance';
import CumulativeValueSharingEbitda from './tableSections/CumulativeValueSharingEbitda';
import CumulativeValueSharingEnterpriseValue from './tableSections/CumulativeValueSharingEnterpriseValue';
import ProjectedCompensationValues from './tableSections/ProjectedCompensationValues';
import ReturnOnTotalRewardsInvestment from './tableSections/ReturnOnTotalRewardsInvestment';
import TotalValueAnalysis from './tableSections/TotalValueAnalysis';

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
}

const BusinessForecast = ({ business, employees }: IProps): JSX.Element => {
    const {
        isError,
        isLoading,
        data: aggregatePaySummary = getBonusRightSampleAggregatePaySummary(employees),
    } = useBonusRightAggregatePaySummary(business.id, !business.isSampleCase);
    if (isError) return <BonusRightErrorPage business={business} reportName="Aggregate Pay Summary" />;
    if (isLoading) return <LoadingIndicator />;
    const { baseValuation, percentProfitToShareholders, returnOnEquity, shareholderEquity, years } =
        aggregatePaySummary;

    return (
        <div className="space-y-10 p-10">
            <div className="mb-6 flex justify-between">
                <h3 className="text-lg leading-6 font-medium mr-2">Business Forecast</h3>
                <PoweredBy business={business} showServicesOnClick={true} />
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <th />
                        {years.map(y => (
                            <th className="font-extrabold text-4xl" key={`label:${y.year}`}>
                                {y.year}
                            </th>
                        ))}
                    </TableRow>
                </TableHead>
                <CompanyPerformance years={years} />
                <ProjectedCompensationValues years={years} />
                <TotalValueAnalysis years={years} />
                <CumulativeValueSharingEnterpriseValue baseValuation={baseValuation} years={years} />
                <CumulativeValueSharingEbitda years={years} />
                <AllocationSummary years={years} />
                <ReturnOnTotalRewardsInvestment
                    percentProfitToShareholders={percentProfitToShareholders}
                    returnOnEquity={returnOnEquity}
                    shareholderEquity={shareholderEquity}
                    years={years}
                />
            </Table>
        </div>
    );
};

export default BusinessForecast;
