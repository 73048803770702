import { Modal as EnvModal } from '@envestnet/envreact-component-library';
import classNames from 'classnames';
import { ReactNode } from 'react';

type width = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

export interface IModalProps {
    children: ReactNode;
    disableBackdropClickClose?: boolean;
    hideHeader?: boolean;
    maxWidth: width;
    open: boolean;
    setOpen: (open: boolean) => void;
    title?: string;
}

const getMaxWidth = (width: width) => {
    return {
        sm: 'sm:max-w-sm',
        md: 'sm:max-w-md',
        lg: 'sm:max-w-lg',
        xl: 'sm:max-w-xl',
        '2xl': 'sm:max-w-xl md:max-w-2xl lg:max-w-2xl',
        '3xl': 'sm:max-w-xl md:max-w-2xl lg:max-w-3xl',
        '4xl': 'sm:max-w-xl md:max-w-2xl lg:max-w-4xl',
        '5xl': 'sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl',
        '6xl': 'sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl',
        '7xl': 'sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl',
    }[width];
};

const Modal = ({
    children,
    disableBackdropClickClose = false,
    hideHeader = false,
    maxWidth,
    open,
    setOpen,
    title,
}: IModalProps): JSX.Element => {
    return (
        <EnvModal
            contentRootContainerClass={classNames(
                'w-full sm:w-screen relative rounded-lg first:p-0',
                getMaxWidth(maxWidth)
            )}
            disableBackdropClickClose={disableBackdropClickClose}
            onClose={() => setOpen(false)}
            open={open}
            position={{
                horizontal: 'center',
                vertical: 'center',
            }}
            hideCloseButton={hideHeader}
        >
            {!hideHeader && (
                <div className="flex p-7 pr-16 bg-background1 rounded-t-lg shadow">
                    <div className="text-xl font-bold">{title}</div>
                </div>
            )}
            {children}
        </EnvModal>
    );
};

export { Modal };
