import { BusinessValuationsApi, IBusinessValuation, IBusinessValuationHistory, IProblemDetails } from '@api';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const businessValuationsApi = new BusinessValuationsApi(undefined, config.api.baseUrl);

export const getBusinessValuationQueryKey = (businessId: string): QueryKey =>
    getBusinessQueryKey(businessId, 'valuation');

export const useBusinessValuation = (
    businessId: string,
    enabled = true
): UseQueryResult<IBusinessValuation, IProblemDetails> =>
    useQuery(
        getBusinessValuationQueryKey(businessId),
        () => businessValuationsApi.getBusinessValuation(businessId).then(response => response.data),
        {
            enabled: !!businessId && enabled,
        }
    );

export const useBusinessValuationHistory = (
    businessId: string
): UseQueryResult<IBusinessValuationHistory[], IProblemDetails> =>
    useQuery(
        getBusinessQueryKey(businessId, 'valuationHistory'),
        () => businessValuationsApi.getHistoryForBusiness(businessId).then(response => response.data),
        {
            enabled: !!businessId,
        }
    );

export const useBusinessValuationSnapshots = (
    businessId: string
): UseQueryResult<IBusinessValuation[], IProblemDetails> =>
    useQuery(
        getBusinessQueryKey(businessId, 'valuationSnapshots'),
        () => businessValuationsApi.getSnapshotsForBusiness(businessId).then(response => response.data),
        {
            enabled: !!businessId,
        }
    );
