import { IBizEquityYearlyStatement } from '@api';
import { SegmentedButton } from '@envestnet/envreact-component-library';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import LegendItem from './LegendItem';
import { Kpi, KpiComparison, kpis, modelKey } from './model';
import Rating from './Rating';
import RatingDetails from './RatingDetails';

interface IProps {
    yearlyStatements: {
        [key: string]: IBizEquityYearlyStatement;
    };
}

export interface IKpiValue {
    comparison?: KpiComparison;
    description: string;
    name: string;
    order: number;
    rating?: number;
    type: Kpi;
}

const getKpis = (yearlyStatement: IBizEquityYearlyStatement): IKpiValue[] =>
    Object.values(Kpi)
        .map(k => {
            const model = yearlyStatement[modelKey[k]];
            const comparison = model?.comparison
                ? KpiComparison[model.comparison as keyof typeof KpiComparison]
                : undefined;

            return {
                ...kpis[k],
                comparison,
                rating: model?.value,
                type: k,
            };
        })
        .sort((a, b) => a.order - b.order);

interface IRatingDetailsState {
    isOpen: boolean;
    kpi?: IKpiValue;
}

const Ratings = ({ yearlyStatements }: IProps): JSX.Element | null => {
    const [yearIndex, setYearIndex] = useState(0);
    const [ratingDetailsState, setRatingDetailsState] = useState<IRatingDetailsState>({
        isOpen: false,
        kpi: undefined,
    });
    const years = Object.keys(yearlyStatements)
        .sort((a, b) => (a > b ? -1 : 1))
        .slice(0, 3);
    const yearlyStatement = yearlyStatements[years[yearIndex]];

    return (
        <div className="flex flex-col h-full space-y-4 w-full">
            <div className="flex justify-between">
                <div className="font-bold text-lg">KPI Ratings</div>
                <SegmentedButton items={years} onChangeSelected={setYearIndex} selectedIndex={yearIndex} />
            </div>
            <div className="flex flex-wrap">
                <LegendItem className="mr-4" color="green" icon={faArrowUp} label="Above Industry Average" />
                <LegendItem color="yellow" icon={faArrowDown} label="Below Industry Average" />
            </div>
            <div className="flex-1 gap-4 grid grid-cols-2 md:grid-cols-3">
                {getKpis(yearlyStatement).map(k => (
                    <Rating {...k} key={k.type} onClick={() => setRatingDetailsState({ kpi: k, isOpen: true })} />
                ))}
            </div>
            <RatingDetails
                {...ratingDetailsState}
                onClose={() => setRatingDetailsState({ ...ratingDetailsState, isOpen: false })}
                yearlyStatements={yearlyStatements}
            />
        </div>
    );
};

export default Ratings;
