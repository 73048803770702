interface IProps {
    observations: string[];
}

const ObservationList = ({ observations }: IProps): JSX.Element => (
    <>
        <h3 className="mb-3 text-lg">Observations</h3>
        <ul>
            {observations.map((o, i) => (
                <li key={i} className="flex items-center mb-3">
                    <div className="h-3 w-3 rounded-full bg-caution mr-3" />
                    {o}
                </li>
            ))}
        </ul>
    </>
);

export default ObservationList;
