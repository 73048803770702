import axios from 'axios';
import { camelCase } from 'lodash';

interface FieldError {
    [fieldName: string]: string[];
}

interface FieldErrors {
    errors: FieldError;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const getFieldValidationErrors = (error: any): { [fieldName: string]: string } | undefined => {
    if (axios.isAxiosError(error)) {
        if (error.response?.status === 400 || error.response?.status === 409) {
            const data = error.response.data as FieldErrors | undefined;
            if (data?.errors) {
                const reducedErrors: { [fieldName: string]: string } = {};
                Object.entries(data.errors).forEach(([fieldName, errors]) => {
                    reducedErrors[camelCase(fieldName)] = errors.join(' ');
                });
                return reducedErrors;
            }
        }
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const isConflictError = (error: any): boolean => axios.isAxiosError(error) && error.response?.status === 409;

export const errorParser = {
    getFieldValidationErrors,
    isConflictError,
};
