import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useRef } from 'react';
import useOutsideClickDetector from '~/hooks/useOutsideClickDetector';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { hideSuccessNotification } from '~/redux/successNotification';
import { defaultTransitionClassNames } from '~/utils/transitions';
import { Button } from '.';

const ConfirmationBar = (): JSX.Element => {
    const { isVisible } = useAppSelector(s => s.successNotification);
    const confirmationBarRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const hide = () => dispatch(hideSuccessNotification());

    useOutsideClickDetector({ ref: confirmationBarRef, handler: hide });

    return (
        <Transition
            show={isVisible}
            className={classNames(defaultTransitionClassNames, 'fixed bottom-0 inset-x-0 transition transform')}
            enterFrom="translate-y-full"
            enterTo="translate-x-0"
            leaveFrom="translate-x-0"
            leaveTo="translate-y-full"
        >
            <div
                ref={confirmationBarRef}
                className="bg-positive text-textDefault flex items-center justify-center flex-wrap py-2 px-2 sm:px-6 lg:px-8 space-x-4"
            >
                <span className="text-xl">
                    <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <p>Changes saved successfully!</p>
                <Button className="text-sm text-textDefault" color="tertiary" text="Dismiss" onClick={hide} />
            </div>
        </Transition>
    );
};

interface IProps {
    children: React.ReactNode;
}

const SuccessNotificationWrapper = ({ children }: IProps): JSX.Element => {
    return (
        <>
            {children}
            <ConfirmationBar />
        </>
    );
};

export default SuccessNotificationWrapper;
