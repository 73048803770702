import { BizEquityRequiredFieldsApi, IBizEquityRequiredFields, IProblemDetails } from '@api';
import { useQuery, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBizEquityCurrentUserQueryKey } from './bizEquityUser';

const bizEquityApi = new BizEquityRequiredFieldsApi(undefined, config.api.baseUrl);

export const useBizEquityRequiredFields = (): UseQueryResult<IBizEquityRequiredFields, IProblemDetails> =>
    useQuery(getBizEquityCurrentUserQueryKey('requiredFields'), () =>
        bizEquityApi.getBizEquityRequiredFields().then(response => response.data)
    );
