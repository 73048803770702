import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideErrorModal } from '~/redux/errorModal';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { Button } from './Button';
import { Modal } from './Modal';

const ErrorModal = (): JSX.Element => {
    const { isVisible, message } = useAppSelector(s => s.errorModal);
    const dispatch = useAppDispatch();
    const hide = () => dispatch(hideErrorModal());

    return (
        <Modal hideHeader maxWidth="md" open={isVisible} setOpen={hide}>
            <div className="px-4 pt-5 pb-4 sm:p-6">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-error">
                    <FontAwesomeIcon aria-hidden="true" icon={faExclamationTriangle} size="lg" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium">{message ? 'Error' : 'Unexpected Error'}</h3>
                    <div className="mt-2">
                        <p className="text-sm">{message}</p>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6">
                    <Button color="primary" fullWidth text="Ok" onClick={hide} />
                </div>
            </div>
        </Modal>
    );
};

export { ErrorModal };
