import { IBusiness } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBizEquityBusinessConnectionStatus } from '~/hooks';
import ConnectedPage from './ConnectedPage';
import UnconnectedPage from './unconnected';

interface IProps {
    business: IBusiness;
}

const Authenticated = ({ business }: IProps): JSX.Element => {
    const {
        data: connectionStatus = business.isSampleCase ? { isConnected: true } : null,
        error,
        isLoading,
    } = useBizEquityBusinessConnectionStatus(business.id, !business.isSampleCase);

    if (isLoading || !connectionStatus) return <LoadingIndicator />;
    if (error) return <ErrorPage />;

    return connectionStatus.isConnected ? (
        <ConnectedPage business={business} />
    ) : (
        <UnconnectedPage business={business} />
    );
};

export default Authenticated;
