import { IBusiness, IEmployee } from '@api';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Avatar, Button, ChartLegend, RadarChart, SlideOver, SlideOverSizeType } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import { useBoundingClientRect } from '~/hooks';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { getArrayOfEnum } from '~/utils/enumUtils';
import NameFormatter from '~/utils/nameFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import { categoryIcon, categoryTitle, modelKey, ratingCategories, RatingCategory } from './models';
import { getTeamAverage } from './utils';

interface IProps {
    business: IBusiness;
    employee?: IEmployee;
    employees: IEmployee[];
    isOpen: boolean;
    onClose: () => void;
    onEditEmployee: () => void;
    onEditRating: () => void;
}

const EmployeeSummarySlideOver = (props: IProps): JSX.Element => {
    const { business, employee, employees, isOpen, onClose, onEditEmployee, onEditRating } = props;
    const sharePercentage = (employee?.shares || 0) / (business.sharesOutstanding || 1);
    const formatOptions = sharePercentage > 0.01 ? { maximumFractionDigits: 0 } : undefined;
    const teamAverage = getTeamAverage(employees);
    const [containerRect, containerRef] = useBoundingClientRect();
    const chartSeries = [
        {
            color: 'primary-600',
            gradients: ['rgba(129, 179, 228, 0.75)', 'rgba(14, 110, 203, 0.5)'],
            name: `${employee?.firstName}'s Value`,
            points: ratingCategories.map<IChartPoint>(d => ({
                icon: categoryIcon[d],
                name: categoryTitle[d],
                value: employee?.[modelKey[d]] || 0,
            })),
        },
        {
            color: 'divider',
            gradients: ['rgba(125, 125, 125, 0.35)', 'rgba(125, 125, 125, 0.25)'],
            name: 'Team',
            points: ratingCategories.map<IChartPoint>(d => ({
                name: categoryTitle[d],
                value: teamAverage[d],
            })),
        },
    ];

    useEffect(() => {
        employee && containerRef(null);
    }, [containerRef, employee]);

    return (
        <SlideOver isOpen={isOpen} onClose={onClose} size={SlideOverSizeType.lg} title="Employee">
            {employee && (
                <div className="space-y-6">
                    <div className="flex items-center w-full">
                        <Avatar src={employee.avatar || ''} size={16} />
                        <div className="ml-4 flex-1">
                            <div className="font-bold text-lg">{NameFormatter.getName(employee)}</div>
                            <div className="text-textDisabled">{employee.title}</div>
                        </div>
                        <Button name="Edit" iconLeft={faEdit} onClick={onEditEmployee} />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div className="bg-background2 rounded-lg flex flex-col p-2">
                            <p>Salary</p>
                            <p className="text-xl font-bold">{CurrencyFormatter.format(employee.salary)}</p>
                        </div>
                        <div className="bg-background2 rounded-lg flex flex-col p-2">
                            <p>Shares</p>
                            <p className="text-xl font-bold">
                                {sharePercentage ? formatPercentage(sharePercentage, formatOptions) : '--'}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <ChartLegend series={chartSeries} />
                        <Button name="Edit Value" iconLeft={faEdit} onClick={onEditRating} />
                    </div>
                    <div ref={containerRef} style={{ minHeight: '30vh' }} className="w-full">
                        <RadarChart
                            axesIconsOnly
                            className="mx-auto"
                            data={chartSeries}
                            dataMax={5}
                            dataMin={0}
                            height={Math.max(containerRect?.height || 1, 400)}
                            levels={5}
                            width={Math.max(containerRect?.width || 1, 450)}
                        />
                    </div>
                    <div className="grid grid-cols-2">
                        {getArrayOfEnum(RatingCategory).map((ratingCategory, index) => (
                            <div
                                key={ratingCategory}
                                className={classNames(
                                    'px-4 py-2 flex items-center justify-between border-highlight border-b-2 border-r-2',
                                    { 'rounded-tl-lg border-t-2': index === 0 },
                                    { 'rounded-tr-lg border-t-2': index === 1 },
                                    { 'rounded-bl-lg': index === 4 },
                                    { 'rounded-br-lg': index === 5 },
                                    { 'border-l-2': index % 2 === 0 }
                                )}
                            >
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={categoryIcon[ratingCategory]} className="mr-2" fixedWidth />
                                    {categoryTitle[ratingCategory]}
                                </div>
                                <div className="font-bold text-lg">{employee[modelKey[ratingCategory]]}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </SlideOver>
    );
};

export default EmployeeSummarySlideOver;
