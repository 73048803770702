import { useState } from 'react';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { Button, FieldSet, TextInput } from '~/components';
import { useCurrentUser, useUpdateBizEquityWhiteLabel } from '~/hooks';
import { trimString, yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    className?: string;
    domain: string;
    subdomain: string;
}

interface ISettingsFormData {
    subdomain: string;
}

const schemaValidation = Yup.object().shape({
    subdomain: Yup.string()
        .matches(/^([A-Za-z0-9\\-]+)$/, {
            message: 'May only contain letters, numbers and hyphens.',
        })
        .transform(trimString)
        .label('White Label Subdomain')
        .required(),
});

const BizEquitySettingsForm = ({ className, domain, subdomain }: IProps): JSX.Element => {
    const { data: currentUser } = useCurrentUser();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [formData, setFormData] = useState<ISettingsFormData>({ subdomain });
    const [isDisabled, setIsDisabled] = useState(false);
    const updateBizEquityWhiteLabel = useUpdateBizEquityWhiteLabel();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const onSave = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        setErrorMessage(undefined);
        setIsDisabled(true);
        setValidationErrors({});
        updateBizEquityWhiteLabel
            .mutateAsync({
                ...formData,
                advisorFirmId: currentUser?.advisorFirmId ?? '',
            })
            .then(() => setErrorMessage(undefined))
            .catch(() => setErrorMessage('Sorry, there was an error saving. Please try again.'))
            .finally(() => setIsDisabled(false));
    };

    return (
        <form
            className={className}
            onSubmit={e => {
                e.preventDefault();
                onSave();
            }}
        >
            <FieldSet disabled={isDisabled}>
                <TextInput
                    className="mb-4"
                    errorMessage={validationErrors.subdomain}
                    iconRight={<>.{domain}</>}
                    label="White Label Subdomain"
                    onChange={subdomain => {
                        setFormData({ ...formData, subdomain });
                    }}
                    value={formData.subdomain}
                />
                {errorMessage && <p className="mb-6 text-sm text-error">{errorMessage}</p>}
                <Button color="primary" size="lg" text="Save" type="submit" />
            </FieldSet>
        </form>
    );
};

export default BizEquitySettingsForm;
