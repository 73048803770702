import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { DeleteConfirmationModal } from '~/components';
import { useDeleteDocument } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { closeModal, ModalType } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useParams } from '~/routing';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { getIconFromExtension } from '../icons/iconMap';
import { getColorFromIcon } from '../utils/colorMap';

enum Status {
    None,
    Error,
    IsSaving,
}

const BulkDeleteModal = (): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const deleteDocument = useDeleteDocument(businessId);
    const [status, setStatus] = useState(Status.None);

    const { selectedItems, selectedModal } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    const handleConfirm = async () => {
        if (status === Status.IsSaving) {
            return;
        }
        try {
            setStatus(Status.IsSaving);
            await Promise.all(Object.values(selectedItems).map(entry => deleteDocument.mutateAsync(entry.id)));
            dispatch(closeModal());
        } catch {
            setStatus(Status.Error);
        }
    };

    return (
        <DeleteConfirmationModal
            isOpen={selectedModal.type === ModalType.Delete}
            isSaving={status === Status.IsSaving}
            onClose={() => dispatch(closeModal())}
            onConfirm={handleConfirm}
            title="Delete Items"
        >
            <p className="text-sm text-textDisabled mb-4">
                Are you sure you want to delete these documents? Documents will be permanently removed. This action
                cannot be undone.
            </p>
            {Object.values(selectedItems).map((entry, index) => {
                const icon = getIconFromExtension(entry.name);
                const color = getColorFromIcon(icon.iconName);
                return (
                    <div key={index} className="flex items-center my-2">
                        <span className="fa-stack fa-sm flex items-center justify-center mr-2">
                            <FontAwesomeIcon icon={icon} className={color} aria-hidden />
                        </span>
                        <span className="truncate" title={entry.name}>
                            {entry.name}
                        </span>
                    </div>
                );
            })}
            {status === Status.Error && (
                <p className="text-error mt-4">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" aria-hidden />
                    {defaultErrorMessage}
                </p>
            )}
        </DeleteConfirmationModal>
    );
};

export default BulkDeleteModal;
