import { IBusinessTeamMember } from '@api';
import { DropdownItem as EnvDropdownItem } from '@envestnet/envreact-component-library';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Avatar, ISelectOption, Select } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

type ITeamMemberOption = ISelectOption & IBusinessTeamMember;

const OptionComponent = (props: ITeamMemberOption) => {
    const teamMember = props as IBusinessTeamMember;
    return (
        <EnvDropdownItem.Basic className="group" value={props} key={props.value}>
            <div className="flex items-center">
                <Avatar src={teamMember.avatar || undefined} />
                <div className="truncate flex-1 ml-2">
                    <div className="truncate text-base">{NameFormatter.getLastNameFirst(teamMember)}</div>
                    <div className="text-textDisabled truncate text-sm">{teamMember.emailAddress || 'No email'}</div>
                </div>
                <div className="flex flex-shrink-0 justify-center items-center rounded-full h-8 w-8 bg-information">
                    <FontAwesomeIcon icon={faPlus} aria-hidden />
                </div>
            </div>
        </EnvDropdownItem.Basic>
    );
};

interface IProps {
    onChange: (option: ITeamMemberOption | null) => void;
    onClickNew?: () => void;
    potentialMembers: IBusinessTeamMember[];
    teamMembers: IBusinessTeamMember[];
}

const PostAssessmentContactSearch = ({ onChange, onClickNew, potentialMembers, teamMembers }: IProps): JSX.Element => {
    const membersNotOnTeam = potentialMembers.filter(e => !teamMembers.some(t => t.id === e.id));
    const options: ITeamMemberOption[] = [
        ...membersNotOnTeam.map(a => ({
            ...a,
            label: NameFormatter.getLastNameFirst(a),
            value: a.id,
        })),
    ];

    return (
        <Select
            isCreatable={!!onClickNew}
            showSearchIcon
            onChange={onChange}
            onCreateOption={onClickNew}
            optionComponent={OptionComponent}
            options={options}
            placeholder="Search"
            showCreateWhenEmpty
        />
    );
};

export default PostAssessmentContactSearch;
