import { IBusinessPageType, IBusinessSizeType, IIndustryType, IUserRoleType } from '@api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faBuilding,
    faBullseyeArrow,
    faCity,
    faFolderOpen,
    faGraduationCap,
    faHandHoldingUsd,
    faProjectDiagram,
    faSearchDollar,
    faTrophyAlt,
    faUsers,
    faUsersClass,
} from '@fortawesome/pro-light-svg-icons';
import { faStarHalfAlt } from '@fortawesome/pro-solid-svg-icons';

export const getEnumFromString = <T>(type: T, str: string): T[keyof T] => {
    const casted = str as keyof T;
    return type[casted];
};

export const getArrayOfEnum = <T extends Record<string, unknown>>(type: T): Array<T[keyof T]> => {
    return Object.keys(type).map(key => getEnumFromString(type, key));
};

export const isEphemeralUserRole = (role?: IUserRoleType): boolean =>
    role === IUserRoleType.BizEquityDataEntryDelegate ||
    role === IUserRoleType.DocumentUploadDelegate ||
    role === IUserRoleType.EmployeeUploadDelegate ||
    role === IUserRoleType.InnerZoneSurveyTaker;

export const businessPageDisplay: Record<IBusinessPageType, string> = {
    BizEquity: 'Business Valuation',
    BonusRight: 'Employee Rewards',
    BusinessForecast: 'Business Forecast',
    CorporateStrategies: 'Corporate Strategies',
    DocumentCenter: 'Document Center',
    Education: 'Education',
    ExecutivePrograms: 'Executive Programs',
    InnerZone: 'Team Communication',
    Observations: 'Observations',
    Purpose: 'Purpose',
    ScoreCard: 'Score Card',
    SuccessionPlan: 'Succession Plan',
    TeamValues: 'Team Values',
};

export const businessPageIcon: Record<IBusinessPageType, IconProp> = {
    BizEquity: faBuilding,
    BonusRight: faTrophyAlt,
    BusinessForecast: faSearchDollar,
    CorporateStrategies: faCity,
    DocumentCenter: faFolderOpen,
    Education: faGraduationCap,
    ExecutivePrograms: faUsersClass,
    InnerZone: faProjectDiagram,
    Observations: faSearchDollar,
    Purpose: faBullseyeArrow,
    ScoreCard: faStarHalfAlt,
    SuccessionPlan: faHandHoldingUsd,
    TeamValues: faUsers,
};

export const businessPageIconColor: Record<IBusinessPageType, string> = {
    BizEquity: 'info-Dm1',
    BonusRight: 'info-Gm1',
    BusinessForecast: 'info-E',
    CorporateStrategies: 'info-C',
    DocumentCenter: 'info-Bm1',
    Education: 'info-Bp1',
    ExecutivePrograms: 'info-G',
    InnerZone: 'info-Bm1',
    Observations: 'info-Bp1',
    Purpose: 'info-Bm1',
    ScoreCard: 'info-Em2',
    SuccessionPlan: 'info-Gm1',
    TeamValues: 'info-E',
};

export const businessPageDescription: Record<IBusinessPageType, string> = {
    BizEquity: 'Use BizEquity to perform a business valuation and evaluate possible next steps.',
    BonusRight:
        'BonusRight has taken the best practices of incentive plan experts and engineered them into a process anyone can follow.',
    BusinessForecast: 'Forecast the growth of your business and how that impacts employee compensation.',
    CorporateStrategies:
        'Compare and visualize different corporate strategies such as recapitalization or limited partnership.',
    DocumentCenter: 'A place for your client to store business related documents in one place.',
    Education: 'Show white papers and other resource materials you can customize to educate your client.',
    ExecutivePrograms: 'Show and discuss value of executive employees.',
    InnerZone: 'InnerZone details the unique communication strengths and challenges between any pair of employees.',
    Observations: 'Shows observations about the business of your client. A place to add objectives.',
    Purpose: 'Introduces your firm to the client and explains our purpose.',
    ScoreCard: 'Show the results and potential value of your ongoing work at a glance.',
    SuccessionPlan: "Show what your client's succession plan would look like in different situations.",
    TeamValues: "Show and discuss team values of client's employees.",
};

export const businessSizeDisplay: Record<IBusinessSizeType, string> = {
    _1_10: '1-10',
    _11_50: '11-50',
    _51_200: '51-200',
    _201_500: '201-500',
    _501_1000: '501-1,000',
    _1001_5000: '1,001-5,000',
    _5001_10000: '5,000-10,000',
    Over10000: '10,000+',
};

export const industryDisplay: Record<IIndustryType, string> = {
    Accounting: 'Accounting',
    AirlinesAviation: 'Airlines/Aviation',
    AlternativeDisputeResolution: 'Alternative Dispute Resolution',
    AlternativeMedicine: 'Alternative Medicine',
    Animation: 'Animation',
    ApparelAndFashion: 'Apparel & Fashion',
    ArchitectureAndPlanning: 'Architecture & Planning',
    ArtsAndCrafts: 'Arts and Crafts',
    Automotive: 'Automotive',
    AviationAndAerospace: 'Aviation & Aerospace',
    Banking: 'Banking',
    Biotechnology: 'Biotechnology',
    BroadcastMedia: 'Broadcast Media',
    BuildingMaterials: 'Building Materials',
    BusinessSuppliesAndEquipment: 'Business Supplies and Equipment',
    CapitalMarkets: 'Capital Markets',
    Chemicals: 'Chemicals',
    CivicAndSocialOrganization: 'Civic & Social Organization',
    CivilEngineering: 'Civil Engineering',
    CommercialRealEstate: 'Commercial Real Estate',
    ComputerAndNetworkSecurity: 'Computer & Network Security',
    ComputerGames: 'Computer Games',
    ComputerHardware: 'Computer Hardware',
    ComputerNetworking: 'Computer Networking',
    ComputerSoftware: 'Computer Software',
    Construction: 'Construction',
    ConsumerElectronics: 'Consumer Electronics',
    ConsumerGoods: 'Consumer Goods',
    ConsumerServices: 'Consumer Services',
    Cosmetics: 'Cosmetics',
    Dairy: 'Dairy',
    DefenseAndSpace: 'Defense & Space',
    Design: 'Design',
    EducationManagement: 'Education Management',
    ELearning: 'E-Learning',
    ElectricalElectronicManufacturing: 'Electrical/Electronic Manufacturing',
    Entertainment: 'Entertainment',
    EnvironmentalServices: 'Environmental Services',
    EventsServices: 'Events Services',
    ExecutiveOffice: 'Executive Office',
    FacilitiesServices: 'Facilities Services',
    Farming: 'Farming',
    FinancialServices: 'Financial Services',
    FineArt: 'Fine Art',
    Fishery: 'Fishery',
    FoodAndBeverages: 'Food & Beverages',
    FoodProduction: 'Food Production',
    FundRaising: 'Fundraising',
    Furniture: 'Furniture',
    GamblingAndCasinos: 'Gambling & Casinos',
    GlassCeramicsAndConcrete: 'Glass Ceramics & Concrete',
    GovernmentAdministration: 'Government Administration',
    GovernmentRelations: 'Government Relations',
    GraphicDesign: 'Graphic Design',
    HealthWellnessAndFitness: 'Health, Wellness and Fitness',
    HigherEducation: 'Higher Education',
    HospitalAndHealthCare: 'Hospital & HealthCare',
    Hospitality: 'Hospitality',
    HumanResources: 'Human Resources',
    ImportAndExport: 'Import and Export',
    IndividualAndFamilyServices: 'Individual & Family Services',
    IndustrialAutomation: 'Industrial Automation',
    InformationServices: 'Information Services',
    InformationTechnologyAndServices: 'Information Technology and Services',
    Insurance: 'Insurance',
    InternationalAffairs: 'International Affairs',
    InternationalTradeAndDevelopment: 'International Trade and Development',
    Internet: 'Internet',
    InvestmentBanking: 'Investment Banking',
    InvestmentManagement: 'Investment Management',
    Judiciary: 'Judiciary',
    LawEnforcement: 'Law Enforcement',
    LawPractice: 'Law Practice',
    LegalServices: 'Legal Services',
    LegislativeOffice: 'Legislative Office',
    LeisureTravelAndTourism: 'Leisure, Travel & Tourism',
    Libraries: 'Libraries',
    LogisticsAndSupplyChain: 'Logistics and Supply Chain',
    LuxuryGoodsAndJewelry: 'Luxury Goods & Jewelry',
    Machinery: 'Machinery',
    ManagementConsulting: 'Management Consulting',
    Maritime: 'Maritime',
    MarketResearch: 'Market Research',
    MarketingAndAdvertising: 'Marketing and Advertising',
    MechanicalOrIndustrialEngineering: 'Mechanical or Industrial Engineering',
    MediaProduction: 'Media Production',
    MedicalDevices: 'Medical Devices',
    MedicalPractice: 'Medical Practice',
    MentalHealthCare: 'Mental Health Care',
    Military: 'Military',
    MiningAndMetals: 'Mining & Metals',
    MotionPicturesAndFilm: 'Motion Pictures and Film',
    MuseumsAndInstitutions: 'Museums and Institutions',
    Music: 'Music',
    Nanotechnology: 'Nanotechnology',
    Newspapers: 'Newspapers',
    NonProfitOrganizationManagement: 'Non-Profit Organization Management',
    OilAndEnergy: 'Oil & Energy',
    OnlineMedia: 'Online Media',
    OutsourcingOffshoring: 'Outsourcing/Offshoring',
    PackageFreightDelivery: 'Package/Freight Delivery',
    PackagingAndContainers: 'Packaging and Containers',
    PaperForestProducts: 'Paper & Forest Products',
    PerformingArts: 'Performing Arts',
    Pharmaceuticals: 'Pharmaceuticals',
    Philanthropy: 'Philanthropy',
    Photography: 'Photography',
    Plastics: 'Plastics',
    PoliticalOrganization: 'Political Organization',
    PrimarySecondaryEducation: 'Primary/Secondary Education',
    Printing: 'Printing',
    ProfessionalTrainingAndCoaching: 'Professional Training & Coaching',
    ProgramDevelopment: 'Program Development',
    PublicPolicy: 'Public Policy',
    PublicRelationsAndCommunications: 'Public Relations and Communications',
    PublicSafety: 'Public Safety',
    Publishing: 'Publishing',
    RailroadManufacture: 'Railroad Manufacture',
    Ranching: 'Ranching',
    RealEstate: 'Real Estate',
    RecreationalFacilitiesAndServices: 'Recreational Facilities and Services',
    ReligiousInstitutions: 'Religious Institutions',
    RenewablesAndEnvironment: 'Renewables & Environment',
    Research: 'Research',
    Restaurants: 'Restaurants',
    Retail: 'Retail',
    SecurityAndInvestigations: 'Security and Investigations',
    Semiconductors: 'Semiconductors',
    Shipbuilding: 'Shipbuilding',
    SportingGoods: 'Sporting Goods',
    Sports: 'Sports',
    StaffingAndRecruiting: 'Staffing and Recruiting',
    Supermarkets: 'Supermarkets',
    Telecommunications: 'Telecommunications',
    Textiles: 'Textiles',
    ThinkTanks: 'Think Tanks',
    Tobacco: 'Tobacco',
    TranslationAndLocalization: 'Translation and Localization',
    TransportationTruckingRailroad: 'Transportation/Trucking/Railroad',
    Utilities: 'Utilities',
    VentureCapitalAndPrivateEquity: 'Venture Capital & Private Equity',
    Veterinary: 'Veterinary',
    Warehousing: 'Warehousing',
    Wholesale: 'Wholesale',
    WineAndSpirits: 'Wine and Spirits',
    Wireless: 'Wireless',
    WritingAndEditing: 'Writing and Editing',
};
