import { IInnerZoneEmployeeLink, IInnerZoneEmployeeUser } from '@api';
import classnames from 'classnames';
import { Avatar } from '~/components';
import { useInnerZoneEmployeeRelationshipSummary } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { useParams } from '~/routing';
import { linkTypeColor } from '../innerZoneEnumUtils';

interface IProps {
    employeeUsers: IInnerZoneEmployeeUser[];
    link: IInnerZoneEmployeeLink;
}
const LinkSummary = ({ employeeUsers, link }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const { data: relationshipSummary } = useInnerZoneEmployeeRelationshipSummary(
        businessId,
        link.employees[0],
        link.employees[1]
    );
    const summaryParagraphs = relationshipSummary?.summary.split('\n\n');

    return (
        <div>
            <div className="flex items-center mb-10">
                <Avatar
                    className={classnames('border-4', `border-${linkTypeColor[link.type]}`)}
                    size={24}
                    src={employeeUsers[0].avatar || undefined}
                />
                <div className={classnames('flex-grow h-1', `bg-${linkTypeColor[link.type]}`)} />
                <Avatar
                    className={classnames('border-4', `border-${linkTypeColor[link.type]}`)}
                    size={24}
                    src={employeeUsers[1].avatar || undefined}
                />
            </div>
            {summaryParagraphs?.map((p, i) => (
                <p key={`relationshipSummary_${i}`} className="mb-3">
                    {p}
                </p>
            ))}
        </div>
    );
};

export default LinkSummary;
