import classnames from 'classnames';
import { formatPercentage } from '~/utils/percentageFormatter';
import { IChartPoint } from './chartModels';

interface IProps {
    hideLabels?: boolean;
    points: IChartPoint[];
}

const minimumPercentageForLabel = 0.1;

const ProgressChart = ({ hideLabels = false, points }: IProps): JSX.Element => {
    const total = points.reduce((acc, current) => (acc += current.value), 0);

    return (
        <div>
            <div className="flex h-4 overflow-hidden" style={{ borderRadius: '2rem' }}>
                {points.map((p, i) => {
                    const percentage = p.value / total;

                    if (!percentage) return null;

                    return (
                        <div
                            key={i}
                            className={classnames('h-full', `bg-${p.color}`)}
                            style={{ marginLeft: i > 0 ? '2px' : 0, width: `${percentage * 100}%` }}
                            title={`${p.name} ${formatPercentage(percentage, { maximumFractionDigits: 1 })}`}
                        />
                    );
                })}
            </div>
            {!hideLabels && (
                <div className="flex mt-3">
                    {points.map((p, i) => {
                        const percentage = p.value / total;

                        return (
                            <div key={i} style={{ width: `${percentage * 100}%` }}>
                                {percentage >= minimumPercentageForLabel ? (
                                    <>
                                        <span className="mr-1">{p.name}</span>
                                        <span className="leading-6 font-bold">
                                            {formatPercentage(percentage, { maximumFractionDigits: 1 })}
                                        </span>
                                    </>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export { ProgressChart };
