import { IUserRoleType } from '@api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBuilding, faPresentation, faUserEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BackLink, ErrorPage, LoadingIndicator } from '~/components';
import { useAdvisor, useCurrentUser } from '~/hooks';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from '~/routing';
import MyFirmInfo from '../advisorFirm/firmInfo/MyFirmInfo';
import MyFirmPresentationSettings from '../advisorFirm/presentationSettings/MyFirmPresentationSettings';
import AdvisorInfo from './advisorInfo/AdvisorInfo';

interface AdvisorParams {
    advisorId: string;
}

export enum EditAdvisorPageType {
    AdvisorInfo = 'AdvisorInfo',
    FirmInfo = 'FirmInfo',
    FirmPresentationSettings = 'FirmPresentationSettings',
}

const pageDisplay: Record<EditAdvisorPageType, string> = {
    AdvisorInfo: 'Advisor Info',
    FirmInfo: 'Firm Info',
    FirmPresentationSettings: 'Firm Presentation Settings',
};

const pageIcon: Record<EditAdvisorPageType, IconProp> = {
    AdvisorInfo: faUserEdit,
    FirmInfo: faBuilding,
    FirmPresentationSettings: faPresentation,
};

const getPages = (role: IUserRoleType): EditAdvisorPageType[] => {
    if (role === IUserRoleType.Advisor) {
        return [
            EditAdvisorPageType.AdvisorInfo,
            EditAdvisorPageType.FirmInfo,
            EditAdvisorPageType.FirmPresentationSettings,
        ];
    }

    return [EditAdvisorPageType.AdvisorInfo];
};

const AdvisorEdit = (): JSX.Element | null => {
    const match = useRouteMatch<AdvisorParams>();
    return (
        <div className="max-w-7xl w-full mx-auto sm:px-6 lg:px-8">
            <AdvisorEditInternal advisorId={match.params.advisorId} />
        </div>
    );
};

interface IProps {
    advisorId: string;
}

export const AdvisorEditInternal = ({ advisorId }: IProps): JSX.Element | null => {
    const match = useRouteMatch();
    const { data: currentUser } = useCurrentUser();
    const { isLoading, error, data: advisor } = useAdvisor(advisorId);

    if (error) return <ErrorPage />;
    if (isLoading || !advisor || !currentUser) return <LoadingIndicator />;

    const pages = getPages(currentUser.role);

    return (
        <>
            <div className="mb-12 pt-10 pb-6 bg-background border-b border-background2">
                <BackLink />
                <h2 className="text-2xl leading-none self-center mt-2">Advisor Settings</h2>
            </div>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                    <nav className="space-y-1">
                        {pages.map(p => (
                            <NavLink
                                key={p}
                                activeClassName="bg-background1 text-textDefault shadow-sm"
                                className="group flex items-center px-2 py-2 rounded-md hover:bg-background1 hover:shadow-sm"
                                to={`${match.url}/${p}`}
                            >
                                <FontAwesomeIcon fixedWidth className="mr-4" icon={pageIcon[p]} />
                                {pageDisplay[p]}
                            </NavLink>
                        ))}
                    </nav>
                </aside>
                <div className="sm:px-6 lg:px-0 sm:pb-6 lg:pb-8 lg:col-span-9">
                    <Switch>
                        {pages.includes(EditAdvisorPageType.AdvisorInfo) && (
                            <Route path={`${match.path}/${EditAdvisorPageType.AdvisorInfo}`}>
                                <AdvisorInfo advisor={advisor} />
                            </Route>
                        )}
                        {pages.includes(EditAdvisorPageType.FirmInfo) && (
                            <Route path={`${match.path}/${EditAdvisorPageType.FirmInfo}`}>
                                <MyFirmInfo advisorFirmId={advisor.advisorFirmId} />
                            </Route>
                        )}
                        {pages.includes(EditAdvisorPageType.FirmPresentationSettings) && (
                            <Route path={`${match.path}/${EditAdvisorPageType.FirmPresentationSettings}`}>
                                <MyFirmPresentationSettings advisorFirmId={advisor.advisorFirmId} />
                            </Route>
                        )}
                        <Redirect to={`${match.url}/${pages[0]}`} />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default AdvisorEdit;
