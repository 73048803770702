import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormActions, SlideOver, useAvatarInput } from '~/components';
import { useAddAdvisor, useAdvisorAvatar } from '~/hooks';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import { yupToFormErrors } from '~/utils/yupUtils';
import AdvisorInfoForm, {
    getInitialFormData,
    IAdvisorInfoFormData,
    mapFormDataToApi,
    schemaValidation,
} from '../advisorEdit/advisorInfo/AdvisorInfoForm';

interface IProps {
    advisorFirmId: string;
    isOpen: boolean;
    onClose: () => void;
}

const AddAdvisor = ({ advisorFirmId, isOpen, onClose }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const [formData, setFormData] = useState<IAdvisorInfoFormData>(getInitialFormData());
    const [newAvatar, setNewAvatar] = useAvatarInput(isOpen);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const addAdvisor = useAddAdvisor(advisorFirmId);
    const updateAdvisorAvatar = useAdvisorAvatar(advisorFirmId);
    const onSave = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        const advisor = await addAdvisor.mutateAsync({
            advisorFirmId,
            ...mapFormDataToApi(formData),
        });
        if (newAvatar?.file) {
            await updateAdvisorAvatar.mutateAsync({ avatar: newAvatar.file, advisorId: advisor.id });
        }
        onClose();
        showSuccess();
    };

    const resetForm = () => {
        setValidationErrors({});
        setFormData(getInitialFormData());
    };

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen]);

    return (
        <SlideOver
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={
                <FormActions
                    conflictErrorMessage="A user already exists with that email address."
                    onCancel={onClose}
                    onSave={onSave}
                    setValidationErrors={setValidationErrors}
                />
            }
            title="Add Advisor"
        >
            <AdvisorInfoForm
                avatar={newAvatar?.url}
                errors={validationErrors}
                formData={formData}
                onChange={setFormData}
                onChangeAvatar={setNewAvatar}
            />
        </SlideOver>
    );
};

export default AddAdvisor;
