import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { allByType, withoutTypes } from 'react-children-by-type';
import { usePopper } from 'react-popper';
import { IButtonProps } from '~/components';

interface IDropdownItem extends Omit<IButtonProps, 'children'> {
    children: React.ReactNode;
    close?: () => void;
}

const DropdownItem = ({ children, close, onClick, ...rest }: IDropdownItem): JSX.Element => {
    return (
        <button
            className="flex items-center px-4 py-2 w-full rounded-md hover:bg-background2"
            onClick={(e: React.SyntheticEvent<Element, Event>) => {
                onClick?.(e);
                close?.();
            }}
            {...rest}
        >
            {children}
        </button>
    );
};

interface IDropdownMenuProps {
    children: React.ReactNode;
    itemsClassName?: string;
    position?: 'left' | 'right';
}

const DropdownMenu = ({ children, itemsClassName, position = 'left' }: IDropdownMenuProps): JSX.Element => {
    const trigger = allByType(children, Popover.Button);
    const items = withoutTypes(children, Popover.Button);
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: position === 'left' ? 'bottom-start' : 'bottom-end',
    });

    return (
        <Popover as="div" className="inline-block text-left" onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
            {React.Children.map([trigger], child => {
                return React.cloneElement(child, { ref: setReferenceElement, tabIndex: 0 });
            })}
            <Popover.Panel
                className={classNames(
                    'w-44 bg-background1 rounded-md shadow-lg ring-1 ring-background ring-opacity-5 focus:outline-none z-50',
                    itemsClassName
                )}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
            >
                {({ close }) => (
                    <Transition
                        as={'div'}
                        enter="transition-opacity ease-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-in duration-75"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="px-1 py-1 flex flex-col">
                            {React.Children.map(items, child => {
                                return React.cloneElement(child, { close });
                            })}
                        </div>
                    </Transition>
                )}
            </Popover.Panel>
        </Popover>
    );
};

DropdownMenu.Trigger = Popover.Button;
DropdownMenu.Item = DropdownItem;

export { DropdownMenu };
