import { IBizEquityYearlyStatementUpdate } from '@api';
import * as Yup from 'yup';
import { yupToFormErrors } from '~/utils/yupUtils';

const schema = Yup.object().shape({
    revenue: Yup.number().required().positive(),
    income: Yup.number().required().positive(),
});

const stripMessages = (errors: { [key: string]: string }) => {
    return Object.keys(errors).reduce((a, v) => ({ ...a, [v]: '' }), {});
};

export const validate = (yearlyStatements: { [key: string]: IBizEquityYearlyStatementUpdate }) => {
    try {
        const mostRecentYear = Object.keys(yearlyStatements).reverse()[0];
        schema.validateSync(yearlyStatements[mostRecentYear], {
            abortEarly: false,
        });
    } catch (err: unknown) {
        if (Yup.ValidationError.isError(err)) {
            return { errors: stripMessages(yupToFormErrors(err)), isValid: false };
        }
    }

    return { errors: {}, isValid: true };
};
