import { IBizEquityLoginStatus, IBusinessPageType } from '@api';
import { useState } from 'react';
import { Button } from '~/components';
import { usePostBizEquityAuthorizationCode } from '~/hooks';
import BizEquitySettingsForm from '~/pages/advisorSettings/bizEquity/BizEquitySettingsForm';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { businessPageDisplay } from '~/utils/enumUtils';
import background from './background.jpg';
import bizEquityLogo from './bizEquityLogo.svg';

interface IProps {
    loginStatus: IBizEquityLoginStatus;
}

const UnauthenticatedPage = ({ loginStatus }: IProps): JSX.Element => {
    const [isSigningIn, setIsSigningIn] = useState(false);
    const { mutateAsync: postAuthCodeAsync } = usePostBizEquityAuthorizationCode();
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const onSignIn = () => {
        setIsSigningIn(true);
        const bizEquityOauthUrl = `https://${loginStatus.bizEquitySubdomain}.${loginStatus.bizEquityDomain}/oauth/authorize?response_type=code&client_id=envestnet-moneyguide&redirect_uri=${loginStatus.bizEquityOAuthRedirectUri}&scope=accounts:admin`;
        const oAuthWindow = window.open(bizEquityOauthUrl, '_blank');
        const oAuthTimer = window.setInterval(() => {
            try {
                if (oAuthWindow?.document.URL.startsWith(loginStatus.bizEquityOAuthRedirectUri)) {
                    window.clearInterval(oAuthTimer);
                    const authorizationCode = new URLSearchParams(oAuthWindow?.document.location.search).get('code');

                    if (authorizationCode) {
                        postAuthCodeAsync({ authorizationCode })
                            .catch(() => showError())
                            .finally(() => setIsSigningIn(false));
                    } else {
                        showError();
                        setIsSigningIn(false);
                    }
                    oAuthWindow.close();
                }
            } catch {
                // ignore errors
            }
        }, 100);
    };
    const hasSubdomainSet = !!loginStatus.bizEquitySubdomain;

    return (
        <>
            <div className="grid xl:grid-cols-2 h-full">
                <div className="flex flex-col items-center justify-center max-w-md mx-auto py-4 space-y-8">
                    <img className="mx-auto w-80 white-logo" src={bizEquityLogo} alt="Biz Equity Logo" />
                    {!hasSubdomainSet && (
                        <>
                            <p>
                                Before you can access BizEquity you must specify the white label subdomain you use to
                                log on to their service.
                            </p>
                            <BizEquitySettingsForm
                                className="w-full"
                                domain={loginStatus.bizEquityDomain}
                                subdomain={loginStatus.bizEquitySubdomain || ''}
                            />
                        </>
                    )}
                    {hasSubdomainSet && (
                        <>
                            <p>
                                {`Before you can access BizEquity we need you to sign in to BizEquity and authorize this integration. Once you have verified your identity you will be redirected back to this ${
                                    businessPageDisplay[IBusinessPageType.BizEquity]
                                } page.`}
                            </p>
                            <div className="w-full">
                                <Button
                                    fullWidth
                                    color="primary"
                                    loading={isSigningIn}
                                    text="Sign In"
                                    onClick={onSignIn}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div
                    className="bg-center bg-cover bg-no-repeat hidden xl:block"
                    style={{ backgroundImage: `url(${background})` }}
                />
            </div>
        </>
    );
};

export default UnauthenticatedPage;
