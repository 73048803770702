import { ReactNode } from 'react';
import { IPanelProps, ITabsProps, Tabs } from './Tabs';

interface ITabProps {
    children: ReactNode;
    tabId: string;
}

export const activeClassName = 'border-action text-action';
export const className = 'whitespace-nowrap py-4 px-1 font-semibold tracking-wide text-sm border-b-2';
export const inactiveClassName = 'border-transparent text-textDefault hover:text-textSubtle hover:border-textSubtle';
export const tabContainerClassName = 'border-b border-background2 -mb-px flex space-x-8';

const Tab = ({ children, tabId }: ITabProps): JSX.Element => (
    <Tabs.Tab
        activeClassName={activeClassName}
        className={className}
        inactiveClassName={inactiveClassName}
        tabId={tabId}
    >
        {children}
    </Tabs.Tab>
);

interface ITabsComposition {
    Tab: React.FC<ITabProps>;
    Panel: React.FC<IPanelProps>;
}

const HorizontalTabs: React.FC<ITabsProps> & ITabsComposition = ({
    activeTabId,
    children,
    containerClassName,
}: ITabsProps) => (
    <Tabs
        activeTabId={activeTabId}
        containerClassName={containerClassName}
        tabContainerClassName={tabContainerClassName}
        tabType={Tab}
    >
        {children}
    </Tabs>
);

HorizontalTabs.Tab = Tab;
HorizontalTabs.Panel = Tabs.Panel;

export { HorizontalTabs };
