import classNames from 'classnames';
import NumberFormatter from '~/utils/numberFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import { Kpi, KpiComparison, percentageKpis } from './model';

interface IProps {
    comparison?: KpiComparison;
    name: string;
    onClick: () => void;
    rating?: number;
    type: Kpi;
}

const formatRating = (rating: number, type: Kpi) => {
    if (percentageKpis.indexOf(type) !== -1) {
        return formatPercentage(rating, { maximumFractionDigits: 0 });
    }

    let maximumFractionDigits = 2;
    if (rating >= 100) {
        maximumFractionDigits = 0;
    } else if (rating >= 10) {
        maximumFractionDigits = 1;
    }
    return NumberFormatter.formatNumber(rating, '', { maximumFractionDigits });
};

const Rating = ({ comparison, name, onClick, rating, type }: IProps): JSX.Element => (
    <div
        className="border border-highlight p-7 flex flex-col text-center items-center justify-center rounded-lg cursor-pointer"
        onClick={onClick}
    >
        <div className="text-sm">{name}</div>
        <div
            className={classNames(
                'font-semibold text-3xl',
                { 'text-positive': comparison === KpiComparison.GREATER },
                { 'text-caution': comparison === KpiComparison.LESS }
            )}
        >
            {rating ? formatRating(rating, type) : '--'}
        </div>
    </div>
);

export default Rating;
