import {
    IInnerZoneBusinessDetail,
    IInnerZoneEmployeeProfile,
    IInnerZoneEmployeeUser,
    IInnerZoneLoginRequest,
    IInnerZoneLoginStatus,
    IInnerZonePrimaryReport,
    IInnerZonePrimaryReportSaveRequest,
    IInnerZoneRelationshipSummary,
    IInnerZoneSurvey,
    IInnerZoneSurveyBusinessInvitationRequest,
    IInnerZoneSurveyEmployeeInvitationRequest,
    IInnerZoneSurveyResultsSaveRequest,
    IInnerZoneSurveyStatus,
    InnerZoneCommunicationApi,
    InnerZoneSettingsApi,
    InnerZoneSurveyApi,
    InnerZoneSurveyInvitationApi,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey, getCurrentUserQueryKey, getEmployeeQueryKey } from '.';

const innerZoneCommunicationApi = new InnerZoneCommunicationApi(undefined, config.api.baseUrl);
const innerZoneSettingsApi = new InnerZoneSettingsApi(undefined, config.api.baseUrl);
const innerZoneSurveyApi = new InnerZoneSurveyApi(undefined, config.api.baseUrl);
const innerZoneSurveyInvitationApi = new InnerZoneSurveyInvitationApi(undefined, config.api.baseUrl);

const innerZoneQueryKey = 'innerZone';

type key = string | number;
export const getInnerZoneBusinessKey = (businessId: string, ...rest: key[]): QueryKey =>
    getBusinessQueryKey(businessId, innerZoneQueryKey, ...(rest || []));

const getInnerZoneBusinessDetailQueryKey = (businessId: string): QueryKey =>
    getInnerZoneBusinessKey(businessId, 'businessDetail');

const getInnerZoneEmployeeKey = (businessId: string, employeeId: string, ...rest: key[]): QueryKey =>
    getEmployeeQueryKey(businessId, employeeId, innerZoneQueryKey, ...(rest || []));

const getInnerZoneCurrentUserQueryKey = (...rest: key[]): QueryKey =>
    getCurrentUserQueryKey(innerZoneQueryKey, ...(rest || []));

const getInnerZonePrimaryReportQueryKey = (businessId: string): QueryKey =>
    getInnerZoneBusinessKey(businessId, 'primaryReport');

const getInnerZoneSurveyQueryKey = (): QueryKey => getInnerZoneCurrentUserQueryKey('survey');

export const useInnerZoneBusinessDetail = (
    businessId: string
): UseQueryResult<IInnerZoneBusinessDetail, IProblemDetails> =>
    useQuery(
        getInnerZoneBusinessDetailQueryKey(businessId),
        () => innerZoneCommunicationApi.getInnerZoneBusinessDetail(businessId).then(response => response.data),
        {
            enabled: !!businessId,
            refetchInterval: 5 * 60 * 1000, // 5 minutes in ms
        }
    );

export const useInnerZoneCurrentUserEmployeeProfile = (): UseQueryResult<IInnerZoneEmployeeProfile, IProblemDetails> =>
    useQuery(getInnerZoneCurrentUserQueryKey('profile'), () =>
        innerZoneCommunicationApi.getCurrentUserInnerZoneEmployeeProfile().then(response => response.data)
    );

export const useInnerZoneEmployeeProfile = (
    businessId: string,
    employeeId: string
): UseQueryResult<IInnerZoneEmployeeProfile, IProblemDetails> =>
    useQuery(
        getInnerZoneEmployeeKey(businessId, employeeId, 'profile'),
        () => innerZoneCommunicationApi.getInnerZoneEmployeeProfile(employeeId).then(response => response.data),
        {
            enabled: !!employeeId,
        }
    );

export const useInnerZoneEmployeeRelationshipSummary = (
    businessId: string,
    employeeId: string,
    comparisonEmployeeId: string
): UseQueryResult<IInnerZoneRelationshipSummary, IProblemDetails> =>
    useQuery(
        getInnerZoneEmployeeKey(businessId, employeeId, 'relationshipSummary', comparisonEmployeeId),
        () =>
            innerZoneCommunicationApi
                .getInnerZoneEmployeeRelationshipSummary(employeeId, comparisonEmployeeId)
                .then(response => response.data),
        {
            enabled: !!employeeId && !!comparisonEmployeeId,
        }
    );

export const useInnerZoneLogin = (
    invalidationDelay: () => Promise<void>
): UseMutationResult<void, IProblemDetails, IInnerZoneLoginRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IInnerZoneLoginRequest>(
        request => innerZoneSettingsApi.postInnerZoneLogin(undefined, request).then(response => response.data),
        {
            onSuccess: async () => {
                await invalidationDelay();
                return queryClient.invalidateQueries({
                    predicate: query => query.queryKey.includes(innerZoneQueryKey),
                });
            },
        }
    );

    return mutation;
};

export const useInnerZoneLoginStatus = (): UseQueryResult<IInnerZoneLoginStatus, IProblemDetails> =>
    useQuery(getInnerZoneCurrentUserQueryKey('loginStatus'), () =>
        innerZoneSettingsApi.getInnerZoneLoginStatus().then(response => response.data)
    );

export const useInnerZonePrimaryReport = (
    businessId: string
): UseQueryResult<IInnerZonePrimaryReport, IProblemDetails> =>
    useQuery(
        getInnerZonePrimaryReportQueryKey(businessId),
        () => innerZoneCommunicationApi.getInnerZonePrimaryReport(businessId).then(response => response.data),
        {
            enabled: !!businessId,
        }
    );

export const useInnerZoneSetPrimaryReport = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IInnerZonePrimaryReportSaveRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IInnerZonePrimaryReportSaveRequest>(
        request =>
            innerZoneCommunicationApi
                .setInnerZonePrimaryReport(businessId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => queryClient.invalidateQueries(getInnerZonePrimaryReportQueryKey(businessId)),
        }
    );

    return mutation;
};

export const useInnerZoneSurvey = (): UseQueryResult<IInnerZoneSurvey, IProblemDetails> =>
    useQuery(getInnerZoneSurveyQueryKey(), () =>
        innerZoneSurveyApi.getInnerZoneSurvey().then(response => response.data)
    );

interface IInvalidate {
    invalidate: () => void;
}

export const useInvalidateInnerZoneSurvey = (): IInvalidate => {
    const queryClient = useQueryClient();
    return { invalidate: () => queryClient.invalidateQueries(getInnerZoneSurveyQueryKey()) };
};

export const useInnerZoneSurveySetResults = (): UseMutationResult<
    void,
    IProblemDetails,
    IInnerZoneSurveyResultsSaveRequest
> =>
    useMutation<void, IProblemDetails, IInnerZoneSurveyResultsSaveRequest>(request =>
        innerZoneSurveyApi.setResults(undefined, request).then(response => response.data)
    );

const updateInnerZoneEmployeeUserSurveyQueryData = async (
    queryClient: QueryClient,
    businessId: string,
    isUser: (u: IInnerZoneEmployeeUser) => boolean
) => {
    const queryKey = getInnerZoneBusinessDetailQueryKey(businessId);
    const businessDetail = queryClient.getQueryData<IInnerZoneBusinessDetail>(queryKey);
    if (businessDetail) {
        await queryClient.cancelQueries(queryKey);
        businessDetail.users.forEach(u => {
            if (isUser(u)) {
                u.surveyDate = new Date().toISOString();
                u.surveyStatus = IInnerZoneSurveyStatus.EmailSent;
            }
        });
        queryClient.setQueryData<IInnerZoneBusinessDetail>(queryKey, businessDetail);
    }
};

export const useInnerZoneSurveyBusinessInvitation = (): UseMutationResult<
    void,
    IProblemDetails,
    IInnerZoneSurveyBusinessInvitationRequest
> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IInnerZoneSurveyBusinessInvitationRequest>(
        request =>
            innerZoneSurveyInvitationApi.sendBusinessInvitation(undefined, request).then(response => response.data),
        {
            onSuccess: async (_, variables) => {
                const surveyStatus = variables.resendOnly
                    ? IInnerZoneSurveyStatus.EmailSent
                    : IInnerZoneSurveyStatus.EmailPending;
                await updateInnerZoneEmployeeUserSurveyQueryData(
                    queryClient,
                    variables.businessId,
                    u => !!u.emailAddress && u.surveyStatus === surveyStatus
                );
            },
        }
    );

    return mutation;
};

export const useInnerZoneSurveyEmployeeInvitation = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IInnerZoneSurveyEmployeeInvitationRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IInnerZoneSurveyEmployeeInvitationRequest>(
        request =>
            innerZoneSurveyInvitationApi.sendEmployeeInvitation(undefined, request).then(response => response.data),
        {
            onSuccess: async (_, variables) => {
                await updateInnerZoneEmployeeUserSurveyQueryData(
                    queryClient,
                    businessId,
                    u => u.employeeId === variables.employeeId
                );
            },
        }
    );

    return mutation;
};
