import { IBusiness, IEmployee, IUserEntitlementType } from '@api';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button, Can, ErrorPage, LoadingIndicator } from '~/components';
import { useBusinessValuation } from '~/hooks';
import { IEditPageType } from '~/pages/business/edit';
import { ILocationState } from '~/pages/ILocationState';
import { Link, useLocation, useRouteMatch } from '~/routing';
import visionLinkLogo from '../../VisionLinkLogo.png';
import TableSelector from '../TableSelector';
import GroupLifeTable from './GroupLifeTable';
import LongTermDisabilityTable from './LongTermDisabilityTable';

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
}

enum Tab {
    LossOfLife,
    IllnessOrInjury,
}

const ExecutiveBenefits = ({ business, employees }: IProps): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.LossOfLife);
    const match = useRouteMatch();
    const businessSettingsState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Executive Programs',
    };
    const businessValuationQuery = useBusinessValuation(business.id);
    if (businessValuationQuery.isError) return <ErrorPage />;
    if (businessValuationQuery.isLoading || !businessValuationQuery.data) return <LoadingIndicator />;
    employees.sort((a, b) => (b.shares ?? 0) - (a.shares ?? 0));

    return (
        <div className="flex flex-col p-10 space-y-4">
            <div className="flex justify-between">
                <div className="space-y-8">
                    <h3 className="text-lg leading-6 font-medium mr-2">Executive Programs</h3>
                    <TableSelector selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </div>
                <div className="flex flex-col">
                    <Link to={`${match.url}/Unconnected`} tabIndex={-1} className="group flex flex-col items-center">
                        <Button
                            className="group-hover:underline"
                            color="link"
                            text="Create a more in-depth plan with"
                        />
                        <img alt="Vision Link Logo" className="h-8 white-logo" src={visionLinkLogo} />
                    </Link>
                    <Can hasEntitlement={IUserEntitlementType.EmployeeUpdate}>
                        <Link
                            className="mt-auto text-right"
                            tabIndex={-1}
                            to={{
                                pathname: `/${business.id}/Edit/${IEditPageType.Employees}`,
                                state: businessSettingsState,
                            }}
                        >
                            <Button iconLeft={faEdit} text="Edit Employee List" />
                        </Link>
                    </Can>
                </div>
            </div>
            {selectedTab === Tab.LossOfLife && (
                <div>
                    <GroupLifeTable
                        business={business}
                        businessValuation={businessValuationQuery.data}
                        employees={employees}
                    />
                </div>
            )}
            {selectedTab === Tab.IllnessOrInjury && (
                <div>
                    <LongTermDisabilityTable employees={employees} />
                </div>
            )}
        </div>
    );
};

export default ExecutiveBenefits;
