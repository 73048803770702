import { IBusinessPageType } from '@api';
import { useState } from 'react';
import { FileUpload, useAvatarInput } from '~/components';
import { IBusinessInfoFormData } from '../business/edit/businessInfo/BusinessInfoForm';
import { IPageSetting } from './PresentationSettingsForm';

const getInitialFormData = (): IBusinessInfoFormData => {
    return {
        city: '',
        description: '',
        industry: '',
        name: '',
        size: '',
        stateOrProvince: '',
        website: '',
    };
};

export interface WizardState extends InnerWizardState {
    businessAvatar?: FileUpload;
    setBusinessAvatar: React.Dispatch<React.SetStateAction<FileUpload | undefined>>;
    setWizardState: (wizardState: Partial<InnerWizardState>) => void;
    toggleAllPageSettings: () => void;
    togglePageSetting: (pageType: IBusinessPageType) => void;
}

interface InnerWizardState {
    businessFormData: IBusinessInfoFormData;
    businessId?: string;
    pageSettings: IPageSetting[];
    primaryEmployeeId?: string;
    validationErrors: { [key: string]: string };
}

const getInitialState = (): InnerWizardState => ({
    businessFormData: getInitialFormData(),
    businessId: undefined,
    pageSettings: [],
    primaryEmployeeId: undefined,
    validationErrors: {},
});

export const useWizardState = (): WizardState => {
    const [businessAvatar, setBusinessAvatar] = useAvatarInput(false);
    const [innerWizardState, setInnerWizardState] = useState(getInitialState());

    const setWizardState = (wizardState: Partial<InnerWizardState>) =>
        setInnerWizardState({
            ...innerWizardState,
            ...wizardState,
        });

    const toggleAllPageSettings = () => {
        const anyTurnedOff = innerWizardState.pageSettings.some(pageSetting => !pageSetting.enabled);
        setWizardState({
            pageSettings: innerWizardState.pageSettings.map(pageSetting => ({
                ...pageSetting,
                enabled: anyTurnedOff,
            })),
        });
    };

    const togglePageSetting = (pageType: IBusinessPageType) => {
        setWizardState({
            pageSettings: innerWizardState.pageSettings.map(pageSetting => {
                if (pageSetting.pageType === pageType) {
                    return {
                        ...pageSetting,
                        enabled: !pageSetting.enabled,
                    };
                }
                return pageSetting;
            }),
        });
    };

    return {
        ...innerWizardState,
        businessAvatar,
        setBusinessAvatar,
        setWizardState,
        toggleAllPageSettings,
        togglePageSetting,
    };
};
