import { IEmployee } from '@api';
import { EmptyStateIcon, RadarChart } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import { businessPageIcon } from '~/utils/enumUtils';
import { categoryIcon, categoryTitle, ITeamRating, modelKey, ratingCategories } from './rating/models';

interface IProps {
    axesIconsOnly?: boolean;
    containerRect?: DOMRect;
    fullTeamRatings: ITeamRating[];
    selectedEmployee?: IEmployee;
}

const TeamPanelChart = ({
    axesIconsOnly = false,
    containerRect,
    fullTeamRatings,
    selectedEmployee,
}: IProps): JSX.Element | null => {
    const chartSeries = [
        {
            color: 'information',
            gradients: ['rgba(129, 179, 228, 0.5)', 'rgba(14, 110, 203, 0.35)'],
            name: '',
            points: fullTeamRatings.map<IChartPoint>(d => ({
                icon: categoryIcon[d.ratingCategory],
                name: categoryTitle[d.ratingCategory],
                value: d.ratingAverage,
            })),
        },
    ];
    if (selectedEmployee) {
        chartSeries.push({
            color: 'positive',
            gradients: ['rgba(22, 162, 158, 0.5)', 'rgba(22, 162, 158, 0.35)'],
            name: '',
            points: ratingCategories.map<IChartPoint>(ratingCategory => ({
                name: categoryTitle[ratingCategory],
                value: selectedEmployee[modelKey[ratingCategory]] || 0,
            })),
        });
    }

    if (!containerRect) return null;

    if (fullTeamRatings.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center flex-1">
                <EmptyStateIcon icon={businessPageIcon.TeamValues} />
                <p className="my-4 text-lg">No employees added yet.</p>
            </div>
        );
    }

    return (
        <RadarChart
            axesIconsOnly={axesIconsOnly}
            className="mx-auto"
            data={chartSeries}
            dataMax={5}
            dataMin={0}
            height={containerRect.height}
            levels={5}
            width={containerRect.width}
        />
    );
};

export default TeamPanelChart;
