import { IBusiness, IBusinessTeamMember, IBusinessTeamMemberType, IExternalTeamMember } from '@api';
import { faSearch, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, ErrorPage, LoadingIndicator, SlideOver } from '~/components';
import { isTrueForAnyQuery, useAdvisorsByFirmId, useBusinessTeamMembers, useEmployees } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import AddTeamMemberForm from './AddTeamMemberForm';
import TeamMember from './TeamMember';
import TeamMemberDeleteModal from './TeamMemberDeleteModal';
import TeamMemberSearch from './TeamMemberSearch';
import UpdateExternalTeamMemberForm from './UpdateExternalTeamMemberForm';

interface IProps {
    business: IBusiness;
}

const TeamMenu = ({ business }: IProps): JSX.Element | null => {
    const [isOpen, setIsOpen] = useState(false);
    const advisorsQuery = useAdvisorsByFirmId(business.advisorFirmId);
    const employeesQuery = useEmployees(business.id, isOpen);
    const teamMembersQuery = useBusinessTeamMembers(business.id, isOpen);
    const [addState, setAddState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const [deleteState, setDeleteState] = useState<{ isOpen: boolean; teamMember?: IBusinessTeamMember }>({
        isOpen: false,
    });
    const [editState, setEditState] = useState<{ isOpen: boolean; teamMember?: IExternalTeamMember }>({
        isOpen: false,
    });
    const advisors = advisorsQuery.data ?? [];
    const employees = employeesQuery.data ?? [];
    const potentialMembers = [
        ...advisors.map(a => ({ ...a, type: IBusinessTeamMemberType.Advisor })),
        ...employees.map(e => ({ ...e, type: IBusinessTeamMemberType.Employee })),
    ].sort((a, b) => NameFormatter.getLastNameFirst(a).localeCompare(NameFormatter.getLastNameFirst(b)));
    const teamMembers = teamMembersQuery.data ?? [];
    const isError = isTrueForAnyQuery('isError', advisorsQuery, employeesQuery, teamMembersQuery);
    const isLoading = isTrueForAnyQuery('isLoading', advisorsQuery, employeesQuery, teamMembersQuery);

    return (
        <div>
            <Button color="link" iconLeft={faUsers} text="Team" title="Team" onClick={() => setIsOpen(true)} />
            <SlideOver isOpen={isOpen} onClose={() => setIsOpen(false)} title="Team">
                {isError && <ErrorPage />}
                {isLoading && <LoadingIndicator />}
                {!isError && !isLoading && (
                    <>
                        <div className="space-y-3">
                            <div>
                                <TeamMemberSearch
                                    businessId={business?.id}
                                    onClickNew={() => setAddState({ ...addState, isOpen: true })}
                                    potentialMembers={potentialMembers}
                                    teamMembers={teamMembers}
                                />
                                {teamMembers.length ? (
                                    teamMembers.map(teamMember => (
                                        <TeamMember
                                            key={teamMember.id}
                                            onClickDelete={
                                                teamMember.id !== business.primaryAdvisorId
                                                    ? () => {
                                                          setDeleteState({
                                                              isOpen: true,
                                                              teamMember,
                                                          });
                                                      }
                                                    : undefined
                                            }
                                            onClickEdit={
                                                teamMember.type === IBusinessTeamMemberType.External
                                                    ? () => {
                                                          setEditState({
                                                              isOpen: true,
                                                              teamMember: teamMember as IExternalTeamMember,
                                                          });
                                                      }
                                                    : undefined
                                            }
                                            teamMember={teamMember}
                                        />
                                    ))
                                ) : (
                                    <div className="flex flex-col justify-center items-center h-96">
                                        <div className="rounded-full bg-background1 p-9 text-highlight">
                                            <FontAwesomeIcon icon={faSearch} size="3x" />
                                        </div>
                                        <p className="mt-5 w-3/4 text-center">
                                            Search above for an employee or advisor to add to your team
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <TeamMemberDeleteModal
                            businessId={business.id}
                            isOpen={deleteState.isOpen}
                            teamMember={deleteState.teamMember}
                            onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                            onConfirm={() => setDeleteState({ ...deleteState, isOpen: false })}
                        />
                        <AddTeamMemberForm
                            businessId={business.id}
                            onClose={() => setAddState({ ...addState, isOpen: false })}
                            {...addState}
                        />
                        <UpdateExternalTeamMemberForm
                            businessId={business.id}
                            onClose={() => setEditState({ ...editState, isOpen: false })}
                            {...editState}
                        />
                    </>
                )}
            </SlideOver>
        </div>
    );
};

export default TeamMenu;
