import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Avatar, Button, ErrorPage, LoadingIndicator } from '~/components';
import { useEmployees } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import EmployeeSearch from '../business/edit/primaryContact/EmployeeSearch';

interface IProps {
    businessId: string;
    isDisabled: boolean;
    primaryEmployeeId?: string;
    setPrimaryEmployeeId: (id: string | undefined) => void;
}

const PrimaryEmployeeStep = ({
    businessId,
    isDisabled,
    primaryEmployeeId,
    setPrimaryEmployeeId,
}: IProps): JSX.Element => {
    const { data: employees, isLoading, isError } = useEmployees(businessId);
    const primaryEmployee = employees?.find(e => e.id === primaryEmployeeId);

    if (isError) return <ErrorPage hideActions />;
    if (isLoading) return <LoadingIndicator />;
    if (!employees || employees.length === 0)
        return (
            <ErrorPage hideActions title="No employees! Either skip this step or add employees on the previous step." />
        );

    return (
        <>
            <EmployeeSearch
                disabled={isDisabled}
                employees={employees}
                label="Choose a Primary Contact for the Business"
                onChange={employeeId => setPrimaryEmployeeId(employeeId)}
                value={primaryEmployeeId}
            />
            {primaryEmployee && (
                <>
                    <div className="mt-10 mb-4">Primary Contact</div>
                    <div className="flex items-center max-w-md">
                        <Avatar src={primaryEmployee.avatar || undefined} />
                        <div className="truncate flex-1 ml-3">
                            <div className="truncate text-base">{NameFormatter.getLastNameFirst(primaryEmployee)}</div>
                            <div className="text-textDisabled truncate text-sm">{primaryEmployee.emailAddress}</div>
                        </div>
                        <Button
                            color="tertiary"
                            iconOnly={faTrash}
                            iconClassName="text-error"
                            onClick={() => setPrimaryEmployeeId(undefined)}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default PrimaryEmployeeStep;
