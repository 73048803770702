import { generalPartnerColor, limitedPartnerColor } from './constants';

interface IProps {
    generalPartnerInterest: string;
}

const InterestGraph = ({ generalPartnerInterest }: IProps): JSX.Element => {
    const parsedInterest = generalPartnerInterest?.length > 0 ? parseFloat(generalPartnerInterest) : 0;
    const cappedGeneralPartnerInterest = Math.min(Math.max(parsedInterest, 1), 99);

    return (
        <div className="relative pt-8" aria-hidden>
            <div className={`flex h-2 rounded bg-current ${limitedPartnerColor}`}>
                <div
                    style={{ width: `${cappedGeneralPartnerInterest}%` }}
                    className={`rounded-l bg-current ${generalPartnerColor}`}
                ></div>
                <div style={{ width: '0.5%' }} className={`bg-textDefault relative`}>
                    <div
                        style={{ left: '-0.675rem' }}
                        className={`absolute h-6 w-6 rounded-full border-4 border-textDefault bottom-6 bg-current ${generalPartnerColor}`}
                    ></div>
                    <div className="absolute left-0 h-6 w-0.5 bottom-0 bg-textDefault"></div>
                </div>
            </div>
            <div className="flex justify-between mt-2">
                <div className={generalPartnerColor}>General Partner</div>
                <div className={limitedPartnerColor}>Limited Partner</div>
            </div>
        </div>
    );
};

export default InterestGraph;
