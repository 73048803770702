import { IBusiness, IEmployee } from '@api';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button, EmptyStateIcon, ErrorPage, LoadingIndicator, RadioGroup, Select } from '~/components';
import { useBusinessValuation, useEmployees } from '~/hooks';
import { ILocationState } from '~/pages/ILocationState';
import { Link, useLocation } from '~/routing';
import { getEquityPercentageByEmployeeId } from '~/utils/businessUtils';
import CurrencyFormatter from '~/utils/currencyFormatter';
import NameFormatter from '~/utils/nameFormatter';
import { IEditPageType } from '../../edit';
import { SuccessionPlanType } from './models';
import ObservationList from './ObservationList';
import PlanIllustration from './PlanIllustration';

interface IProps {
    business: IBusiness;
}

const planTypeLabel: Record<SuccessionPlanType, string> = {
    CrossPurchase: 'Cross Purchase',
    NoPlan: 'No Plan',
    StockRedemption: 'Stock Redemption',
};
const getObservations = (
    successionPlanType: SuccessionPlanType,
    deceasedOwner: IEmployee,
    deceasedEquityAmount: number,
    businessName: string
): string[] => {
    switch (successionPlanType) {
        case SuccessionPlanType.CrossPurchase:
            return [
                `The surviving owners have the obligation to pay ${deceasedOwner.firstName}'s estate for their stock.`,
                `The surviving owners owe ${deceasedOwner.firstName}'s estate ${CurrencyFormatter.format(
                    deceasedEquityAmount
                )}.`,
                `${deceasedOwner.firstName}'s estate has no vote, no salary, and no benefits.`,
            ];
        case SuccessionPlanType.NoPlan:
            return [
                `The surviving owners are now partners with ${deceasedOwner.firstName}'s estate.`,
                `${deceasedOwner.firstName}'s estate continues with ${deceasedOwner.firstName}'s income and benefits.`,
                `${deceasedOwner.firstName}'s estate can use the stock to borrow assets or can sell their shares to any or all partners.`,
            ];
        case SuccessionPlanType.StockRedemption:
            return [
                `${businessName} has the obligation to pay ${deceasedOwner.firstName}'s estate for their stock.`,
                `${businessName} owes ${deceasedOwner.firstName}'s estate ${CurrencyFormatter.format(
                    deceasedEquityAmount
                )}.`,
                `${deceasedOwner.firstName}'s estate has no vote, no salary, and no benefits.`,
            ];
    }
};

const SuccessionPlan = (props: IProps): JSX.Element => {
    const { business } = props;
    const [selectedPlanOption, setSelectedPlanOption] = useState(SuccessionPlanType.NoPlan);
    const { isError, isLoading, data: employees = [] } = useEmployees(business.id);
    const owners = employees.filter(e => e.isOwner).sort(a => (a.id === business.primaryEmployeeId ? -1 : 1));
    const [selectedOwnerId, setSelectedOwnerId] = useState<string | undefined>(owners[0]?.id);
    const businessValuationQuery = useBusinessValuation(business.id);
    const businessSettingsState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Succession Plan',
    };

    if (isError || businessValuationQuery.isError || !businessValuationQuery.data) return <ErrorPage />;
    if (isLoading || businessValuationQuery.isLoading) return <LoadingIndicator />;
    if (owners.length === 0) {
        return (
            <div className="flex flex-col h-full p-10">
                <h2 className="text-xl">Succession Plan</h2>
                <div className="p-3 p-md-5 flex flex-col items-center text-center">
                    <EmptyStateIcon icon={faUsers} />
                    <p className="my-4 text-lg">No owners added yet.</p>
                    <Link
                        to={{
                            pathname: `/${business.id}/Edit/${IEditPageType.Employees}`,
                            state: businessSettingsState,
                        }}
                    >
                        <Button color="primary" text="Add Owners" />
                    </Link>
                </div>
            </div>
        );
    }

    const { data: businessValuation } = businessValuationQuery;
    const deceasedOwner = owners.find(o => o.id === selectedOwnerId) as IEmployee;
    const otherOwners = owners.filter(o => o.id !== selectedOwnerId);
    const equityPercentageByEmployeeId = getEquityPercentageByEmployeeId(business, employees);
    const deceasedEquityPercentage = deceasedOwner ? equityPercentageByEmployeeId[deceasedOwner.id] : 0;
    const deceasedEquityAmount = businessValuation.value * deceasedEquityPercentage;

    return (
        <div className="flex flex-col h-full p-10">
            <div>
                <h2 className="mb-5 text-xl">Succession Plan</h2>
                <div className="flex">
                    <Select
                        className="w-80 mr-4"
                        options={owners.map(o => ({
                            label: NameFormatter.getName(o),
                            value: o.id,
                        }))}
                        value={selectedOwnerId}
                        onChange={e => setSelectedOwnerId(e?.value)}
                    />
                    <RadioGroup
                        value={selectedPlanOption.toString()}
                        options={[
                            SuccessionPlanType.NoPlan,
                            SuccessionPlanType.StockRedemption,
                            SuccessionPlanType.CrossPurchase,
                        ].map(s => ({
                            label: planTypeLabel[s],
                            value: s,
                        }))}
                        onChange={e => setSelectedPlanOption(e as SuccessionPlanType)}
                    />
                </div>
            </div>
            {selectedOwnerId && (
                <div className="flex flex-col flex-grow">
                    <div className="flex items-center flex-grow">
                        <PlanIllustration
                            businessValuation={businessValuation}
                            employees={employees}
                            deceasedOwner={deceasedOwner}
                            otherOwners={otherOwners}
                            successionPlanType={selectedPlanOption}
                            {...props}
                        />
                    </div>
                    <div>
                        <ObservationList
                            observations={getObservations(
                                selectedPlanOption,
                                deceasedOwner,
                                deceasedEquityAmount,
                                business.name
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SuccessionPlan;
