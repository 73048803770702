import { configureStore } from '@reduxjs/toolkit';
import documentCenter from './documentCenter';
import employeeUpload from './employeeUpload';
import errorModal from './errorModal';
import successNotification from './successNotification';

const store = configureStore({
    reducer: {
        documentCenter,
        employeeUpload,
        errorModal,
        successNotification,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
