import { IAdvisorFirmSummary } from '@api';
import { VerticalTabs as Tabs } from '~/components';
import IPanel from './IPanel';
import PanelContainer from './PanelContainer';
import ProcessInfo from './ProcessInfo';
import teaser from './teaser-incentives.png';

const TabId = 'DesigningIncentivePlans';

const DesigningIncentivePlans = (advisorFirm: IAdvisorFirmSummary | undefined): IPanel => ({
    Id: TabId,
    Tab: <Tabs.Tab tabId={TabId}>Designing an STIP and LTIP</Tabs.Tab>,
    Panel: (
        <Tabs.Panel tabId={TabId}>
            <PanelContainer>
                <img alt="" aria-hidden src={teaser} className="object-cover w-full h-15"></img>
                <h2 className="font-bold">Designing a Short-Term Incentive Plan</h2>
                <p>
                    An annual incentive plan is the most common type of variable pay program offered by businesses. Yet,
                    studies show that only 10% of private company leaders think theirs is effective. This is because
                    most plans are over-engineered, uninsipiring or both. A well-designed plan will be simple to
                    understand, have credible performance targets, provide meaningful value to participants, and
                    increase shareholder value. Effective incentive plans promote a partnership mindset and drive higher
                    productivity and innovation.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} partners with The VisionLink Advisory Group to offer incentive
                    plan design services to our business clients. Since 1996, VisionLink has designed hundreds of
                    value-sharing plans for companies in nearly every industry. Through its decades of experience, it
                    has virtually perfected incentive plan design. VisionLink uses proprietary technology to ensure
                    effective design, implementation and ongoing management of both short and long-term value-sharing
                    plans.
                </p>
                <ProcessInfo
                    outcome="A compelling value-sharing program that communicates a strong partnership relationship with your employees while assuring a positive ROI for the company."
                    steps={[
                        'Clearly identify the high-level intent and purpose of your annual incentive plan. Keep it simple. E.g., "Achieve 20% profit margin."',
                        'Establish your eligible group by identifying the company employees who will have the greatest impact on your desired result.',
                        'Establish Company performance expectations at minimum (Base), and expected (Target) levels of achievement, as well as conceivable Company overachievement (Superior)',
                        'Budget  incentive opportunities for each role. Set a different level of opportunity correlated to Company performance at Base, Target, & Superior.  Adjust until the plan budget is affordable at all levels.',
                        'Consider and determine if and how to include department and individual performance metrics in the award calculations.',
                        'Prepare clear communication materials to present the plan to your employees. Update possible plan results throughout the year. Communicate values promptly at year-end.',
                    ]}
                />
                <div className="py-8">
                    <hr className="border-t border-divider"></hr>
                </div>
                <h2 className="font-bold">Adopting a Phantom Stock Plan</h2>
                <p>
                    Phantom stock is a type of long-term incentive plan (LTIP) that promises participants a future cash
                    benefit that is tied to the value of the business (or the growth in the business). It is like
                    offering stock or stock options without actual shares being exchanged (i.e., no dilution). A phantom
                    stock plan (sometimes called synthetic equity) is perfect for growth-oriented private business
                    owners seeking a tax-friendly way to reward employees and strengthen long-term engagement.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} partners with The VisionLink Advisory Group to provide phantom
                    stock design services to our business clients. VisionLink has designed more phantom stock plans than
                    any other firm in the country. They are phantom stock experts. Since 1996, VisionLink has designed
                    hundreds of plans for businesses of all types. With its proprietary software, it can help you
                    design, implement, communicate and manage a phantom stock plan simply and inexpensively.
                </p>
                <ProcessInfo
                    outcome="A significant wealth-building opportunity for your premier talent that will align them with your view of your company's future, and motivate them to help fulfill it."
                    steps={[
                        'Select from the VisionLink Decision Tree the right type of phantom stock program (or other long-term incentive plan alternative).',
                        'Establish your eligible group by identifying the company employees who will have the greatest impact on your long-term growth objectives.',
                        'Set your "Value-Sharing Budget" (the percentage of growth in company value that might be shared under the plan).',
                        'Arrive at a basic company valuation that can be used to to form individual units of value (phantom shares).',
                        'Determine the terms under which the units can be redeemed (vesting, events, etc.) as well as the number of units for employees (staying within the value-sharing budget).',
                        'Prepare proper documentation and clear communication materials to present the plan to your employees. Promote the plan as part of your "partnership with leadership."',
                    ]}
                />
            </PanelContainer>
        </Tabs.Panel>
    ),
});

export { DesigningIncentivePlans };
