import { IBusinessTeamMember, IBusinessTeamMemberType } from '@api';
import { EntitySelect, IEntitySelectOption } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    onChange: (id: string) => void;
    onClickNew: () => void;
    potentialMembers: IBusinessTeamMember[];
    teamMembers: IBusinessTeamMember[];
}

export interface IBusinessTeamMemberSelectOption extends IEntitySelectOption {
    type: IBusinessTeamMemberType;
}

const TeamMemberSearch = ({ onChange, onClickNew, potentialMembers, teamMembers }: IProps): JSX.Element => {
    const membersNotOnTeam = potentialMembers.filter(e => !teamMembers.some(t => t.id === e.id));
    const options: IBusinessTeamMemberSelectOption[] = [
        ...membersNotOnTeam.map(a => ({
            avatar: a.avatar || undefined,
            description: a.title || undefined,
            label: NameFormatter.getLastNameFirst(a),
            name: NameFormatter.getLastNameFirst(a),
            showAddButton: true,
            type: a.type,
            value: a.id,
        })),
    ];

    const handleChange = (option: IEntitySelectOption | null | undefined) => {
        if (!option?.value) {
            return;
        }
        onChange(option.value);
    };

    return (
        <EntitySelect
            isCreatable
            options={options}
            placeholder="Add people"
            onEntityOptionSelected={handleChange}
            onNewSelected={onClickNew}
        />
    );
};

export default TeamMemberSearch;
