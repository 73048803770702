import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    icon: IconProp;
}

const EmptyStateIcon = ({ icon }: IProps): JSX.Element => (
    <div className="border-2 p-6 rounded-full border-dashed border-current text-divider">
        <div className="p-6 rounded-full relative">
            <FontAwesomeIcon icon={icon} size="3x" className="absolute top-0 -left-1.5" fixedWidth />
        </div>
    </div>
);

export { EmptyStateIcon };
