import { IDocumentPathSegment } from '@api';
import { faFolder } from '@fortawesome/pro-light-svg-icons';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons';
import { Fragment } from 'react';
import { Button } from '~/components';
import { useAppSelector } from '~/redux/hooks';
import { getRootFolderName } from '../../utils/rootFolder';

interface IProps {
    folderSegments: IDocumentPathSegment[];
    onChangeActiveFolder: (segmentId: string) => void;
}

const ModalBreadcrumbs = ({ folderSegments, onChangeActiveFolder }: IProps): JSX.Element => {
    const { rootFolder } = useAppSelector(s => s.documentCenter);
    const immediateAncestorId = folderSegments.length > 1 ? folderSegments[folderSegments.length - 2].id : null;
    const [activeFolder] = folderSegments.slice(-1);
    const parentSegments = folderSegments.filter(s => s.id !== activeFolder.id);
    if (!rootFolder) {
        return <></>;
    }

    const rootFolderName = getRootFolderName(rootFolder);

    return (
        <div className="flex items-center mb-2">
            <Button
                className="mr-2"
                color="secondary"
                disabled={!immediateAncestorId}
                iconOnly={faCaretLeft}
                size="sm"
                title="View previous folder"
                onClick={() => immediateAncestorId && onChangeActiveFolder(immediateAncestorId)}
            />
            <Button
                className="mr-2"
                color="link"
                iconOnly={faFolder}
                size="lg"
                title="View root folder"
                onClick={() => folderSegments.length > 0 && onChangeActiveFolder(folderSegments[0].id)}
            />
            {parentSegments.map(s => (
                <Fragment key={s.id}>
                    <Button
                        className="mr-2 text-action"
                        color="link"
                        size="lg"
                        text={rootFolder.folderId === s.id ? rootFolderName : s.name}
                        onClick={() => onChangeActiveFolder(s.id)}
                    />
                    <div className="mr-2">/</div>
                </Fragment>
            ))}
            <div>{rootFolder.folderId === activeFolder.id ? rootFolderName : activeFolder.name}</div>
        </div>
    );
};

export default ModalBreadcrumbs;
