import classnames from 'classnames';
import { IChartSeries } from './chartModels';

interface IProps {
    className?: string;
    series: IChartSeries[];
}

const ChartLegend = ({ className, series }: IProps): JSX.Element => {
    return (
        <div className={classnames(className, 'flex')}>
            {series.map(s => (
                <div key={s.name} className="flex items-center mr-6">
                    <div className={`bg-${s.color} rounded-full self-center h-4 w-4 mr-2`} />
                    {s.name}
                </div>
            ))}
        </div>
    );
};

export { ChartLegend };
