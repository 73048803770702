import { Button } from '~/components';
import { Link, useRouteMatch } from '~/routing';
import visionLinkLogo from '../VisionLinkLogo.png';

interface ITestNotTakenYetProps {
    setSampleCaseOpen: (isOpen: boolean) => void;
    setServicesModalOpen: (isOpen: boolean) => void;
}

const TestNotTaKenYet = ({ setSampleCaseOpen, setServicesModalOpen }: ITestNotTakenYetProps): JSX.Element => {
    const match = useRouteMatch();
    return (
        <>
            <img className="h-10 sm:h-auto mx-auto white-logo" alt="VisionLink Logo" src={visionLinkLogo} />
            <div className="my-8">
                Design comprehensive incentive plans to inspire employees. Individualized reporting allows for in-depth
                discussions around customized employee incentive plans and benefits.
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-3">
                <Button
                    color="primary"
                    fullWidth
                    className="bg-affirmation"
                    text="Sample Case"
                    onClick={() => setSampleCaseOpen(true)}
                />
                <Button color="secondary" fullWidth text="Learn More" onClick={() => setServicesModalOpen(true)} />
            </div>
            <div className="mt-6 mb-8">
                We have made a survey test to better serve you with your needs, Please take the test to connect with a
                VisionLink Advisor.
            </div>
            <Link to={`${match.url}/Assessment`}>
                <Button color="primary" className="w-full justify-center" text="Take Test" />
            </Link>
        </>
    );
};

export default TestNotTaKenYet;
