import { faFileAlt, faFolderOpen } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DropdownMenu } from '~/components';
import { ModalType, setSelectedModal } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { rootSharedFolderId } from './utils/rootFolder';

const AddButton = (): JSX.Element => {
    const { currentFolderId } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();
    const canAddToCurrentLocation = currentFolderId !== rootSharedFolderId;

    return (
        <DropdownMenu position="right">
            <DropdownMenu.Trigger
                as={Button}
                color="primary"
                disabled={!canAddToCurrentLocation}
                iconLeft={faPlusCircle}
                refName="buttonRef"
                text="Add New"
                title={canAddToCurrentLocation ? undefined : 'Cannot add here. Please select a folder first.'}
            />
            <DropdownMenu.Item onClick={() => dispatch(setSelectedModal({ type: ModalType.AddFolder }))}>
                <FontAwesomeIcon aria-hidden="true" className="mr-2" fixedWidth icon={faFolderOpen} size="lg" />
                Folder
            </DropdownMenu.Item>
            <DropdownMenu.Item
                onClick={() => dispatch(setSelectedModal({ type: ModalType.AddFiles, folderId: currentFolderId }))}
            >
                <FontAwesomeIcon aria-hidden="true" className="mr-2" fixedWidth icon={faFileAlt} size="lg" />
                File
            </DropdownMenu.Item>
        </DropdownMenu>
    );
};

export default AddButton;
