import { IBusinessPageType } from '@api';
import { matchPath } from '~/routing';

const APP_NAME = IBusinessPageType.DocumentCenter;

export const getBaseRoute = (pathname = window.location.pathname): string => {
    const base = pathname.split(`/${APP_NAME}`)[0];
    return `${base}/${APP_NAME}`;
};

export const getFolderRoute = (folderId: string, pathname = window.location.pathname): string => {
    const subRoute = 'Folder';

    return `${getBaseRoute(pathname)}/${subRoute}/${folderId}`;
};

export const getFolderIdFromUrl = (pathname = window.location.pathname): string | undefined => {
    const subRoute = 'Folder';
    const match = matchPath<{ folderId: string }>(pathname, {
        path: `${getBaseRoute(pathname)}/${subRoute}/:folderId`,
        exact: false,
        strict: false,
    });

    return match?.params?.folderId;
};
