import { useEffect } from 'react';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useDocumentList } from '~/hooks';
import { setRootFolder } from '~/redux/documentCenter';
import { useAppDispatch } from '~/redux/hooks';
import { Redirect, Route, RouteComponentProps, Switch, useParams, withRouter } from '~/routing';
import { BusinessParams } from '../..';
import { DocumentCenterContextProvider } from './DocumentCenterContext';
import Folder from './Folder';

export interface FolderParams extends BusinessParams {
    folderId: string;
}

const DocumentCenter = ({ match }: RouteComponentProps): JSX.Element | null => {
    const params = useParams<BusinessParams>();
    const rootDocumentList = useDocumentList(params.businessId);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (rootDocumentList.data) {
            dispatch(setRootFolder(rootDocumentList.data));
        }
    }, [dispatch, rootDocumentList]);

    if (rootDocumentList.isLoading) return <LoadingIndicator />;
    if (rootDocumentList.error || !rootDocumentList.data?.folderId) return <ErrorPage />;

    const rootFolderId = rootDocumentList.data.folderId;
    return (
        <DocumentCenterContextProvider>
            <Switch>
                <Route exact path={`${match.path}/Folder/:folderId`} component={Folder} />
                <Redirect to={`${match.url}/Folder/${rootFolderId}`} />
            </Switch>
        </DocumentCenterContextProvider>
    );
};

export default withRouter(DocumentCenter);
