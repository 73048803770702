import { IEmployee } from '@api';
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '~/components';
import NameFormatter from '~/utils/nameFormatter';
import CurrencyTableCell from '../../CurrencyTableCell';
import { getExposure } from './getExposure';

interface IProps {
    employees: IEmployee[];
}

const LongTermDisabilityTable = ({ employees }: IProps): JSX.Element => {
    return (
        <div className="max-w-7xl">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Title</TableHeaderCell>
                        <TableHeaderCell>Salary</TableHeaderCell>
                        <TableHeaderCell>Coverage</TableHeaderCell>
                        <TableHeaderCell>Exposure</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employees.map(employee => (
                        <TableRow key={employee.id}>
                            <TableCell>{NameFormatter.getLastNameFirst(employee)}</TableCell>
                            <TableCell>{employee.title}</TableCell>
                            <CurrencyTableCell value={employee.salary} />
                            <CurrencyTableCell value={employee.disabilityInsuranceCoverage} />
                            <CurrencyTableCell
                                background="bg-background1"
                                value={getExposure(employee.disabilityInsuranceCoverage, employee.salary)}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default LongTermDisabilityTable;
