import { Slider } from '~/components';

interface IProps {
    onChange: (value: number) => void;
    value: number | null | undefined;
}

const roundValue = (value: number | null | undefined): number => Math.round((value || 0) * 100);

const PercentageSlider = ({ onChange, value }: IProps): JSX.Element => {
    return (
        <Slider
            max={100}
            maxLabel="100%"
            min={0}
            minLabel="0%"
            onChange={onChange}
            step={1}
            unit="%"
            value={roundValue(value)}
        />
    );
};

export default PercentageSlider;
