import { Popover } from '@envestnet/envreact-component-library';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IRadarChartAxis {
    icon?: IconDefinition;
    label: string;
}
export const axisIconMargin = 8;
export const axisIconWidth = 30;
export const axisIconHeight = 30;
export const axisLabelMargin = 8;
export const axisLabelWidth = 120;
export const axisLabelHeight = 50;

interface IProps {
    axes: IRadarChartAxis[];
    iconsOnly?: boolean;
    radians: number;
    radius: number;
}

const RadarChartAxes = ({ axes, iconsOnly = false, radians, radius }: IProps): JSX.Element => {
    const axisMargin = iconsOnly ? axisIconMargin : axisLabelMargin;
    const axisWidth = iconsOnly ? axisIconWidth : axisLabelWidth;
    const axisHeight = iconsOnly ? axisIconHeight : axisLabelHeight;

    return (
        <>
            {axes.map((a, i) => {
                const axisX2 = radius * (1 - Math.sin((i * radians) / axes.length));
                const axisY2 = radius * (1 - Math.cos((i * radians) / axes.length));
                let axisLabelX;
                let axisLabelY;

                if (
                    Math.floor(axisX2) === Math.floor(radius) ||
                    Math.floor(axisX2) === Math.ceil(radius) ||
                    Math.ceil(axisX2) === Math.floor(radius) ||
                    Math.ceil(axisX2) === Math.ceil(radius)
                ) {
                    axisLabelX = axisX2 - axisWidth / 2;
                } else if (axisX2 < radius) {
                    axisLabelX = axisX2 - axisWidth - axisMargin;
                } else {
                    axisLabelX = axisX2 + axisMargin;
                }

                if (
                    Math.floor(axisY2) === Math.floor(radius) ||
                    Math.floor(axisY2) === Math.ceil(radius) ||
                    Math.ceil(axisY2) === Math.floor(radius) ||
                    Math.ceil(axisY2) === Math.ceil(radius)
                ) {
                    axisLabelY = axisY2 - axisHeight / 2;
                } else if (axisY2 < radius) {
                    axisLabelY = axisY2 - axisHeight - axisMargin;
                } else {
                    axisLabelY = axisY2 + axisMargin;
                }

                return (
                    <g key={i}>
                        <line
                            x1={radius}
                            y1={radius}
                            x2={axisX2}
                            y2={axisY2}
                            className="stroke-current text-divider"
                            strokeWidth=".5px"
                        ></line>
                        <foreignObject height={axisHeight} width={axisWidth} x={axisLabelX} y={axisLabelY}>
                            {iconsOnly ? (
                                a.icon && (
                                    <Popover
                                        backgroundColor="var(--color-bg-700)"
                                        content={a.label}
                                        className="text-center inline-flex"
                                        placement="top"
                                        style={{ zIndex: 1500 }}
                                        triggers={['click', 'hover', 'focus']}
                                    >
                                        <FontAwesomeIcon className="cursor-pointer" size="lg" icon={a.icon} />
                                    </Popover>
                                )
                            ) : (
                                <div className="leading-none rounded text-center p-2 bg-background1">{a.label}</div>
                            )}
                        </foreignObject>
                    </g>
                );
            })}
        </>
    );
};

export default RadarChartAxes;
