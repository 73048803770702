import { IBusiness } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ValidationError } from 'yup';
import { Button, ErrorPage, LoadingIndicator, useAvatarInput } from '~/components';
import { useAddEmployee, useBusinessValuation, useEmployeeAvatar, useEmployees, useUpdateBusiness } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import { errorParser } from '~/utils/errorParser';
import { yupToFormErrors } from '~/utils/yupUtils';
import EmployeeSearch from './EmployeeSearch';
import PrimaryEmployeeForm, {
    IPrimaryEmployeeFormData,
    mapFormDataToApi,
    schemaValidation,
} from './PrimaryEmployeeForm';

interface IProps {
    business: IBusiness;
}

const getInitialFormData = (): IPrimaryEmployeeFormData => {
    return {
        emailAddress: '',
        firstName: '',
        lastName: '',
        title: '',
    };
};

const PrimaryEmployee = ({ business }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));

    const [formData, setFormData] = useState<IPrimaryEmployeeFormData>(getInitialFormData());
    const [primaryEmployeeAvatar, setPrimaryEmployeeAvatar] = useAvatarInput(business);
    const [primaryEmployeeId, setPrimaryEmployeeId] = useState<string | null | undefined>(business.primaryEmployeeId);
    const {
        isError: isErrorBusinessValuation,
        isLoading: isLoadingBusinessValuation,
        data: businessValuation,
    } = useBusinessValuation(business.id);
    const [isSaving, setIsSaving] = useState(false);
    const isDisabled = isSaving || isLoadingBusinessValuation || isErrorBusinessValuation;
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const addEmployee = useAddEmployee(business.id);
    const updateBusiness = useUpdateBusiness(business.id);
    const updateEmployeeAvatar = useEmployeeAvatar(business.id);
    const {
        isError: isEmployeesError,
        isLoading: isEmployeesLoading,
        data: employees = [],
    } = useEmployees(business.id);

    const onAddPrimaryEmployee = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        const apiData = mapFormDataToApi(formData);
        const { id: primaryEmployeeId } = await addEmployee.mutateAsync({
            ...apiData,
            businessId: business.id,
        });
        if (primaryEmployeeAvatar?.file) {
            await updateEmployeeAvatar.mutateAsync({
                avatar: primaryEmployeeAvatar.file,
                employeeId: primaryEmployeeId,
            });
        }
        setPrimaryEmployeeId(primaryEmployeeId);
        showSuccess();
    };
    const onUpdatePrimaryEmployee = async (primaryEmployeeId: string) => {
        await updateBusiness.mutateAsync({
            ...business,
            primaryEmployeeId,
            value: businessValuation?.value,
        });
        showSuccess();
    };
    const handleSave = async () => {
        try {
            setIsSaving(true);
            if (!primaryEmployeeId) {
                await onAddPrimaryEmployee();
            } else {
                await onUpdatePrimaryEmployee(primaryEmployeeId);
            }
        } catch (error) {
            const fieldValidationErrors = errorParser.getFieldValidationErrors(error);

            if (fieldValidationErrors) {
                setValidationErrors(fieldValidationErrors);
                return;
            }

            showError();
        } finally {
            setIsSaving(false);
        }
    };

    if (isEmployeesError) return <ErrorPage />;
    if (isEmployeesLoading) return <LoadingIndicator />;

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Primary Contact Info</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-textSubtle">
                Enter information or choose an employee as the primary contact for the business.
            </p>
            {(isErrorBusinessValuation || !businessValuation) && (
                <div className="mb-4">
                    <FontAwesomeIcon className="mr-2 text-caution" icon={faExclamationTriangle} size="1x" />
                    An unknown error occurred retrieving the business valuation.
                </div>
            )}
            <div className="space-y-3">
                <EmployeeSearch
                    disabled={isDisabled}
                    employees={employees}
                    onChange={employeeId => setPrimaryEmployeeId(employeeId)}
                    onClickNew={() => setPrimaryEmployeeId(undefined)}
                    value={primaryEmployeeId}
                />
                {!primaryEmployeeId && (
                    <div className="mt-5">
                        <PrimaryEmployeeForm
                            autoFocus
                            avatar={primaryEmployeeAvatar?.url}
                            disabled={isDisabled}
                            errors={validationErrors}
                            formData={formData}
                            onChange={primaryEmployeeFormData => setFormData({ ...primaryEmployeeFormData })}
                            onChangeAvatar={setPrimaryEmployeeAvatar}
                        />
                    </div>
                )}
                <div className="py-3 border-t border-background2 text-right sm:px-0">
                    <Button color="primary" disabled={isDisabled} text="Save" onClick={handleSave} />
                </div>
            </div>
        </>
    );
};

export default PrimaryEmployee;
