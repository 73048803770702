import { IBizEquityBusinessUpdateRequest } from '@api';
import { CurrencyInput, ISelectOption, Label, NumericInput, RadioGroup, Select } from '~/components';
import InfoTooltip from './InfoTooltip';
import PercentageSlider from './PercentageSlider';

interface IProps {
    formData: IBizEquityBusinessUpdateRequest;
    setFormData: (formData: IBizEquityBusinessUpdateRequest) => void;
}

const ownerLeftImpact: ISelectOption[] = [
    {
        label: 'Remain the same',
        value: 'REMAIN_THE_SAME',
    },
    {
        label: 'Decline minimally',
        value: 'DECLINE_MINIMALLY',
    },
    {
        label: 'Decline moderately',
        value: 'DECLINE_MODERATELY',
    },
    {
        label: 'Fall substantially',
        value: 'FALL_SUBSTANTIALLY',
    },
    {
        label: 'Plummet',
        value: 'PLUMMET',
    },
];

const OperationsForm = ({ formData, setFormData }: IProps): JSX.Element => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 xl:gap-y-6">
            <div className="flex flex-nowrap lg:col-span-2 xl:col-span-1">
                <InfoTooltip>
                    <b>Sales from top 3 customers:</b> How much of the business's revenue comes from a small portion of
                    the customer base? Concentration of revenue streams may have an impact on the business's value.
                </InfoTooltip>
                <div>What percentage of sales comes from your top three customers?</div>
            </div>
            <div className="lg:col-span-2 mb-6 xl:mb-0">
                <PercentageSlider
                    onChange={e => setFormData({ ...formData, topThreeCustomers: e / 100 })}
                    value={formData.topThreeCustomers}
                />
            </div>
            <div className="flex flex-nowrap lg:col-span-2 xl:col-span-1">
                <InfoTooltip>
                    <b>Owner leaving impact:</b> An owner's departure may have a significant impact on business value.
                    In a succession planning scenario this is a key question to address for purposes of ensuring that
                    revenue-generating relationships are transferred ahead of or as part of a sale. This question can
                    also show the impact of a "key person" leaving the organization, and may show a need for a
                    key-person insurance coverage conversation.
                </InfoTooltip>
                <div>If the owner of the business left, how would your profits and revenues be impacted?</div>
            </div>
            <div className="lg:col-span-2 mb-6 mt-1 xl:my-0">
                <Select
                    options={ownerLeftImpact}
                    value={formData.ownerLeftImpact || 'REMAIN_THE_SAME'}
                    onChange={e => setFormData({ ...formData, ownerLeftImpact: e?.value })}
                />
            </div>
            <div className="flex lg:col-span-2 xl:col-span-3 flex-col lg:flex-row mb-6 xl:mb-0">
                <div className="flex flex-nowrap">
                    <InfoTooltip>
                        <b>Key Person Insurance:</b> Life Insurance and "Key Person" or "Key Man" Insurance may be an
                        important protection that this business does not have. While this question has no effect on the
                        valuation figures, and is included for data gathering purposes, the concept of proper protection
                        should be explored with a financial professional.
                    </InfoTooltip>
                    <Label
                        labelClassName="mr-4"
                        label="Does your current life or key person insurance coverage and risk management program cover the business valuation quotation above?"
                    />
                </div>
                <RadioGroup
                    className="flex-shrink-0"
                    options={[
                        {
                            label: 'Yes',
                            value: 'true',
                        },
                        {
                            label: 'No',
                            value: 'false',
                        },
                    ]}
                    onChange={e => setFormData({ ...formData, insuranceCovered: e === 'true' })}
                    value={formData.insuranceCovered ? 'true' : 'false'}
                />
            </div>
            <div className="flex lg:col-span-2 xl:col-span-3 flex-col lg:flex-row mb-6 xl:mb-0">
                <div className="flex flex-nowrap">
                    <InfoTooltip>
                        <b>Rent or Own:</b> If your business owns the building or property you operate from, knowing the
                        value of your business with property included may be of interest. When property is owned by the
                        business entity and property value details are entered, an "including real estate" value will
                        appear just below the Estimated Business Value. Property value will also have an effect on the
                        Asset Sale and Liquidation Values found in the valuation report. It is also possible to include
                        the value of real estate used by the business BUT owned by another separate legal entity (with
                        common ownership)
                    </InfoTooltip>
                    <Label labelClassName="mr-4" label="Do you rent or own?" />
                </div>
                <RadioGroup
                    className="flex-shrink-0"
                    options={[
                        {
                            label: 'Rent',
                            value: 'RENT',
                        },
                        {
                            label: 'Own',
                            value: 'OWN',
                        },
                    ]}
                    onChange={ownership =>
                        setFormData({ ...formData, realEstate: { ...formData.realEstate, ownership } })
                    }
                    value={formData.realEstate?.ownership}
                />
            </div>
            <div className="lg:pl-14 xl:pl-0 xl:col-start-2 lg:col-span-2 space-y-4">
                {formData.realEstate?.ownership === 'RENT' && (
                    <>
                        <RadioGroup
                            vertical
                            options={[
                                {
                                    label: 'Rent is paid to an independent 3rd party.',
                                    value: 'THIRD_PARTY',
                                },
                                {
                                    label: 'Rent is paid to an entity that you own or are affiliated with.',
                                    value: 'SECOND_PARTY',
                                },
                            ]}
                            onChange={ownershipParty =>
                                setFormData({ ...formData, realEstate: { ...formData.realEstate, ownershipParty } })
                            }
                            value={formData.realEstate?.ownershipParty}
                        />
                        {formData.realEstate?.ownershipParty === 'THIRD_PARTY' && (
                            <>
                                <CurrencyInput
                                    label="What is your monthly rent?"
                                    value={formData.realEstate?.monthlyRent || 0}
                                    onChange={monthlyRent =>
                                        setFormData({
                                            ...formData,
                                            realEstate: { ...formData.realEstate, monthlyRent },
                                        })
                                    }
                                />
                                <NumericInput
                                    label="What is the square footage?"
                                    value={formData.realEstate?.area || undefined}
                                    onChange={area =>
                                        setFormData({ ...formData, realEstate: { ...formData.realEstate, area } })
                                    }
                                />
                            </>
                        )}
                        {formData.realEstate?.ownershipParty === 'SECOND_PARTY' && (
                            <>
                                <CurrencyInput
                                    label="What is the fair market value of your property?"
                                    value={formData.realEstate?.value || 0}
                                    onChange={value =>
                                        setFormData({ ...formData, realEstate: { ...formData.realEstate, value } })
                                    }
                                />
                                <CurrencyInput
                                    label={
                                        <div className="flex flex-nowrap">
                                            <InfoTooltip>
                                                The remaining mortgage owed on the real estate. This amount should NOT
                                                be included in the liabilities step. If it was entered in the
                                                liabilities section of the balance sheet, please remove the amount and
                                                enter it here.
                                            </InfoTooltip>
                                            <div>What is the remaining mortgage on the property?</div>
                                        </div>
                                    }
                                    value={formData.realEstate?.mortgage || 0}
                                    onChange={mortgage =>
                                        setFormData({
                                            ...formData,
                                            realEstate: { ...formData.realEstate, mortgage },
                                        })
                                    }
                                />
                                <CurrencyInput
                                    label={
                                        <div className="flex flex-nowrap">
                                            <InfoTooltip>
                                                The current monthly rent you are paying. This amount should have been
                                                deducted to reach the pre-tax income figure entered in Step 3.
                                            </InfoTooltip>
                                            <div>What is the current monthly rent you are paying?</div>
                                        </div>
                                    }
                                    value={formData.realEstate?.monthlyRent || 0}
                                    onChange={monthlyRent =>
                                        setFormData({
                                            ...formData,
                                            realEstate: { ...formData.realEstate, monthlyRent },
                                        })
                                    }
                                />
                                <CurrencyInput
                                    label={
                                        <div className="flex flex-nowrap">
                                            <InfoTooltip>
                                                The true amount of monthly rent that would normally be paid to a third
                                                party owner on an arms length basis.
                                            </InfoTooltip>
                                            <div>What would the true market level monthly rent be?</div>
                                        </div>
                                    }
                                    value={formData.realEstate?.trueMarketLevelMonthlyRent || 0}
                                    onChange={trueMarketLevelMonthlyRent =>
                                        setFormData({
                                            ...formData,
                                            realEstate: { ...formData.realEstate, trueMarketLevelMonthlyRent },
                                        })
                                    }
                                />
                                <NumericInput
                                    label="What is the square footage?"
                                    value={formData.realEstate?.area || undefined}
                                    onChange={area =>
                                        setFormData({ ...formData, realEstate: { ...formData.realEstate, area } })
                                    }
                                />
                            </>
                        )}
                    </>
                )}
                {formData.realEstate?.ownership === 'OWN' && (
                    <>
                        <CurrencyInput
                            label="What is the fair market value of your property?"
                            value={formData.realEstate?.value || 0}
                            onChange={value =>
                                setFormData({ ...formData, realEstate: { ...formData.realEstate, value } })
                            }
                        />
                        <CurrencyInput
                            label={
                                <div className="flex flex-nowrap">
                                    <InfoTooltip>
                                        The remaining mortgage owed on the real estate. This amount should NOT be
                                        included in the liabilities step. If it was entered in the liabilities section
                                        of the balance sheet, please remove the amount and enter it here.
                                    </InfoTooltip>
                                    <div>What is the remaining mortgage on the property?</div>
                                </div>
                            }
                            value={formData.realEstate?.mortgage || 0}
                            onChange={mortgage =>
                                setFormData({ ...formData, realEstate: { ...formData.realEstate, mortgage } })
                            }
                        />
                        <NumericInput
                            label="What is the square footage?"
                            value={formData.realEstate?.area || undefined}
                            onChange={area =>
                                setFormData({ ...formData, realEstate: { ...formData.realEstate, area } })
                            }
                        />
                        <RadioGroup
                            vertical
                            options={[
                                {
                                    label: 'The property is owned by this business entity.',
                                    value: 'THIRD_PARTY',
                                },
                                {
                                    label: 'The property owned by a 2nd entity with common ownership.',
                                    value: 'SECOND_PARTY',
                                },
                            ]}
                            value={formData.realEstate?.ownershipParty}
                            onChange={ownershipParty =>
                                setFormData({ ...formData, realEstate: { ...formData.realEstate, ownershipParty } })
                            }
                        />
                        {formData.realEstate?.ownershipParty === 'THIRD_PARTY' && (
                            <CurrencyInput
                                label={
                                    <div className="flex flex-nowrap">
                                        <InfoTooltip>
                                            The true amount of monthly rent that would normally be paid to a third party
                                            owner on an arms length basis.
                                        </InfoTooltip>
                                        <div>What would the true market level monthly rent be?</div>
                                    </div>
                                }
                                onChange={trueMarketLevelMonthlyRent =>
                                    setFormData({
                                        ...formData,
                                        realEstate: { ...formData.realEstate, trueMarketLevelMonthlyRent },
                                    })
                                }
                                value={formData.realEstate?.trueMarketLevelMonthlyRent || 0}
                            />
                        )}
                        {formData.realEstate?.ownershipParty === 'SECOND_PARTY' && (
                            <>
                                <CurrencyInput
                                    label={
                                        <div className="flex flex-nowrap">
                                            <InfoTooltip>
                                                The current monthly rent you are paying. This amount should have been
                                                deducted to reach the pre-tax income figure entered in Step 3.
                                            </InfoTooltip>
                                            <div>What is the current monthly rent paid to the 2nd entity?</div>
                                        </div>
                                    }
                                    value={formData.realEstate?.monthlyRent || 0}
                                    onChange={monthlyRent =>
                                        setFormData({
                                            ...formData,
                                            realEstate: { ...formData.realEstate, monthlyRent },
                                        })
                                    }
                                />
                                <CurrencyInput
                                    label={
                                        <div className="flex flex-nowrap">
                                            <InfoTooltip>
                                                The true amount of monthly rent that would normally be paid to a third
                                                party owner on an arms length basis.
                                            </InfoTooltip>
                                            <div>What would the true market level monthly rent be?</div>
                                        </div>
                                    }
                                    value={formData.realEstate?.trueMarketLevelMonthlyRent || 0}
                                    onChange={trueMarketLevelMonthlyRent =>
                                        setFormData({
                                            ...formData,
                                            realEstate: { ...formData.realEstate, trueMarketLevelMonthlyRent },
                                        })
                                    }
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default OperationsForm;
