import {
    IBizEquityBusiness,
    IBonusRightAggregatePaySummary,
    IBonusRightEmployeeRewards,
    IBonusRightEmployeeValueStatement,
    IBonusRightExecutiveBenefitAnalysis,
    IEmployee,
} from '@api';
import { getAge } from '~/utils/dateHelpers';
import NameFormatter from '~/utils/nameFormatter';

const currentYear = new Date().getFullYear();
const ltipYearlyMultiplier = 0.02;
const profitPercentOfValue = 0.25;
const revenuePercentOfValue = 0.667;
const salaryYearlyMultiplier = 0.04;
const stipYearlyMultiplier = 0.02;

export const getBizEquityBusiness = (businessValuation: number): IBizEquityBusiness => {
    return {
        assetValuation: businessValuation * 0.79,
        enterpriseValuation: businessValuation * 0.71,
        equityValuation: businessValuation,
        hasCompletedDataEntry: true,
        liquidationValuation: businessValuation * 0.19,
        yearlyStatements: {
            [currentYear]: {
                accountsPayables: businessValuation * 0.17,
                accountsReceivables: businessValuation * 0.26,
                accountsReceivablesToIncome: { comparison: 'LESS', value: 2.36 },
                accountsReceivablesToRevenue: { comparison: 'LESS', value: 48 },
                bankLoans: businessValuation * 0.07,
                cashToDebt: { comparison: 'GREATER', value: 0.95 },
                debtToEquity: { comparison: 'LESS', value: 1.3 },
                debtToIncome: { comparison: 'LESS', value: 3.74 },
                fixedAssets: businessValuation * 0.02,
                fixedAssetsToIncome: { comparison: 'GREATER', value: 0.22 },
                income: businessValuation * 0.11,
                incomeToEquity: { comparison: 'LESS', value: 0.35 },
                incomeToRevenue: { comparison: 'EQUAL', value: 0.06 },
                interestCoverage: { comparison: 'GREATER', value: 25.066 },
                inventory: businessValuation * 0.01,
                inventoryToIncome: { comparison: 'GREATER', value: 0.03 },
                otherLongTermLiabilities: businessValuation * 0.02,
                otherShortTermLiabilities: businessValuation * 0.13,
                revenue: businessValuation * 1.92,
                revenueToFixedAssets: { comparison: 'GREATER', value: 80.235 },
                revenueToInventory: { comparison: 'GREATER', value: 595.589 },
            },
            [currentYear - 1]: {
                accountsPayables: businessValuation * 0.14,
                accountsReceivables: businessValuation * 0.25,
                accountsReceivablesToIncome: { comparison: 'LESS', value: 4.33 },
                accountsReceivablesToRevenue: { comparison: 'LESS', value: 45 },
                bankLoans: 0,
                cashToDebt: { comparison: 'EQUAL', value: 0.53 },
                debtToEquity: { comparison: 'LESS', value: 1.09 },
                debtToIncome: { comparison: 'LESS', value: 3.95 },
                fixedAssets: businessValuation * 0.03,
                fixedAssetsToIncome: { comparison: 'GREATER', value: 0.49 },
                income: businessValuation * 0.06,
                incomeToEquity: { comparison: 'LESS', value: 0.28 },
                incomeToRevenue: { comparison: 'LESS', value: 0.03 },
                interestCoverage: { comparison: 'GREATER', value: 20.593 },
                inventory: 0,
                otherLongTermLiabilities: businessValuation * 0.02,
                otherShortTermLiabilities: businessValuation * 0.06,
                revenue: businessValuation * 2.06,
                revenueToFixedAssets: { comparison: 'GREATER', value: 71.739 },
            },
            [currentYear - 2]: {
                accountsPayables: businessValuation * 0.13,
                accountsReceivables: businessValuation * 0.22,
                accountsReceivablesToIncome: { comparison: 'LESS', value: 2.47 },
                accountsReceivablesToRevenue: { comparison: 'EQUAL', value: 41 },
                bankLoans: 0,
                cashToDebt: { comparison: 'GREATER', value: 0.71 },
                debtToEquity: { comparison: 'LESS', value: 1.05 },
                debtToIncome: { comparison: 'LESS', value: 2.45 },
                fixedAssets: businessValuation * 0.02,
                fixedAssetsToIncome: { comparison: 'GREATER', value: 0.2 },
                income: businessValuation * 0.09,
                incomeToEquity: { comparison: 'EQUAL', value: 0.43 },
                incomeToRevenue: { comparison: 'EQUAL', value: 0.04 },
                interestCoverage: { comparison: 'GREATER', value: 29.004 },
                inventory: 0,
                otherLongTermLiabilities: businessValuation * 0.03,
                otherShortTermLiabilities: businessValuation * 0.06,
                revenue: businessValuation * 2.03,
                revenueToFixedAssets: { comparison: 'GREATER', value: 110.937 },
            },
        },
    };
};

const getBonusRightSampleEmployeeRewardsForEmployee = (employee: IEmployee, bonusRightEmployeeId: number) => ({
    age: getAge(employee.dateOfBirth),
    avatar: employee.avatar,
    benefits: (employee.salary || 0) / 15,
    bonusRightEmployeeId,
    equity: null,
    grade: '',
    longTermIncentives: bonusRightEmployeeId <= 3 ? (employee.salary || 0) / 18 : null,
    name: NameFormatter.getName(employee),
    retirement: (employee.salary || 0) / 16.67,
    salary: employee.salary || 0,
    shortTermIncentives: (employee.salary || 0) / 4,
});

export const getBonusRightSampleAggregatePaySummary = (employees: IEmployee[]): IBonusRightAggregatePaySummary => {
    const numberOfYears = 5;
    const enterpriseValue = 20_000_000;
    const ebitda = enterpriseValue * profitPercentOfValue;
    const grossSales = enterpriseValue * revenuePercentOfValue;
    const totals = employees.reduce(
        (total, employee, i) => {
            const employeeRewards = getBonusRightSampleEmployeeRewardsForEmployee(employee, i + 1);

            total.ltip += employeeRewards.longTermIncentives || 0;
            total.salary += employeeRewards.salary;
            total.stip += employeeRewards.shortTermIncentives;

            return total;
        },
        { salary: 0, stip: 0, ltip: 0 }
    );

    return {
        baseValuation: 20_000_000,
        percentProfitToShareholders: 0,
        returnOnEquity: 0,
        shareholderEquity: 0,
        years: [...Array(numberOfYears).keys()].map(yearIndex => {
            const grossSalesForYear = grossSales + grossSales * salaryYearlyMultiplier * yearIndex;
            return {
                ebitda: ebitda + ebitda * salaryYearlyMultiplier * yearIndex,
                formulaValue: grossSalesForYear * 0.94,
                grossSales: grossSalesForYear,
                ltipRedemptions: totals.ltip + totals.salary * ltipYearlyMultiplier * yearIndex,
                ltipTotalValue: totals.ltip + totals.salary * ltipYearlyMultiplier * yearIndex,
                salaries: totals.salary + totals.salary * salaryYearlyMultiplier * yearIndex,
                stips: totals.stip + totals.salary * stipYearlyMultiplier * yearIndex,
                year: currentYear + yearIndex,
            };
        }),
    };
};

export const getBonusRightSampleEmployeeRewards = (
    employees: IEmployee[],
    enabled: boolean
): IBonusRightEmployeeRewards[] => {
    if (!enabled) return [];
    return employees.map((e, i) => getBonusRightSampleEmployeeRewardsForEmployee(e, i + 1));
};

export const getBonusRightSampleEmployeeValueStatement = (
    employees: IEmployee[],
    bonusRightEmployeeId: number,
    enabled: boolean
): IBonusRightEmployeeValueStatement => {
    if (!enabled) return {};
    const numberOfYears = 5;
    const employee = employees[bonusRightEmployeeId - 1];
    const employeeRewards = getBonusRightSampleEmployeeRewardsForEmployee(employee, bonusRightEmployeeId);

    return {
        employeeDisplayName: NameFormatter.getName(employee),
        years: [...Array(numberOfYears).keys()].map(yearIndex => {
            const { longTermIncentives, salary, shortTermIncentives } = employeeRewards;

            return {
                ltipRedemption:
                    (longTermIncentives || 0) + (longTermIncentives || 0) * ltipYearlyMultiplier * yearIndex,
                salary: salary + salary * salaryYearlyMultiplier * yearIndex,
                stipPayment: shortTermIncentives + shortTermIncentives * stipYearlyMultiplier * yearIndex,
                unvestedValue:
                    (longTermIncentives || 0) + (longTermIncentives || 0) * ltipYearlyMultiplier * (yearIndex + 2),
                vestedValue:
                    (longTermIncentives || 0) + (longTermIncentives || 0) * ltipYearlyMultiplier * (yearIndex + 1),
                year: (currentYear + yearIndex).toString(),
            };
        }),
    };
};

export const getBonusRightSampleExecutiveBenefitAnalysis = (
    employees: IEmployee[]
): IBonusRightExecutiveBenefitAnalysis => {
    const executives = employees.filter(e => e.title?.startsWith('C'));

    return {
        employees: executives.map(e => {
            const employeeRewards = getBonusRightSampleEmployeeRewardsForEmployee(e, 1);
            const totalCashCompensation =
                employeeRewards.salary +
                (employeeRewards.longTermIncentives || 0) +
                (employeeRewards.shortTermIncentives || 0);

            return {
                currentDeathBenefit: Math.min(500_000, employeeRewards.salary),
                currentLtdBenefit: Math.min(120_000, employeeRewards.salary * 0.6),
                grade: '',
                longTermIncentive: employeeRewards.longTermIncentives,
                name: NameFormatter.getName(e),
                salary: employeeRewards.salary,
                shortTermIncentive: employeeRewards.shortTermIncentives,
                targetDeathBenefit: employeeRewards.salary * 2,
                targetLtdBenefit: totalCashCompensation * 0.8,
                title: e.title,
                totalCashCompensation,
            };
        }),
    };
};
