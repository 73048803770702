import { IBusiness, ITodoPriorityType } from '@api';
import { faTasks } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button, EmptyStateIcon, ErrorPage, HorizontalTabs as Tabs, LoadingIndicator, SlideOver } from '~/components';
import { isTrueForAnyQuery, useBusinessTeamMembers, useTodos } from '~/hooks';
import AddTodoForm from './AddTodoForm';
import TodoItemSet from './TodoItemSet';

interface IProps {
    business: IBusiness;
}

const TodosMenu = ({ business }: IProps): JSX.Element | null => {
    const [isOpen, setIsOpen] = useState(false);
    const [addIsOpen, setAddIsOpen] = useState(false);
    const teamMembersQuery = useBusinessTeamMembers(business.id, isOpen);
    const todosQuery = useTodos(business.id, isOpen);
    const isError = isTrueForAnyQuery('isError', teamMembersQuery, todosQuery);
    const isLoading = isTrueForAnyQuery('isLoading', teamMembersQuery, todosQuery);
    const todoTabId = 'TODO';
    const todoItems = todosQuery.data?.filter(t => !t.completed) ?? [];
    const completedTabId = 'COMPLETED';
    const completedItems = todosQuery.data?.filter(t => t.completed) ?? [];
    const teamMembers = teamMembersQuery?.data ?? [];

    return (
        <div>
            <Button color="link" iconLeft={faTasks} text="Tasks" title="Tasks" onClick={() => setIsOpen(true)} />
            <SlideOver isOpen={isOpen} onClose={() => setIsOpen(false)} title="Tasks">
                {isError && <ErrorPage />}
                {isLoading && <LoadingIndicator />}
                {!isError && !isLoading && (
                    <div>
                        <Button
                            className="float-right mt-3"
                            size="sm"
                            text="Add Task"
                            onClick={() => setAddIsOpen(true)}
                        />
                        <Tabs activeTabId={todoTabId}>
                            <Tabs.Tab tabId={todoTabId}>{`TODO (${todoItems.length})`}</Tabs.Tab>
                            <Tabs.Tab tabId={completedTabId}>{`COMPLETED (${completedItems.length})`}</Tabs.Tab>
                            <Tabs.Panel tabId={todoTabId}>
                                {todoItems?.length ? (
                                    <div className="border-t border-background2">
                                        <TodoItemSet
                                            businessId={business.id}
                                            label="High"
                                            teamMembers={teamMembers}
                                            todos={todoItems.filter(t => t.priority === ITodoPriorityType.High)}
                                        />
                                        <TodoItemSet
                                            businessId={business.id}
                                            label="Medium"
                                            teamMembers={teamMembers}
                                            todos={todoItems.filter(t => t.priority === ITodoPriorityType.Medium)}
                                        />
                                        <TodoItemSet
                                            businessId={business.id}
                                            label="Low"
                                            teamMembers={teamMembers}
                                            todos={todoItems.filter(t => t.priority === ITodoPriorityType.Low)}
                                        />
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center py-10 border-t border-background2">
                                        <EmptyStateIcon icon={faTasks} />
                                        <p className="my-4 text-lg">No tasks added yet.</p>
                                        <Button color="primary" text="Add Task" onClick={() => setAddIsOpen(true)} />
                                    </div>
                                )}
                            </Tabs.Panel>
                            <Tabs.Panel tabId={completedTabId}>
                                {completedItems?.length ? (
                                    <div className="border-t border-background2">
                                        <TodoItemSet
                                            businessId={business.id}
                                            label="Completed"
                                            teamMembers={teamMembers}
                                            todos={completedItems}
                                        />
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center items-center h-60 border-t border-background2">
                                        <p className="mb-3">No completed tasks yet</p>
                                    </div>
                                )}
                            </Tabs.Panel>
                        </Tabs>
                        <AddTodoForm businessId={business.id} isOpen={addIsOpen} onClose={() => setAddIsOpen(false)} />
                    </div>
                )}
            </SlideOver>
        </div>
    );
};

export default TodosMenu;
