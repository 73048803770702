import { ErrorPage, LoadingIndicator } from '~/components';
import { useBizEquityLoginStatus } from '~/hooks';
import bizEquityPreview from '~/pages/business/present/bizEquity/background.jpg';
import bizEquityLogo from '~/pages/business/present/bizEquity/bizEquityLogo.svg';
import BizEquitySettingsForm from './BizEquitySettingsForm';

const BizEquitySettings = (): JSX.Element => {
    const { isError, isLoading, data: loginStatus } = useBizEquityLoginStatus();
    if (isLoading) return <LoadingIndicator />;
    if (isError || !loginStatus) return <ErrorPage />;

    return (
        <div className="sm:rounded-2xl bg-background1 overflow-hidden shadow">
            <img alt="BizEquity" className="hidden sm:block opacity-80 h-56 object-cover" src={bizEquityPreview} />
            <div className="px-9 py-6">
                <div className="max-w-lg h-full mx-auto flex flex-col justify-center">
                    <img alt="BizEquity Logo" className="white-logo" src={bizEquityLogo} />
                    <div className="mt-6 mb-8">
                        Configure the white label subdomain used to access the BizEquity service.
                    </div>
                    <BizEquitySettingsForm
                        domain={loginStatus.bizEquityDomain}
                        subdomain={loginStatus.bizEquitySubdomain || ''}
                    />
                </div>
            </div>
        </div>
    );
};

export default BizEquitySettings;
