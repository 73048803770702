import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import { Button, DropdownMenu, Table, TableBody, TableHead, TableHeaderCell, TableRow } from '~/components';
import CurrencyFormatter from '~/utils/currencyFormatter';
import Header from '../Header';
import IncomeTableRow from './IncomeTableRow';

interface IProps {
    currentValuation: number;
    growthRate: number;
}

const proceedsPercentage = 0.7;

const getTextForSellInYears = (sellInYears: number) =>
    sellInYears === 0 ? 'Today' : `In ${sellInYears} Year${sellInYears !== 1 ? 's' : ''}`;

const NetSaleAnalysis = ({ currentValuation, growthRate }: IProps): JSX.Element => {
    const [sellInYears, setSellInYears] = useState(0);
    const salePrice = currentValuation * Math.pow(1 + growthRate, sellInYears);
    const saleProceeds = salePrice * proceedsPercentage;

    return (
        <div>
            <Header text="Net Sale Analysis" />
            <div className="text-sm mb-2">Choose when you want to sell</div>
            <DropdownMenu>
                <DropdownMenu.Trigger
                    as={Button}
                    iconRight={faAngleDown}
                    refName="buttonRef"
                    text={getTextForSellInYears(sellInYears)}
                />
                <DropdownMenu.Item onClick={() => setSellInYears(0)}>{getTextForSellInYears(0)}</DropdownMenu.Item>
                <DropdownMenu.Item onClick={() => setSellInYears(1)}>{getTextForSellInYears(1)}</DropdownMenu.Item>
                <DropdownMenu.Item onClick={() => setSellInYears(3)}>{getTextForSellInYears(3)}</DropdownMenu.Item>
                <DropdownMenu.Item onClick={() => setSellInYears(5)}>{getTextForSellInYears(5)}</DropdownMenu.Item>
            </DropdownMenu>
            <div className="mt-4">Estimated sale proceeds</div>
            <div className="text-2xl leading-8 font-bold">{CurrencyFormatter.format(saleProceeds)}</div>
            <div className="text-sm mt-1 mb-5">
                * assumes 30% expense which includes long term capital gains and other expenses
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell background="bg-background2">Withdrawal Rate</TableHeaderCell>
                        <TableHeaderCell background="bg-background2" className="text-right">
                            Estimate Annual Income
                        </TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <IncomeTableRow investment={saleProceeds} withdrawalRate={0.03} />
                    <IncomeTableRow investment={saleProceeds} withdrawalRate={0.04} />
                    <IncomeTableRow investment={saleProceeds} withdrawalRate={0.05} />
                </TableBody>
            </Table>
        </div>
    );
};

export default NetSaleAnalysis;
