import { TextField as EnvTextField } from '@envestnet/envreact-component-library';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps, Label } from './Label';

type TextInputType = 'password' | 'text';

interface IProps
    extends ILabelProps,
        Omit<
            ComponentPropsWithoutRef<typeof EnvTextField>,
            'className' | 'hint' | 'label' | 'numberOfRows' | 'onChangeText' | 'secureEntry' | 'text'
        > {
    className?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    type?: TextInputType;
    value: string | undefined;
}

const TextInput = ({
    className,
    label,
    labelClassName,
    onChange,
    placeholder,
    type = 'text',
    value,
    ...rest
}: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return (
        <div className={className}>
            <Label label={label} labelClassName={labelClassName} />
            <EnvTextField
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                autoComplete="off"
                hint={placeholder}
                onChangeText={text => onChange(text)}
                secureEntry={type === 'password'}
                text={value}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            />
        </div>
    );
};

export { TextInput };
