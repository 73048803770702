import { TextField as EnvTextField } from '@envestnet/envreact-component-library';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';
import { ILabelProps, Label } from './Label';

interface IProps
    extends ILabelProps,
        Omit<
            ComponentPropsWithoutRef<typeof EnvTextField>,
            'className' | 'label' | 'numberOfRows' | 'onChangeText' | 'text'
        > {
    className?: string;
    onChange: (value: string) => void;
    rows?: number;
    value: string | undefined;
}

const TextArea = ({ className, label, labelClassName, onChange, rows = 6, value, ...rest }: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return (
        <div className={className}>
            <Label label={label} labelClassName={labelClassName} />
            <EnvTextField
                numberOfRows={rows}
                onChangeText={text => onChange(text)}
                text={value}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            />
        </div>
    );
};

export { TextArea };
