import { useState } from 'react';
import { IQuestion, VisibleSection } from './models';
import Question from './Question';

interface IProps {
    answers: Record<string, number>;
    questions: IQuestion[];
    resultsElementFactory: (answers: Record<string, number>) => React.ReactNode;
    setAnswers: (answers: Record<string, number>) => void;
    setVisibleSection: (visibleSection: VisibleSection) => void;
    submitHandler: (answerMap: Record<string, number>) => Promise<unknown>;
    surveyName: string;
    visibleSection: VisibleSection;
}

const Survey = ({
    answers,
    questions,
    resultsElementFactory,
    setAnswers,
    setVisibleSection,
    submitHandler,
    surveyName,
    visibleSection,
}: IProps): JSX.Element => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

    const onNext = (answer: number) => {
        const updatedAnswers = { ...answers, [questions[currentQuestionIndex].id]: answer };
        setAnswers(updatedAnswers);
        if (currentQuestionIndex === questions.length - 1) {
            submitHandler(updatedAnswers).then(() => setVisibleSection(VisibleSection.Results));
        } else {
            setCurrentQuestionIndex(Math.min(questions.length - 1, currentQuestionIndex + 1));
        }
    };

    const onBack = (answer: number) => {
        setAnswers({ ...answers, [questions[currentQuestionIndex].id]: answer });
        setCurrentQuestionIndex(Math.max(0, currentQuestionIndex - 1));
    };

    return (
        <div className="flex flex-col h-full justify-between px-7 py-6 bg-background1 lg:rounded-br-xl lg:rounded-tr-xl">
            {
                {
                    [VisibleSection.None]: <></>,
                    [VisibleSection.Questions]: (
                        <Question
                            currentAnswer={{ value: answers[questions[currentQuestionIndex].id] ?? 5 }}
                            currentQuestion={questions[currentQuestionIndex]}
                            currentQuestionIndex={currentQuestionIndex}
                            numberOfQuestions={questions.length}
                            onNext={onNext}
                            onBack={onBack}
                            surveyName={surveyName}
                        />
                    ),
                    [VisibleSection.Results]: resultsElementFactory(answers),
                }[visibleSection]
            }
        </div>
    );
};

export default Survey;
