import { useEffect, useState } from 'react';
import { Button } from '~/components';
import { useOnConfirm } from '~/hooks/employeeUpload';
import { setHideConfirmationButton } from '~/redux/employeeUpload';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { Redirect, Route, Switch, useHistory } from '~/routing';
import BusinessInfoForm from '../business/edit/businessInfo/BusinessInfoForm';
import { useSaveBusiness, useSavePresentationSettings, useSavePrimaryContact, validateBusiness } from './actions';
import EmployeeList from './EmployeeList';
import PresentationSettingsForm from './PresentationSettingsForm';
import PrimaryEmployeeStep from './PrimaryEmployeeStep';
import { useWizardState } from './useWizardState';
import VerticalNav, { IVerticalNavStep, VerticalNavStepStatus } from './VerticalNav';

const getStepStatus = (activeStepIndex: number, stepIndex: number): VerticalNavStepStatus => {
    if (activeStepIndex > stepIndex) {
        return VerticalNavStepStatus.Complete;
    } else if (activeStepIndex === stepIndex) {
        return VerticalNavStepStatus.Current;
    }
    return VerticalNavStepStatus.Upcoming;
};

const AddBusinessWizard = (): JSX.Element => {
    const [activeStep, setActiveStep] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const wizardState = useWizardState();
    const history = useHistory();
    const onSaveBusiness = useSaveBusiness(wizardState);
    const onSavePresentationSettings = useSavePresentationSettings(wizardState);
    const onSavePrimaryContact = useSavePrimaryContact(wizardState);

    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const { isUploading, pendingUploads } = useAppSelector(s => s.employeeUpload);

    useEffect(() => {
        dispatch(setHideConfirmationButton(true));
        return () => {
            dispatch(setHideConfirmationButton(false));
        };
    }, [dispatch]);

    const {
        businessAvatar,
        businessId,
        businessFormData,
        pageSettings,
        setBusinessAvatar,
        setWizardState,
        toggleAllPageSettings,
        togglePageSetting,
        validationErrors,
    } = wizardState;

    const onConfirm = useOnConfirm(businessId);

    const steps: IVerticalNavStep[] = [
        {
            name: 'Business Info',
            description: 'Basic info such as name and size.',
            disabled: false,
            onClick: () => setActiveStep(0),
            onSave: onSaveBusiness,
            onValidate: () => validateBusiness(wizardState),
            status: getStepStatus(activeStep, 0),
        },
        {
            name: 'Employee Info',
            description: 'Choose how to enter employee info.',
            disabled: !wizardState.businessId,
            onClick: () => setActiveStep(1),
            onSave: () => Promise.resolve(true),
            status: getStepStatus(activeStep, 1),
        },
        {
            name: 'Primary Contact',
            description: 'Primary contact for the Business.',
            disabled: !wizardState.businessId,
            onClick: () => setActiveStep(2),
            onSave: onSavePrimaryContact,
            status: getStepStatus(activeStep, 2),
        },
        {
            name: 'Presentation',
            description: 'Settings for your presentation.',
            disabled: !wizardState.businessId,
            onClick: () => setActiveStep(3),
            onSave: onSavePresentationSettings,
            status: getStepStatus(activeStep, 3),
        },
    ];

    const onClickNext = () => async () => {
        const step = steps[activeStep];

        if (step?.onValidate) {
            if (!step.onValidate()) return;
        }
        try {
            setIsDisabled(true);
            if (activeStep === 3 && pageSettings.filter(s => s.enabled === true).length === 0) {
                showError('You must turn on at least one page.');
                return;
            }
            if (activeStep === 1 && pendingUploads?.length) {
                await onConfirm();
            } else {
                const successful = await step?.onSave?.();
                if (!successful) return;
            }
            setActiveStep(activeStep + 1);
        } catch {
            showError();
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <>
            <div className="flex flex-col md:flex-row" style={{ minHeight: '22rem' }}>
                <div className="md:w-64 lg:w-80 md:mr-8 flex-shrink-0">
                    <VerticalNav steps={steps} />
                </div>
                <div className="flex flex-col flex-grow overflow-y-auto h-full mt-8 md:mt-0">
                    {activeStep === 0 && (
                        <div className="px-7">
                            <BusinessInfoForm
                                avatar={businessAvatar?.url}
                                disabled={isDisabled}
                                errors={validationErrors}
                                formData={businessFormData}
                                onChange={formData => setWizardState({ businessFormData: formData })}
                                onChangeAvatar={setBusinessAvatar}
                            />
                        </div>
                    )}
                    {activeStep === 1 && businessId && (
                        <div className="px-7">
                            <Switch>
                                <Route path="/AddBusiness/:businessId">
                                    <EmployeeList businessId={businessId} />
                                </Route>
                                <Redirect to={`/AddBusiness/${businessId}/Upload`} />
                            </Switch>
                        </div>
                    )}
                    {activeStep === 2 && (
                        <div className="px-7">
                            <PrimaryEmployeeStep
                                businessId={businessId || ''}
                                isDisabled={isDisabled}
                                primaryEmployeeId={wizardState.primaryEmployeeId}
                                setPrimaryEmployeeId={primaryEmployeeId => setWizardState({ primaryEmployeeId })}
                            />
                        </div>
                    )}
                    {activeStep === 3 && (
                        <>
                            <Button
                                className="self-end mb-3 mx-3 uppercase text-base"
                                onClick={toggleAllPageSettings}
                                color="link"
                                name="All On / All Off"
                            />
                            <PresentationSettingsForm pageSettings={pageSettings} onChange={togglePageSetting} />
                        </>
                    )}
                    <Redirect to="/AddBusiness" />
                </div>
            </div>
            <hr className="my-4 border-highlight" />
            <>
                <div className="flex justify-between">
                    <Button
                        text={activeStep === 0 ? 'Cancel' : 'Back'}
                        disabled={isUploading || isDisabled}
                        onClick={activeStep === 0 ? history.goBack : () => setActiveStep(activeStep - 1)}
                    />

                    <Button color="primary" loading={isUploading || isDisabled} text="Next" onClick={onClickNext()} />
                </div>
            </>
        </>
    );
};

export default AddBusinessWizard;
