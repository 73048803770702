import { faChartLine } from '@fortawesome/pro-solid-svg-icons';
import { SeriesOptionsType } from 'highcharts';
import { EmptyStateIcon, LineChart } from '~/components';
import { useBoundingClientRect } from '~/hooks';
import Card from '../../../scorecard/Card';

interface IProps {
    description: string;
    series: SeriesOptionsType[] | null;
    title: string;
}

const currentYear = new Date().getFullYear();

const MetricsChart = ({ description, series, title }: IProps): JSX.Element => {
    const [chartContainerRect, chartContainerRef] = useBoundingClientRect();
    return (
        <Card padding className="w-full justify-between">
            <div className="font-bold text-lg">{title}</div>
            <div className="flex h-96 items-center justify-center w-full" ref={chartContainerRef}>
                {series ? (
                    <LineChart
                        height={chartContainerRect?.height || 0}
                        plotOptions={{ series: { pointStart: currentYear - 2 } }}
                        series={series}
                        width={chartContainerRect?.width || 0}
                        xAxis={{ allowDecimals: false }}
                    />
                ) : (
                    <div className="flex flex-col items-center text-center justify-center flex-1">
                        <EmptyStateIcon icon={faChartLine} />
                        <p className="my-4 text-lg">This chart is not available.</p>
                        <p>{description}</p>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default MetricsChart;
