import { IBusinessTeamMember } from '@api';
import { faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    onClickDelete?: (id: string) => void;
    onClickEdit?: (id: string) => void;
    teamMember: IBusinessTeamMember;
}

const TeamMember = ({ onClickDelete, onClickEdit, teamMember }: IProps): JSX.Element => {
    const { avatar, emailAddress, id, title } = teamMember;
    const hasActions = onClickDelete || onClickEdit;

    return (
        <div className="rounded-md bg-background1 shadow p-4 mt-4">
            <div className="flex mb-3">
                <Avatar className="self-stretch" size={12} src={avatar || undefined} />
                <div className="flex-1 truncate ml-3">
                    <div className="text-textDefault font-medium text-lg truncate">
                        {NameFormatter.getLastNameFirst(teamMember)}
                    </div>
                    <p className="text-textDisabled text-sm truncate">{title}</p>
                </div>
            </div>
            {emailAddress?.length && (
                <div className="truncate">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" aria-hidden />
                    <span>{emailAddress}</span>
                </div>
            )}
            {hasActions && (
                <>
                    <hr className="my-3 border-background2" />
                    <div className="flex flex-row-reverse">
                        {onClickEdit && (
                            <Button className="ml-3" iconLeft={faEdit} text="Edit" onClick={() => onClickEdit(id)} />
                        )}
                        {onClickDelete && <Button iconLeft={faTrash} text="Remove" onClick={() => onClickDelete(id)} />}
                    </div>
                </>
            )}
        </div>
    );
};

export default TeamMember;
