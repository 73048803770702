import { IBusiness } from '@api';
import { faCloudUpload, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button } from '~/components';
import { Link } from '~/routing';
import Employees from '../business/employees';
import AddEmployeeForm from '../business/employees/AddEmployeeForm';

interface IProps {
    business: IBusiness;
    onSuccess: (id: string) => void;
}

const EmployeeListPage = ({ business, onSuccess }: IProps): JSX.Element => {
    const [addState, setAddState] = useState({ isOpen: false });

    return (
        <>
            <div className="z-10 top-0 sticky flex justify-between mb-12 pt-10 pb-6 bg-background border-b border-background2">
                <h2 className="text-2xl leading-none self-center">{business.name} Employees</h2>
                <div className="self-center space-x-3">
                    <Button
                        color="primary"
                        iconLeft={faPlusCircle}
                        text="Add New"
                        onClick={() => setAddState({ ...addState, isOpen: true })}
                    />
                    <Link to="EmployeesUpload">
                        <Button iconLeft={faCloudUpload} text="Bulk Upload" />
                    </Link>
                </div>
            </div>
            <Employees businessId={business.id} onSuccess={onSuccess} />
            <AddEmployeeForm
                businessId={business.id}
                onClose={() => setAddState({ ...addState, isOpen: false })}
                onSuccess={onSuccess}
                {...addState}
            />
        </>
    );
};

export default EmployeeListPage;
