import { faUser, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { ComponentPropsWithoutRef, useState } from 'react';

interface IProps {
    placeholderIcon?: IconDefinition;
    rounded?: 'full' | 'none' | 'sm';
    size?: number;
}

const roundedClassNameMap = {
    full: 'rounded-full',
    none: 'rounded-none',
    sm: 'rounded-sm',
};

export type AvatarProps = IProps & ComponentPropsWithoutRef<'img'>;

const Avatar = ({
    className,
    placeholderIcon = faUser,
    rounded = 'full',
    size = 10,
    src,
    ...rest
}: AvatarProps): JSX.Element => {
    const [hasImageError, setHasImageError] = useState(false);
    const showImg = !hasImageError && src;

    return showImg ? (
        <img
            alt=""
            className={classnames(
                'inline-block object-cover',
                `h-${size} w-${size}`,
                roundedClassNameMap[rounded],
                className
            )}
            src={src}
            onError={() => setHasImageError(true)}
            {...rest}
        />
    ) : (
        <span
            className={classnames(
                'inline-block text-center overflow-hidden bg-background2 dark:bg-textDefault align-middle',
                `h-${size} w-${size}`,
                roundedClassNameMap[rounded],
                className
            )}
            style={{ lineHeight: `${size * 0.25}rem` }}
        >
            <FontAwesomeIcon
                className="text-divider dark:text-textDisabled"
                icon={placeholderIcon}
                style={{ fontSize: `${size * 0.25 - 0.5}rem`, marginTop: '.5rem' }}
            />
        </span>
    );
};

export { Avatar };
