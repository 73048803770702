import { IBizEquityYearlyStatement } from '@api';

export enum Kpi {
    CashToDebt = 'CashToDebt',
    DebtToEquity = 'DebtToEquity',
    FixedAssetsToIncome = 'FixedAssetsToIncome',
    FixedAssetsTurnover = 'FixedAssetsTurnover',
    IncomeToRevenue = 'IncomeToRevenue',
    InterestCoverage = 'InterestCoverage',
    InventoryToIncome = 'InventoryToIncome',
    InventoryTurnover = 'InventoryTurnover',
    Receivables = 'Receivables',
    ReturnOnEquity = 'ReturnOnEquity',
    ReceivablesToIncome = 'ReceivablesToIncome',
    TotalDebtToIncome = 'TotalDebtToIncome',
}

export type KpiModelKey = keyof Pick<
    IBizEquityYearlyStatement,
    | 'accountsReceivablesToIncome'
    | 'accountsReceivablesToRevenue'
    | 'cashToDebt'
    | 'debtToEquity'
    | 'debtToIncome'
    | 'fixedAssetsToIncome'
    | 'incomeToEquity'
    | 'incomeToRevenue'
    | 'interestCoverage'
    | 'inventoryToIncome'
    | 'revenueToFixedAssets'
    | 'revenueToInventory'
>;

export const modelKey: Record<Kpi, KpiModelKey> = {
    CashToDebt: 'cashToDebt',
    DebtToEquity: 'debtToEquity',
    FixedAssetsToIncome: 'fixedAssetsToIncome',
    FixedAssetsTurnover: 'revenueToFixedAssets',
    IncomeToRevenue: 'incomeToRevenue',
    InterestCoverage: 'interestCoverage',
    InventoryToIncome: 'inventoryToIncome',
    InventoryTurnover: 'revenueToInventory',
    Receivables: 'accountsReceivablesToRevenue',
    ReturnOnEquity: 'incomeToEquity',
    ReceivablesToIncome: 'accountsReceivablesToIncome',
    TotalDebtToIncome: 'debtToIncome',
};

interface IKpi {
    chartDescription: string;
    description: string;
    giveMeAnExample: string;
    name: string;
    noChartMessage: string;
    order: number;
    whatDoesItMean: string;
    whyShouldItMatter: string;
}

export enum KpiComparison {
    GREATER = 'GREATER',
    EQUAL = 'EQUAL',
    LESS = 'LESS',
}

export const kpis: Record<Kpi, IKpi> = {
    CashToDebt: {
        chartDescription: `Shows the ability to pay off existing debts.`,
        description: `This solvency ratio compares a company's operating cash balance to its total debt. This ratio provides an indication of the company's ability to cover total debt (ST and LT) with its operating cash holdings.`,
        giveMeAnExample: `If a furniture store has a ratio of 74% this means that for
            every 1 of debt, it has 74 cents in liquid holdings which
            could be used to service this debt.`,
        name: 'Cash to Debt',
        noChartMessage: `Additional Cash and Debt information is needed to generate this chart.`,
        order: 3,
        whatDoesItMean: `This solvency ratio compares a company's operating cash
            balance to its total debt. This ratio provides an indication of
            the company's ability to cover total debt (ST and LT) with its
            operating cash holdings.`,
        whyShouldItMatter: `A higher percentage ratio indicates that the company is
            better equipped to carry and service its total debt. A high
            ratio may also indicate "excess cash" or "excess net working
            capital" which could be returned to the shareholders
            or invested into new equipment or other avenues for
            expansion. A low ratio could signal future difficulties
            in servicing debt or even meeting payroll or vendor obligations.`,
    },
    DebtToEquity: {
        chartDescription: `Shows the extent of the debt load, in comparison to a company's equity value.`,
        description: `This solvency ratio is a function of the firm's "capital structure" (all assets must be financed by either debt or equity) and provides a measure of the company's financial leverage. It often takes into account the total liabilities of the company while some versions include only long term debt. It indicates the proportion of equity (owner investments and retained profits) and liabilities the company is using to finance its asset base.`,
        giveMeAnExample: `If a machinery manufacturer has a ratio of 2.8. This means that
            for every 1 owned by the shareholders the company owes
            2.8 to its creditors.`,
        name: 'Debt to Equity',
        noChartMessage: `Additional Debt and Equity information is needed to generate chart.`,
        order: 2,
        whatDoesItMean: `This solvency ratio is a function of the firm's "capital structure"
            (all assets must be financed by either debt or equity) and
            provides a measure of the company's financial leverage. It often
            takes into account the total liabilities of the company while
            some versions include only long term debt. It indicates the
            proportion of equity (owner investments and retained profits)
            and liabilities the company is using to finance its asset base.`,
        whyShouldItMatter: `A higher ratio generally means that the company has been
            aggressive to finance its growth with debt and the creditors
            are assuming a higher risk. A lower ratio generally indicates
            that the company is "safer" (better equipped to withstand an
            economic downturn) due to lower mandatory principal and
            interest payments, but it may also suggest an overly cautious
            ownership. Capital intensive industries tend to have a higher
            debt to equity ratio than others.`,
    },
    FixedAssetsToIncome: {
        chartDescription: '',
        description:
            "This ratio provides insight into the firm's profitability relative to it's stock of fixed assets (furniture, fixtures and equipment/vehicles). All other things equal, the firm seeks higher pretax profits for each dollar invested into fixed assets.",
        giveMeAnExample: `A ratio greater than one suggests that more money has
            been invested into capital assets than profits have been
            generated. This and other ratios should be reviewed "over
            time" and against industry norms.`,
        name: 'Fixed Assets to Income',
        noChartMessage: `Additional Fixed Assets and Income information is needed to generate this chart.`,
        order: 7,
        whatDoesItMean: `This ratio provides insight into the firm's profitability
            relative to its stock of fixed assets (furniture, fixtures and
            equipment/vehicles).`,
        whyShouldItMatter: `All other things equal, the firm seeks higher pretax profits
            for each dollar invested into fixed assets. As this ratio
            declines, the company is generating higher profits per dollar
            of capital expenditures.`,
    },
    FixedAssetsTurnover: {
        chartDescription: `Shows how productive a company's assets are.`,
        description: `This activity ratio shows the company's ability to generate net sales from their investments in fixed assets.`,
        giveMeAnExample: `If a manufacturing company had a fixed asset turnover of
            3.8, this means the company generated sales worth 3.8 for
            every 1 of investment in fixed assets.`,
        name: 'Fixed Assets Turnover',
        noChartMessage: `Additional Revenue and Fixed Assets information is needed to generate this chart.`,
        order: 11,
        whatDoesItMean: `This activity ratio shows the company's ability to generate
            net sales from their investments in fixed assets.`,
        whyShouldItMatter: `A higher ratio shows productive fixed asset investment. This
            ratio is more vital and useful to the manufacturing industry.`,
    },
    IncomeToRevenue: {
        chartDescription: `A rising percentage will often lead to a higher valuation.`,
        description:
            'This "pretax" profitability ratio known as "return on sales" indicates the relative profit margin of the company for each dollar of sales.',
        giveMeAnExample: `If a convenience store has a percentage ratio of 17%, this
            means that for every 1 of revenue it has a pretax income of 17 cents.`,
        name: 'Income to Revenue',
        noChartMessage: '',
        order: 4,
        whatDoesItMean: `This "pretax" profitability ratio known as "return on sales"
            indicates the relative profit margin of the company for each
            dollar of sales.`,
        whyShouldItMatter: `Similar to the return on equity ratio, a higher percentage
            ratio indicates a higher rate of relative profitability. Unlike
            the return on equity ratio, this measure is "pretax" in nature
            and is not affected by the actual tax burden. Higher gross
            profits and lower operating expenses coupled with higher
            revenues will bolster this important metric, which can be
            compared both over time and against the industry peer group.`,
    },
    InterestCoverage: {
        chartDescription: `Shows how much cushion a company has in paying its interest expenses.`,
        description:
            'Also referred to as "times interest earned", this solvency ratio is equal to earnings before interest and taxes (EBIT) divided by interest expense and it is used to determine the ease by which your company can pay interest on outstanding debt obligations.',
        giveMeAnExample: `If a software company has an interest coverage ratio over 2
            times, this suggests that it has the ability to meet its interest
            payments two times over and may qualify for additional debt.`,
        name: 'Interest Coverage',
        noChartMessage: `Additional Interest Coverage data is needed to generate this chart.`,
        order: 6,
        whatDoesItMean: `Also referred to as "times interest earned", this solvency
            ratio is equal to earnings before interest and taxes (EBIT)
            divided by interest expense and it is used to determine
            the ease by which your company can pay interest on
            outstanding debt obligations.`,
        whyShouldItMatter: `A lower ratio may cast doubt on the company's ability to
            meet ongoing principal and interest burdens. The higher
            the ratio, the easier it is for the firm to repay its current debt
            and take on additional debt if necessary. Bankers, creditors
            and even investors often calculate and analyze this ratio to
            gauge the firm's solvency position. Similar to most ratios,
            averages will differ by industry.`,
    },
    InventoryToIncome: {
        chartDescription: '',
        description:
            'This ratio illustrates the relative importance of inventory holdings to company profitability. The way that inventory is sourced and managed can impact the different profit levels of your income statement.',
        giveMeAnExample: `Over time, the goal might be to decrease this ratio, e.g.
            generate higher pretax profit with lower average inventory holdings.`,
        name: 'Inventory to Income',
        noChartMessage: `Additional Inventory and Pretax Income information is needed to generate this chart.`,
        order: 10,
        whatDoesItMean: `This ratio illustrates the relative importance of inventory
            holdings (typically carried at lower of cost or market) to company profitability.`,
        whyShouldItMatter: `For retail and manufacturing firms in particular, inventory is
            one of the factors that you can control to improve your small
            business profitability. The way that inventory is sourced
            and managed can impact the different profit levels of your
            income statement. Ignorance of how to use inventory to
            your advantage prevents you from maximizing operational efficiency.`,
    },
    InventoryTurnover: {
        chartDescription: `How long it takes to sell inventory on hand.`,
        description: `This activity or "turnover" ratio addresses how efficiently goods are sold by calculating how many times a company's inventory is sold and replaced in a given time period.`,
        giveMeAnExample: `If a soda manufacturer had an inventory turnover of 5.7,
            this means it sold all of its average inventory 5.7 times each year.`,
        name: 'Inventory Turnover',
        noChartMessage: `Additional Revenue and Inventory information is needed to generate this chart.`,
        order: 5,
        whatDoesItMean: `This activity or "turnover" ratio addresses how efficiently
            goods are sold by calculating how many times a company's
            inventory is sold and replaced in a given time period.`,
        whyShouldItMatter: `A lower ratio could mean poor sales and excessive inventory,
            possibly due to pricing policies. A higher ratio may indicate
            a too narrow selection of product and possibly lost sales.
            Companies selling perishable goods have a very high
            inventory turnover. Keeping inventory balances to a
            minimum will reduce costs but may reduce sales volume.`,
    },
    Receivables: {
        chartDescription: `Increases over time could signal difficulty in collecting from customers.`,
        description: 'The time period shows the number of days it takes a company to collect its accounts receivables.',
        giveMeAnExample: `If a lumber wholesaler has a receivables conversion of
            24 days, it means it takes 24 days on average to collect
            its account receivables. If the firm's credit terms are "net
            30 days", this would be considered a positive result.`,
        name: 'Receivables (Conversion)',
        noChartMessage: `Additional Revenue and Accounts Receivables information is needed to generate this chart.`,
        order: 8,
        whatDoesItMean: `The time period shows the number of days it takes a
            company to collect its accounts receivables.`,
        whyShouldItMatter: `A lower time period indicates that a company relies mainly
            on cash or is efficient in imparting credit and collecting its
            debts. On the other hand a higher time period could mean
            some inefficiency in collecting the account receivables and
            require a review of the current credit and collections
            policies of the company. The quicker receivables are
            collected, the sooner cash is available to meet other
            business needs (thereby reducing the need to borrow funds).`,
    },
    ReturnOnEquity: {
        chartDescription: `Compares profitability to the equity value of a company.
            Indication of the strength of the business model.`,
        description: `This is the amount of net income generated as a percentage of shareholder's equity. Return on Equity (ROE) measures a company's profitability by depicting how much profit a company generates with money shareholder's have invested.`,
        giveMeAnExample: `If an E-Commerce company has an ROE of 0.48 this means
            it generated 48 cents in net income for every 1 the
            shareholder had invested.`,
        name: 'Return on Equity',
        noChartMessage: `Additional Revenue and Equity information is needed to generate this chart.`,
        order: 1,
        whatDoesItMean: `This is the amount of net income generated as a percentage
            of shareholder's equity. Return on Equity (ROE) measures
            a company's profitability by depicting how much profit
            a company generates with money shareholders have invested.`,
        whyShouldItMatter: `ROE is a universal and very useful measure to compare
            a company's profitability to that of its peers in the same
            industry. High growth companies tend to have a high ROE.`,
    },
    ReceivablesToIncome: {
        chartDescription: '',
        description:
            'This measure provides an indication of the amount of credit being granted to the customer base relative to ongoing profits of the business. If the receivables are greater than pretax profit, the importance of maintaining an effective and efficient credit, billing and collections process is heightened.',
        giveMeAnExample: `A company with 100K in receivables and 100K in pretax
            profit must collect all receivables to maintain the firm's profit margin.`,
        name: 'Receivables to Income',
        noChartMessage: `Additional Accounts Receivables and Pretax Income information is needed to generate this chart.`,
        order: 9,
        whatDoesItMean: `This measure provides an indication of the amount of credit
            being granted to the customer base relative to ongoing
            profits.`,
        whyShouldItMatter: `If the receivables are greater than pretax profit, the
            importance of establishing and maintaining an effective
            and efficient credit, billing and collections process is
            heightened.`,
    },
    TotalDebtToIncome: {
        chartDescription: '',
        description:
            'This measure shows the relationship between total company obligations at any point in time (short and long term debt) and ongoing profit performance. Firms with high debts relative to pretax profits are often riskier than those with lower total debts.',
        giveMeAnExample: `If total debts are 100K and total pretax profits are 50K, it
            would take two years to pay off debts out of ongoing profits.`,
        name: 'Total Debt to Income',
        noChartMessage: `Additional Debt and Income information is needed to generate this chart.`,
        order: 12,
        whatDoesItMean: `This measure shows the relationship between total
            company obligations at any point in time (short and long
            term debt) and ongoing profit performance.`,
        whyShouldItMatter: `Firms with high debts relative to pretax profits are often
            riskier than those with lower total debts. At the same
            time, some companies rely on the use of debt to grow and
            enhance profit margins (when the return on investment of
            borrowed funds is greater than the cost of borrowing). From
            a valuation perspective, firms with lower debts and higher
            pretax profits will be worth more than those with higher
            debts and lower profits (all other things equal).`,
    },
};

export const percentageKpis: Kpi[] = [
    Kpi.CashToDebt,
    Kpi.DebtToEquity,
    Kpi.FixedAssetsToIncome,
    Kpi.IncomeToRevenue,
    Kpi.InventoryToIncome,
    Kpi.ReturnOnEquity,
    Kpi.ReceivablesToIncome,
    Kpi.TotalDebtToIncome,
];
