import { RatingCategory } from '../../../teamValues/rating/models';
import EstimatedValueLine from './EstimatedValueLine';
import { getModifier } from './modifiers';
import ValueDriverSlider from './ValueDriverSlider';

interface IProps {
    baseValue: number;
    modifiers: Record<RatingCategory, number>;
    setModifiers: (value: Record<RatingCategory, number>) => void;
}

const ValueEstimator = ({ baseValue, modifiers, setModifiers }: IProps): JSX.Element => {
    const currentModifier = getModifier(modifiers);

    return (
        <div className="px-7 py-5 w-full pb-10">
            <div className="space-y-1">
                <div className="font-semibold text-xl">Value Estimator</div>
                <div className="text-sm">
                    Rate your business as it currently exists on the value drivers below, with 1 indicating a major
                    weakness and 5 indicating a major strength.
                </div>
            </div>
            <div className="auto-rows-fr grid grid-cols-1 gap-y-8 my-6">
                <ValueDriverSlider
                    category={RatingCategory.Product}
                    modifiers={modifiers}
                    setModifiers={setModifiers}
                />
                <ValueDriverSlider category={RatingCategory.Sales} modifiers={modifiers} setModifiers={setModifiers} />
                <ValueDriverSlider
                    category={RatingCategory.Operations}
                    modifiers={modifiers}
                    setModifiers={setModifiers}
                />
                <ValueDriverSlider category={RatingCategory.Team} modifiers={modifiers} setModifiers={setModifiers} />
                <ValueDriverSlider category={RatingCategory.Growth} modifiers={modifiers} setModifiers={setModifiers} />
                <ValueDriverSlider
                    category={RatingCategory.Technology}
                    modifiers={modifiers}
                    setModifiers={setModifiers}
                />
            </div>
            <EstimatedValueLine baseValue={baseValue} currentModifier={currentModifier} />
        </div>
    );
};

export default ValueEstimator;
