import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrentUserMenu, NavBar } from '~/components';
import { Link } from '~/routing';
import AddBusinessWizard from './AddBusinessWizard';

const BusinessAdd = (): JSX.Element | null => {
    return (
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <NavBar>
                <div></div>
                <div className="flex items-center">
                    <CurrentUserMenu />
                </div>
            </NavBar>
            <main className="flex flex-col h-full max-h-full overflow-y-auto focus:outline-none w-full" tabIndex={0}>
                <div className="flex flex-col h-full max-h-full mx-auto pb-4 px-4 sm:px-6 lg:px-8 max-w-7xl w-full">
                    <div className="mb-8 pt-10 pb-6 bg-background border-b border-background2">
                        <Link className="text-sm text-textLink" to="/">
                            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                            Back to Business List
                        </Link>
                        <h2 className="text-2xl leading-none self-center mt-2">Add New Business</h2>
                    </div>
                    <AddBusinessWizard />
                </div>
            </main>
        </div>
    );
};

export default BusinessAdd;
