import { useAuth } from '~/hooks';
import { useAgreeToTermsOfService, useTermsOfServiceAgreementStatus } from '~/hooks/termsOfServiceAgreements';
import { Button } from './Button';
import { ErrorPage } from './ErrorPage';
import { LoadingIndicator } from './LoadingIndicator';

interface IProps {
    children: React.ReactNode;
}

interface ITosPageProps {
    agreementId: string;
    content: string;
}

const TermsOfServicePage = ({ agreementId, content }: ITosPageProps): JSX.Element => {
    const { logout } = useAuth();
    const agreeToTermsOfService = useAgreeToTermsOfService().mutateAsync;

    return (
        <div className="flex flex-col h-screen max-h-screen overflow-hidden">
            <div className="bg-background1 flex flex-col mx-auto my-auto overflow-hidden rounded-lg">
                <h1 className="font-semibold mb-2 mt-6 text-center text-lg">Terms &amp; Conditions</h1>
                <div className="md:max-h-96 max-w-2xl xl:max-w-4xl mx-4 my-4 flex-shrink overflow-y-scroll px-4">
                    <div
                        className="text-sm md:text-base p-4 space-y-4"
                        dangerouslySetInnerHTML={{ __html: content ?? '' }}
                    ></div>
                </div>
                <div className="bg-background2 flex justify-end max-w-5xl mx-auto px-4 py-4 rounded-b-lg space-x-8 w-full">
                    <Button color="link" text="Decline" onClick={() => logout()} />
                    <Button
                        color="primary"
                        text="Accept Terms"
                        onClick={() => agreeToTermsOfService({ termsOfServiceAgreementId: agreementId })}
                    />
                </div>
            </div>
        </div>
    );
};

const TermsOfServiceAgreementWrapper = ({ children }: IProps): JSX.Element => {
    const { data, isError, isLoading } = useTermsOfServiceAgreementStatus();

    if (isError) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;
    if (data?.needsAgreement && data.agreementId && data.content)
        return <TermsOfServicePage agreementId={data.agreementId} content={data.content} />;
    return <>{children}</>;
};

export default TermsOfServiceAgreementWrapper;
