import classNames from 'classnames';
import { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
    className?: string;
    padding?: boolean;
}

const Card = ({ children, className, padding }: IProps): JSX.Element => (
    <div className={classNames('flex flex-col bg-background1 rounded-lg', { 'py-4 px-5': padding }, className)}>
        {children}
    </div>
);

export default Card;
