import { IBusinessTeamMember } from '@api';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Avatar, Button } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    onClickDelete: (id: string) => void;
    onClickEdit: (id: string) => void;
    teamMember: IBusinessTeamMember;
}

const TeamMember = ({ onClickDelete, onClickEdit, teamMember }: IProps): JSX.Element => {
    const { avatar, emailAddress, id } = teamMember;

    return (
        <div className="flex items-center py-2 border-b border-background2">
            <Avatar src={avatar || undefined} />
            <div className="truncate flex-1 ml-2">
                <div className="truncate text-base">{NameFormatter.getLastNameFirst(teamMember)}</div>
                {emailAddress ? (
                    <div className="text-textDisabled truncate text-sm">{emailAddress}</div>
                ) : (
                    <div className="text-error truncate text-sm">Email address missing. Invite will be skipped.</div>
                )}
            </div>
            {emailAddress ? (
                <Button
                    color="tertiary"
                    iconOnly={faTrash}
                    iconClassName="text-error"
                    onClick={() => onClickDelete(id)}
                />
            ) : (
                <Button color="secondary" iconLeft={faPlus} text="Add Email" onClick={() => onClickEdit(id)} />
            )}
        </div>
    );
};

export default TeamMember;
