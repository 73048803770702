import { ITodoSystemActivityType } from '@api';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBusiness, useGetMyTodos } from '~/hooks';
import { useParams } from '~/routing';
import { CompletableTodoItem } from '../business/header/todos/TodoItemSet';
import DocumentCenter from '../business/present/documentCenter';
import DelegateUserPageHeader from '../DelegateUserPageHeader';

interface BusinessParams {
    businessId: string;
}

const DocumentUploadDelegateApp = (): JSX.Element => {
    const todos = useGetMyTodos();
    const { businessId } = useParams<BusinessParams>();
    const business = useBusiness(businessId);
    const relatedTodos = todos.data?.filter(t => t.systemActivity === ITodoSystemActivityType.DocumentUpload) || [];
    const incompleteTodos = relatedTodos.filter(t => !t.completed);
    const completedTodos = relatedTodos.filter(t => t.completed);

    if (todos.isLoading || business.isLoading) {
        return <LoadingIndicator />;
    }
    if (todos.error || business.error || !business.data) {
        return <ErrorPage />;
    }

    return (
        <div className="w-full h-full">
            <DelegateUserPageHeader business={business.data} />
            <div className="flex h-full">
                <div className="w-96 p-4 pb-20 border-r border-background2 overflow-y-auto">
                    {incompleteTodos.length > 0 ? (
                        incompleteTodos.map(todo => (
                            <CompletableTodoItem key={todo.id} businessId={businessId} todo={todo} />
                        ))
                    ) : (
                        <div className="flex flex-col">
                            <div className="flex flex-grow">
                                <div className="w-full text-center my-auto">
                                    <FontAwesomeIcon className="text-positive my-4" icon={faCheckCircle} size="5x" />
                                    <p>You have completed all your assigned tasks.</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {completedTodos.length > 0 && (
                        <div className="mt-8 pt-6 border-t border-background2">
                            <div className="text-xl">Completed</div>
                            {completedTodos.map(todo => (
                                <CompletableTodoItem key={todo.id} businessId={businessId} todo={todo} />
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex-grow p-8">
                    <DocumentCenter />
                </div>
            </div>
        </div>
    );
};

export default DocumentUploadDelegateApp;
