import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button, Modal, TextInput } from '~/components';
import { useInviteBizEquityBusinessDataEntryDelegate } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    businessId: string;
    isOpen: boolean;
    onClose: () => void;
}

const schemaValidation = Yup.object().shape({
    emailAddress: Yup.string().email().required().label('Email'),
});

const DelegateModal = ({ businessId, isOpen, onClose }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const inviteBizEquityBusinessDataEntryDelegate = useInviteBizEquityBusinessDataEntryDelegate(businessId);
    const onCloseInternal = () => {
        setEmailAddress('');
        setIsDisabled(false);
        setValidationErrors({});
        onClose();
    };
    const onSendInvite = () => {
        try {
            schemaValidation.validateSync({ emailAddress }, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        setValidationErrors({});
        setIsDisabled(true);
        inviteBizEquityBusinessDataEntryDelegate
            .mutateAsync({ emailAddress })
            .then(onCloseInternal)
            .catch(() => showError())
            .finally(() => setIsDisabled(false));
    };

    return (
        <Modal maxWidth="2xl" open={isOpen} setOpen={onCloseInternal} title="Business Value">
            <div className="flex flex-col items-center px-6 py-16 space-y-6">
                <div className="bg-background2 inline-block p-8 rounded-full text-border-background">
                    <FontAwesomeIcon icon={faPaperPlane} size="4x" />
                </div>
                <p className="max-w-md text-center">
                    Clicking send will invite the recipient to fill out Income, Assets, and Liabilities sections. The
                    recipient will have 7 days to complete this activity.
                </p>
                <form>
                    <div className="flex">
                        <TextInput
                            className="w-60"
                            disabled={isDisabled}
                            errorMessage={validationErrors.emailAddress}
                            placeholder="recipient@example.com"
                            textFieldStyle={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                            value={emailAddress}
                            onChange={setEmailAddress}
                        />
                        <Button
                            color="primary"
                            disabled={isDisabled}
                            style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                            text="Send"
                            onClick={onSendInvite}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default DelegateModal;
