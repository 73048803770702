/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as H from 'history';
// eslint-disable-next-line no-restricted-imports
import * as RR from 'react-router';
// eslint-disable-next-line no-restricted-imports
import * as RRD from 'react-router-dom';

export const BrowserRouter = RRD.BrowserRouter;
export const HashRouter = RRD.HashRouter;
export const Link = RRD.Link;
export const matchPath = RR.matchPath;
export const NavLink = RRD.NavLink;
export const Redirect = RR.Redirect;
export const Route = RR.Route;
export interface RouteComponentProps<
    Params extends { [K in keyof Params]?: string } = {},
    C extends RR.StaticContext = RR.StaticContext,
    S = H.LocationState
> {
    history: H.History<S>;
    location: H.Location<S>;
    match: RR.match<Params>;
    staticContext?: C | undefined;
}
export const Switch = RR.Switch;
export const useHistory = <HistoryLocationState = H.LocationState>() => RR.useHistory<HistoryLocationState>();
export const useLocation = <S = H.LocationState>() => RR.useLocation<S>();
export const useParams = <Params extends { [K in keyof Params]?: string } = {}>() => RR.useParams<Params>();
export const useRouteMatch = <Params extends { [K in keyof Params]?: string } = {}>(): RR.match<Params> =>
    RR.useRouteMatch<Params>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withRouter = <P extends RouteComponentProps<any>, C extends React.ComponentType<P>>(
    component: C & React.ComponentType<P>
) => RR.withRouter<P, C>(component);
