import { IBusiness, IEmployee } from '@api';
import { faExclamationTriangle, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, EmptyStateIcon } from '~/components';
import { ILocationState } from '~/pages/ILocationState';
import { Link, useLocation } from '~/routing';
import { formatPercentage } from '~/utils/percentageFormatter';
import { GridView } from '../GridView';

const getSharesAllocated = (employees: IEmployee[]) => employees.reduce((a, e) => a + (e.shares ?? 0), 0);

const getTotalShares = (business: IBusiness) => business.sharesOutstanding ?? 0;

interface IWarningProps {
    business: IBusiness;
    employees: IEmployee[];
}

const WarningText = ({ business, employees }: IWarningProps): JSX.Element => {
    const totalShares = getTotalShares(business);
    const sharesAllocated = getSharesAllocated(employees);
    if (totalShares === 0 || sharesAllocated <= totalShares) {
        return <></>;
    }

    return (
        <div>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-caution" />
            Shares Allocated: <span className="text-caution">{sharesAllocated.toLocaleString()}</span> /{' '}
            {totalShares.toLocaleString()}
        </div>
    );
};

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
    onEditEmployee: (employee: IEmployee) => void;
}

const Shares = ({ business, employees, onEditEmployee }: IProps): JSX.Element => {
    const businessSettingsState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Team Values',
    };

    const employeesSortedByShares = [...employees];
    employeesSortedByShares.sort((a, b) => (b.shares ?? 0) - (a.shares ?? 0));

    return (
        <>
            <div className="border-t border-background2 pt-3"></div>
            {!business.sharesOutstanding ? (
                <div className="p-3 p-md-5 flex flex-col items-center text-center">
                    <EmptyStateIcon icon={faQuestion} />
                    <p className="my-4 text-lg">Missing shares outstanding for business.</p>
                    <Link to={{ pathname: `/${business.id}/Edit`, state: businessSettingsState }}>
                        <Button color="primary" text="Add Shares" />
                    </Link>
                </div>
            ) : (
                <>
                    <WarningText business={business} employees={employees} />
                    <GridView
                        employees={employeesSortedByShares}
                        onClickEmployee={onEditEmployee}
                        renderValue={(employee: IEmployee) => {
                            const sharePercentage = (employee.shares || 0) / (business.sharesOutstanding || 1);
                            return (
                                <div className="pr-3">{sharePercentage ? formatPercentage(sharePercentage) : '--'}</div>
                            );
                        }}
                    />
                </>
            )}
        </>
    );
};

export default Shares;
