import { useRef } from 'react';
import { ModalType, setSelectedModal } from '~/redux/documentCenter';
import { useAppDispatch } from '~/redux/hooks';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';

interface IProps {
    folderId: string;
}

const ItemNewUploadTableView = ({ folderId }: IProps): JSX.Element => {
    const fileDialog = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    return (
        <tr>
            <td
                colSpan={5}
                className="text-center cursor-pointer bg-background1 hover:bg-background2"
                onClick={() => fileDialog.current?.click()}
            >
                <DragAndDropUploadHandler folderId={folderId} className="p-4">
                    <input
                        onChange={e =>
                            dispatch(
                                setSelectedModal({
                                    type: ModalType.AddFiles,
                                    files: e.currentTarget?.files || undefined,
                                    folderId,
                                })
                            )
                        }
                        ref={fileDialog}
                        type="file"
                        multiple
                        className="hidden"
                    />
                    Click or drag-and-drop here to add new files
                </DragAndDropUploadHandler>
            </td>
        </tr>
    );
};

export default ItemNewUploadTableView;
