import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { Color, PlotAreaOptions, SeriesAreaOptions } from 'highcharts';
import { AreaChart, Button, DropdownMenu } from '~/components';
import { useBoundingClientRect } from '~/hooks';
import CurrencyFormatter from '~/utils/currencyFormatter';
import Header from '../Header';

interface IProps {
    currentValuation: number;
    growthRate: number;
    onSetGrowthRate: (growthRate: number) => void;
}

const getTextForGrowthRate = (growthRate: number) => `@ ${growthRate * 100}% Growth`;

const toChartSeries = (currentValuation: number, growthRate: number): SeriesAreaOptions => {
    const currentYear = new Date().getFullYear();
    const data = [...Array(5).keys()].map(yearIndex => {
        const valuationForYear = currentValuation * Math.pow(1 + growthRate, yearIndex);

        return [currentYear + yearIndex, valuationForYear];
    });

    return {
        data,
        type: 'area',
    };
};

const ValuationProjection = ({ currentValuation, growthRate, onSetGrowthRate }: IProps): JSX.Element => {
    const [chartContainerRect, chartContainerRef] = useBoundingClientRect();
    const successColor = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--color-affirmation')
        .trimStart();
    const areaPlotOptions: PlotAreaOptions = {
        fillColor: {
            linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
            },
            stops: [
                [0, Color.parse(successColor).setOpacity(0.5).get('rgba') as string],
                [1, 'transparent'],
            ],
        },
        lineColor: 'var(--color-affirmation)',
    };
    const series = toChartSeries(currentValuation, growthRate);

    return (
        <div className="flex flex-col">
            <Header text="Projected Valuation" />
            <div className="flex justify-between">
                <div className="leading-9 font-semibold text-3xl mb-5">
                    {CurrencyFormatter.format(currentValuation)}
                </div>
                <div>
                    <DropdownMenu>
                        <DropdownMenu.Trigger
                            as={Button}
                            iconRight={faAngleDown}
                            refName="buttonRef"
                            text={getTextForGrowthRate(growthRate)}
                        />
                        <DropdownMenu.Item onClick={() => onSetGrowthRate(0.08)}>
                            {getTextForGrowthRate(0.08)}
                        </DropdownMenu.Item>
                        <DropdownMenu.Item onClick={() => onSetGrowthRate(0.1)}>
                            {getTextForGrowthRate(0.1)}
                        </DropdownMenu.Item>
                        <DropdownMenu.Item onClick={() => onSetGrowthRate(0.15)}>
                            {getTextForGrowthRate(0.15)}
                        </DropdownMenu.Item>
                    </DropdownMenu>
                </div>
            </div>
            <div ref={chartContainerRef} className="h-96">
                <AreaChart
                    height={chartContainerRect?.height || 0}
                    plotOptions={{ area: areaPlotOptions, series: { color: 'var(--color-affirmation)' } }}
                    series={[series]}
                    xAxis={{ allowDecimals: false }}
                />
            </div>
        </div>
    );
};

export default ValuationProjection;
