import { IBonusRightExecutiveBenefitAnalysisEmployee } from '@api';
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '~/components';
import CurrencyTableCell from '../CurrencyTableCell';

interface IProps {
    employees: IBonusRightExecutiveBenefitAnalysisEmployee[];
}

const GroupLifeTable = ({ employees }: IProps): JSX.Element => {
    return (
        <div className="max-w-7xl">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Title</TableHeaderCell>
                        <TableHeaderCell>Salary</TableHeaderCell>
                        <TableHeaderCell>STIP</TableHeaderCell>
                        <TableHeaderCell>LTIP</TableHeaderCell>
                        <TableHeaderCell>
                            Total Cash
                            <br />
                            Compensation
                        </TableHeaderCell>
                        <TableHeaderCell>Target Coverage</TableHeaderCell>
                        <TableHeaderCell>Actual Coverage</TableHeaderCell>
                        <TableHeaderCell>Exposure</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employees.map(employee => (
                        <TableRow key={employee.name}>
                            <TableCell>{employee.name}</TableCell>
                            <TableCell>{employee.title}</TableCell>
                            <CurrencyTableCell value={employee.salary} />
                            <CurrencyTableCell value={employee.shortTermIncentive} />
                            <CurrencyTableCell value={employee.longTermIncentive} />
                            <CurrencyTableCell value={employee.totalCashCompensation} />
                            <CurrencyTableCell value={employee.targetDeathBenefit} />
                            <CurrencyTableCell value={employee.currentDeathBenefit} />
                            <CurrencyTableCell
                                background="bg-background1"
                                value={Math.max(
                                    (employee.targetDeathBenefit ?? 0) - (employee.currentDeathBenefit ?? 0),
                                    0
                                )}
                            />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default GroupLifeTable;
