import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

interface IconButtonProps {
    'aria-label'?: string | undefined;
    className?: string | undefined;
    icon: IconProp;
    iconClassName?: string | undefined;
    iconFixedWidth?: boolean;
    iconSize?: 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';
    title?: string | undefined;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const IconButton = forwardRef(
    (
        { className, icon, iconClassName, iconFixedWidth, iconSize, ...rest }: IconButtonProps,
        ref: ForwardedRef<HTMLButtonElement>
    ) => (
        <button
            className={classNames(
                className,
                'bg-transparent rounded-md p-2 text-textDefault hover:text-textSubtle dark:hover:text-textSubtle active:ring-0 focus:outline-none focus:ring-2 focus:ring-success'
            )}
            ref={ref}
            type="button"
            {...rest}
        >
            {rest['aria-label'] && <span className="sr-only">{rest['aria-label']}</span>}
            <FontAwesomeIcon
                aria-hidden="true"
                className={iconClassName}
                fixedWidth={iconFixedWidth}
                icon={icon}
                size={iconSize}
            />
        </button>
    )
);

export { IconButton };
