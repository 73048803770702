import { faExclamationTriangle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentPropsWithoutRef } from 'react';
import { Button } from './Button';
import { IconButton } from './IconButton';
import { Modal } from './Modal';

interface IProps {
    confirmButtonText?: string;
    isOpen: boolean;
    isSaving?: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const DeleteConfirmationModal = ({
    children,
    confirmButtonText = 'Delete',
    isOpen,
    isSaving = false,
    onClose,
    onConfirm,
    title,
}: IProps & ComponentPropsWithoutRef<'dialog'>): JSX.Element => {
    return (
        <Modal open={isOpen} setOpen={onClose} maxWidth="lg" hideHeader disableBackdropClickClose={isSaving}>
            <div className="px-4 pt-5 pb-4 text-left sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <div className="h-7 flex items-center">
                        <IconButton aria-label="Close" icon={faTimes} iconSize="lg" onClick={onClose} />
                    </div>
                </div>
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-error sm:mx-0 sm:h-10 sm:w-10">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="h-6 w-6 text-textDefault"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-textDefault">{title}</h3>
                        <div className="mt-2">
                            {typeof children === 'string' ? (
                                <p className="text-sm text-textDisabled">{children}</p>
                            ) : (
                                children
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <Button
                        color="danger"
                        className="w-full sm:ml-3 sm:w-auto sm:text-sm"
                        loading={isSaving}
                        text={confirmButtonText}
                        onClick={onConfirm}
                    />
                    <Button
                        color="tertiary"
                        className="mt-3 w-full sm:mt-0 sm:w-auto"
                        text="Cancel"
                        onClick={onClose}
                    />
                </div>
            </div>
        </Modal>
    );
};

export { DeleteConfirmationModal };
