import { IEmployee } from '@api';
import {
    faBullseyeArrow,
    faChartLine,
    faCogs,
    faLightbulbOn,
    faMicrochip,
    faUsersClass,
    IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { getArrayOfEnum } from '~/utils/enumUtils';

export enum RatingCategory {
    Growth = 'Growth',
    Operations = 'Operations',
    Product = 'Product',
    Sales = 'Sales',
    Team = 'Team',
    Technology = 'Technology',
}

export enum RatingColor {
    Lowest = 'info-Gp2',
    Low = 'info-Gp1',
    Average = 'info-G',
    High = 'info-Gm1',
    Highest = 'info-Gm2',
}

export const ratingCategories = getArrayOfEnum(RatingCategory);

export type CategoryFields = Pick<
    IEmployee,
    'ratingGrowth' | 'ratingOperations' | 'ratingProduct' | 'ratingSales' | 'ratingTeam' | 'ratingTechnology'
>;

export const modelKey: Record<RatingCategory, keyof CategoryFields> = {
    [RatingCategory.Growth]: 'ratingGrowth',
    [RatingCategory.Operations]: 'ratingOperations',
    [RatingCategory.Product]: 'ratingProduct',
    [RatingCategory.Sales]: 'ratingSales',
    [RatingCategory.Team]: 'ratingTeam',
    [RatingCategory.Technology]: 'ratingTechnology',
};

export const categoryTitle: Record<RatingCategory, string> = {
    [RatingCategory.Growth]: 'Growth',
    [RatingCategory.Operations]: 'Operations',
    [RatingCategory.Product]: 'Product',
    [RatingCategory.Sales]: 'Sales & Marketing',
    [RatingCategory.Team]: 'Team',
    [RatingCategory.Technology]: 'Technology',
};

export const categoryDescription: Record<RatingCategory, string> = {
    [RatingCategory.Growth]: 'Customers, Growth, Satisfaction',
    [RatingCategory.Operations]: 'Legal, HR, Culture',
    [RatingCategory.Product]: 'Pricing, Innovation, Differentiation',
    [RatingCategory.Sales]: 'Social Media, Talent',
    [RatingCategory.Team]: 'Messaging, Team as a Unit',
    [RatingCategory.Technology]: 'Security, Systems, Integration',
};

export const categoryIcon: Record<RatingCategory, IconDefinition> = {
    [RatingCategory.Growth]: faChartLine,
    [RatingCategory.Operations]: faCogs,
    [RatingCategory.Product]: faLightbulbOn,
    [RatingCategory.Sales]: faBullseyeArrow,
    [RatingCategory.Team]: faUsersClass,
    [RatingCategory.Technology]: faMicrochip,
};

export interface ITeamRating {
    ratedEmployeeCount: number;
    ratingAverage: number;
    ratingCategory: RatingCategory;
    ratingSum: number;
}
