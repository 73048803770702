import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from './store';

export interface SuccessNotificationState {
    isVisible: boolean;
}

const initialState: SuccessNotificationState = {
    isVisible: false,
};

export const successNotificationSlice = createSlice({
    name: 'successNotification',
    initialState,
    reducers: {
        hideSuccessNotification: state => {
            state.isVisible = false;
        },
        showSuccessNotification: state => {
            state.isVisible = true;
        },
    },
});

export const withSuccessNotification =
    (dispatch: AppDispatch, action: () => Promise<void>) => async (): Promise<void> => {
        action().then(() => dispatch(showSuccessNotification()));
    };

export const { hideSuccessNotification, showSuccessNotification } = successNotificationSlice.actions;

export default successNotificationSlice.reducer;
