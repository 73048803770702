import { IBusiness, IEmployee } from '@api';

export enum ShareAllocationState {
    NoSharesOutstanding = 'NoSharesOutstanding',
    TooManySharesAllocated = 'TooManySharesAllocated',
    Valid = 'Valid',
}

interface ReturnType {
    outstandingShares: number;
    sharesAllocatedToEmployees: number;
    state: ShareAllocationState;
}

export const getAllocationState = (business: IBusiness, employees: IEmployee[]): ReturnType => {
    const outstandingShares = business.sharesOutstanding ?? 0;

    const sharesAllocatedToEmployees = employees.reduce((a, v) => {
        return a + (v.shares ?? 0);
    }, 0);

    return {
        outstandingShares,
        sharesAllocatedToEmployees,
        state: getState(outstandingShares, sharesAllocatedToEmployees),
    };
};

const getState = (outstandingShares: number, sharesAllocatedToEmployees: number) => {
    if (outstandingShares === 0) return ShareAllocationState.NoSharesOutstanding;
    if (outstandingShares < sharesAllocatedToEmployees) return ShareAllocationState.TooManySharesAllocated;
    return ShareAllocationState.Valid;
};
