import { IDocumentFolderCreateRequest } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Modal, TextInput } from '~/components';
import { useAddFolder } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { closeModal, ModalType } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useParams } from '~/routing';
import { errorParser } from '~/utils/errorParser';
import { defaultErrorMessage } from '~/utils/errorUtils';

type IDocumentFolderCreateForm = Pick<IDocumentFolderCreateRequest, 'name'>;

const AddFolderModal = (): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const createFolder = useAddFolder(businessId);
    const [formData, setFormData] = useState<IDocumentFolderCreateForm>({ name: '' });
    const [saveFailed, setSaveFailed] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const { currentFolderId: parentFolderId, selectedModal } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    const handleOnSubmit = async () => {
        try {
            setSaveFailed(false);
            await createFolder.mutateAsync({ ...formData, businessId, parentFolderId });
            dispatch(closeModal());
        } catch (e) {
            const fieldValidationErrors = errorParser.getFieldValidationErrors(e);

            if (fieldValidationErrors) {
                setValidationErrors(fieldValidationErrors);
                return;
            }

            setSaveFailed(true);

            throw e;
        }
    };

    return (
        <Modal
            maxWidth="xl"
            open={selectedModal.type === ModalType.AddFolder}
            setOpen={() => dispatch(closeModal())}
            title="Add Folder"
        >
            <div className="p-6">
                <div className="my-5">
                    <TextInput
                        errorMessage={validationErrors.name}
                        label="Name"
                        onChange={name => setFormData({ ...formData, name })}
                        value={formData.name}
                        autoFocus
                    />
                    {saveFailed && (
                        <p className="text-error mt-3">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" aria-hidden />
                            {defaultErrorMessage}
                        </p>
                    )}
                </div>
                <div className="space-x-3 flex justify-end">
                    <Button color="secondary" text="Cancel" onClick={() => dispatch(closeModal())} />
                    <Button color="primary" text="Save" onClick={handleOnSubmit} loading={createFolder?.isLoading} />
                </div>
            </div>
        </Modal>
    );
};

export default AddFolderModal;
