import { IBusiness } from '@api';
import { ErrorPage } from '~/components';
import { useBoundingClientRect, useEmployees } from '~/hooks';
import { getTeamRatings } from '../../teamValues/panels/Team';
import TeamPanelChart from '../../teamValues/TeamPanelChart';
import CardSkeleton from '../CardSkeleton';

interface IProps {
    business: IBusiness;
}

const TeamValuesCardContent = ({ business }: IProps): JSX.Element => {
    const { data: employees, isLoading, isError } = useEmployees(business.id);
    const [containerRect, containerRef] = useBoundingClientRect();

    if (isError) return <ErrorPage hideActions />;
    if (isLoading || !employees) return <CardSkeleton />;

    return (
        <div className="flex flex-col flex-grow">
            <div className="text-textLink">{employees.length} employees</div>
            <div className="flex flex-col flex-grow items-center justify-center" ref={containerRef}>
                <TeamPanelChart
                    axesIconsOnly
                    containerRect={containerRect}
                    fullTeamRatings={getTeamRatings(employees)}
                />
            </div>
        </div>
    );
};

export default TeamValuesCardContent;
