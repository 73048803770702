import { RatingColor } from './models';

const RatingLegend = (): JSX.Element => (
    <div className="mt-4 w-96 max-w-full">
        <div className="flex justify-between text-sm">
            <span>Worst</span>
            <span>Best</span>
        </div>
        <div className="space-x-0.5 h-2 flex flex-nowrap">
            {Object.values(RatingColor).map(color => (
                <div key={color} className={`w-full bg-${color}`}></div>
            ))}
        </div>
        <div className="flex justify-between font-bold">
            <span>1</span>
            <span>5</span>
        </div>
    </div>
);

export default RatingLegend;
