import { IBusiness, IBusinessPageType } from '@api';
import { Button, SlideOver, SlideOverSizeType } from '~/components';
import { businessPageDisplay } from '~/utils/enumUtils';
import EducationSettings from './EducationSettings';

interface Foo {
    business: IBusiness;
    isOpen: boolean;
    onClose: () => void;
    pageType?: IBusinessPageType;
}

export type IPageSettingsSlideOver = Required<Foo>;

const SettingsNotImplemented = ({ isOpen, onClose, pageType }: IPageSettingsSlideOver): JSX.Element => (
    <SlideOver
        size={SlideOverSizeType.lg}
        isOpen={isOpen}
        onClose={onClose}
        stickyFooter={() => (
            <div className="space-x-3 flex justify-end">
                <Button color="primary" text="Done" onClick={onClose} />
            </div>
        )}
        title={businessPageDisplay[pageType]}
    >
        No settings to change for this page.
    </SlideOver>
);

const pageSettingsMap: Record<IBusinessPageType, (props: IPageSettingsSlideOver) => JSX.Element> = {
    BizEquity: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    BonusRight: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    BusinessForecast: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    CorporateStrategies: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    DocumentCenter: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    Education: (props: IPageSettingsSlideOver) => <EducationSettings {...props} />,
    ExecutivePrograms: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    InnerZone: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    Observations: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    Purpose: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    ScoreCard: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    SuccessionPlan: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
    TeamValues: (props: IPageSettingsSlideOver) => <SettingsNotImplemented {...props} />,
};

const PageSettingsSlideOver = ({ pageType, ...rest }: Foo): JSX.Element | null => {
    if (!pageType) {
        return null;
    }
    const SettingsComponent = pageSettingsMap[pageType];
    return <SettingsComponent {...rest} pageType={pageType} />;
};

export default PageSettingsSlideOver;
