import classNames from 'classnames';

export interface ILabelProps {
    htmlFor?: string;
    label?: React.ReactNode;
    labelClassName?: string;
}

const Label = ({ htmlFor, label, labelClassName }: ILabelProps): JSX.Element | null => {
    if (!label) return null;

    return (
        <label className={classNames(labelClassName, 'block text-sm font-medium mb-2')} htmlFor={htmlFor}>
            {label}
        </label>
    );
};

export { Label };
