import { useEffect } from 'react';
import { clearSelectedItems, ModalType } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useHistory } from '~/routing';
import AddFilesModal from './modals/AddFilesModal';
import AddFolderModal from './modals/AddFolderModal';
import BulkDeleteModal from './modals/BulkDeleteModal';
import MoveItemsModal from './modals/moveItems/MoveItemsModal';
import RenameItemModal from './modals/renameItem/RenameItemModal';
import SharingModal from './modals/sharing/SharingModal';
import { getFolderIdFromUrl } from './utils/routes';

interface IProps {
    children: React.ReactNode;
}

export const DocumentCenterContextProvider = ({ children }: IProps): JSX.Element => {
    const { selectedModal } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    const currentFolderId = getFolderIdFromUrl() || '';
    const history = useHistory();

    useEffect(() => {
        return history.listen(() => {
            dispatch(clearSelectedItems());
        });
    }, [dispatch, history]);

    return (
        <>
            {children}
            {selectedModal.type === ModalType.AddFiles && (
                <AddFilesModal
                    filesToAdd={selectedModal.files}
                    targetFolderId={selectedModal.folderId ?? currentFolderId}
                />
            )}
            {selectedModal.type === ModalType.AddFolder && <AddFolderModal />}
            {selectedModal.type === ModalType.Delete && <BulkDeleteModal />}
            {selectedModal.type === ModalType.Move && <MoveItemsModal />}
            {selectedModal.type === ModalType.Rename && <RenameItemModal />}
            {selectedModal.type === ModalType.Share && <SharingModal />}
        </>
    );
};
