import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '~/components';
import CurrencyTableCell from '../CurrencyTableCell';
import { IEmployeeRewardsViewModel } from './models';

interface IProps {
    employeeRewardsList: IEmployeeRewardsViewModel[];
    onClickEmployee: (bonusRightEmployeeId: number) => void;
}

const EmployeeRewardsTable = ({ employeeRewardsList, onClickEmployee }: IProps): JSX.Element => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell className="text-right">Age</TableHeaderCell>
                    <TableHeaderCell className="text-right">Grade</TableHeaderCell>
                    <TableHeaderCell className="text-right">Salary</TableHeaderCell>
                    <TableHeaderCell className="text-right">STIP</TableHeaderCell>
                    <TableHeaderCell className="text-right">LTIP</TableHeaderCell>
                    <TableHeaderCell className="text-right">Equity</TableHeaderCell>
                    <TableHeaderCell className="text-right whitespace-nowrap">Total Direct</TableHeaderCell>
                    <TableHeaderCell className="text-right">Benefits</TableHeaderCell>
                    <TableHeaderCell className="text-right">Retirement</TableHeaderCell>
                    <TableHeaderCell className="text-right whitespace-nowrap">Total Indirect</TableHeaderCell>
                    <TableHeaderCell className="text-right whitespace-nowrap">Total Rewards</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {employeeRewardsList.map((e, i) => (
                    <TableRow key={i}>
                        <TableCell className="whitespace-nowrap">
                            <div className="cursor-pointer" onClick={() => onClickEmployee(e.bonusRightEmployeeId)}>
                                <div>{e.name}</div>
                            </div>
                        </TableCell>
                        <TableCell className="text-right">{e.age || '--'}</TableCell>
                        <TableCell className="text-right">{e.grade}</TableCell>
                        <CurrencyTableCell value={e.salary} />
                        <CurrencyTableCell value={e.shortTermIncentives} />
                        <CurrencyTableCell value={e.longTermIncentives} />
                        <CurrencyTableCell value={e.equity} />
                        <CurrencyTableCell
                            background="bg-background1"
                            className="border-r border-l border-background1"
                            value={e.totalDirectRewards}
                        />
                        <CurrencyTableCell value={e.benefits} />
                        <CurrencyTableCell value={e.retirement} />
                        <CurrencyTableCell
                            background="bg-background1"
                            className="border-r border-l border-background1"
                            value={e.totalIndirectRewards}
                        />
                        <CurrencyTableCell
                            background="bg-background2"
                            className="border-r border-l border-background1"
                            value={e.totalRewards}
                        />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default EmployeeRewardsTable;
