import { IEmployee } from '@api';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import {
    AvatarEmployeeEditable,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { getAgeAsString } from '~/utils/dateHelpers';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    businessId: string;
    employees: IEmployee[];
    onEdit: (employeeId: string) => void;
}

const EmployeeList = ({ businessId, employees, onEdit }: IProps): JSX.Element => {
    return (
        <Table className="w-full">
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Age</TableHeaderCell>
                    <TableHeaderCell>Title</TableHeaderCell>
                    <TableHeaderCell className="text-right">Salary</TableHeaderCell>
                    <TableHeaderCell className="w-28">
                        <span className="sr-only">Edit</span>
                    </TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {employees.map(employee => (
                    <TableRow key={employee.id}>
                        <TableCell>
                            <AvatarEmployeeEditable
                                className="mr-4"
                                businessId={businessId}
                                employeeId={employee.id}
                                src={employee.avatar || ''}
                            />
                            <span>{NameFormatter.getLastNameFirst(employee)}</span>
                        </TableCell>
                        <TableCell>{getAgeAsString(employee.dateOfBirth)}</TableCell>
                        <TableCell>{employee.title}</TableCell>
                        <TableCell className="text-right">{CurrencyFormatter.format(employee.salary)}</TableCell>
                        <TableCell className="flex justify-end w-28">
                            <Button
                                className="text-textLink"
                                color="link"
                                iconLeft={faEdit}
                                text="Edit"
                                onClick={() => onEdit(employee.id)}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export { EmployeeList };
