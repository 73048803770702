import { IDocument } from '@api';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTextColorFromType } from '../utils/colorMap';
import { getIconFromType } from './iconMap';

type IProps = Pick<IDocument, 'color' | 'icon'>;

const FolderIconGridView = ({ color, icon }: IProps): JSX.Element => {
    const folderColor = getTextColorFromType(color);
    const iconSrc = getIconFromType(icon);

    return (
        <span className={'fa-stack fa-2x'}>
            <FontAwesomeIcon icon={faFolder} className={`fa-stack-2x ${folderColor} mb-1`} />
            <FontAwesomeIcon icon={iconSrc} className="fa-stack-1x opacity-60 text-textDefault" />
        </span>
    );
};

export default FolderIconGridView;
