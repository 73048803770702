import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ErrorPage } from '~/components';
import { useEducationForAdvisor } from '~/hooks';
import { Link, useRouteMatch } from '~/routing';
import previewImage from '../../../images/education-preview.jpg';

const Status = (): JSX.Element => {
    const { data: education, error } = useEducationForAdvisor();
    const { url } = useRouteMatch();

    return (
        <div className="sm:rounded-2xl bg-background1 overflow-hidden shadow">
            <img
                alt="Education screenshot"
                className="hidden sm:block opacity-80 h-56 object-cover"
                src={previewImage}
            />
            <div className="px-9 py-6">
                <h4 className="text-2xl">Education</h4>
                {error && (
                    <div className="mt-6 mb-8">
                        <ErrorPage title="Something went wrong loading education. Please try again." />
                    </div>
                )}
                {education && (
                    <>
                        <div className="mt-6 mb-8">
                            Configure educational resources to share with your clients such as white papers, community
                            articles or videos.
                        </div>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faFileAlt} size="5x" />
                            <div className="mt-4">{`${education.length} items`}</div>
                            <div className="mt-8">
                                <Link to={`${url}/Education`}>
                                    <Button color="primary" size="lg" text="Add / Edit" />
                                </Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Status;
