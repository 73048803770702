import { IBizEquityYearlyStatement } from '@api';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons';
import { SeriesColumnOptions } from 'highcharts';
import { EmptyStateIcon, SlideOver } from '~/components';
import { ColumnChart } from '~/components/charts/ColumnChart';
import { Kpi, KpiModelKey, kpis, modelKey, percentageKpis } from './model';
import { IKpiValue } from './Ratings';

interface IYearlyStatements {
    [key: string]: IBizEquityYearlyStatement;
}
interface IProps {
    kpi?: IKpiValue;
    isOpen: boolean;
    onClose: () => void;
    yearlyStatements: IYearlyStatements;
}

export const getDataOverTime = (
    statements: IYearlyStatements,
    dataKey: KpiModelKey,
    isPercentageKpi: boolean
): (string | number | null)[][] | null => {
    let count = 0;

    const data = Object.keys(statements)
        .sort((a, b) => (a < b ? -1 : 1))
        .map(year => {
            let value = statements[year][dataKey]?.value;
            if (value !== undefined) {
                value *= isPercentageKpi ? 100 : 1;
                count++;
            }
            return [year, value || null];
        });

    if (count < 2) {
        return null;
    }

    return data;
};

const toChartSeries = (statements: IYearlyStatements, kpi?: IKpiValue): SeriesColumnOptions | null => {
    if (!statements || !kpi) {
        return null;
    }

    const isPercentageKpi = percentageKpis.indexOf(kpi.type) !== -1;
    const data = getDataOverTime(statements, modelKey[kpi.type], isPercentageKpi);
    if (!data) {
        return null;
    }

    return {
        name: kpi.name,
        data,
        type: 'column',
        showInLegend: false,
    };
};

const RatingDetails = ({ onClose, isOpen, kpi, yearlyStatements }: IProps): JSX.Element => {
    const kpiType = kpi?.type || Kpi.CashToDebt;
    const viewModel = kpis[kpiType];
    const isPercentageKpi = percentageKpis.indexOf(kpiType) !== -1;
    const series = toChartSeries(yearlyStatements, kpi);

    return (
        <SlideOver isOpen={isOpen} onClose={onClose} title={kpi?.name}>
            {kpi ? (
                <div className="flex flex-col space-y-5">
                    <div>{kpi.description}</div>
                    <div className="w-full">
                        {series ? (
                            <ColumnChart
                                height={250}
                                plotOptions={{ series: { color: 'var(--color-action)' } }}
                                series={[series]}
                                xAxis={{
                                    allowDecimals: false,
                                    type: 'category',
                                }}
                                yAxis={{
                                    title: {
                                        text: isPercentageKpi ? 'Percentage' : 'Ratio',
                                    },
                                }}
                            />
                        ) : (
                            <div className="flex flex-col text-center items-center bg-background1 p-4">
                                <EmptyStateIcon icon={faChartBar} />
                                <p className="my-4 text-lg">This chart is not available.</p>
                                <p>{viewModel.noChartMessage}</p>
                            </div>
                        )}
                    </div>
                    {viewModel.chartDescription && (
                        <div>
                            <p className="font-bold mb-1">{kpi.name} Over Time</p>
                            <p>{viewModel.chartDescription}</p>
                        </div>
                    )}
                    <div>
                        <p className="font-bold mb-1">What does it mean?</p>
                        <p>{viewModel.whatDoesItMean}</p>
                    </div>
                    <div>
                        <p className="font-bold mb-1">Why should it matter?</p>
                        <p>{viewModel.whyShouldItMatter}</p>
                    </div>
                    <div>
                        <p className="font-bold mb-1">Give me an example</p>
                        <p>{viewModel.giveMeAnExample}</p>
                    </div>
                </div>
            ) : null}
        </SlideOver>
    );
};

export default RatingDetails;
