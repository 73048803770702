import Highcharts, { PlotOptions, SeriesOptionsType, XAxisOptions, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CurrencyFormatter from '~/utils/currencyFormatter';

interface IProps {
    height: number;
    plotOptions?: PlotOptions;
    series: Array<SeriesOptionsType>;
    width?: number;
    xAxis?: XAxisOptions;
    yAxis?: YAxisOptions;
}

const LineChart = ({ height, plotOptions, series, width, xAxis, yAxis }: IProps): JSX.Element => {
    const options: Highcharts.Options = {
        chart: {
            animation: false,
            backgroundColor: 'transparent',
            height,
            width,
        },
        credits: {
            enabled: false,
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            itemStyle: {
                color: 'var(--color-textDefault)',
                pointerEvents: 'none',
            },
        },
        plotOptions: {
            ...(plotOptions || {}),
            series: {
                animation: false,
                label: {
                    connectorAllowed: false,
                },
                ...(plotOptions?.series || {}),
            },
        },
        series,
        title: {
            text: '',
        },
        tooltip: {
            backgroundColor: 'var(--color-background)',
            borderRadius: 8,
            formatter: function () {
                return '<b class="text-textDefault fill-current">' + CurrencyFormatter.format(this.y) + '</b>';
            },
        },
        xAxis: {
            ...xAxis,
            labels: {
                ...xAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '0.875rem',
                    ...xAxis?.labels?.style,
                },
            },
            lineColor: 'var(--color-bg-highlight)',
            tickLength: 0,
        },
        yAxis: {
            ...yAxis,
            gridLineColor: 'var(--color-background2)',
            labels: {
                ...yAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '0.875rem',
                    ...yAxis?.labels?.style,
                },
            },
            title: {
                text: null,
                ...yAxis?.title,
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export { LineChart };
