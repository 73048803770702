import { IBusiness, IEmployee, IUserEntitlementType } from '@api';
import { faPlusCircle, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import axios from 'axios';
import { useState } from 'react';
import {
    Avatar,
    Button,
    Can,
    DeleteConfirmationModal,
    EntitySelect,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import {
    useAddBusinessOwner,
    useBusinessOwners,
    useCurrentUser,
    useDeleteBusinessOwner,
    useEmployees,
    useUpdateEmployee,
} from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification, withSuccessNotification } from '~/redux/successNotification';
import NameFormatter from '~/utils/nameFormatter';
import EmployeesNoData from '../../employees/EmployeesNoData';
import EmployeeEmailModal from './EmployeeEmailModal';

interface IPortalUserActionsProps {
    business: IBusiness;
    userId: string;
}

const PortalUserActions = ({ business, userId }: IPortalUserActionsProps) => {
    const dispatch = useAppDispatch();

    const [deleteState, setDeleteState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const deleteBusinessOwner = useDeleteBusinessOwner(business.id);

    return (
        <div className="space-x-6">
            <Can hasEntitlement={IUserEntitlementType.BusinessOwnerDelete}>
                <Button
                    color="secondary"
                    disabled={deleteBusinessOwner.isLoading}
                    iconLeft={faTrashAlt}
                    text="Delete"
                    onClick={() => setDeleteState({ isOpen: true })}
                />
                <DeleteConfirmationModal
                    onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                    onConfirm={withSuccessNotification(dispatch, () => deleteBusinessOwner.mutateAsync(userId))}
                    title="Delete Portal User"
                    {...deleteState}
                >
                    Are you sure you want to delete this portal user? All of your data will be permanently removed from
                    our servers forever. This action cannot be undone.
                </DeleteConfirmationModal>
            </Can>
        </div>
    );
};

interface IProps {
    business: IBusiness;
}
const PortalAccess = ({ business }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));

    const [emailEditEmployee, setEmailEditEmployee] = useState<IEmployee>();
    const [employeesBeingAdded, setEmployeesBeingAdded] = useState<string[]>([]);
    const [isSavingEmployeeEmail, setIsSavingEmployeeEmail] = useState(false);
    const [userSelectIsOpen, setUserSelectIsOpen] = useState(false);
    const {
        isError: isBusinessOwnersError,
        isLoading: isBusinessOwnersLoading,
        data: businessOwners = [],
    } = useBusinessOwners(business.id);
    const addBusinessOwner = useAddBusinessOwner(business.id);
    const { data: currentUser } = useCurrentUser();
    const {
        isError: isEmployeesError,
        isLoading: isEmployeesLoading,
        data: employees = [],
    } = useEmployees(business.id);

    const updateEmployee = useUpdateEmployee(business.id);
    const onAddBusinessOwnerUser = (employeeId: string) => {
        setEmployeesBeingAdded([...employeesBeingAdded, employeeId]);
        addBusinessOwner
            .mutateAsync({ userId: employeeId })
            .then(() => showSuccess())
            .catch((err: unknown) => {
                showError(
                    axios.isAxiosError(err) && err.response?.status === 409
                        ? `The email address already has portal access: ${
                              employees.find(e => e.id === employeeId)?.emailAddress
                          }`
                        : undefined
                );
            })
            .finally(() => setEmployeesBeingAdded(employeesBeingAdded.filter(id => id !== employeeId)));
    };
    const onTryAddBusinessOwnerUser = (employeeId: string) => {
        const employee = employees.find(e => e.id === employeeId);

        if (!employee?.emailAddress) {
            setEmailEditEmployee(employee);
            return;
        }
        onAddBusinessOwnerUser(employeeId);
    };
    const onUpdateEmail = async (emailAddress: string) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const employee = emailEditEmployee!;

        setIsSavingEmployeeEmail(true);
        try {
            await updateEmployee.mutateAsync({ ...employee, emailAddress, employeeId: employee.id });
        } catch {
            setIsSavingEmployeeEmail(false);
            showError();
        }
        setIsSavingEmployeeEmail(false);
        onAddBusinessOwnerUser(employee.id);
        setEmailEditEmployee(undefined);
    };

    if (isBusinessOwnersError || isEmployeesError) return <ErrorPage />;
    if (isBusinessOwnersLoading || isEmployeesLoading) return <LoadingIndicator />;

    const userOptions = employees
        .filter(e => !businessOwners.some(u => u.id === e.id))
        .sort(a => (a.isOwner ? -1 : 1))
        .map(e => ({
            avatar: e.avatar || undefined,
            description: e.emailAddress || undefined,
            disabled: employeesBeingAdded.some(id => id === e.id),
            label: NameFormatter.getLastNameFirst(e),
            name: NameFormatter.getLastNameFirst(e),
            showAddButton: !employeesBeingAdded.some(id => id === e.id),
            showLoading: employeesBeingAdded.some(id => id === e.id),
            value: e.id,
        }));
    const hasEmployees = employees.length > 0;

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Portal Access</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-textSubtle">
                Invite employees to use the Business Portal.
            </p>
            {!hasEmployees && <EmployeesNoData businessId={business.id} />}
            {hasEmployees && (
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>User</TableHeaderCell>
                                <TableHeaderCell className="text-right relative">
                                    <Can hasEntitlement={IUserEntitlementType.BusinessOwnerCreate}>
                                        <Button
                                            iconLeft={faPlusCircle}
                                            text="Add New"
                                            onClick={() => setUserSelectIsOpen(!userSelectIsOpen)}
                                        />
                                        {userSelectIsOpen && (
                                            <EntitySelect
                                                menuIsOpen
                                                className="absolute top-0 right-0 invisible"
                                                isCreatable={false}
                                                onClose={() => setUserSelectIsOpen(false)}
                                                label=""
                                                options={userOptions}
                                                onEntityOptionSelected={e => onTryAddBusinessOwnerUser(e.value)}
                                            />
                                        )}
                                    </Can>
                                </TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {businessOwners.length === 0 && (
                                <TableRow key="no-data">
                                    <TableCell className="text-lg">No employees with portal access yet.</TableCell>
                                </TableRow>
                            )}
                            {businessOwners.map(u => (
                                <TableRow key={u.id}>
                                    <TableCell className="flex">
                                        <Avatar size={12} src={u.avatar || undefined} />
                                        <div className="ml-3">
                                            <div className="font-medium text-lg">
                                                {NameFormatter.getLastNameFirst(u)}
                                                <p className="text-textDisabled text-sm truncate">{u.emailAddress}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {currentUser?.entitlements.some(
                                            e => e === IUserEntitlementType.BusinessOwnerDelete
                                        ) && <PortalUserActions business={business} userId={u.id} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <EmployeeEmailModal
                        disabled={isSavingEmployeeEmail}
                        employee={emailEditEmployee}
                        open={!!emailEditEmployee}
                        onSave={onUpdateEmail}
                        setIsOpen={open => {
                            if (!open) {
                                setEmailEditEmployee(undefined);
                            }
                        }}
                    />
                </>
            )}
        </>
    );
};

export default PortalAccess;
