import { IEmployee } from '@api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Avatar, Button, Modal, Slider } from '~/components';
import { useUpdateEmployee } from '~/hooks';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import { getArrayOfEnum } from '~/utils/enumUtils';
import NameFormatter from '~/utils/nameFormatter';
import { categoryDescription, CategoryFields, categoryIcon, categoryTitle, modelKey, RatingCategory } from './models';

interface IProps {
    businessId: string;
    employee?: IEmployee;
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (employee: IEmployee) => void;
}

const defaultRating = 3;

const getInitialValues = (employee?: IEmployee): Required<CategoryFields> => {
    return {
        ratingGrowth: employee?.ratingGrowth ?? defaultRating,
        ratingOperations: employee?.ratingOperations ?? defaultRating,
        ratingProduct: employee?.ratingProduct ?? defaultRating,
        ratingSales: employee?.ratingSales ?? defaultRating,
        ratingTeam: employee?.ratingTeam ?? defaultRating,
        ratingTechnology: employee?.ratingTechnology ?? defaultRating,
    };
};

const EditEmployeeRatingModal = (props: IProps): JSX.Element => {
    const { businessId, employee, isOpen, onClose, onSuccess } = props;
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const updateEmployee = useUpdateEmployee(businessId);
    const [ratingFields, setRatingFields] = useState(getInitialValues(employee));
    const [isSaving, setIsSaving] = useState(false);
    const resetForm = (employee: IEmployee | undefined) => {
        setRatingFields(getInitialValues(employee));
    };
    const onSave = async () => {
        if (!employee) return;
        setIsSaving(true);
        try {
            await updateEmployee.mutateAsync({
                ...employee,
                ...ratingFields,
                employeeId: employee.id,
            });
            showSuccess();
            onSuccess({
                ...employee,
                ...ratingFields,
            });
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            resetForm(employee);
        }
    }, [employee, isOpen]);

    return (
        <Modal maxWidth="4xl" open={isOpen} setOpen={onClose} title="Value Input">
            <div className="p-6">
                <div className="flex items-center mb-6">
                    <Avatar src={employee?.avatar || ''} />
                    <div className="ml-4">
                        <div className="font-bold text-lg">{NameFormatter.getName(employee)}</div>
                        <div className="text-textDisabled">{employee?.title}</div>
                    </div>
                </div>
                <div className="grid xl:grid-cols-2 gap-4">
                    {getArrayOfEnum(RatingCategory).map(ratingCategory => (
                        <div key={ratingCategory} className="rounded-lg bg-background2 dark:bg-background1 p-4 shadow">
                            <div className="flex items-center">
                                <div className="text-lg font-bold">
                                    <FontAwesomeIcon icon={categoryIcon[ratingCategory]} className="mr-2" fixedWidth />
                                    {categoryTitle[ratingCategory]}
                                </div>
                            </div>
                            <div className="mb-2">{categoryDescription[ratingCategory]}</div>
                            <Slider
                                disabled={isSaving}
                                min={1}
                                max={5}
                                onChange={value =>
                                    setRatingFields({
                                        ...ratingFields,
                                        [modelKey[ratingCategory]]: value,
                                    })
                                }
                                value={ratingFields[modelKey[ratingCategory]] || defaultRating}
                            />
                        </div>
                    ))}
                </div>
                <div className="space-x-3 mt-4 flex justify-end">
                    <Button color="secondary" disabled={isSaving} text="Cancel" onClick={onClose} />
                    <Button color="primary" disabled={isSaving} text="Save" onClick={onSave} />
                </div>
            </div>
        </Modal>
    );
};

export default EditEmployeeRatingModal;
