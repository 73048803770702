import { useState } from 'react';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { Button, FieldSet, TextInput } from '~/components';
import { useCurrentUser, useInnerZoneLogin } from '~/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    className?: string;
    username?: string;
}

interface ILoginFormData {
    password: string;
    username: string;
}

const schemaValidation = Yup.object().shape({
    password: Yup.string().label('Password').required(),
    username: Yup.string().label('Username').required(),
});

const InnerZoneLoginForm = ({ className, username }: IProps): JSX.Element => {
    const { data: currentUser } = useCurrentUser();
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<ILoginFormData>({ password: '', username: username || '' });
    const [isLoading, setIsLoading] = useState(false);
    const innerZoneLogin = useInnerZoneLogin(
        () =>
            new Promise(res => {
                setIsLoading(true);
                setTimeout(res, 15000); // artificial delay for hangfire jobs to run
            })
    );
    const onLogin = () => {
        setIsLoading(true);

        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            setIsLoading(false);
            return;
        }
        setLoginErrorMessage(undefined);
        setValidationErrors({});
        innerZoneLogin
            .mutateAsync({
                ...formData,
                advisorFirmId: currentUser?.advisorFirmId ?? '',
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch(e => {
                setLoginErrorMessage(
                    'There was an error logging into InnerZone. Please check your credentials and try again.'
                );
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <form
            className={className}
            onSubmit={e => {
                e.preventDefault();
                onLogin();
            }}
        >
            <FieldSet disabled={isLoading}>
                <TextInput
                    className="mb-4"
                    errorMessage={validationErrors.username}
                    label="Username"
                    onChange={username => {
                        setFormData({ ...formData, username });
                        setLoginErrorMessage(undefined);
                        setValidationErrors({});
                    }}
                    value={formData.username}
                />
                <TextInput
                    className="mb-6"
                    errorMessage={validationErrors.password}
                    label="Password"
                    onChange={password => {
                        setFormData({ ...formData, password });
                        setLoginErrorMessage(undefined);
                        setValidationErrors({});
                    }}
                    type="password"
                    value={formData.password}
                />
                {loginErrorMessage && <p className="mb-6 text-sm text-error">{loginErrorMessage}</p>}
                <Button color="primary" size="lg" text={isLoading ? 'Loading data...' : 'Sign In'} type="submit" />
            </FieldSet>
        </form>
    );
};

export default InnerZoneLoginForm;
