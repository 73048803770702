import { INoteType, IUserEntitlementType } from '@api';
import { faBinoculars, faBullseyeArrow, faCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Can } from '~/components';

interface IProps {
    noteType: INoteType;
    onAddClick: () => void;
}

const noteTypeIcon = {
    [INoteType.Objective]: faBullseyeArrow,
    [INoteType.Observation]: faBinoculars,
};

const NotesEmptyState = ({ noteType, onAddClick }: IProps): JSX.Element => (
    <div className="text-center">
        <span className="fa-layers fa-fw fa-10x mb-5">
            <FontAwesomeIcon className="text-background1" icon={faCircle} />
            <FontAwesomeIcon className="text-background2" icon={noteTypeIcon[noteType]} transform="shrink-8" />
        </span>
        <Can hasEntitlement={IUserEntitlementType.NoteCreate}>
            <>
                <div className="mb-7">Click below to add an {noteType.toLocaleLowerCase()}</div>
                <Button color="primary" iconLeft={faPlusCircle} text="Add New" onClick={onAddClick} />
            </>
        </Can>
    </div>
);

export default NotesEmptyState;
