import { IBusiness, IBusinessValuation, IEsop } from '@api';
import { faUniversity } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useAddEsop, useDeleteEsop, useEmployees, useEsopForBusiness, useUpdateEsop } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { getEmployeesById } from '~/utils/businessUtils';
import CurrencyFormatter from '~/utils/currencyFormatter';
import NameFormatter from '~/utils/nameFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import EntityCard from '../../successionPlan/EntityCard';
import ObservationList from '../../successionPlan/ObservationList';
import { entityCardWidth } from '../constants';
import CurrentOwnersAndLines from '../CurrentOwnersAndLines';
import getCorporateStrategyOwners from '../model/getCorporateStrategyOwners';
import { getAdjustedEquity } from '../model/models';
import ResetButton from '../ResetButton';
import UpdatedOwnersAndLines from '../UpdatedOwnersAndLines';
import EsopForm from './EsopForm';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const getObservations = (business: IBusiness, businessValue: number, esopValues: IEsop): string[] => {
    const esopFundedAmount = businessValue * esopValues.percentage;

    return [
        `${business.name} make a ${CurrencyFormatter.format(
            esopFundedAmount
        )} tax deductible contribution to fund the ESOP.`,
        `The ESOP buys ${formatPercentage(esopValues.percentage)} of the owners shares for ${CurrencyFormatter.format(
            esopFundedAmount
        )}.`,
        'Employees receive shares from the ESOP which vest over time.',
    ];
};

const Esop = (props: IProps): JSX.Element => {
    const { business, businessValuation } = props;
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const [isFormOpen, setIsFormOpen] = useState(false);
    const employeesQuery = useEmployees(business.id);
    const esopQuery = useEsopForBusiness(business.id);
    const addEsop = useAddEsop(business.id);
    const deleteEsop = useDeleteEsop(business.id);
    const updateEsop = useUpdateEsop(business.id);
    const isError = isTrueForAnyQuery('isError', employeesQuery, esopQuery);
    const isLoading = isTrueForAnyQuery('isLoading', employeesQuery, esopQuery);
    if (isError && esopQuery.error?.response?.status !== 404) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    const { data: employees = [] } = employeesQuery;
    const { data: esop } = esopQuery;
    const employeesById = getEmployeesById(employees);
    const owners = getCorporateStrategyOwners(business, employees);
    const onFormSave = async (employees: string[], percentage: number) => {
        try {
            if (esop) {
                await updateEsop.mutateAsync({ esopId: esop.id, request: { employees, percentage } });
            } else {
                await addEsop.mutateAsync({ businessId: business.id, employees, percentage });
            }
            setIsFormOpen(false);
        } catch {
            showError();
        }
    };

    return (
        <>
            <div className="mx-auto max-w-full flex flex-col">
                <CurrentOwnersAndLines ownerIds={owners.map(o => o.employeeId)} showLines={!!esop?.percentage}>
                    {owners.map(o => {
                        const employee = employeesById[o.employeeId];
                        const adjustedEquity = getAdjustedEquity(o, esop?.percentage || 0);

                        return (
                            <EntityCard
                                key={o.employeeId}
                                avatar={employee.avatar || undefined}
                                className={entityCardWidth}
                                description={employee.title || undefined}
                                name={NameFormatter.getName(employee)}
                                value={formatPercentage(adjustedEquity)}
                            />
                        );
                    })}
                </CurrentOwnersAndLines>
                {esop ? (
                    <>
                        <div className="flex justify-center">
                            <button
                                className="text-left bg-background1 px-5 py-4 rounded-md hover:bg-background2 shadow"
                                onClick={() => setIsFormOpen(true)}
                            >
                                <div className="flex">
                                    <FontAwesomeIcon className="text-textDisabled" icon={faUniversity} size="3x" />
                                    <div className="flex items-center ml-4">
                                        <div>
                                            <div className="text-xl leading-7 font-semibold">ESOP</div>
                                            <p className="text-lg leading-6">
                                                {CurrencyFormatter.format(businessValuation.value * esop.percentage)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <UpdatedOwnersAndLines ownerIds={esop.employees}>
                            {esop.employees.map(id => {
                                const employee = employeesById[id];
                                return (
                                    <EntityCard
                                        key={employee.id}
                                        avatar={employee.avatar || undefined}
                                        className={entityCardWidth}
                                        description={employee.title || undefined}
                                        name={NameFormatter.getName(employee)}
                                        value={formatPercentage(esop.percentage / esop.employees.length)}
                                    />
                                );
                            })}
                        </UpdatedOwnersAndLines>
                    </>
                ) : (
                    <div className="flex justify-center mt-4">
                        <Button color="primary" text="Create ESOP" onClick={() => setIsFormOpen(true)} />
                    </div>
                )}
            </div>
            {esop && (
                <div className="mt-12">
                    <ObservationList observations={getObservations(business, businessValuation.value, esop)} />
                </div>
            )}
            <EsopForm
                esop={esop}
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSave={onFormSave}
                {...props}
            />
            {esop && <ResetButton modelTitle="ESOP" onConfirm={() => deleteEsop.mutateAsync(esop.id)} />}
        </>
    );
};

export default Esop;
