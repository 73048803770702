import { BrowserRouter, Route, Switch } from '~/routing';
import AdvisorSettings from './advisorSettings';
import BusinessSwitch from './business';
import BusinessAdd from './businessAdd';
import BusinessList from './businessList';

const AdvisorLayout = (): JSX.Element => (
    <BrowserRouter>
        <Switch>
            <Route path="/settings">
                <AdvisorSettings />
            </Route>
            <Route path="/AddBusiness">
                <BusinessAdd />
            </Route>
            <Route path="/:businessId">
                <BusinessSwitch />
            </Route>
            <Route path="/">
                <BusinessList />
            </Route>
        </Switch>
    </BrowserRouter>
);

export default AdvisorLayout;
