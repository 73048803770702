import { Button as EnvButton } from '@envestnet/envreact-component-library';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentPropsWithoutRef } from 'react';

export interface IButtonProps
    extends Omit<
        ComponentPropsWithoutRef<typeof EnvButton>,
        'disabled' | 'fullWidth' | 'iconLeft' | 'iconOnly' | 'iconRight' | 'loading' | 'onClick'
    > {
    color?: 'danger' | 'link' | 'primary' | 'primaryLink' | 'secondary' | 'secondaryDanger' | 'tertiary';
    disabled?: boolean;
    fullWidth?: boolean;
    iconClassName?: string | undefined;
    iconLeft?: IconProp;
    iconOnly?: IconProp;
    iconRight?: IconProp;
    iconSize?: 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';
    loading?: boolean;
    onClick?: (event: React.SyntheticEvent) => void;
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    text?: string;
    title?: string | undefined;
}

const envButtonSizeBySize: Record<string, 'small' | 'regular' | 'marketing'> = {
    sm: 'small',
    md: 'regular',
    lg: 'regular',
    xl: 'marketing',
    xxl: 'marketing',
};

const envButtonStyleByColor: Record<
    string,
    'link' | 'primary' | 'primarywarning' | 'secondary' | 'secondarywarning' | 'tertiary'
> = {
    danger: 'primarywarning',
    link: 'link',
    primary: 'primary',
    primaryLink: 'link',
    secondary: 'secondary',
    secondaryDanger: 'secondarywarning',
    tertiary: 'tertiary',
};

const Button = ({
    color = 'secondary',
    iconClassName,
    iconLeft,
    iconOnly,
    iconRight,
    iconSize,
    size = 'md',
    onClick,
    text,
    title,
    type = 'button',
    ...rest
}: IButtonProps): JSX.Element => (
    <EnvButton
        buttonSize={envButtonSizeBySize[size]}
        buttonStyle={envButtonStyleByColor[color]}
        iconLeft={iconLeft && <FontAwesomeIcon className={iconClassName} icon={iconLeft} size={iconSize} />}
        iconOnly={iconOnly && <FontAwesomeIcon className={iconClassName} icon={iconOnly} size={iconSize} />}
        iconRight={iconRight && <FontAwesomeIcon className={iconClassName} icon={iconRight} size={iconSize} />}
        name={text}
        onClick={e => (onClick ? onClick(e) : true)}
        type={type}
        {...rest}
    />
);

export { Button };
