import { CircularProgress as EnvCircularProgress } from '@envestnet/envreact-component-library';

const LoadingIndicator = (): JSX.Element => (
    <div className="absolute inset-0 flex h-full">
        <div className="flex flex-col items-center m-auto bg-background1 border-4 border-background px-8 py-6 rounded-xl space-y-2 shadow-sm">
            <EnvCircularProgress />
            <p className="text-textDefault">Loading</p>
        </div>
    </div>
);

export { LoadingIndicator };
