import { IDocument } from '@api';
import { useEffect } from 'react';
import { ErrorPage, LoadingIndicator, Table, TableBody, TableHead, TableHeaderCell, TableRow } from '~/components';
import { useDocumentList, useDocumentPath } from '~/hooks';
import { DisplayType, setCurrentFolder } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useParams } from '~/routing';
import { FolderParams } from '.';
import Breadcrumbs from './Breadcrumbs';
import Header from './Header';
import ItemFileGridView from './ItemFileGridView';
import ItemFileTableView from './ItemFileTableView';
import ItemFolderGridView from './ItemFolderGridView';
import ItemFolderTableView from './ItemFolderTableView';
import ItemNewUploadGridView from './ItemNewUploadGridView';
import ItemNewUploadTableView from './ItemNewUploadTableView';
import NoDataUpload from './NoDataUpload';
import { rootSharedFolderId } from './utils/rootFolder';

interface ViewProps {
    canUploadToCurrentLocation: boolean;
    items: IDocument[];
    folderId: string;
}

const GridView = ({ canUploadToCurrentLocation, items, folderId }: ViewProps) => (
    <div className="flex flex-row flex-wrap">
        {items.map(i =>
            i.isFolder ? <ItemFolderGridView key={i.id} item={i} /> : <ItemFileGridView key={i.id} item={i} />
        )}
        {canUploadToCurrentLocation && <ItemNewUploadGridView folderId={folderId} />}
    </div>
);

const TableView = ({ canUploadToCurrentLocation, items, folderId }: ViewProps) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableHeaderCell className="w-9" />
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Count</TableHeaderCell>
                <TableHeaderCell>Updated</TableHeaderCell>
                <TableHeaderCell />
            </TableRow>
        </TableHead>
        <TableBody>
            {items.map(i =>
                i.isFolder ? <ItemFolderTableView key={i.id} item={i} /> : <ItemFileTableView key={i.id} item={i} />
            )}
            {canUploadToCurrentLocation && <ItemNewUploadTableView folderId={folderId} />}
        </TableBody>
    </Table>
);

const Folder = (): JSX.Element => {
    const { businessId, folderId } = useParams<FolderParams>();
    const folder = useDocumentList(businessId, folderId);
    const documentPath = useDocumentPath(businessId, folderId);
    const { selectedDisplayType } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentFolder(folderId));
    }, [dispatch, folderId]);

    if (folder.isLoading || documentPath.isLoading) return <LoadingIndicator />;
    if (folder.error || documentPath.error) return <ErrorPage />;
    if (!folder.data || !documentPath.data) return <div>Nothing to show yet. Please try again later.</div>;

    const View = selectedDisplayType === DisplayType.Grid ? GridView : TableView;

    return (
        <div className="flex flex-col p-10">
            <Header>
                <Breadcrumbs folder={folder.data} segments={documentPath.data} />
            </Header>
            {folder.data.documents.length ? (
                <View
                    canUploadToCurrentLocation={folderId !== rootSharedFolderId}
                    items={folder.data.documents}
                    folderId={folderId}
                />
            ) : (
                <NoDataUpload folderId={folderId} />
            )}
        </div>
    );
};

export default Folder;
