import {
    IAdvisorFirm,
    IAdvisorFirmCreateRequest,
    IAdvisorFirmUpdateRequest,
    IBusinessSizeType,
    IStateOrProvinceType,
} from '@api';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import * as Yup from 'yup';
import { AvatarInput, FieldSet, FileUpload, Select, TextArea, TextInput } from '~/components';
import { businessSizeDisplay, getEnumFromString } from '~/utils/enumUtils';
import { transformEmptyStringToNull, trimString } from '~/utils/yupUtils';

export type IFirmInfoFormData = Strings<IAdvisorFirmCreateRequest>;

export const getInitialFormData = (advisorFirm?: IAdvisorFirm): IFirmInfoFormData => {
    return {
        city: advisorFirm?.city || '',
        description: advisorFirm?.description || '',
        name: advisorFirm?.name || '',
        partnerId: advisorFirm?.partnerId || '',
        size: advisorFirm?.size || '',
        stateOrProvince: advisorFirm?.stateOrProvince || '',
        website: advisorFirm?.website || '',
        yearFounded: advisorFirm?.yearFounded?.toString(),
    };
};

export const mapFormDataToApi = (
    formData: IFirmInfoFormData
): IAdvisorFirmCreateRequest | IAdvisorFirmUpdateRequest => {
    return {
        ...formData,
        size: formData.size?.length ? getEnumFromString(IBusinessSizeType, formData.size) : undefined,
        stateOrProvince: formData.stateOrProvince?.length
            ? getEnumFromString(IStateOrProvinceType, formData.stateOrProvince)
            : undefined,
        website: formData.website?.length ? formData.website : undefined,
        yearFounded: formData.yearFounded?.length ? parseInt(formData.yearFounded) : undefined,
    };
};
export const schemaValidation = Yup.object().shape({
    name: Yup.string().transform(trimString).label('Firm Name').required(),
    partnerId: Yup.string().label('Partner Id ').required(),
    website: Yup.string().label('Website').url(),
    yearFounded: Yup.number()
        .positive()
        .integer()
        .transform(transformEmptyStringToNull)
        .nullable(true)
        .max(new Date().getFullYear())
        .label('Year Founded'),
});

interface IProps {
    avatar: string | null | undefined;
    disabled?: boolean;
    errors: { [key: string]: string };
    formData: IFirmInfoFormData;
    onChange: (formData: IFirmInfoFormData) => void;
    onChangeAvatar: React.Dispatch<React.SetStateAction<FileUpload | undefined>>;
}

const FirmInfoForm = ({
    avatar,
    disabled = false,
    errors,
    formData,
    onChange,
    onChangeAvatar,
}: IProps): JSX.Element => {
    return (
        <FieldSet className="grid grid-cols-6 gap-6" disabled={disabled}>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.name}
                    label="Firm Name"
                    value={formData.name}
                    onChange={name => onChange({ ...formData, name })}
                    autoFocus
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.partnerId}
                    label="Partner ID"
                    value={formData.partnerId}
                    onChange={partnerId => onChange({ ...formData, partnerId })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.city}
                    label="City"
                    value={formData.city}
                    onChange={city => onChange({ ...formData, city })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <Select
                    errorMessage={errors.stateOrProvince}
                    label="State"
                    value={formData.stateOrProvince}
                    onChange={e =>
                        onChange({
                            ...formData,
                            stateOrProvince: e?.value,
                        })
                    }
                    options={[
                        { label: '', value: '' },
                        ...Object.keys(IStateOrProvinceType).map(s => ({
                            label: s,
                            value: s,
                        })),
                    ]}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.website}
                    label="Website"
                    value={formData.website}
                    onChange={website => onChange({ ...formData, website })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <Select
                    errorMessage={errors.size}
                    label="Firm Size"
                    value={formData.size}
                    onChange={e => onChange({ ...formData, size: e?.value })}
                    options={[
                        { label: '', value: '' },
                        ...Object.keys(IBusinessSizeType).map(s => ({
                            label: businessSizeDisplay[getEnumFromString(IBusinessSizeType, s)],
                            value: s,
                        })),
                    ]}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.yearFounded}
                    label="Year Founded"
                    value={formData.yearFounded}
                    onChange={yearFounded => onChange({ ...formData, yearFounded })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <AvatarInput
                    errorMessage={errors.avatar}
                    label="Logo"
                    onChange={onChangeAvatar}
                    placeholderIcon={faBuilding}
                    src={avatar}
                />
            </div>
            <div className="col-span-6">
                <TextArea
                    errorMessage={errors.description}
                    label="About"
                    rows={6}
                    value={formData.description}
                    onChange={description => onChange({ ...formData, description })}
                />
            </div>
        </FieldSet>
    );
};

export default FirmInfoForm;
