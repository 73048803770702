import {
    IBusinessCreateRequest,
    IBusinessSizeType,
    IBusinessUpdateRequest,
    IIndustryType,
    IStateOrProvinceType,
} from '@api';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import * as Yup from 'yup';
import {
    AvatarInput,
    CurrencyInput,
    FieldSet,
    FileUpload,
    NumericInput,
    Select,
    TextArea,
    TextInput,
} from '~/components';
import { businessSizeDisplay, getEnumFromString, industryDisplay } from '~/utils/enumUtils';
import { transformEmptyStringToNull, trimString } from '~/utils/yupUtils';

export type IBusinessInfoFormData = Strings<
    Omit<
        IBusinessCreateRequest,
        'advisorFirmId' | 'hasWealthStudios' | 'pages' | 'primaryAdvisorId' | 'sharesOutstanding' | 'value'
    >
> &
    Pick<IBusinessCreateRequest, 'sharesOutstanding' | 'value'>;

export const mapFormDataToApi = (formData: IBusinessInfoFormData): IBusinessCreateRequest | IBusinessUpdateRequest => {
    return {
        ...formData,
        industry: formData.industry?.length ? getEnumFromString(IIndustryType, formData.industry) : undefined,
        size: formData.size?.length ? getEnumFromString(IBusinessSizeType, formData.size) : undefined,
        stateOrProvince: formData.stateOrProvince?.length
            ? getEnumFromString(IStateOrProvinceType, formData.stateOrProvince)
            : undefined,
        website: formData.website?.length ? formData.website : undefined,
        yearFounded: formData.yearFounded?.length ? parseInt(formData.yearFounded) : undefined,
    };
};
export const schemaValidation = Yup.object().shape({
    name: Yup.string().transform(trimString).label('Business Name').required(),
    sharesOutstanding: Yup.number()
        .min(0)
        .transform(transformEmptyStringToNull)
        .nullable(true)
        .label('Shares Outstanding'),
    value: Yup.number().min(0).transform(transformEmptyStringToNull).nullable(true).label('Value'),
    website: Yup.string().label('Website').url(),
    yearFounded: Yup.number()
        .min(0)
        .integer()
        .transform(transformEmptyStringToNull)
        .nullable(true)
        .max(new Date().getFullYear())
        .label('Year Founded'),
});

interface IProps {
    avatar: string | null | undefined;
    disabled: boolean;
    errors: { [key: string]: string };
    formData: IBusinessInfoFormData;
    onChange: (formData: IBusinessInfoFormData) => void;
    onChangeAvatar: React.Dispatch<React.SetStateAction<FileUpload | undefined>>;
}

const BusinessInfoForm = ({ avatar, disabled, errors, formData, onChange, onChangeAvatar }: IProps): JSX.Element => {
    return (
        <FieldSet className="grid grid-cols-6 gap-6" disabled={disabled}>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.name}
                    label="Business Name"
                    value={formData.name}
                    onChange={name => onChange({ ...formData, name })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <Select
                    errorMessage={errors.industry}
                    label="Industry"
                    value={formData.industry}
                    onChange={e => onChange({ ...formData, industry: e?.value })}
                    options={[
                        { label: '', value: '' },
                        ...Object.keys(IIndustryType).map(i => ({
                            label: industryDisplay[getEnumFromString(IIndustryType, i)],
                            value: i,
                        })),
                    ]}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.city}
                    label="City"
                    value={formData.city}
                    onChange={city => onChange({ ...formData, city })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <Select
                    errorMessage={errors.stateOrProvince}
                    label="State"
                    value={formData.stateOrProvince}
                    onChange={e =>
                        onChange({
                            ...formData,
                            stateOrProvince: e?.value,
                        })
                    }
                    options={[
                        { label: '', value: '' },
                        ...Object.keys(IStateOrProvinceType).map(s => ({
                            label: s,
                            value: s,
                        })),
                    ]}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.website}
                    label="Website"
                    value={formData.website}
                    onChange={website => onChange({ ...formData, website })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <Select
                    errorMessage={errors.size}
                    label="Business Size"
                    value={formData.size}
                    onChange={e => onChange({ ...formData, size: e?.value })}
                    options={[
                        { label: '', value: '' },
                        ...Object.keys(IBusinessSizeType).map(s => ({
                            label: businessSizeDisplay[getEnumFromString(IBusinessSizeType, s)],
                            value: s,
                        })),
                    ]}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    errorMessage={errors.yearFounded}
                    label="Year Founded"
                    value={formData.yearFounded}
                    onChange={yearFounded => onChange({ ...formData, yearFounded })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <CurrencyInput
                    errorMessage={errors.value}
                    label="Current Value"
                    value={formData.value ?? undefined}
                    onChange={value => onChange({ ...formData, value })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <AvatarInput
                    errorMessage={errors.avatar}
                    label="Logo"
                    onChange={onChangeAvatar}
                    placeholderIcon={faBuilding}
                    src={avatar}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <NumericInput
                    errorMessage={errors.sharesOutstanding}
                    label="Shares Outstanding"
                    value={formData.sharesOutstanding ?? undefined}
                    onChange={sharesOutstanding => onChange({ ...formData, sharesOutstanding })}
                />
            </div>
            <div className="col-span-6">
                <TextArea
                    errorMessage={errors.description}
                    label="About"
                    rows={6}
                    value={formData.description}
                    onChange={description => onChange({ ...formData, description })}
                />
            </div>
        </FieldSet>
    );
};

export default BusinessInfoForm;
