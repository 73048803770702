import { IBusiness } from '@api';
import { Button } from '~/components';
import { useHistory } from '~/routing';
import EmployeesUpload from '../business/employees/EmployeesUpload';

interface IProps {
    business: IBusiness;
}

const EmployeesUploadPage = ({ business }: IProps): JSX.Element => {
    const history = useHistory();

    return (
        <>
            <div className="z-10 top-0 sticky flex justify-between mb-12 pt-10 pb-6 bg-background border-b border-background2">
                <h2 className="text-2xl leading-none self-center">{business.name} Employee Upload</h2>
                <div className="self-center space-x-3">
                    <Button text="Cancel" onClick={() => history.goBack()} />
                </div>
            </div>
            <EmployeesUpload businessId={business.id} />
        </>
    );
};

export default EmployeesUploadPage;
