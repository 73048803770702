import { IAdvisorFirm, IBusinessPageType, IUserRoleType } from '@api';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Button,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Toggle,
} from '~/components';
import { useCurrentUser, useUpdateAdvisorFirm } from '~/hooks';
import {
    getInitialBusinessPageSettings,
    IPageSetting,
    reorder,
    togglePage,
} from '~/pages/business/edit/presentationSettings/PresentationSettings';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import { businessPageDisplay, businessPageIcon, businessPageIconColor } from '~/utils/enumUtils';

interface IPageTypeTableCellProps {
    pageType: IBusinessPageType;
}

const PageTypeTableCell = ({ pageType }: IPageTypeTableCellProps) => {
    return (
        <TableCell className="w-full text-sm leading-5">
            <FontAwesomeIcon
                fixedWidth
                className={`mr-4 text-${businessPageIconColor[pageType]}`}
                icon={businessPageIcon[pageType]}
            />
            {businessPageDisplay[pageType]}
        </TableCell>
    );
};

interface IProps {
    advisorFirm: IAdvisorFirm;
}

const PresentationSettings = ({ advisorFirm }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));

    const [isDisabled, setIsDisabled] = useState(false);
    const [pagesExcluded, setPagesExcluded] = useState<IBusinessPageType[]>(
        advisorFirm.pagesExcluded.sort((a, b) => businessPageDisplay[a].localeCompare(businessPageDisplay[b]))
    );
    const [pageSettings, setPageSettings] = useState<IPageSetting[]>(
        getInitialBusinessPageSettings(advisorFirm.pages, advisorFirm.pagesExcluded)
    );
    const updateAdvisorFirm = useUpdateAdvisorFirm(advisorFirm.id);
    const { isError, isLoading, data: currentUser } = useCurrentUser();
    if (isLoading) return <LoadingIndicator />;
    if (isError || !currentUser) return <ErrorPage />;

    const handleSave = async (pages: IPageSetting[], pagesExcluded: IBusinessPageType[]) => {
        try {
            setIsDisabled(true);
            await updateAdvisorFirm.mutateAsync({
                ...advisorFirm,
                pages: pages.filter(ps => ps.enabled).map(ps => ps.pageType),
                pagesExcluded,
            });
            showSuccess();
        } catch {
            showError();
        } finally {
            setIsDisabled(false);
        }
    };
    const onChange = async (pageType: IBusinessPageType) => {
        const originalPageSettings = [...pageSettings];
        try {
            const updatedPageSettings = togglePage(originalPageSettings, pageType);
            setPageSettings(updatedPageSettings);
            await handleSave(updatedPageSettings, pagesExcluded);
        } catch {
            setPageSettings(originalPageSettings);
        }
    };
    const onReorder = async (sourceIndex: number, destinationIndex: number) => {
        const originalPageSettings = [...pageSettings];
        try {
            const updatedPageSettings = reorder(originalPageSettings, sourceIndex, destinationIndex);
            setPageSettings(updatedPageSettings);
            await handleSave(updatedPageSettings, pagesExcluded);
        } catch {
            setPageSettings(originalPageSettings);
        }
    };
    const onExclude = async (pageType: IBusinessPageType) => {
        const originalPageSettings = [...pageSettings];
        const originalPagesExcluded = [...pagesExcluded];
        try {
            const updatedPagesExcluded = [...originalPagesExcluded, pageType].sort((a, b) =>
                businessPageDisplay[a].localeCompare(businessPageDisplay[b])
            );
            const updatedPageSettings = getInitialBusinessPageSettings(
                originalPageSettings.filter(p => p.enabled && p.pageType !== pageType).map(p => p.pageType),
                updatedPagesExcluded
            );
            setPageSettings(updatedPageSettings);
            setPagesExcluded(updatedPagesExcluded);
            await handleSave(updatedPageSettings, updatedPagesExcluded);
        } catch {
            setPageSettings(originalPageSettings);
            setPagesExcluded(originalPagesExcluded);
        }
    };
    const onInclude = async (pageType: IBusinessPageType) => {
        const originalPageSettings = [...pageSettings];
        const originalPagesExcluded = [...pagesExcluded];
        try {
            const updatedPagesExcluded = originalPagesExcluded.filter(p => p !== pageType);
            const updatedPageSettings = getInitialBusinessPageSettings(
                originalPageSettings.filter(p => p.enabled).map(p => p.pageType),
                updatedPagesExcluded
            );
            setPageSettings(updatedPageSettings);
            setPagesExcluded(updatedPagesExcluded);
            await handleSave(updatedPageSettings, updatedPagesExcluded);
        } catch {
            setPageSettings(originalPageSettings);
            setPagesExcluded(originalPagesExcluded);
        }
    };
    const isAdmin = currentUser.role === IUserRoleType.Admin;

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Firm Presentation Settings</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-textSubtle">
                Set the default pages to show in your new presentations. Click and grab to change the order of pages in
                your presentation.
                {isAdmin && <> Exclude a page to remove it from all presentations including existing businesses.</>}
            </p>
            <DragDropContext
                onDragEnd={result => result.destination && onReorder(result.source.index, result.destination.index)}
            >
                <Droppable droppableId="droppable">
                    {provided => (
                        <Table ref={provided.innerRef}>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell>New Presentation Defaults</TableHeaderCell>
                                    <TableHeaderCell>On/Off</TableHeaderCell>
                                    {isAdmin && <TableHeaderCell></TableHeaderCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageSettings.map((pageSetting, index) => (
                                    <Draggable
                                        key={pageSetting.pageType}
                                        draggableId={pageSetting.pageType}
                                        index={index}
                                        isDragDisabled={isDisabled}
                                    >
                                        {provided => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <TableCell>
                                                    <FontAwesomeIcon className="text-divider" icon={faGripVertical} />
                                                </TableCell>
                                                <PageTypeTableCell {...pageSetting} />
                                                <TableCell>
                                                    <Toggle
                                                        checked={pageSetting.enabled}
                                                        disabled={isDisabled}
                                                        id={pageSetting.pageType}
                                                        onChange={() => onChange(pageSetting.pageType)}
                                                    />
                                                </TableCell>
                                                {isAdmin && (
                                                    <TableCell className="text-right">
                                                        <Button
                                                            color="link"
                                                            disabled={isDisabled}
                                                            onClick={() => onExclude(pageSetting.pageType)}
                                                            text="Exclude"
                                                        />
                                                    </TableCell>
                                                )}
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        </Table>
                    )}
                </Droppable>
            </DragDropContext>
            {isAdmin && pagesExcluded.length > 0 && (
                <div className="mt-8">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Excluded From All Presentations</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pagesExcluded.map((pageType, index) => (
                                <TableRow key={`ExcludedPages_${index}`}>
                                    <PageTypeTableCell pageType={pageType} />
                                    <TableCell className="text-right">
                                        <Button
                                            color="link"
                                            disabled={isDisabled}
                                            onClick={() => onInclude(pageType)}
                                            text="Include"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
        </>
    );
};

export default PresentationSettings;
