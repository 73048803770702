import { IBizEquityBusiness, IBizEquityBusinessUpdateRequest, IBizEquityYearlyStatementUpdate } from '@api';
import { useState } from 'react';
import { Button, ErrorPage, LoadingIndicator } from '~/components';
import { useBizEquityRequiredFields, useUpdateBizEquityBusiness } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import FinancialGrid from './FinancialGrid';
import GrowthForm from './GrowthForm';
import { assetYearRows, incomeAndExpensesYearRows, liabilitiesYearRows, Step, steps } from './models';
import OperationsForm from './OperationsForm';
import Progress from './Progress';
import { validate } from './schemaValidation';

interface IProps {
    bizEquityBusiness: IBizEquityBusiness;
    businessId: string;
    canCancel?: boolean;
    canSkip?: boolean;
    onClose: () => void;
}

const currentYear = new Date().getFullYear();
const numberOfYear = 3;
const years = Array.from(Array(numberOfYear).keys()).map(i => (currentYear - i).toString());
const orderedSteps = steps.map(s => s.step);

const getInitialYearlyStatements = (
    bizEquityBusiness: IBizEquityBusiness
): { [key: string]: IBizEquityYearlyStatementUpdate } => {
    return years.reduce(
        (acc, year) => ({
            ...acc,
            [year]: {
                accountsPayables: bizEquityBusiness.yearlyStatements?.[year]?.accountsPayables,
                accountsReceivables: bizEquityBusiness.yearlyStatements?.[year]?.accountsReceivables,
                bankLoans: bizEquityBusiness.yearlyStatements?.[year]?.bankLoans,
                cash: bizEquityBusiness.yearlyStatements?.[year]?.cash,
                contingentLiabilities: bizEquityBusiness.yearlyStatements?.[year]?.contingentLiabilities,
                fixedAssets: bizEquityBusiness.yearlyStatements?.[year]?.fixedAssets,
                income: bizEquityBusiness.yearlyStatements?.[year]?.income,
                intangibleAssets: bizEquityBusiness.yearlyStatements?.[year]?.intangibleAssets,
                interestExpense: bizEquityBusiness.yearlyStatements?.[year]?.interestExpense,
                inventory: bizEquityBusiness.yearlyStatements?.[year]?.inventory,
                nonCashExpenses: bizEquityBusiness.yearlyStatements?.[year]?.nonCashExpenses,
                officerCompensation: bizEquityBusiness.yearlyStatements?.[year]?.officerCompensation,
                oneTimeExpenses: bizEquityBusiness.yearlyStatements?.[year]?.oneTimeExpenses,
                oneTimeRevenues: bizEquityBusiness.yearlyStatements?.[year]?.oneTimeRevenues,
                otherCurrentAssets: bizEquityBusiness.yearlyStatements?.[year]?.otherCurrentAssets,
                otherLongTermLiabilities: bizEquityBusiness.yearlyStatements?.[year]?.otherLongTermLiabilities,
                otherShortTermLiabilities: bizEquityBusiness.yearlyStatements?.[year]?.otherShortTermLiabilities,
                revenue: bizEquityBusiness.yearlyStatements?.[year]?.revenue,
            },
        }),
        {} as { [key: string]: IBizEquityYearlyStatementUpdate }
    );
};

const getInitialFormData = (bizEquityBusiness: IBizEquityBusiness): IBizEquityBusinessUpdateRequest => ({
    ebitdaMarginGrowth: bizEquityBusiness.ebitdaMarginGrowth || 0,
    insuranceCovered: bizEquityBusiness.insuranceCovered || false,
    intellectualProperty: bizEquityBusiness.intellectualProperty || false,
    ownerLeftImpact: bizEquityBusiness.ownerLeftImpact,
    realEstate: {
        area: bizEquityBusiness.realEstate?.area,
        monthlyRent: bizEquityBusiness.realEstate?.monthlyRent,
        mortgage: bizEquityBusiness.realEstate?.mortgage,
        ownership: bizEquityBusiness.realEstate?.ownership,
        ownershipParty: bizEquityBusiness.realEstate?.ownershipParty,
        trueMarketLevelMonthlyRent: bizEquityBusiness.realEstate?.trueMarketLevelMonthlyRent,
        value: bizEquityBusiness?.realEstate?.value,
    },
    recurringRevenue: bizEquityBusiness.recurringRevenue || 0,
    revenueGrowth: bizEquityBusiness.revenueGrowth || 0,
    topThreeCustomers: bizEquityBusiness.topThreeCustomers || 0,
    yearlyStatements: getInitialYearlyStatements(bizEquityBusiness),
});

const UpdateBizEquityBusinessWizard = ({
    bizEquityBusiness,
    businessId,
    canCancel = true,
    canSkip,
    onClose,
}: IProps): JSX.Element => {
    const [currentStep, setCurrentStep] = useState<Step>(Step.Income);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const { data: requiredFields, isLoading, isError } = useBizEquityRequiredFields();
    const [formData, setFormData] = useState<IBizEquityBusinessUpdateRequest>(getInitialFormData(bizEquityBusiness));
    const updateBizEquityBusiness = useUpdateBizEquityBusiness(businessId);
    const isLastStep = orderedSteps.indexOf(currentStep) === orderedSteps.length - 1;
    const [incomeValidationErrors, setIncomeValidationErrors] = useState<Record<string, string>>({});

    const onNextClick = () => {
        setIsSaving(true);

        if (currentStep === Step.Income && formData.yearlyStatements) {
            const validation = validate(formData.yearlyStatements);
            if (!validation.isValid) {
                setIncomeValidationErrors(validation.errors);
                setIsSaving(false);
                return;
            } else {
                setIncomeValidationErrors({});
            }
        }

        updateBizEquityBusiness
            .mutateAsync({ ...formData, hasCompletedDataEntry: bizEquityBusiness.hasCompletedDataEntry || isLastStep })
            .then(() => {
                if (isLastStep) {
                    onClose();
                } else {
                    setCurrentStep(orderedSteps[orderedSteps.indexOf(currentStep) + 1]);
                }
            })
            .catch(() => showError())
            .finally(() => setIsSaving(false));
    };

    if (isError) return <ErrorPage hideActions />;
    if (isLoading || !requiredFields) return <LoadingIndicator />;

    return (
        <>
            <div className="flex flex-col md:inline-flex md:flex-row align-bottom sm:align-middle w-full">
                <div className="flex flex-col flex-grow relative">
                    <div className="mt-7 mx-4 px-4 xl:mx-6 xl:px-7">
                        <h3 className="text-xl leading-6 font-medium text-textDefault">
                            {steps[orderedSteps.indexOf(currentStep)].displayName}
                        </h3>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-col px-4 pb-4 xl:p-6 md:flex-grow">
                            <div className="my-4 px-4 md:pr-7 xl:my-7 xl:px-7">
                                {
                                    {
                                        Income: (
                                            <FinancialGrid
                                                requiredFields={requiredFields}
                                                rows={incomeAndExpensesYearRows}
                                                formData={formData.yearlyStatements || {}}
                                                setFormData={yearlyStatements =>
                                                    setFormData({ ...formData, yearlyStatements })
                                                }
                                                validationErrors={incomeValidationErrors}
                                            />
                                        ),
                                        Assets: (
                                            <FinancialGrid
                                                requiredFields={requiredFields}
                                                rows={assetYearRows}
                                                formData={formData.yearlyStatements || {}}
                                                setFormData={yearlyStatements =>
                                                    setFormData({ ...formData, yearlyStatements })
                                                }
                                            />
                                        ),
                                        Liabilities: (
                                            <FinancialGrid
                                                requiredFields={requiredFields}
                                                rows={liabilitiesYearRows}
                                                formData={formData.yearlyStatements || {}}
                                                setFormData={yearlyStatements =>
                                                    setFormData({ ...formData, yearlyStatements })
                                                }
                                            />
                                        ),
                                        Growth: <GrowthForm formData={formData} setFormData={setFormData} />,
                                        Operations: <OperationsForm formData={formData} setFormData={setFormData} />,
                                    }[currentStep]
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-shrink-0 md:w-64 lg:w-80 xl:w-96 bg-background1 border-l border-background2 p-5 md:pl-0 flex flex-col space-y-8">
                    <div className="flex items-center flex-grow">
                        <Progress currentStep={currentStep} onClickStep={canSkip ? setCurrentStep : undefined} />
                    </div>
                    <div className="flex flex-row-reverse md:flex-col space-x-reverse space-x-4 md:space-y-4 md:pl-4">
                        <Button
                            fullWidth
                            color="primary"
                            disabled={isSaving}
                            text={isLastStep ? 'Finish' : 'Next'}
                            onClick={onNextClick}
                        />
                        {canCancel && currentStep === orderedSteps[0] && (
                            <Button fullWidth color="secondary" disabled={isSaving} onClick={onClose} text="Cancel" />
                        )}
                        {currentStep !== orderedSteps[0] && (
                            <Button
                                fullWidth
                                color="secondary"
                                disabled={isSaving}
                                onClick={() => setCurrentStep(orderedSteps[orderedSteps.indexOf(currentStep) - 1])}
                                text="Back"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateBizEquityBusinessWizard;
