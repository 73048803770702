const {
    REACT_APP_API_BASE_URL,
    REACT_APP_OKTA_AUTH_SERVER,
    REACT_APP_OKTA_CLIENT_ID,
    REACT_APP_OKTA_DOMAIN,
    REACT_APP_OKTA_SCOPE,
} = process.env;

const config = {
    api: {
        baseUrl: REACT_APP_API_BASE_URL || '',
    },
    env: { ...process.env },
    okta: {
        authServer: REACT_APP_OKTA_AUTH_SERVER || '',
        clientId: REACT_APP_OKTA_CLIENT_ID || '',
        domain: REACT_APP_OKTA_DOMAIN || '',
        scope: REACT_APP_OKTA_SCOPE || '',
    },
};

export default config;
