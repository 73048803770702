import classNames from 'classnames';
import { ReactNode } from 'react';
import { IPanelProps, ITabsProps, Tabs } from './Tabs';

interface ITabProps {
    children: ReactNode;
    className?: string;
    tabId: string;
}

const Tab = ({ children, className = '', tabId }: ITabProps): JSX.Element => (
    <Tabs.Tab
        activeClassName="bg-background2"
        className={classNames([`whitespace-nowrap py-6 px-4 border-b border-t border-background2`, className])}
        inactiveClassName="hover:bg-highlight"
        tabId={tabId}
    >
        {children}
    </Tabs.Tab>
);

interface ITabFlowElementProps {
    children: ReactNode;
    className?: string;
}

const TabFlowElement = ({ children, className = '' }: ITabFlowElementProps): JSX.Element => (
    <Tabs.TabFlowElement className={className}>{children}</Tabs.TabFlowElement>
);

interface ITabsComposition {
    Tab: React.FC<ITabProps>;
    TabFlowElement: React.FC<ITabFlowElementProps>;
    Panel: React.FC<IPanelProps>;
}

const VerticalTabs: React.FC<ITabsProps> & ITabsComposition = ({ activeTabId, children }: ITabsProps) => (
    <Tabs
        activeTabId={activeTabId}
        containerClassName="flex flex-row h-full"
        tabContainerClassName="flex flex-col h-full border-r border-background2"
        tabType={VerticalTabs.Tab}
        tabFlowElementType={VerticalTabs.TabFlowElement}
    >
        {children}
    </Tabs>
);

VerticalTabs.Tab = Tab;
VerticalTabs.TabFlowElement = TabFlowElement;
VerticalTabs.Panel = Tabs.Panel;

export { VerticalTabs };
