import {
    RadioButton as EnvRadioButton,
    RadioButtonGroup as EnvRadioButtonGroup,
} from '@envestnet/envreact-component-library';
import classNames from 'classnames';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { FieldSetContext } from './FieldSet';

export interface IRadioOption {
    label: string;
    value: string;
}

interface IProps extends Omit<ComponentPropsWithoutRef<typeof EnvRadioButtonGroup>, 'className' | 'onChange'> {
    className?: string;
    errorMessage?: string;
    onChange: (value: string) => void;
    options: IRadioOption[];
    vertical?: boolean;
}

const RadioGroup = ({ className, errorMessage, onChange, options, vertical = false, ...rest }: IProps): JSX.Element => {
    const fieldSetContext = useContext(FieldSetContext) || {};
    return (
        <div className={className}>
            <EnvRadioButtonGroup
                error={errorMessage}
                onChange={value => onChange(value)}
                {...rest}
                disabled={fieldSetContext.disabled || rest.disabled}
            >
                <div
                    className={classNames(
                        'flex flex-wrap',
                        { 'space-x-2': !vertical },
                        { 'flex-col space-y-2': vertical }
                    )}
                >
                    {options.map(option => (
                        <EnvRadioButton key={`radio-button-option-${option.value}`} {...option} />
                    ))}
                </div>
            </EnvRadioButtonGroup>
        </div>
    );
};

export { RadioGroup };
