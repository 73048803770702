import { IBizEquityBusiness } from '@api';
import { SeriesOptionsType } from 'highcharts';
import { getDataOverTime, getTotalDebtOverTime } from './chartUtils';
import MetricsChart from './MetricsChart';

interface IProps {
    bizEquityBusiness: IBizEquityBusiness;
}

const toChartSeries = (bizEquityBusiness: IBizEquityBusiness): SeriesOptionsType[] | null => {
    const statements = bizEquityBusiness.yearlyStatements;
    if (!statements) {
        return null;
    }

    const income = getDataOverTime(statements, 'income');
    const receivables = getDataOverTime(statements, 'accountsReceivables');
    const inventory = getDataOverTime(statements, 'inventory');
    const fixedAssets = getDataOverTime(statements, 'fixedAssets');
    const totalDebt = getTotalDebtOverTime(statements);

    if (!income || !receivables || !inventory || !fixedAssets || !totalDebt) {
        return null;
    }

    const data: SeriesOptionsType[] = [
        {
            name: 'Inventory',
            color: 'var(--color-info-F)',
            data: inventory,
            type: 'line',
        },
        {
            name: 'Total Debt',
            color: 'var(--color-info-B)',
            data: totalDebt,
            type: 'line',
        },
        {
            name: 'Income',
            color: 'var(--color-info-D)',
            data: income,
            type: 'line',
        },
        {
            name: 'Fixed Assets',
            color: 'var(--color-info-E)',
            data: fixedAssets,
            type: 'line',
        },
        {
            name: 'Receivables',
            color: 'var(--color-info-G)',
            data: receivables,
            type: 'line',
        },
    ];
    return data;
};

const FinancialMetricsChart = ({ bizEquityBusiness }: IProps): JSX.Element => {
    return (
        <MetricsChart
            description="This chart shows your financial metrics compared to Pretax Income. Please provide three years of Pretax Income, Accounts Receivables, Inventory, Fixed Assets and Total Debt information to view."
            series={toChartSeries(bizEquityBusiness)}
            title="Financial Metrics Compared to Pretax Income"
        />
    );
};

export default FinancialMetricsChart;
