import { useEffect, useState } from 'react';
import { Button, Slider } from '~/components';
import { IAnswer, IQuestion } from '~/components/surveys/models';
import DiscreteProgressBar from '../../pages/business/present/bonusRight/unconnected/DiscreteProgressBar';

interface IProps {
    currentAnswer: IAnswer;
    currentQuestion: IQuestion;
    currentQuestionIndex: number;
    numberOfQuestions: number;
    onBack: (answer: number) => void;
    onNext: (answer: number) => void;
    surveyName: string;
}

const Question = ({
    currentAnswer,
    currentQuestion,
    currentQuestionIndex,
    numberOfQuestions,
    onBack,
    onNext,
    surveyName,
}: IProps): JSX.Element => {
    const [currentAnswerValue, setCurrentAnswerValue] = useState<number>(currentAnswer.value);

    useEffect(() => {
        setCurrentAnswerValue(currentAnswer.value);
    }, [currentAnswer]);

    return (
        <>
            <div>
                <h2 className="text-xl mb-2">{surveyName}</h2>
                <div>
                    <DiscreteProgressBar max={numberOfQuestions} value={currentQuestionIndex + 1} />
                </div>
                <h3 className="text-xl md:text-2xl mb-3 mt-6">{currentQuestion.header}</h3>
                <p>{currentQuestion.content}</p>
            </div>
            <div>
                <p className="text-6xl md:text-9xl text-center">{currentAnswerValue}</p>
            </div>
            <div>
                <Slider min={0} max={10} onChange={value => setCurrentAnswerValue(value)} value={currentAnswerValue} />
                <div className="flex justify-between mb-4 mt-5">
                    <p className="text-sm">Not Even Close</p>
                    <p className="text-sm">Describes Perfectly</p>
                </div>
                <div className="flex justify-between">
                    <Button
                        disabled={currentQuestionIndex === 0}
                        color="secondary"
                        text="Back"
                        onClick={() => onBack(currentAnswerValue)}
                    />
                    <Button color="primary" text="Next" onClick={() => onNext(currentAnswerValue)} />
                </div>
            </div>
        </>
    );
};

export default Question;
