import { IBizEquityBusinessConnectRequest, IBizEquityBusinessCreateRequest, IBusiness } from '@api';
import { useState } from 'react';
import { Button } from '~/components';
import { useConnectBizEquityBusiness, useCreateBizEquityBusiness } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import background from '../background.jpg';
import bizEquityLogo from '../bizEquityLogo.svg';
import BusinessSelect from './BusinessSelect';
import CreateBusinessForm from './CreateBusinessForm';

interface IProps {
    business: IBusiness;
}

const UnconnectedPage = ({ business }: IProps): JSX.Element => {
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [bizEquityBusinessId, setBizEquityBusinessId] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const connectBizEquityBusiness = useConnectBizEquityBusiness(business.id);
    const createBizEquityBusiness = useCreateBizEquityBusiness(business.id);
    const onConnectBizEquityBusiness = (connectRequest: IBizEquityBusinessConnectRequest) => {
        setIsSaving(true);
        connectBizEquityBusiness
            .mutateAsync(connectRequest)
            .then(() => {
                setIsSaving(false);
            })
            .catch(() => {
                showError();
                setIsSaving(false);
            });
    };
    const onCreateBizEquityBusiness = (createRequest: IBizEquityBusinessCreateRequest) => {
        setIsSaving(true);
        createBizEquityBusiness
            .mutateAsync(createRequest)
            .then(() => {
                setIsSaving(false);
            })
            .catch(() => {
                showError();
                setIsSaving(false);
            });
    };

    return (
        <>
            <div className="grid xl:grid-cols-2 h-full">
                <div className="flex flex-col items-center justify-center max-w-md mx-auto py-4 space-y-8">
                    <img className="mx-auto w-80 white-logo" src={bizEquityLogo} alt="Biz Equity Logo" />
                    <div className="flex flex-col w-full space-y-8">
                        {isAddingNew ? (
                            <CreateBusinessForm
                                businessName={business.name}
                                isCreatingBusiness={isSaving}
                                onCreateBizEquityBusiness={onCreateBizEquityBusiness}
                            />
                        ) : (
                            <>
                                <Button
                                    color="primary"
                                    text={`Add ${business.name} to BizEquity`}
                                    onClick={() => setIsAddingNew(true)}
                                />
                                <div className="text-center">Or</div>
                                <div className="flex">
                                    <BusinessSelect
                                        className="flex-grow mr-2"
                                        disabled={isSaving}
                                        bizEquityBusinessId={bizEquityBusinessId}
                                        onChange={setBizEquityBusinessId}
                                    />
                                    <Button
                                        disabled={!bizEquityBusinessId || isSaving}
                                        text="Link"
                                        onClick={() =>
                                            bizEquityBusinessId && onConnectBizEquityBusiness({ bizEquityBusinessId })
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div
                    className="bg-center bg-cover bg-no-repeat hidden xl:block"
                    style={{ backgroundImage: `url(${background})` }}
                />
            </div>
        </>
    );
};

export default UnconnectedPage;
