import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

type SupportedColor = 'green' | 'yellow';

interface IProps {
    className?: string;
    color: SupportedColor;
    icon: IconDefinition;
    label: string;
}

const colors: Record<SupportedColor, string> = {
    green: 'border-positive text-positive',
    yellow: 'border-caution text-caution',
};

const LegendItem = ({ className, color, icon, label }: IProps): JSX.Element => {
    return (
        <div
            className={classNames(
                className,
                colors[color],
                'border inline-block px-2 py-1 rounded-l-full rounded-r-full space-x-2 text-sm'
            )}
        >
            <span>
                <FontAwesomeIcon icon={icon} />
            </span>
            <span>{label}</span>
        </div>
    );
};

export default LegendItem;
