import { IInnerZoneEmployeeProfile, IInnerZoneEmployeeProfileType } from '@api';
import { BreakdownChart } from '~/components';
import ProfileTypePercentageCard from './ProfileTypePercentageCard';

interface IProps {
    profile: IInnerZoneEmployeeProfile;
}

const EmployeeProfile = ({ profile }: IProps): JSX.Element => {
    const communicationParagraphs = profile?.descriptions?.communication.split('\n\n');

    return (
        <>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 pb-6 border-b border-background2">
                {[
                    IInnerZoneEmployeeProfileType.Thinker,
                    IInnerZoneEmployeeProfileType.BridgeBuilder,
                    IInnerZoneEmployeeProfileType.Energizer,
                    IInnerZoneEmployeeProfileType.BottomLiner,
                ].map(profileType => (
                    <ProfileTypePercentageCard
                        key={profileType}
                        percentage={profile.percentages[profileType]}
                        profileType={profileType}
                    />
                ))}
            </div>
            <div className="pt-6 pb-3 border-b border-background2">
                <div className="mb-2 text-2xl leading-8">Communication Projection</div>
                {communicationParagraphs?.map((p, i) => (
                    <p key={`communicationDescription_${i}`} className="pb-3">
                        {p}
                    </p>
                ))}
            </div>
            <div className="py-6 border-b border-background2">
                <div className="mb-2 text-2xl leading-8">Direct vs. Subtle</div>
                <p className="mb-2">{profile.descriptions.subtleDirectSection1}</p>
                <BreakdownChart
                    point1={{ color: 'affirmation', name: 'Direct', value: profile.secondaryStyles.direct }}
                    point2={{ color: 'information', name: 'Subtle', value: profile.secondaryStyles.subtle }}
                    valueFormatter={value => `${value}%`}
                />
                <p className="mt-2">{profile.descriptions.subtleDirectSection2}</p>
            </div>
            <div className="py-6">
                <div className="mb-2 text-2xl leading-8">Task vs. Personable</div>
                <p className="mb-2">{profile.descriptions.personableTaskSection1}</p>
                <BreakdownChart
                    point1={{ color: 'affirmation', name: 'Task', value: profile.secondaryStyles.task }}
                    point2={{
                        color: 'information',
                        name: 'Personable',
                        value: profile.secondaryStyles.personable,
                    }}
                    valueFormatter={value => `${value}%`}
                />
                <p className="mt-2">{profile.descriptions.personableTaskSection2}</p>
            </div>
        </>
    );
};

export default EmployeeProfile;
