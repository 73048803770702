import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Step, steps } from './models';

interface IProps {
    currentStep: Step;
    onClickStep?: (step: Step) => void;
}
const orderedSteps = steps.map(s => s.step);

const CurrentIndicator = (): JSX.Element => (
    <span className="relative z-10 w-8 h-8 -mx-4 flex items-center justify-center bg-textDefault border-2 border-positive rounded-full">
        <span className="h-2.5 w-2.5 bg-positive rounded-full" />
    </span>
);

const DoneIndicator = (): JSX.Element => (
    <span className="relative z-10 w-8 h-8 -mx-4 flex items-center justify-center bg-information border-2 border-information rounded-full">
        <FontAwesomeIcon icon={faCheck} className="w-5 h-5 text-textDefault" aria-hidden="true" />
    </span>
);

const NextIndicator = (): JSX.Element => (
    <span className="relative z-10 w-8 h-8 -mx-4 flex items-center justify-center bg-background1 border-2 border-background2 rounded-full group-hover:border-highlight">
        <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-background2" />
    </span>
);

const Progress = ({ currentStep, onClickStep }: IProps): JSX.Element => {
    return (
        <div className="flex flex-col space-y-10">
            {steps.map(s => (
                <div
                    className={classNames('flex space-x-8', { 'cursor-pointer': !!onClickStep })}
                    key={s.step}
                    onClick={onClickStep ? () => onClickStep(s.step) : undefined}
                >
                    {currentStep === s.step && <CurrentIndicator />}
                    {orderedSteps.indexOf(s.step) < orderedSteps.indexOf(currentStep) && <DoneIndicator />}
                    {orderedSteps.indexOf(s.step) > orderedSteps.indexOf(currentStep) && <NextIndicator />}
                    <div>
                        <div className="font-semibold text-xs uppercase">{s.displayName}</div>
                        <div className="text-sm">{s.description}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Progress;
