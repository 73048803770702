import { IBizEquityBusiness, IBusiness } from '@api';
import { useState } from 'react';
import { Button, tabContainerClassName } from '~/components';
import { Redirect, Route, Switch, useHistory, useParams } from '~/routing';
import bizEquityLogo from '../bizEquityLogo.svg';
import ManualEntryModal from '../valuation/ManualEntryModal';
import KpiPage from './kpi';
import NavTab from './NavTab';
import OverviewPage from './overview';

interface ITabOptions {
    business: IBusiness;
}

interface IParams {
    tabId?: string;
}

export enum TabId {
    KPI = 'KPI',
    Overview = 'Overview',
}

const sanitizeTabId = (tabId: string | undefined): TabId => {
    if (typeof tabId === undefined) return TabId.Overview;
    return TabId[tabId as keyof typeof TabId] ?? TabId.Overview;
};

const TabOptions = ({ business }: ITabOptions) => {
    const tabId = sanitizeTabId(useParams<IParams>().tabId);
    const history = useHistory();
    const navigate = (tabId: TabId) => () => history.push(`/${business.id}/Present/BizEquity/${tabId}`);
    return (
        <nav aria-label="Tabs" className={tabContainerClassName}>
            <NavTab isActive={tabId === TabId.Overview} onClick={navigate(TabId.Overview)}>
                Overview
            </NavTab>
            <NavTab isActive={tabId === TabId.KPI} onClick={navigate(TabId.KPI)}>
                KPI
            </NavTab>
        </nav>
    );
};

const TabContent = ({ business, bizEquityBusiness }: ITabContent) => {
    return (
        <Switch>
            <Route path={`/:businessId/Present/BizEquity/${TabId.KPI}`}>
                <KpiPage bizEquityBusiness={bizEquityBusiness} />
            </Route>
            <Route path={`/:businessId/Present/BizEquity/${TabId.Overview}`}>
                <OverviewPage business={business} bizEquityBusiness={bizEquityBusiness} />
            </Route>
            <Redirect to={`/${business.id}/Present/BizEquity/${TabId.Overview}`} />
        </Switch>
    );
};

interface IProps {
    business: IBusiness;
    bizEquityBusiness: IBizEquityBusiness;
}

interface ITabContent {
    business: IBusiness;
    bizEquityBusiness: IBizEquityBusiness;
}

const BizEquityViews = ({ bizEquityBusiness, business }: IProps): JSX.Element => {
    const [manualEntryModalOpen, setManualEntryModalOpen] = useState(false);

    return (
        <div className="flex flex-col h-full p-10 relative">
            <div className="sm:absolute right-10 flex items-center justify-end">
                <Button color="secondary" text="Re-run Valuation" onClick={() => setManualEntryModalOpen(true)} />
                <img className="ml-5 h-6 white-logo" src={bizEquityLogo} alt="Biz Equity Logo" />
            </div>
            <Route path="/:businessId/Present/BizEquity/:tabId?">
                <TabOptions business={business} />
            </Route>
            <TabContent business={business} bizEquityBusiness={bizEquityBusiness} />
            <ManualEntryModal
                bizEquityBusiness={bizEquityBusiness}
                businessId={business.id}
                canSkip
                isOpen={manualEntryModalOpen}
                onClose={() => setManualEntryModalOpen(false)}
            />
        </div>
    );
};

export default BizEquityViews;
