import { useState } from 'react';
import { Button, DeleteConfirmationModal } from '~/components';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';

interface IProps {
    modelTitle: string;
    onConfirm: () => Promise<void>;
}

const ResetButton = ({ modelTitle, onConfirm }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const [deleteState, setDeleteState] = useState<{ isOpen: boolean; isSaving: boolean }>({
        isOpen: false,
        isSaving: false,
    });

    const onConfirmDelete = async () => {
        try {
            setDeleteState({ ...deleteState, isSaving: true });
            await onConfirm();
        } catch {
            showError();
        } finally {
            setDeleteState({ isOpen: false, isSaving: false });
        }
    };

    return (
        <div className="self-start mt-auto">
            <Button color="primary" text="Reset" onClick={() => setDeleteState({ ...deleteState, isOpen: true })} />
            <DeleteConfirmationModal
                onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                onConfirm={onConfirmDelete}
                title={`Delete ${modelTitle}`}
                {...deleteState}
            >
                Are you sure you want to delete this {modelTitle}? All of your data will be permanently removed from our
                servers forever. This action cannot be undone.
            </DeleteConfirmationModal>
        </div>
    );
};

export default ResetButton;
