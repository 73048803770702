import { IBusinessPageType } from '@api';
import { HorizontalTabs as Tabs, Modal } from '~/components';
import { businessPageDisplay } from '~/utils/enumUtils';
import sampleForecast from './sample-forecast.png';
import samplePrograms from './sample-programs.png';
import sampleRewards from './sample-rewards.png';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const SampleCaseModal = ({ open, setOpen }: IProps): JSX.Element | null => {
    return (
        <Modal maxWidth="7xl" open={open} setOpen={setOpen} title="Sample Data">
            <div className="p-3 pt-0 w-full overflow-x-auto" style={{ minHeight: '50vh' }}>
                <Tabs activeTabId={IBusinessPageType.BonusRight} containerClassName="h-full">
                    <Tabs.Tab tabId={IBusinessPageType.BonusRight}>{businessPageDisplay.BonusRight}</Tabs.Tab>
                    <Tabs.Tab tabId={IBusinessPageType.BusinessForecast}>
                        {businessPageDisplay.BusinessForecast}
                    </Tabs.Tab>
                    <Tabs.Tab tabId={IBusinessPageType.ExecutivePrograms}>
                        {businessPageDisplay.ExecutivePrograms}
                    </Tabs.Tab>
                    <Tabs.Panel tabId={IBusinessPageType.BonusRight}>
                        <img
                            alt="preview of employee rewards page"
                            className="object-scale-down"
                            src={sampleRewards}
                        ></img>
                    </Tabs.Panel>
                    <Tabs.Panel tabId={IBusinessPageType.BusinessForecast}>
                        <img
                            alt="preview of business forecast page"
                            className="object-scale-down"
                            src={sampleForecast}
                        ></img>
                    </Tabs.Panel>
                    <Tabs.Panel tabId={IBusinessPageType.ExecutivePrograms}>
                        <img
                            alt="preview of executive programs page"
                            className="object-scale-down"
                            src={samplePrograms}
                        ></img>
                    </Tabs.Panel>
                </Tabs>
            </div>
        </Modal>
    );
};

export default SampleCaseModal;
