import { IBusiness, IEmployee } from '@api';
import { getEquityPercentageByEmployeeId } from '~/utils/businessUtils';
import { employeeToOwner } from './models';
import getVotingEquityPercentage from './getVotingEquityPercentage';

const getCorporateStrategyOwners = (
    business: Pick<IBusiness, 'primaryEmployeeId' | 'sharesOutstanding'>,
    employees: IEmployee[]
) => {
    const equityPercentageByEmployeeId = getEquityPercentageByEmployeeId(business, employees);
    const votingEquityPercentage = getVotingEquityPercentage(employees, equityPercentageByEmployeeId);

    return employees
        .filter(e => (e.shares ?? 0) > 0)
        .sort(e => (e.isOwner ? -1 : 1))
        .sort(e => (e.id === business.primaryEmployeeId ? -1 : 1))
        .map(e => employeeToOwner(e, equityPercentageByEmployeeId, votingEquityPercentage));
};

export default getCorporateStrategyOwners;
