import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, IModalProps, Modal, NumericInput } from '~/components';
import { transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';

interface IProps extends Pick<IModalProps, 'open'> {
    onClose: () => void;
    onSubmit: (years: number) => void;
}

const schemaValidation = Yup.object().shape({
    years: Yup.number()
        .label('Years')
        .min(0)
        .max(50)
        .nullable(true)
        .positive()
        .required()
        .transform(transformEmptyStringToNull),
});

const CustomAgePreviewModal = ({ onClose, onSubmit, open }: IProps): JSX.Element => {
    const [agePreviewInput, setAgePreviewInput] = useState<number | null>(null);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

    const onSave = () => {
        try {
            schemaValidation.validateSync({ years: agePreviewInput }, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        onClose();
        onSubmit(agePreviewInput ?? 0);
    };

    useEffect(() => {
        if (open) {
            setValidationErrors({});
            setAgePreviewInput(null);
        }
    }, [open]);

    return (
        <Modal maxWidth="sm" open={open} setOpen={onClose} title="Custom Age Advance">
            <div className="p-6">
                <NumericInput
                    errorMessage={validationErrors.years}
                    label="Years"
                    onChange={years => setAgePreviewInput(years)}
                    value={agePreviewInput ?? undefined}
                />
                <div className="mt-4 flex flex-row-reverse">
                    <Button className="ml-3" color="primary" text="Update" onClick={onSave} />
                    <Button text="Cancel" onClick={onClose} />
                </div>
            </div>
        </Modal>
    );
};

export default CustomAgePreviewModal;
