import { IBusiness } from '@api';
import { businessPageDisplay } from '~/utils/enumUtils';
import Card from '../Card';
import Header from '../Header';
import TeamValuesCardContent from './TeamValuesCardContent';

interface IProps {
    business: IBusiness;
}

const TeamValuesCard = ({ business }: IProps): JSX.Element => {
    return (
        <Card padding>
            <Header text={businessPageDisplay.TeamValues} />
            <TeamValuesCardContent business={business} />
        </Card>
    );
};

export default TeamValuesCard;
