import Highcharts, { PlotOptions, SeriesOptionsType, XAxisOptions, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NumberFormatter from '~/utils/numberFormatter';

interface IProps {
    height: number;
    plotOptions?: PlotOptions;
    series: Array<SeriesOptionsType>;
    xAxis?: XAxisOptions;
    yAxis?: YAxisOptions;
}

const ColumnChart = ({ height, plotOptions, series, xAxis, yAxis }: IProps): JSX.Element => {
    const options: Highcharts.Options = {
        chart: {
            backgroundColor: 'transparent',
            height,
            type: 'column',
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            ...plotOptions,
            series: {
                animation: false,
                ...plotOptions?.series,
            },
        },
        series,
        title: {
            text: '',
        },
        tooltip: {
            backgroundColor: 'var(--color-background1)',
            borderRadius: 8,
            formatter: function () {
                return '<b class="text-textDefault fill-current">' + NumberFormatter.formatNumber(this.y) + '</b>';
            },
        },
        xAxis: {
            ...xAxis,
            labels: {
                ...xAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '0.875rem',
                    ...xAxis?.labels?.style,
                },
            },
            lineColor: 'var(--color-highlight)',
            tickLength: 0,
        },
        yAxis: {
            ...yAxis,
            gridLineColor: 'var(--color-background2)',
            labels: {
                ...yAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '0.875rem',
                    ...yAxis?.labels?.style,
                },
            },
            title: {
                text: null,
                style: {
                    color: 'var(--color-textDefault)',
                },
                ...yAxis?.title,
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export { ColumnChart };
