import { IBusiness, IBusinessPageType } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import { useBonusRightConnectionStatus } from '~/hooks/bonusRightConnection';
import { Route, Switch, useRouteMatch } from '~/routing';
import ExecutivePrograms from './executivePrograms';
import UnconnectedExecutivePrograms from './executivePrograms/unconnected';
import UnconnectedPage from './unconnected';

interface IProps {
    business: IBusiness;
}

const ExecutiveProgramsPage = ({ business }: IProps): JSX.Element => {
    const match = useRouteMatch();
    const employeesQuery = useEmployees(business.id);
    const connectionStatusQuery = useBonusRightConnectionStatus(
        business.id,
        !business.isSampleCase && !employeesQuery.isLoading
    );
    const isLoading = isTrueForAnyQuery('isLoading', connectionStatusQuery, employeesQuery);
    const isError = isTrueForAnyQuery('isError', connectionStatusQuery, employeesQuery);
    if (isLoading) return <LoadingIndicator />;
    if (isError) return <ErrorPage />;

    const { data: connectionStatus = { isConnected: business.isSampleCase } } = connectionStatusQuery;

    return connectionStatus.isConnected ? (
        <ExecutivePrograms business={business} employees={employeesQuery.data ?? []} />
    ) : (
        <Switch>
            <Route exact={false} path={`${match.path}/Unconnected`}>
                <UnconnectedPage
                    business={business}
                    businessPageType={IBusinessPageType.ExecutivePrograms}
                    showBackLink
                />
            </Route>
            <Route path={match.path}>
                <UnconnectedExecutivePrograms business={business} employees={employeesQuery.data ?? []} />
            </Route>
        </Switch>
    );
};

export default ExecutiveProgramsPage;
