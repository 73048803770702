import { IUserRoleType } from '@api';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAuth, useCurrentUser } from '~/hooks';
import { IEditPageType } from '~/pages/business/edit';
import { ILocationState } from '~/pages/ILocationState';
import { NavLink, useLocation } from '~/routing';
import { defaultTransitionDuration } from '~/utils/transitions';
import { Avatar } from './Avatar';

export const CurrentUserMenu = (): JSX.Element | null => {
    const { logout } = useAuth();
    const [showDropdown, setShowDropdown] = useState(false);
    const { isLoading, error, data: currentUser } = useCurrentUser();
    const locationState: ILocationState = {
        backUrl: useLocation().pathname,
    };

    const dropdownTransitionStyles = {
        entering: { opacity: 1, scale: 100 },
        entered: { opacity: 1, scale: 100 },
        exiting: { opacity: 0, scale: 95 },
        exited: { display: 'none', opacity: 0, scale: 95 },
        unmounted: { display: 'none', opacity: 0, scale: 95 },
    };

    if (isLoading) return null;
    if (error) return null;
    if (!currentUser) return null;

    return (
        <div>
            <button
                className="focus:outline-none"
                onClick={() => setShowDropdown(!showDropdown)}
                onBlur={() => setShowDropdown(false)}
            >
                <div className="flex flex-row cursor-pointer">
                    <div className="flex flex-col justify-center md:mr-4">
                        <Avatar src={currentUser.avatar || undefined} />
                    </div>
                    <div className="hidden md:flex flex-col justify-center font-bold text-lg">
                        <div>
                            {currentUser.firstName} {currentUser.lastName}
                        </div>
                    </div>
                </div>
            </button>
            <CSSTransition in={showDropdown} timeout={defaultTransitionDuration}>
                {state => (
                    <div
                        className="origin-top-right z-40 absolute right-0 w-48 rounded-md shadow-lg py-2 bg-background1 ring-1 ring-background ring-opacity-5 divide-y divide-background2 focus:outline-none cursor-pointer"
                        style={dropdownTransitionStyles[state]}
                    >
                        {currentUser.role === IUserRoleType.Admin && (
                            <div>
                                <div className="py-1" role="none">
                                    <NavLink
                                        to="/"
                                        className="block px-4 py-2 text-sm hover:bg-background2"
                                        role="menuItem"
                                    >
                                        Back to Firm List
                                    </NavLink>
                                </div>
                            </div>
                        )}
                        {currentUser.role === IUserRoleType.Advisor && (
                            <div>
                                <div className="py-1" role="none">
                                    <NavLink
                                        to="/settings/Me"
                                        className="block px-4 py-2 text-sm hover:bg-background2"
                                        role="menuItem"
                                    >
                                        My Info
                                    </NavLink>
                                </div>
                                <div className="py-1" role="none">
                                    <NavLink
                                        to="/settings"
                                        className="block px-4 py-2 text-sm hover:bg-background2"
                                        role="menuItem"
                                    >
                                        Tools Settings
                                    </NavLink>
                                </div>
                                <div className="py-1" role="none">
                                    <NavLink
                                        to="/"
                                        className="block px-4 py-2 text-sm hover:bg-background2"
                                        role="menuItem"
                                    >
                                        Back to Business List
                                    </NavLink>
                                </div>
                            </div>
                        )}
                        {currentUser.role === IUserRoleType.BusinessOwner && (
                            <div>
                                <div className="py-1" role="none">
                                    <NavLink
                                        to={{
                                            pathname: `/${currentUser.businessId}/Edit`,
                                            state: locationState,
                                        }}
                                        className="block px-4 py-2 text-sm hover:bg-background2"
                                        role="menuItem"
                                    >
                                        Edit Business
                                    </NavLink>
                                </div>
                                <div className="py-1" role="none">
                                    <NavLink
                                        to={{
                                            pathname: `/${currentUser.businessId}/Edit/${IEditPageType.Employees}`,
                                            state: locationState,
                                        }}
                                        className="block px-4 py-2 text-sm hover:bg-background2"
                                        role="menuItem"
                                    >
                                        Edit Employees
                                    </NavLink>
                                </div>
                                <div className="py-1" role="none">
                                    <NavLink
                                        to={`/${currentUser.businessId}`}
                                        className="block px-4 py-2 text-sm hover:bg-background2"
                                        role="menuItem"
                                    >
                                        Back to Dashboard
                                    </NavLink>
                                </div>
                            </div>
                        )}
                        <div className="py-1" role="none">
                            <div
                                className="block px-4 py-2 text-sm hover:bg-background2"
                                onClick={() => logout()}
                                role="menuitem"
                            >
                                Sign Out
                            </div>
                        </div>
                    </div>
                )}
            </CSSTransition>
        </div>
    );
};
