import { IBusiness, IBusinessValuation, IEmployee } from '@api';
import { useBoundingClientRect } from '~/hooks';
import { getEquityPercentageByEmployeeId } from '~/utils/businessUtils';
import CurrencyFormatter from '~/utils/currencyFormatter';
import NameFormatter from '~/utils/nameFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import { entityCardWidth } from '../corporateStrategies/constants';
import EntityCard from './EntityCard';
import { SuccessionPlanType } from './models';
import PlanIllustrationEquityTransfer from './PlanIllustrationEquityTransfer';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    deceasedOwner: IEmployee;
    employees: IEmployee[];
    otherOwners: IEmployee[];
    successionPlanType: SuccessionPlanType;
}

const getEstateValue = (
    successionPlanType: SuccessionPlanType,
    deceasedEquityPercentage: number,
    deceasedEquityAmount: number
): string => {
    switch (successionPlanType) {
        case SuccessionPlanType.NoPlan:
            return formatPercentage(deceasedEquityPercentage);
        case SuccessionPlanType.CrossPurchase:
        case SuccessionPlanType.StockRedemption:
            return CurrencyFormatter.format(deceasedEquityAmount);
    }
};

const getOwnerEquityPercentage = (
    successionPlanType: SuccessionPlanType,
    currentEquityPercentage: number,
    deceasedEquityPercentage: number,
    otherOwnerCount: number
): number => {
    switch (successionPlanType) {
        case SuccessionPlanType.NoPlan:
            return currentEquityPercentage;
        case SuccessionPlanType.CrossPurchase:
        case SuccessionPlanType.StockRedemption:
            return currentEquityPercentage + deceasedEquityPercentage / otherOwnerCount;
    }
};

const successionEntityWidth = `${entityCardWidth} 2xl:w-80`;

const PlanIllustration = ({
    business,
    businessValuation,
    deceasedOwner,
    employees,
    otherOwners,
    successionPlanType,
}: IProps): JSX.Element => {
    const [illustrationContainerRect, illustrationContainerRef] = useBoundingClientRect();
    const [deceasedContainerRect, deceasedContainerRef] = useBoundingClientRect();
    const [estateContainerRect, estateContainerRef] = useBoundingClientRect();
    const [otherOwnersContainerRect, otherOwnersContainerRef] = useBoundingClientRect();
    const equityPercentageByEmployeeId = getEquityPercentageByEmployeeId(business, employees);
    const deceasedEquityPercentage = equityPercentageByEmployeeId[deceasedOwner.id];
    const deceasedEquityAmount = businessValuation.value * deceasedEquityPercentage;
    const columnCount = Math.min(4, otherOwners.length + 1);

    return (
        <div className="flex flex-col flex-grow relative" ref={illustrationContainerRef}>
            <div className="flex items-center flex-grow">
                <div className={`grid grid-cols-${columnCount} gap-x-4 gap-y-16 w-max`}>
                    <div ref={deceasedContainerRef}>
                        <EntityCard
                            avatar={deceasedOwner.avatar || undefined}
                            className={`opacity-50 ${successionEntityWidth}`}
                            description="Deceased"
                            name={NameFormatter.getName(deceasedOwner)}
                            value={formatPercentage(equityPercentageByEmployeeId[deceasedOwner.id])}
                        />
                    </div>
                    <div className={`col-span-${columnCount - 1}`} ref={otherOwnersContainerRef}>
                        <div className={`grid grid-cols-${columnCount - 1} gap-4`}>
                            {otherOwners.map(o => {
                                return (
                                    <EntityCard
                                        key={o.id}
                                        avatar={o.avatar || undefined}
                                        className={successionEntityWidth}
                                        description={o.title || undefined}
                                        name={NameFormatter.getName(o)}
                                        value={formatPercentage(
                                            getOwnerEquityPercentage(
                                                successionPlanType,
                                                equityPercentageByEmployeeId[o.id],
                                                deceasedEquityPercentage,
                                                otherOwners.length
                                            )
                                        )}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div ref={estateContainerRef}>
                        <EntityCard
                            className={successionEntityWidth}
                            name={`${deceasedOwner.firstName}'s Estate`}
                            value={getEstateValue(successionPlanType, deceasedEquityPercentage, deceasedEquityAmount)}
                        />
                    </div>
                    {deceasedContainerRect &&
                        estateContainerRect &&
                        otherOwnersContainerRect &&
                        illustrationContainerRect && (
                            <PlanIllustrationEquityTransfer
                                business={business}
                                deceasedContainerRect={deceasedContainerRect}
                                deceasedEquityAmount={deceasedEquityAmount}
                                estateContainerRect={estateContainerRect}
                                illustrationContainerRect={illustrationContainerRect}
                                otherOwnersContainerRect={otherOwnersContainerRect}
                                successionPlanType={successionPlanType}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export default PlanIllustration;
