import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slider } from '~/components';
import { categoryDescription, categoryIcon, categoryTitle, RatingCategory } from '../../../teamValues/rating/models';

interface IProps {
    category: RatingCategory;
    modifiers: Record<RatingCategory, number>;
    setModifiers: (value: Record<RatingCategory, number>) => void;
}

const ValueDriverSlider = ({ category, modifiers, setModifiers }: IProps): JSX.Element => {
    return (
        <div className="grid grid-cols-2 gap-x-4">
            <div className="flex gap-4 items-center">
                <div>
                    <FontAwesomeIcon icon={categoryIcon[category]} size={'2x'} />
                </div>
                <div>
                    <div className="font-bold text-xl">{categoryTitle[category]}</div>
                    <div>{categoryDescription[category]}</div>
                </div>
            </div>
            <Slider
                min={1}
                max={5}
                onChange={value => setModifiers({ ...modifiers, [category]: value })}
                value={modifiers[category]}
            />
        </div>
    );
};

export default ValueDriverSlider;
