export const getExposure = (
    coverage: number | null | undefined,
    risk: number | null | undefined
): number | undefined => {
    if (risk === null || risk === undefined) {
        return undefined;
    }

    return Math.max(0, risk - (coverage || 0));
};
