import {
    BonusRightEmployeeRewardsApi,
    IBonusRightAggregatePaySummary,
    IBonusRightEmployeeRewards,
    IBonusRightEmployeeValueStatement,
    IBonusRightExecutiveBenefitAnalysis,
    IProblemDetails,
} from '@api';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const bonusRightEmployeeRewardsApi = new BonusRightEmployeeRewardsApi(undefined, config.api.baseUrl);

export const getBonusRightQueryKey = (businessId: string, ...keyPieces: string[]): QueryKey =>
    getBusinessQueryKey(businessId, 'bonusRight', ...keyPieces);

export const getBonusRightAggregatePaySummaryQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'aggregatePaySummary');

export const getBonusRightEmployeeValueStatementQueryKey = (businessId: string, employeeId: number): QueryKey =>
    getBonusRightQueryKey(businessId, `${employeeId}`, 'employeeValueStatement');

export const getBonusRightExecutiveBenefitAnalysisQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'executiveBenefitAnalysis');

export const getBonusRightTotalRewardsAssessmentQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'totalRewardsAssessment');

export const useBonusRightAggregatePaySummary = (
    businessId: string,
    enabled = true
): UseQueryResult<IBonusRightAggregatePaySummary, IProblemDetails> =>
    useQuery(
        getBonusRightAggregatePaySummaryQueryKey(businessId),
        () => bonusRightEmployeeRewardsApi.getBonusRightAggregatePaySummary(businessId).then(response => response.data),
        { enabled: !!businessId && enabled }
    );

export const useBonusRightEmployeeValueStatement = (
    businessId: string,
    bonusRightEmployeeId: number,
    enabled = true
): UseQueryResult<IBonusRightEmployeeValueStatement, IProblemDetails> =>
    useQuery(
        getBonusRightEmployeeValueStatementQueryKey(businessId, bonusRightEmployeeId),
        () =>
            bonusRightEmployeeRewardsApi
                .getBonusRightEmployeeValueStatement(businessId, bonusRightEmployeeId)
                .then(response => response.data),
        { enabled: !!businessId && !!bonusRightEmployeeId && enabled }
    );

export const useBonusRightExecutiveBenefitAnalysis = (
    businessId: string,
    enabled = true
): UseQueryResult<IBonusRightExecutiveBenefitAnalysis, IProblemDetails> =>
    useQuery(
        getBonusRightExecutiveBenefitAnalysisQueryKey(businessId),
        () =>
            bonusRightEmployeeRewardsApi
                .getBonusRightExecutiveBenefitAnalysis(businessId)
                .then(response => response.data),
        { enabled: !!businessId && enabled }
    );

export const useBonusRightTotalRewardsAssessment = (
    businessId: string,
    enabled = true
): UseQueryResult<IBonusRightEmployeeRewards[], IProblemDetails> =>
    useQuery(
        getBonusRightTotalRewardsAssessmentQueryKey(businessId),
        () =>
            bonusRightEmployeeRewardsApi
                .getBonusRightTotalRewardsAssessment(businessId)
                .then(response => response.data),
        { enabled: !!businessId && enabled }
    );
