import { ErrorPage, LoadingIndicator } from '~/components';
import { useAdvisorFirm } from '~/hooks';
import FirmInfo from './FirmInfo';

interface IProps {
    advisorFirmId: string;
}

const MyFirmInfo = ({ advisorFirmId }: IProps): JSX.Element => {
    const { isError, isLoading, data: myFirm } = useAdvisorFirm(advisorFirmId);

    if (isLoading) return <LoadingIndicator />;
    if (isError || !myFirm) return <ErrorPage />;

    return <FirmInfo advisorFirm={myFirm} />;
};

export default MyFirmInfo;
