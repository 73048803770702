import { IBusiness, IBusinessValuation, IUserEntitlementType } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { Button, Can, DeleteConfirmationModal, useAvatarInput } from '~/components';
import { useBusinessAvatar, useBusinessValuation, useDeleteBusiness, useUpdateBusiness } from '~/hooks';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification, withSuccessNotification } from '~/redux/successNotification';
import { useHistory } from '~/routing';
import { errorParser } from '~/utils/errorParser';
import { yupToFormErrors } from '~/utils/yupUtils';
import BusinessInfoForm, { IBusinessInfoFormData, mapFormDataToApi, schemaValidation } from './BusinessInfoForm';

interface IProps {
    business: IBusiness;
}

const getInitialFormData = (business: IBusiness, businessValuation?: IBusinessValuation): IBusinessInfoFormData => {
    return {
        city: business.city || '',
        description: business.description || '',
        industry: business.industry || '',
        name: business.name || '',
        size: business.size || '',
        sharesOutstanding: business.sharesOutstanding,
        stateOrProvince: business.stateOrProvince || '',
        value: businessValuation?.value,
        website: business.website || '',
        yearFounded: business.yearFounded?.toString(),
    };
};

const BusinessInfo = ({ business }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));

    const [deleteState, setDeleteState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const {
        isError: isErrorBusinessValuation,
        isLoading: isLoadingBusinessValuation,
        data: businessValuation,
    } = useBusinessValuation(business.id);
    const [formData, setFormData] = useState<IBusinessInfoFormData>(getInitialFormData(business, businessValuation));
    useEffect(() => {
        if (businessValuation) {
            setFormData(getInitialFormData(business, businessValuation));
        }
    }, [business, businessValuation]);
    const [isSaving, setIsSaving] = useState(false);
    const isDisabled = isSaving || isLoadingBusinessValuation || isErrorBusinessValuation;
    const [newAvatar, setNewAvatar] = useAvatarInput(business);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const deleteBusiness = useDeleteBusiness();
    const history = useHistory();
    const updateBusiness = useUpdateBusiness(business.id);
    const updateBusinessAvatar = useBusinessAvatar();
    const onSave = async () => {
        try {
            try {
                schemaValidation.validateSync(formData, { abortEarly: false });
            } catch (err: unknown) {
                if (ValidationError.isError(err)) {
                    setValidationErrors(yupToFormErrors(err));
                }
                return;
            }
            setIsSaving(true);
            await updateBusiness.mutateAsync({
                ...business,
                ...mapFormDataToApi(formData),
            });
            if (newAvatar?.file) {
                await updateBusinessAvatar.mutateAsync({ avatar: newAvatar.file, businessId: business.id });
            }
            showSuccess();
        } catch (e) {
            const fieldValidationErrors = errorParser.getFieldValidationErrors(e);

            if (fieldValidationErrors) {
                setValidationErrors(fieldValidationErrors);
                return;
            }

            showError();
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Business Information</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-textSubtle">
                Enter general information about the business.
            </p>
            {(isErrorBusinessValuation || !businessValuation) && (
                <div className="mb-4">
                    <FontAwesomeIcon className="mr-2 text-caution" icon={faExclamationTriangle} size="1x" />
                    An unknown error occurred retrieving the business valuation.
                </div>
            )}
            <div className="space-y-3">
                <BusinessInfoForm
                    avatar={newAvatar?.url || business?.avatar}
                    disabled={isDisabled}
                    errors={validationErrors}
                    formData={formData}
                    onChange={setFormData}
                    onChangeAvatar={setNewAvatar}
                />
                <div className="py-3 border-t border-background2 flex flex-row-reverse justify-between sm:px-0">
                    <Button color="primary" disabled={isDisabled} text="Save" onClick={onSave} />
                    <Can hasEntitlement={IUserEntitlementType.BusinessDelete}>
                        <Button
                            color="secondaryDanger"
                            disabled={isDisabled}
                            text="Delete Business"
                            onClick={() => setDeleteState({ isOpen: true })}
                        />
                    </Can>
                </div>
            </div>
            <DeleteConfirmationModal
                onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                onConfirm={withSuccessNotification(dispatch, () => {
                    setIsSaving(true);
                    return deleteBusiness
                        .mutateAsync(business.id)
                        .then(() => history.push('/'))
                        .finally(() => setIsSaving(false));
                })}
                title="Delete Business"
                {...deleteState}
            >
                Are you sure you want to delete this business? All of your data will be permanently removed from our
                servers forever. This action cannot be undone.
            </DeleteConfirmationModal>
        </>
    );
};

export default BusinessInfo;
