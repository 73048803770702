import classnames from 'classnames';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { showErrorModal } from '~/redux/errorModal';
import { useAppDispatch } from '~/redux/hooks';
import { errorParser } from '~/utils/errorParser';
import { Button } from './Button';

interface IFormCancelAndSaveActionsProps {
    disabled: boolean;
    style?: CSSProperties;
    onCancel: () => void;
    onSave: () => void;
}

const FormCancelAndSaveActions = ({
    disabled,
    style,
    onCancel,
    onSave,
}: IFormCancelAndSaveActionsProps): JSX.Element => (
    <div className="space-x-3 flex justify-end" style={style}>
        <Button disabled={disabled} text="Cancel" onClick={onCancel} />
        <Button color="primary" disabled={disabled} text="Save" onClick={onSave} />
    </div>
);

interface IFormWithDeleteActionsProps {
    disabled: boolean;
    onCancel: () => void;
    onDelete: () => void;
    onSave: () => void;
}

const FormWithDeleteActions = ({ disabled, onCancel, onDelete, onSave }: IFormWithDeleteActionsProps): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const deleteButtonRef = useRef<HTMLButtonElement>(null);
    const deleteButtonsParentRef = useRef<HTMLDivElement>(null);
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const [deleteButtonLeftFromParent, setDeleteButtonLeftFromParent] = useState<number>(0);
    const [deleteButtonRightParent, setDeleteButtonRightFromParent] = useState<number>(0);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (!isMounted) {
            setTimeout(() => setIsMounted(true), 0);
        }
    }, [isMounted]);

    useEffect(() => {
        if (containerRef.current && deleteButtonRef.current && deleteButtonsParentRef.current) {
            setContainerHeight(containerRef.current.offsetHeight);
            setDeleteButtonLeftFromParent(
                deleteButtonsParentRef.current.offsetWidth - deleteButtonRef.current.offsetWidth
            );
            setDeleteButtonRightFromParent(
                containerRef.current.offsetWidth - deleteButtonsParentRef.current.offsetWidth
            );
        }
    }, [containerRef, deleteButtonRef, deleteButtonsParentRef]);

    return (
        <>
            <div
                className={classnames('flex flex-grow justify-between', {
                    'duration-500 transition-transform': isMounted,
                })}
                ref={containerRef}
                style={{
                    transform: isConfirmingDelete
                        ? `translate(${deleteButtonRightParent}px)`
                        : `translate(-${deleteButtonLeftFromParent}px)`,
                }}
            >
                <div
                    className={classnames(
                        { 'opacity-0': !isConfirmingDelete },
                        { 'opacity-100': isConfirmingDelete },
                        'absolute flex items-center transition-transform transition-opacity duration-500'
                    )}
                    style={{
                        height: `${containerHeight}px`,
                        transform: isConfirmingDelete
                            ? `translate(-${deleteButtonRightParent}px)`
                            : `translate(${deleteButtonLeftFromParent}px)`,
                    }}
                >
                    Are you sure?
                </div>
                <div className="z-10 space-x-3 flex justify-start" ref={deleteButtonsParentRef}>
                    <Button
                        className={classnames(
                            { 'opacity-0': !isConfirmingDelete },
                            { 'opacity-100': isConfirmingDelete },
                            'transition-opacity duration-500'
                        )}
                        disabled={disabled}
                        text="Cancel"
                        onClick={() => setIsConfirmingDelete(false)}
                    />
                    <Button
                        buttonRef={deleteButtonRef}
                        className="transition"
                        color={isConfirmingDelete ? 'danger' : 'secondaryDanger'}
                        disabled={disabled}
                        text="Delete"
                        onClick={() => (isConfirmingDelete ? onDelete() : setIsConfirmingDelete(true))}
                    />
                </div>
                <FormCancelAndSaveActions
                    disabled={disabled}
                    style={{ transform: `translate(${deleteButtonLeftFromParent}px)` }}
                    onCancel={onCancel}
                    onSave={onSave}
                />
            </div>
        </>
    );
};

interface IFormActionsProps {
    conflictErrorMessage?: string;
    onCancel: () => void;
    onDelete?: () => Promise<void>;
    onSave: () => Promise<void>;
    setValidationErrors: (validationErrors: { [key: string]: string }) => void;
}

const FormActions = ({
    conflictErrorMessage,
    onCancel,
    onDelete,
    onSave,
    setValidationErrors,
}: IFormActionsProps): JSX.Element => {
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useAppDispatch();
    const showError = (errorMessage?: string) => dispatch(showErrorModal(errorMessage));
    const handleDelete = async () => {
        setIsDisabled(true);
        if (onDelete) {
            await onDelete()
                .catch(() => {
                    showError();
                })
                .finally(() => {
                    setIsDisabled(false);
                });
        }
    };
    const handleSave = async () => {
        setIsDisabled(true);
        await onSave()
            .catch(error => {
                const fieldValidationErrors = errorParser.getFieldValidationErrors(error);

                if (fieldValidationErrors) {
                    setValidationErrors(fieldValidationErrors);
                    return;
                }

                const isConflictError = errorParser.isConflictError(error);
                if (isConflictError) {
                    showError(conflictErrorMessage);
                } else {
                    showError();
                }
            })
            .finally(() => {
                setIsDisabled(false);
            });
    };

    return (
        <>
            {onDelete ? (
                <FormWithDeleteActions
                    disabled={isDisabled}
                    onCancel={onCancel}
                    onDelete={handleDelete}
                    onSave={handleSave}
                />
            ) : (
                <FormCancelAndSaveActions disabled={isDisabled} onCancel={onCancel} onSave={handleSave} />
            )}
        </>
    );
};

export { FormActions };
