import { IEmployee } from '@api';
import Papa from 'papaparse';
import { IEmployeeCsvExport } from '~/pages/business/employees/model';

export const missingDepartmentValue = 'Unassigned';

export const formatBooleanString = (value: boolean | undefined): string => (value ? 'TRUE' : 'FALSE');

export type IEmployeeWithOptionalId = Omit<IEmployee, 'id'> & { id?: string };

export const downloadCsv = (employees: IEmployeeWithOptionalId[], fileName = 'employees.csv'): void => {
    if (employees.length === 0) return;
    const employeeExport: IEmployeeCsvExport[] = employees.map(e => ({
        firstName: e.firstName,
        lastName: e.lastName,
        dateOfBirth: e.dateOfBirth?.split('T')[0],
        emailAddress: e.emailAddress,
        department: e.department,
        isOwner: formatBooleanString(e.isOwner),
        salary: e.salary?.toString(),
        shares: e.shares?.toString(),
        title: e.title,
        disabilityInsuranceCoverage: e.disabilityInsuranceCoverage?.toString(),
        lifeInsuranceCoverage: e.lifeInsuranceCoverage?.toString(),
        id: e.id,
    }));
    const csv = Papa.unparse(employeeExport);

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
