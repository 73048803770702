import { IBusinessValuation, IEmployee } from '@api';
import CurrencyFormatter from '~/utils/currencyFormatter';
import NameFormatter from '~/utils/nameFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import EntityCard from '../../successionPlan/EntityCard';
import { entityCardWidth } from '../constants';
import UpdatedOwnersAndLines from '../UpdatedOwnersAndLines';
import { IOwner } from '../model/models';

interface IProps {
    businessValuation: IBusinessValuation;
    employeesById: Record<string, IEmployee>;
    onClick: () => void;
    owners: IOwner[];
}

const capitalInvestmentDiscountPercentage = 0.2;

const RecapitalizedOwnersAndLines = ({ businessValuation, employeesById, onClick, owners }: IProps): JSX.Element => (
    <UpdatedOwnersAndLines ownerIds={owners.map(o => o.employeeId)}>
        {owners.map(o => {
            const employee = employeesById[o.employeeId];

            return (
                <div>
                    <button className="text-left" onClick={onClick}>
                        <EntityCard
                            key={o.employeeId}
                            avatar={employee.avatar || undefined}
                            className={`${entityCardWidth} hover:bg-background2 cursor-pointer`}
                            description={employee.title || undefined}
                            name={NameFormatter.getName(employee)}
                            value={formatPercentage(o.equityPercentage)}
                        />
                    </button>
                    <div className="mt-3 text-center text-caution">
                        <div className="mb-2">{formatPercentage(o.votingSharesPercentage)} Voting Share</div>
                        {o.requiresInvestment && (
                            <>
                                <div>
                                    {CurrencyFormatter.format(
                                        businessValuation.value *
                                            o.equityPercentage *
                                            (1 - capitalInvestmentDiscountPercentage)
                                    )}{' '}
                                    Capital Investment
                                </div>
                                <div>
                                    ({formatPercentage(capitalInvestmentDiscountPercentage)} Discount for Non-Voting
                                    Shares)
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        })}
    </UpdatedOwnersAndLines>
);

export default RecapitalizedOwnersAndLines;
