import { ReactNode, useEffect } from 'react';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useAuth } from '~/hooks';

interface IProps {
    children?: ReactNode;
}

const LoginRedirect = ({ children }: IProps): JSX.Element => {
    const { error, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth();

    console.log({ isLoading, isAuthenticated, error });

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !error) {
            loginWithRedirect();
        }
    }, [error, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

    if (error?.message === 'IsExpired') {
        return <ErrorPage fullScreen hideActions title="This link has expired" />;
    }
    if (error) {
        return <ErrorPage fullScreen />;
    }
    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (isAuthenticated) {
        return <>{children}</>;
    }

    return <div>Redirecting...</div>;
};

export default LoginRedirect;
