import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';
import { formatRating, getRatingColor } from './utils';

interface IProps {
    rating?: number | null;
}

const EmployeeRating = ({ className, rating }: IProps & ComponentPropsWithoutRef<'div'>): JSX.Element => {
    const borderColor = getRatingColor(rating);
    return (
        <div
            className={classNames(
                className,
                `border-${borderColor}`,
                'bg-background1 border-4 inline-flex flex items-center justify-center font-medium text-textDefault rounded-full'
            )}
        >
            {rating ? formatRating(rating) : <FontAwesomeIcon icon={faPlus} />}
        </div>
    );
};

export default EmployeeRating;
