import {
    BizEquityBusinessesApi,
    IBizEquityBusiness,
    IBizEquityBusinessConnectionStatus,
    IBizEquityBusinessConnectRequest,
    IBizEquityBusinessCreateRequest,
    IBizEquityBusinessDataEntryDelegateInvitationRequest,
    IBizEquityBusinessSearchResultItem,
    IBizEquityBusinessUpdateRequest,
    IProblemDetails,
} from '@api';
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBizEquityCurrentUserQueryKey } from './bizEquityUser';
import { getBusinessValuationQueryKey } from './businessValuations';
import { getTodosQueryKey } from './todos';

const bizEquityBusinessesApi = new BizEquityBusinessesApi(undefined, config.api.baseUrl);

export const getBizEquityBusinessQueryKey = (businessId: string, ...keyPieces: string[]): QueryKey =>
    getBizEquityCurrentUserQueryKey(businessId, ...keyPieces);

const getBizEquityBusinessConnectionStatusQueryKey = (businessId: string): QueryKey =>
    getBizEquityBusinessQueryKey(businessId, 'connectionStatus');

const getBizEquityBusinessesSearchQueryKey = (search: string): QueryKey =>
    getBizEquityCurrentUserQueryKey('businessSearch', search);

export const useBizEquityBusiness = (
    businessId: string,
    enabled = true
): UseQueryResult<IBizEquityBusiness, IProblemDetails> =>
    useQuery(
        getBizEquityBusinessQueryKey(businessId, 'business'),
        () => bizEquityBusinessesApi.getBizEquityBusiness(businessId).then(response => response.data),
        {
            enabled: !!businessId && enabled,
        }
    );

export const useBizEquityBusinessConnectionStatus = (
    businessId: string,
    enabled = true
): UseQueryResult<IBizEquityBusinessConnectionStatus, IProblemDetails> =>
    useQuery(
        getBizEquityBusinessConnectionStatusQueryKey(businessId),
        () => bizEquityBusinessesApi.getBizEquityBusinessConnectionStatus(businessId).then(response => response.data),
        {
            enabled,
        }
    );

export const useConnectBizEquityBusiness = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBizEquityBusinessConnectRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBizEquityBusinessConnectRequest>(
        request =>
            bizEquityBusinessesApi
                .connectBizEquityBusiness(businessId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBizEquityBusinessQueryKey(businessId));
                queryClient.invalidateQueries(getBusinessValuationQueryKey(businessId));
            },
        }
    );

    return mutation;
};

export const useCreateBizEquityBusiness = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBizEquityBusinessCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBizEquityBusinessCreateRequest>(
        request =>
            bizEquityBusinessesApi
                .createBizEquityBusiness(businessId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBizEquityBusinessQueryKey(businessId));
                queryClient.invalidateQueries(getBusinessValuationQueryKey(businessId));
            },
        }
    );

    return mutation;
};

export const useSearchBizEquityBusinesses = (
    search: string
): UseQueryResult<IBizEquityBusinessSearchResultItem[], IProblemDetails> =>
    useQuery(getBizEquityBusinessesSearchQueryKey(search), () =>
        bizEquityBusinessesApi.searchBizEquityBusinesses(search).then(response => response.data)
    );

export const useInviteBizEquityBusinessDataEntryDelegate = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBizEquityBusinessDataEntryDelegateInvitationRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBizEquityBusinessDataEntryDelegateInvitationRequest>(
        request =>
            bizEquityBusinessesApi
                .createInvitationForBizEquityDataEntryDelegate(businessId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getTodosQueryKey(businessId));
            },
        }
    );

    return mutation;
};

export const useUpdateBizEquityBusiness = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBizEquityBusinessUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBizEquityBusinessUpdateRequest>(
        request =>
            bizEquityBusinessesApi
                .updateBizEquityBusiness(businessId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBizEquityBusinessQueryKey(businessId));
                queryClient.invalidateQueries(getBusinessValuationQueryKey(businessId));
            },
        }
    );

    return mutation;
};
