import { IDocumentColorType } from '@api';
import { IconName } from '@fortawesome/fontawesome-common-types';

export const getTextColorFromType = (colorType?: IDocumentColorType): string => {
    const result = colorMap[colorType || IDocumentColorType.Gray];
    return result ?? colorMap[IDocumentColorType.Gray];
};

export const colorMap: Record<IDocumentColorType, string> = {
    Blue: 'text-info-G',
    Gray: 'text-info-H',
    Orange: 'text-info-B',
    Purple: 'text-info-Cm1',
    Red: 'text-info-Bm1',
    Teal: 'text-info-A',
    Yellow: 'text-info-E',
};

export const getColorFromIcon = (iconName: IconName): string => {
    switch (iconName) {
        case 'calendar-alt':
        case 'envelope-open-text':
        case 'file-word':
            return 'text-info-G';
        case 'file-archive':
        case 'file-code':
            return 'text-info-B';
        case 'file-audio':
            return 'text-info-E';
        case 'file-csv':
        case 'file-excel':
            return 'text-info-Dm1';
        case 'file-image':
        case 'file-pdf':
        case 'file-powerpoint':
        case 'file-video':
            return 'text-info-Bm1';
        default:
            return 'text-textDisabled';
    }
};
