import Highcharts, { PlotOptions, SeriesOptionsType, XAxisOptions, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const margin = {
    top: 10,
    right: 30,
    bottom: 30,
    left: 80,
};

interface IProps {
    height: number;
    plotOptions?: PlotOptions;
    series: Array<SeriesOptionsType>;
    xAxis?: XAxisOptions;
    yAxis?: YAxisOptions;
}

const StackedAreaChart = ({ height, plotOptions, series, xAxis, yAxis }: IProps): JSX.Element => {
    const options: Highcharts.Options = {
        chart: {
            backgroundColor: 'transparent',
            height,
            type: 'area',
            margin: [margin.top, margin.right, margin.bottom, margin.left],
        },
        credits: {
            enabled: false,
        },
        legend: { enabled: false },
        plotOptions: {
            ...plotOptions,
            area: {
                ...plotOptions?.area,
                stacking: 'normal',
                marker: {
                    enabled: false,
                },
            },
            series: {
                animation: false,
                enableMouseTracking: false,
                ...plotOptions?.series,
            },
        },
        series,
        title: {
            text: '',
        },
        tooltip: {
            enabled: false,
        },
        xAxis: {
            ...xAxis,
            gridLineColor: 'var(--color-background2)',
            gridLineWidth: 1,
            labels: {
                ...xAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '1rem',
                    ...xAxis?.labels?.style,
                },
            },
            lineColor: 'var(--color-bg-600)',
            tickLength: 0,
        },
        yAxis: {
            ...yAxis,
            gridLineWidth: 0,
            labels: {
                ...yAxis?.labels,
                style: {
                    color: 'var(--color-textDefault)',
                    fontSize: '1rem',
                    ...yAxis?.labels?.style,
                },
            },
            title: {
                text: null,
                ...yAxis?.title,
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default StackedAreaChart;
