import { faCaretRight, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { Button, LoadingIndicator, Modal } from '~/components';
import { useDocumentList, useDocumentPath, useUpdateDocument } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { clearSelectedItems, closeModal, ModalType } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useParams } from '~/routing';
import { defaultErrorMessage } from '~/utils/errorUtils';
import FolderIconTableView from '../../icons/FolderIconTableView';
import SelectionIcon from '../../icons/SelectionIcon';
import ModalBreadcrumbs from './ModalBreadcrumbs';
import NoSubfoldersMessage from './NoSubfoldersMessage';

enum Status {
    None,
    IsSaving,
    SubmitError,
}

const MoveItemsModal = (): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const updateDocument = useUpdateDocument(businessId);
    const { selectedItems, currentFolderId } = useAppSelector(s => s.documentCenter);
    const [activeFolderId, setActiveFolderId] = useState(currentFolderId);
    const [status, setStatus] = useState(Status.None);
    const [targetFolderId, setTargetFolderId] = useState(currentFolderId);
    const showProgress = status === Status.IsSaving;
    const folder = useDocumentList(businessId, activeFolderId);
    const folderPath = useDocumentPath(businessId, activeFolderId);
    const activeFolder = folder.data;
    const subFolders = activeFolder?.documents.filter(d => d.isFolder);
    const isLoading = folder.isFetching || folderPath.isFetching;
    const hasError = folder.isError || folderPath.isError || status === Status.SubmitError;
    const { selectedModal } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();

    const onChangeActiveFolder = (itemId: string) => {
        if (showProgress) {
            return;
        }

        setActiveFolderId(itemId);
        setTargetFolderId(itemId);
    };

    const onSubmit = async () => {
        if (!currentFolderId || currentFolderId === targetFolderId) {
            dispatch(closeModal());
            return;
        }

        setStatus(Status.IsSaving);

        try {
            await Promise.all(
                Object.values(selectedItems).map(item =>
                    updateDocument.mutateAsync({
                        documentId: item.id,
                        request: { ...item, parentFolderId: targetFolderId },
                    })
                )
            );
            setStatus(Status.None);
            setTargetFolderId(currentFolderId);
            dispatch(clearSelectedItems());
            dispatch(closeModal());
        } catch (e) {
            setStatus(Status.SubmitError);
            throw e;
        }
    };

    const handleCloseWithErrors = () => {
        if (status === Status.SubmitError) {
            dispatch(closeModal());
        } else {
            dispatch(closeModal());
        }
    };

    return (
        <Modal
            disableBackdropClickClose={showProgress}
            maxWidth="xl"
            open={selectedModal.type === ModalType.Move}
            setOpen={handleCloseWithErrors}
            title={`Move ${Object.keys(selectedItems).length} Selected Item(s)`}
        >
            <div className="p-6">
                <div className="flex flex-col">
                    {isLoading && (
                        <div className="h-40">
                            <LoadingIndicator />
                        </div>
                    )}
                    {hasError && (
                        <p className="text-error">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" aria-hidden />
                            {defaultErrorMessage}
                        </p>
                    )}
                    {!isLoading && !hasError && activeFolder && folderPath.data && (
                        <>
                            <ModalBreadcrumbs
                                folderSegments={folderPath.data}
                                onChangeActiveFolder={onChangeActiveFolder}
                            />
                            <hr className="m-0 border-t border-background2" />
                            {!subFolders?.length ? (
                                <NoSubfoldersMessage />
                            ) : (
                                <div className="overflow-y-scroll" style={{ maxHeight: '50vh' }}>
                                    {subFolders.map(item => {
                                        const isSelected = targetFolderId === item.id;
                                        return (
                                            <div
                                                key={item.id}
                                                className={classNames(
                                                    'hover:bg-background2 flex items-center w-full justify-between py-2 px-3',
                                                    { 'bg-background2': isSelected },
                                                    { 'odd:bg-background1': !isSelected }
                                                )}
                                                onClick={() => setTargetFolderId(item.id)}
                                            >
                                                <div className="flex">
                                                    <FolderIconTableView color={item.color} icon={item.icon} />
                                                    <Button
                                                        color="link"
                                                        iconRight={faCaretRight}
                                                        iconSize="sm"
                                                        text={item.name}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            onChangeActiveFolder(item.id);
                                                        }}
                                                    />
                                                </div>
                                                <div className="text-right">
                                                    <SelectionIcon isSelected={isSelected} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="space-x-3 flex justify-end">
                    {hasError && <Button color="primary" text="OK" onClick={handleCloseWithErrors} />}
                    {!hasError && (
                        <>
                            <Button
                                className="mr-2"
                                color="secondary"
                                disabled={showProgress}
                                text="Cancel"
                                onClick={() => dispatch(closeModal())}
                            />
                            <Button color="primary" disabled={showProgress} text="Move" onClick={onSubmit} />
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default MoveItemsModal;
