import { IBusiness } from '@api';
import { useState } from 'react';
import { VisibleSection } from '~/components/surveys/models';
import Survey from '~/components/surveys/Survey';
import { useSubmitBonusRightAssessment } from '~/hooks/bonusRightOnboardingAssessment';
import { Redirect } from '~/routing';
import AssessmentInstructions from './AssessmentInstructions';
import { questions } from './models';
import visionLinkAssessmentBackground from './VisionLinkAssessmentBackground.png';

interface IProps {
    business: IBusiness;
    redirectLink: string;
}

const Assessment = ({ business, redirectLink }: IProps): JSX.Element => {
    const submitBonusRightAssessment = useSubmitBonusRightAssessment(business.id).mutateAsync;

    const [answers, setAnswers] = useState<Record<string, number>>({});
    const [visibleSection, setVisibleSection] = useState<VisibleSection>(VisibleSection.Questions);

    return (
        <div className="h-full sm:h-auto max-h-full max-w-full mx-auto my-auto space-y-5">
            <div className="flex flex-col xl:flex-row h-full sm:h-auto">
                <div className="bg-background1 flex flex-col justify-between xl:hidden max-w-full pt-6 px-7 rounded-t-xl space-y-4 w-128">
                    <AssessmentInstructions />
                </div>
                <div
                    className="xl:flex flex-col h-160 hidden justify-between p-12 rounded-bl-xl rounded-tl-xl w-128"
                    style={{
                        backgroundImage: `url("${visionLinkAssessmentBackground}")`,
                        backgroundSize: 'cover',
                    }}
                >
                    <AssessmentInstructions />
                </div>
                <div className="bg-background1 flex flex-col flex-grow sm:h-160 justify-between max-w-full xl:px-7 pb-6 xl:pt-6 rounded-bl-xl xl:rounded-bl-none rounded-br-xl xl:rounded-tr-xl w-128">
                    <Survey
                        answers={answers}
                        questions={questions}
                        resultsElementFactory={answers => <Redirect to={redirectLink} />}
                        setAnswers={setAnswers}
                        setVisibleSection={setVisibleSection}
                        submitHandler={answerMap => submitBonusRightAssessment({ answerMap, businessId: business.id })}
                        surveyName="Employee Rewards Assessment"
                        visibleSection={visibleSection}
                    ></Survey>
                </div>
            </div>
        </div>
    );
};

export default Assessment;
