import axios, { AxiosRequestConfig } from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '~/hooks';

interface IProps {
    children?: ReactNode;
}

export const defaultHeaders = {
    'api-version': '1.0',
};

const AxiosConfigurationProvider = ({ children }: IProps): JSX.Element | null => {
    const [initialized, setInitialized] = useState(false);
    const { getAccessTokenSilently } = useAuth();
    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.defaults.headers = defaultHeaders;
        axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
            if (config && config.headers) {
                const accessToken = await getAccessTokenSilently();
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return await Promise.resolve(config);
        });

        setInitialized(true);
    }, [getAccessTokenSilently]);

    return initialized ? <>{children}</> : null;
};

export default AxiosConfigurationProvider;
