import { IEmployee } from '@api';
import { EntitySelect, ISelectOption } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    disabled?: boolean;
    employees: IEmployee[];
    label?: string;
    onChange: (id: string) => void;
    onClickNew?: () => void;
    value: string | null | undefined;
}

const EmployeeSearch = ({
    disabled = false,
    employees,
    label = 'Primary Contact for the Business',
    onChange,
    onClickNew,
    value,
}: IProps): JSX.Element => {
    const options = [
        ...employees.map(a => ({
            avatar: a.avatar || undefined,
            label: NameFormatter.getLastNameFirst(a),
            name: NameFormatter.getLastNameFirst(a),
            value: a.id,
        })),
    ];

    const handleChange = (option: ISelectOption | null) => {
        if (!option?.value) {
            return;
        }
        onChange(option.value);
    };

    return (
        <EntitySelect
            disabled={disabled}
            label={label}
            options={options}
            placeholder="Search for employee"
            value={value || ''}
            onEntityOptionSelected={handleChange}
            onNewSelected={onClickNew}
        />
    );
};

export default EmployeeSearch;
