import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultErrorMessage } from '~/utils/errorUtils';

export interface ErrorModalState {
    isVisible: boolean;
    message: string;
}

const initialState: ErrorModalState = {
    isVisible: false,
    message: defaultErrorMessage,
};

export const errorModalSlice = createSlice({
    name: 'errorModal',
    initialState,
    reducers: {
        hideErrorModal: state => {
            state.isVisible = false;
        },
        showErrorModal: (state, action: PayloadAction<string | undefined>) => {
            state.isVisible = true;
            state.message = action.payload ?? defaultErrorMessage;
        },
    },
});

export const { hideErrorModal, showErrorModal } = errorModalSlice.actions;

export default errorModalSlice.reducer;
