import { IDocumentUpdateRequest } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Modal, TextInput } from '~/components';
import { useUpdateDocument } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { closeModal, ModalType } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useParams } from '~/routing';
import { errorParser } from '~/utils/errorParser';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { getNameParts } from './getNameParts';

type IDocumentUpdateForm = Pick<IDocumentUpdateRequest, 'name'>;

const RenameItemModal = (): JSX.Element => {
    const { selectedItems, selectedModal } = useAppSelector(s => s.documentCenter);
    const item = Object.values(selectedItems)[0];
    const { businessId } = useParams<BusinessParams>();
    const updateDocument = useUpdateDocument(businessId);
    const itemNameParts = getNameParts(item.name);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<IDocumentUpdateForm>({ name: itemNameParts.name });
    const [saveFailed, setSaveFailed] = useState(false);
    const dispatch = useAppDispatch();

    const handleOnSubmit = async () => {
        if (!formData.name) {
            return;
        }
        try {
            setSaveFailed(false);
            const newName = formData?.name?.concat(itemNameParts.extension);
            await updateDocument.mutateAsync({
                documentId: item.id,
                request: { ...item, name: newName },
            });
            dispatch(closeModal());
        } catch (e) {
            const fieldValidationErrors = errorParser.getFieldValidationErrors(e);

            if (fieldValidationErrors) {
                setValidationErrors(fieldValidationErrors);
                return;
            }

            setSaveFailed(true);

            throw e;
        }
    };

    return (
        <Modal
            maxWidth="xl"
            open={selectedModal.type === ModalType.Rename}
            setOpen={() => dispatch(closeModal())}
            title={`Rename ${item.isFolder ? 'Folder' : 'File'}`}
        >
            <div className="p-6">
                <div className="my-5">
                    <TextInput
                        errorMessage={validationErrors.name}
                        label="Name"
                        onChange={name => setFormData({ ...formData, name })}
                        value={formData.name}
                    />
                    {saveFailed && (
                        <p className="text-error mt-3">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" aria-hidden />
                            {defaultErrorMessage}
                        </p>
                    )}
                </div>
                <div className="space-x-3 flex justify-end">
                    <Button color="secondary" text="Cancel" onClick={() => dispatch(closeModal())} />
                    <Button color="primary" text="Save" onClick={handleOnSubmit} />
                </div>
            </div>
        </Modal>
    );
};

export default RenameItemModal;
