import { IBizEquityBusiness } from '@api';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons';
import { EmptyStateIcon } from '~/components';
import Card from '../../../scorecard/Card';
import FinancialMetricsChart from './FinancialMetricsChart';
import GrowthTrendsOfDriversChart from './GrowthTrendsOfDriversChart';
import Ratings from './Ratings';

interface IProps {
    bizEquityBusiness: IBizEquityBusiness;
}

const KpiPage = ({ bizEquityBusiness }: IProps): JSX.Element => {
    return (
        <div className="flex-1 gap-4 grid grid-cols-1 xl:grid-cols-2 auto-rows-min py-6">
            <div className="gap-y-4 grid grid-cols-1">
                <FinancialMetricsChart bizEquityBusiness={bizEquityBusiness} />
                <GrowthTrendsOfDriversChart bizEquityBusiness={bizEquityBusiness} />
            </div>
            <Card padding>
                {bizEquityBusiness.yearlyStatements ? (
                    <Ratings yearlyStatements={bizEquityBusiness.yearlyStatements} />
                ) : (
                    <div className="flex flex-col h-full flex-grow items-center justify-center">
                        <div className="font-bold text-lg">KPI Ratings</div>
                        <EmptyStateIcon icon={faChartBar} />
                        <p className="my-4 text-lg">No KPIs to show.</p>
                    </div>
                )}
            </Card>
        </div>
    );
};

export default KpiPage;
