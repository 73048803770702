import { ICreateBonusRightConnectionRequest } from '@api';
import { useState } from 'react';
import * as Yup from 'yup';
import { FieldSet, TextInput } from '~/components';
import { useCreateBonusRightConnection } from '~/hooks/bonusRightConnection';
import { yupToFormErrors } from '~/utils/yupUtils';
import visionLinkLogo from '../VisionLinkLogo.png';

interface IProps {
    businessId: string;
    isLoggingIn: boolean;
    onConnected?: () => void;
    setIsLoggingIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const schemaValidation = Yup.object().shape({
    email: Yup.string().label('Email').required().email(),
    password: Yup.string().label('Password').required(),
});

const ConnectToBonusRightForm = ({
    businessId,
    children,
    isLoggingIn,
    onConnected,
    setIsLoggingIn,
    ...rest
}: React.ComponentPropsWithoutRef<'form'> & IProps): JSX.Element => {
    const createBonusRightConnection = useCreateBonusRightConnection(businessId);
    const [formData, setFormData] = useState<ICreateBonusRightConnectionRequest>({ email: '', password: '' });
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const onSave = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        setIsLoggingIn(true);
        setLoginErrorMessage(undefined);
        setValidationErrors({});
        createBonusRightConnection
            .mutateAsync(formData)
            .then(d => {
                if (d.isConnected) {
                    onConnected?.();
                } else {
                    setLoginErrorMessage(
                        'Sorry, there was an error logging into VisionLink. Please check your username and password.'
                    );
                }
            })
            .catch(() => {
                setLoginErrorMessage(
                    'Sorry, there was an error logging into VisionLink. Please check your username and password.'
                );
            })
            .finally(() => setIsLoggingIn(false));
    };

    return (
        <form
            {...rest}
            onSubmit={e => {
                e.preventDefault();
                onSave();
            }}
        >
            <FieldSet disabled={isLoggingIn}>
                <div className="py-6 space-y-8">
                    <div className="flex flex-col items-center">
                        <img alt="Vision Link Logo" className="white-logo" src={visionLinkLogo} />
                    </div>
                    <div className="space-y-4">
                        <TextInput
                            errorMessage={validationErrors.email}
                            label="Email"
                            onChange={email => {
                                setFormData({ ...formData, email });
                                setLoginErrorMessage(undefined);
                                setValidationErrors({});
                            }}
                            value={formData.email}
                        />
                        <TextInput
                            errorMessage={validationErrors.password}
                            onChange={password => {
                                setFormData({ ...formData, password });
                                setLoginErrorMessage(undefined);
                                setValidationErrors({});
                            }}
                            label="Password"
                            type="password"
                            value={formData.password}
                        />
                    </div>
                    {loginErrorMessage && <p className="space-y-4 text-sm text-error">{loginErrorMessage}</p>}
                    {children}
                </div>
            </FieldSet>
        </form>
    );
};

export default ConnectToBonusRightForm;
