import { BizEquityIndustriesApi, IBizEquityIndustry, IProblemDetails } from '@api';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBizEquityCurrentUserQueryKey } from './bizEquityUser';

const bizEquityApi = new BizEquityIndustriesApi(undefined, config.api.baseUrl);

const getBizEquityIndustrySearchQueryKey = (search: string): QueryKey =>
    getBizEquityCurrentUserQueryKey('industrySearch', search);

export const useSearchBizEquityIndustries = (search: string): UseQueryResult<IBizEquityIndustry[], IProblemDetails> =>
    useQuery(
        getBizEquityIndustrySearchQueryKey(search),
        () => bizEquityApi.searchBizEquityIndustries(search).then(response => response.data),
        { enabled: !!search }
    );
