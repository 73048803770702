import { IEmployee } from '@api';
import { EntitySelect, IEntitySelectOption, ISelectOption } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    employees: IEmployee[];
    onSelect: (id: string) => void;
    selectedEmployee?: string;
}

const EmployeeSearch = ({ employees, onSelect, selectedEmployee }: IProps): JSX.Element => {
    const options: IEntitySelectOption[] = [
        ...employees.map(a => ({
            avatar: a.avatar || undefined,
            label: NameFormatter.getLastNameFirst(a),
            name: NameFormatter.getLastNameFirst(a),
            value: a.id,
        })),
    ];

    const handleChange = (option: ISelectOption) => onSelect(option.value);

    return (
        <EntitySelect
            options={options}
            placeholder="Employee comparison"
            onEntityOptionSelected={handleChange}
            value={selectedEmployee || ''}
        />
    );
};

export default EmployeeSearch;
