import { IBusiness } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useAdvisor, useAdvisorFirm } from '~/hooks';
import AdvisorFirmInfoSection from './AdvisorFirmInfoSection';
import AdvisorInfoSection from './AdvisorInfoSection';

interface IProps {
    business: IBusiness;
}

const AdvisorInfoScreen = ({ business }: IProps): JSX.Element | null => {
    const advisorFirm = useAdvisorFirm(business.advisorFirmId);
    const primaryAdvisor = useAdvisor(business.primaryAdvisorId);
    const isError = isTrueForAnyQuery('isError', advisorFirm, primaryAdvisor);
    const isLoading = isTrueForAnyQuery('isLoading', advisorFirm, primaryAdvisor);
    if (isError) return <ErrorPage />;
    if (isLoading || !advisorFirm.data || !primaryAdvisor.data) return <LoadingIndicator />;

    return (
        <div className="mx-auto max-w-4xl p-6">
            <AdvisorInfoSection advisor={primaryAdvisor.data} business={business} />
            <div className="my-8 border-t border-highlight">
                <AdvisorFirmInfoSection advisorFirm={advisorFirm.data} />
            </div>
        </div>
    );
};

export default AdvisorInfoScreen;
