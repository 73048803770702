import { CurrentUserMenu, NavBar } from '~/components';
import { BrowserRouter, Redirect, Route, Switch } from '~/routing';
import { AdminPageType } from './admin/AdminPageType';
import AdvisorEdit from './advisorEdit';
import AdvisorFirmEdit from './advisorFirm';
import AdvisorFirmList from './advisorFirmList';

const AdminLayout = (): JSX.Element => {
    return (
        <BrowserRouter>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <NavBar>
                    <div className="flex w-full justify-end items-center">
                        <CurrentUserMenu />
                    </div>
                </NavBar>
                <main
                    className="flex flex-col h-full max-h-full mx-auto overflow-y-auto pb-4 px-4 sm:px-6 lg:px-8 focus:outline-none w-full"
                    tabIndex={0}
                >
                    <Switch>
                        <Route path="/" exact>
                            <AdvisorFirmList />
                        </Route>
                        <Route path={`/${AdminPageType.Advisors}/:advisorId`}>
                            <AdvisorEdit />
                        </Route>
                        <Route path={`/:firmId`}>
                            <AdvisorFirmEdit />
                        </Route>
                        <Redirect to="/" />
                    </Switch>
                </main>
            </div>
        </BrowserRouter>
    );
};

export default AdminLayout;
