import { IBusinessPageType } from '@api';
import { useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { useAddBusiness, useAdvisorFirm, useBusinessAvatar, useCurrentUser, useUpdateBusiness } from '~/hooks';
import {
    mapFormDataToApi,
    schemaValidation as businessInfoFormSchemaValidation,
} from '~/pages/business/edit/businessInfo/BusinessInfoForm';
import { useHistory } from '~/routing';
import { getArrayOfEnum } from '~/utils/enumUtils';
import { errorParser } from '~/utils/errorParser';
import { yupToFormErrors } from '~/utils/yupUtils';
import { IPageSetting } from './PresentationSettingsForm';
import { WizardState } from './useWizardState';

const getInitialPresentationSettings = (
    pages: IBusinessPageType[],
    firmExcludedPages: IBusinessPageType[]
): IPageSetting[] => {
    const defaultEnabled = pages.map(pageType => ({
        enabled: true,
        pageType,
    }));
    const defaultDisabled = getArrayOfEnum(IBusinessPageType)
        .filter(p => !defaultEnabled.some(e => e.pageType === p) && !firmExcludedPages.includes(p))
        .map(pageType => ({
            enabled: false,
            pageType,
        }));

    return defaultEnabled.concat(defaultDisabled);
};

export const useSaveBusiness = (wizardState: WizardState): (() => Promise<boolean>) => {
    const { businessAvatar, businessFormData, businessId: savedBusinessId, setWizardState } = wizardState;
    const { data: currentUser } = useCurrentUser();
    const advisorFirmId = currentUser?.advisorFirmId || '';
    const { data: advisorFirm, refetch: refetchAdvisorFirm } = useAdvisorFirm(advisorFirmId);
    const addBusiness = useAddBusiness();
    const updateBusiness = useUpdateBusiness(savedBusinessId || '');
    const updateBusinessAvatar = useBusinessAvatar();

    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (!initialized) {
            refetchAdvisorFirm();
            setInitialized(true);
        }
    }, [initialized, refetchAdvisorFirm]);

    return async () => {
        let businessId = savedBusinessId;
        if (businessId) {
            await updateBusiness.mutateAsync(mapFormDataToApi(businessFormData));
        } else {
            try {
                const { id, pages } = await addBusiness.mutateAsync({
                    ...mapFormDataToApi(businessFormData),
                    advisorFirmId: currentUser?.advisorFirmId || '',
                    pages: advisorFirm?.pages,
                    primaryAdvisorId: currentUser?.id || '',
                });
                businessId = id;
                setWizardState({
                    businessId,
                    pageSettings: getInitialPresentationSettings(pages || [], advisorFirm?.pagesExcluded || []),
                });
            } catch (e) {
                const fieldValidationErrors = errorParser.getFieldValidationErrors(e);
                if (fieldValidationErrors) setWizardState({ validationErrors: fieldValidationErrors });
                return false;
            }
        }
        if (businessAvatar?.file) {
            await updateBusinessAvatar.mutateAsync({ avatar: businessAvatar.file, businessId });
        }
        return true;
    };
};

export const validateBusiness = (wizardState: WizardState): boolean => {
    const { businessFormData, setWizardState } = wizardState;

    try {
        businessInfoFormSchemaValidation.validateSync(businessFormData, { abortEarly: false });
    } catch (err: unknown) {
        if (ValidationError.isError(err)) {
            setWizardState({ validationErrors: yupToFormErrors(err) });
        }
        return false;
    }
    return true;
};

export const useSavePrimaryContact = (wizardState: WizardState): (() => Promise<boolean>) => {
    const { businessFormData, businessId, primaryEmployeeId } = wizardState;
    const updateBusiness = useUpdateBusiness(businessId || '');

    return async () => {
        await updateBusiness.mutateAsync({
            ...mapFormDataToApi(businessFormData),
            primaryEmployeeId,
        });
        return true;
    };
};

export const useSavePresentationSettings = (wizardState: WizardState): (() => Promise<boolean>) => {
    const { businessFormData, pageSettings, primaryEmployeeId, businessId: savedBusinessId } = wizardState;
    const { push } = useHistory();
    const updateBusiness = useUpdateBusiness(savedBusinessId || '');

    return async () => {
        await updateBusiness.mutateAsync({
            ...mapFormDataToApi(businessFormData),
            pages: pageSettings.filter(ps => ps.enabled).map(ps => ps.pageType),
            primaryEmployeeId,
        });
        push(`/${savedBusinessId}/Present`);
        return true;
    };
};
