import { IBusiness } from '@api';
import { useState } from 'react';
import { Button, ErrorPage } from '~/components';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import { useBonusRightConnectionStatus } from '~/hooks/bonusRightConnection';
import ConnectToBonusRightForm from '../../bonusRight/teaser/ConnectToBonusRightForm';
import CardSkeleton from '../CardSkeleton';
import CompensationChart from './CompensationChart';

interface IProps {
    business: IBusiness;
}

const CompensationCardContent = ({ business }: IProps): JSX.Element => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const employeesQuery = useEmployees(business.id);
    const connectionStatusQuery = useBonusRightConnectionStatus(
        business.id,
        !business.isSampleCase && !employeesQuery.isLoading
    );
    const isLoading = isTrueForAnyQuery('isLoading', connectionStatusQuery, employeesQuery);
    const isError = isTrueForAnyQuery('isError', connectionStatusQuery, employeesQuery);
    const { data: connectionStatus = { isConnected: business.isSampleCase } } = connectionStatusQuery;

    if (isError) return <ErrorPage className="pb-5" hideActions iconColor="divider" />;
    if (isLoading) return <CardSkeleton />;

    return connectionStatus.isConnected ? (
        <CompensationChart business={business} employees={employeesQuery.data || []} />
    ) : (
        <ConnectToBonusRightForm businessId={business.id} isLoggingIn={isLoggingIn} setIsLoggingIn={setIsLoggingIn}>
            <Button color="primary" text="Log In" type="submit" />
        </ConnectToBonusRightForm>
    );
};

export default CompensationCardContent;
