import { IEmployee } from '@api';

const getVotingEquityPercentage = (
    employees: Pick<IEmployee, 'id' | 'isOwner'>[],
    equityPercentageByEmployeeId: Record<string, number>
) =>
    employees.reduce<number>((a, e) => {
        if (!e.isOwner) return a;
        return a + equityPercentageByEmployeeId[e.id];
    }, 0);

export default getVotingEquityPercentage;
