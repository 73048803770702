import classNames from 'classnames';
import ArrowHead, { ArrowHeadDirection, arrowHeadSize } from './ArrowHead';

interface IProps {
    colorClassName?: string;
    containerHeight: number;
    containerWidth: number;
    markerId?: string;
    strokeWidth?: number;
}

const arrowheadId = 'arrowhead';

const DownArrow = ({
    colorClassName = 'text-textDisabled',
    containerHeight,
    containerWidth,
    markerId = arrowheadId,
    strokeWidth = 6,
}: IProps): JSX.Element => (
    <svg height={containerHeight} width={containerWidth}>
        <defs>
            <ArrowHead className={colorClassName} direction={ArrowHeadDirection.down} id={markerId} />
        </defs>
        <line
            className={classNames('stroke-current', colorClassName)}
            x1={containerWidth / 2}
            y1={0}
            x2={containerWidth / 2}
            y2={containerHeight - arrowHeadSize * strokeWidth}
            strokeWidth={strokeWidth}
            markerEnd={`url(#${markerId})`}
        />
    </svg>
);

export default DownArrow;
