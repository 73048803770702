import {
    AdvisorFirmsApi,
    IAdvisorFirm,
    IAdvisorFirmCreateRequest,
    IAdvisorFirmSummary,
    IAdvisorFirmUpdateRequest,
    IAvatar,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { businessQueryKey } from '.';

const advisorFirmsApi = new AdvisorFirmsApi(undefined, config.api.baseUrl);

const advisorFirmsQueryKey = 'advisorFirms';
export const getAdvisorFirmQueryKey = (advisorFirmId: string, ...rest: string[]): QueryKey => [
    advisorFirmsQueryKey,
    advisorFirmId,
    ...(rest || []),
];

export const useAddAdvisorFirm = (): UseMutationResult<IAdvisorFirm, IProblemDetails, IAdvisorFirmCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IAdvisorFirm, IProblemDetails, IAdvisorFirmCreateRequest>(
        request => advisorFirmsApi.postAdvisorFirm(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(advisorFirmsQueryKey);
            },
        }
    );

    return mutation;
};

export const useAdvisorFirms = (): UseQueryResult<IAdvisorFirm[], IProblemDetails> =>
    useQuery(advisorFirmsQueryKey, () => advisorFirmsApi.getAdvisorFirms().then(response => response.data));

export const useAdvisorFirm = (advisorFirmId: string, enabled = true): UseQueryResult<IAdvisorFirm, IProblemDetails> =>
    useQuery(
        getAdvisorFirmQueryKey(advisorFirmId),
        () => advisorFirmsApi.getAdvisorFirm(advisorFirmId).then(response => response.data),
        { enabled: !!advisorFirmId && enabled }
    );

export const useAdvisorFirmSummary = (
    advisorFirmId: string,
    enabled = true
): UseQueryResult<IAdvisorFirmSummary, IProblemDetails> =>
    useQuery(
        getAdvisorFirmQueryKey(advisorFirmId),
        () => advisorFirmsApi.getAdvisorFirmSummary(advisorFirmId).then(response => response.data),
        { enabled: !!advisorFirmId && enabled }
    );

export const useDeleteAdvisorFirm = (): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        advisorFirmId => advisorFirmsApi.deleteAdvisorFirm(advisorFirmId, undefined).then(response => response.data),
        {
            onSuccess: (_, advisorFirmId) => {
                invalidateAdvisorFirm(queryClient, advisorFirmId);
            },
        }
    );

    return mutation;
};

export const useUpdateAdvisorFirm = (
    advisorFirmId: string
): UseMutationResult<void, IProblemDetails, IAdvisorFirmUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IAdvisorFirmUpdateRequest>(
        request => advisorFirmsApi.putAdvisorFirm(advisorFirmId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateAdvisorFirm(queryClient, advisorFirmId);
            },
        }
    );

    return mutation;
};

interface IAdvisorFirmAvatarParams {
    advisorFirmId: string;
    avatar: File;
}

export const useAdvisorFirmAvatar = (): UseMutationResult<IAvatar, IProblemDetails, IAdvisorFirmAvatarParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IAvatar, IProblemDetails, IAdvisorFirmAvatarParams>(
        ({ avatar, advisorFirmId }: IAdvisorFirmAvatarParams) =>
            advisorFirmsApi.setAdvisorFirmAvatar(advisorFirmId, avatar).then(response => response.data),
        {
            onSuccess: (_, { advisorFirmId }) => {
                invalidateAdvisorFirm(queryClient, advisorFirmId);
            },
        }
    );

    return mutation;
};

function invalidateAdvisorFirm(queryClient: QueryClient, advisorFirmId: string) {
    queryClient.invalidateQueries(advisorFirmsQueryKey);
    queryClient.invalidateQueries(getAdvisorFirmQueryKey(advisorFirmId));

    // invalidate individual businesses to clear advisorFirm properties
    queryClient.invalidateQueries([businessQueryKey]);
}
