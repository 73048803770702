import { IEmployee } from '@api';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, Modal, TextInput } from '~/components';
import { useAppDispatch } from '~/redux/hooks';
import { showSuccessNotification } from '~/redux/successNotification';
import NameFormatter from '~/utils/nameFormatter';
import { yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    disabled?: boolean;
    employee?: IEmployee;
    open: boolean;
    onSave: (emailAddress: string) => Promise<void>;
    setIsOpen: (open: boolean) => void;
}

const schemaValidation = Yup.object().shape({
    emailAddress: Yup.string().required().email().label('Email'),
});

const EmployeeEmailModal = ({ disabled, employee, open, onSave, setIsOpen }: IProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const showSuccess = () => dispatch(showSuccessNotification());
    const [emailAddress, setEmailAddress] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const onSaveClick = async () => {
        try {
            schemaValidation.validateSync({ emailAddress }, { abortEarly: false });
            await onSave(emailAddress);
            showSuccess();
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
        }
    };

    useEffect(() => {
        setEmailAddress('');
        setErrors({});
    }, [open]);

    return (
        <Modal maxWidth="md" open={open} title="Add Email" setOpen={setIsOpen} disableBackdropClickClose={disabled}>
            <div className="p-6">
                <div className="mb-6">
                    <p className="mb-6 text-sm">
                        You will need to add an email address for {NameFormatter.getName(employee)} before they can be
                        added as a Portal User.
                    </p>
                    <TextInput
                        errorMessage={errors.emailAddress}
                        disabled={disabled}
                        label="Email"
                        value={emailAddress}
                        onChange={setEmailAddress}
                    />
                </div>
                <div className="space-x-3 flex justify-end">
                    <Button color="secondary" disabled={disabled} text="Cancel" onClick={() => setIsOpen(false)} />
                    <Button color="primary" disabled={disabled} text="Save" onClick={onSaveClick} />
                </div>
            </div>
        </Modal>
    );
};

export default EmployeeEmailModal;
