import { IBusiness } from '@api';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrentUserMenu, NavBar } from '~/components';
import TeamMenu from './team/TeamMenu';
import TodosMenu from './todos/TodosMenu';

interface IProps {
    business: IBusiness;
    onOpenNav?: () => void;
}

const BusinessPageHeader = ({ business, onOpenNav }: IProps): JSX.Element => (
    <NavBar>
        {onOpenNav && (
            <button className="lg:hidden" onClick={() => onOpenNav()}>
                <FontAwesomeIcon icon={faBars} />
            </button>
        )}
        <div></div>
        <div className="flex items-center space-x-4">
            <TeamMenu business={business} />
            <TodosMenu business={business} />
            <CurrentUserMenu />
        </div>
    </NavBar>
);

export default BusinessPageHeader;
