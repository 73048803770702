import { IDocument, IUserEntitlementType } from '@api';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Button, Can, DropdownMenu } from '~/components';
import { ModalType, setSelectedModal, toggleSelectedItem } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';

interface IProps {
    item: IDocument;
}

const ItemDropdown = ({ item }: IProps): JSX.Element => {
    const { selectedItems } = useAppSelector(s => s.documentCenter);
    const dispatch = useAppDispatch();
    const isSelected = selectedItems[item.id] !== undefined;
    const invisible = Object.keys(selectedItems).length > 1 || (Object.keys(selectedItems).length === 1 && !isSelected);

    return (
        <DropdownMenu position="right">
            <DropdownMenu.Trigger
                as={Button}
                className={classNames('px-3 py-1 mr-1', { invisible })}
                color="link"
                iconOnly={faEllipsisV}
                refName="buttonRef"
                title={item.name}
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            />
            <DropdownMenu.Item
                onClick={() => {
                    if (!isSelected) dispatch(toggleSelectedItem(item));
                    dispatch(setSelectedModal({ type: ModalType.Delete }));
                }}
            >
                Delete
            </DropdownMenu.Item>
            <Can hasEntitlement={IUserEntitlementType.DocumentUpdate}>
                <DropdownMenu.Item
                    onClick={() => {
                        if (!isSelected) dispatch(toggleSelectedItem(item));
                        dispatch(setSelectedModal({ type: ModalType.Move }));
                    }}
                >
                    Move
                </DropdownMenu.Item>
                <DropdownMenu.Item
                    onClick={() => {
                        if (!isSelected) dispatch(toggleSelectedItem(item));
                        dispatch(setSelectedModal({ type: ModalType.Rename }));
                    }}
                >
                    Rename
                </DropdownMenu.Item>
            </Can>
            {/* <Can hasEntitlement={IUserEntitlementType.DocumentShare} condition={item.isFolder}>
                <DropdownMenu.Item
                    onClick={() => {
                        if (!isSelected) dispatch(toggleSelectedItem(item));
                        dispatch(setSelectedModal({ type: ModalType.Share }));
                    }}
                >
                    Share
                </DropdownMenu.Item>
            </Can> */}
        </DropdownMenu>
    );
};

export default ItemDropdown;
