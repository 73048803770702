import { IBizEquityBusiness, IBusiness } from '@api';
import { useState } from 'react';
import { useStorageState } from 'react-storage-hooks';
import { SlideOver } from '~/components';
import { getStorageKey } from '~/utils/storageKeys';
import Card from '../../../scorecard/Card';
import Map from './Map';
import { getInitialValues } from './modifiers';
import Projection from './Projection';
import ValuationMetric from './ValuationMetric';
import ValueEstimator from './ValueEstimator';
import ValueRank from './ValueRank';

interface IProps {
    business: IBusiness;
    bizEquityBusiness: IBizEquityBusiness;
}

const OverviewPage = ({ business, bizEquityBusiness }: IProps): JSX.Element => {
    const [isAssetInfoOpen, setIsAssetInfoOpen] = useState(false);
    const [isEnterpriseInfoOpen, setIsEnterpriseInfoOpen] = useState(false);
    const [isEquityInfoOpen, setIsEquityInfoOpen] = useState(false);
    const [isLiquidationInfoOpen, setIsLiquidationInfoOpen] = useState(false);
    const [modifiers, setModifiers] = useStorageState(
        localStorage,
        getStorageKey(`${business.id}.valueEstimator`),
        getInitialValues(3)
    );

    return (
        <div className="flex-1 gap-4 grid grid-cols-1 3xl:grid-cols-2 py-6">
            <div className="gap-4 grid grid-cols-1 md:grid-cols-2 auto-rows-min">
                <div className="gap-4 grid grid-cols-1">
                    <ValuationMetric
                        backgroundColorClass="bg-information"
                        iconColorClass="text-textDefault"
                        name="Estimated Value"
                        onClick={() => setIsEquityInfoOpen(true)}
                        value={bizEquityBusiness.equityValuation}
                    />
                    <ValuationMetric
                        name="Asset Value"
                        onClick={() => setIsAssetInfoOpen(true)}
                        value={bizEquityBusiness.assetValuation}
                    />
                    <ValuationMetric
                        name="Enterprise Value"
                        onClick={() => setIsEnterpriseInfoOpen(true)}
                        value={bizEquityBusiness.enterpriseValuation}
                    />
                    <ValuationMetric
                        name="Liquidation Value"
                        onClick={() => setIsLiquidationInfoOpen(true)}
                        value={bizEquityBusiness.liquidationValuation}
                    />
                </div>
                <Projection initialValue={bizEquityBusiness.equityValuation || 0} />
                <Card className="md:col-span-2 grid grid-cols-2 h-80">
                    <ValueRank bizEquityBusiness={bizEquityBusiness} />
                    <Map business={business} bizEquityBusiness={bizEquityBusiness} />
                </Card>
            </div>
            <div className="bg-background1 rounded-lg">
                <ValueEstimator
                    baseValue={bizEquityBusiness.equityValuation || 0}
                    modifiers={modifiers}
                    setModifiers={setModifiers}
                />
            </div>
            <SlideOver isOpen={isAssetInfoOpen} onClose={() => setIsAssetInfoOpen(false)} title="Asset Value">
                <p>
                    Includes only inventory/supplies, fixed assets and all intangible assets. Excludes all liquid
                    financial assets and all liabilities. Buyer operates from newly formed legal entity.
                </p>
                <p>
                    This common transaction-oriented fair market value conclusion includes the firm's inventory,
                    furniture, fixtures and equipment and all intangible assets ranging from customer base to goodwill.
                </p>
                <p className="p-8 bg-background2 rounded-lg">
                    The seller keeps the cash and receivables but delivers the business free and clear of all debt.
                </p>
            </SlideOver>
            <SlideOver
                isOpen={isEnterpriseInfoOpen}
                onClose={() => setIsEnterpriseInfoOpen(false)}
                title="Enterprise Value"
            >
                <p>
                    In middle-market transactions, it is also helpful to distinguish between "equity value" and
                    "enterprise value". Enterprise value is a reflection of the firm's value as a functioning entity and
                    it is helpful in that it facilitates the comparison of companies with varying levels of debt.
                </p>
                <p>
                    This fair market value estimate is equal to the "total value of the firm" or the value of the firm's
                    equity plus its long term debt, e.g. it reflects the value of the entire capital structure (equity
                    holders and debt holders) or "enterprise".
                </p>
            </SlideOver>
            <SlideOver isOpen={isEquityInfoOpen} onClose={() => setIsEquityInfoOpen(false)} title="Estimated Value">
                <p>
                    This fair market value conclusion is the value of the company available to its owners or
                    shareholders and incorporates all of the assets included in the “asset value” plus the firm's liquid
                    financial assets (cash, A/R, deposits, etc.) and minus its liabilities (ST and LT).
                </p>
                <p className="p-8 bg-background2 rounded-lg">
                    The buyer is acquiring all of the assets and liabilities, on and off the balance sheet.
                </p>
            </SlideOver>
            <SlideOver
                isOpen={isLiquidationInfoOpen}
                onClose={() => setIsLiquidationInfoOpen(false)}
                title="Liquidation Value"
            >
                <p>
                    The liquidation value conclusion is based on the key assumption of insolvency and the immediate sale
                    of all assets (on or off the balance sheet) at or near "fire sale" level coupled with the nearly
                    simultaneous retirement of all liabilities. This figure does not include accounts receivable.
                </p>
            </SlideOver>
        </div>
    );
};

export default OverviewPage;
