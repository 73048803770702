import { TableCell, TableRow } from '~/components';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';

interface IProps {
    investment: number;
    withdrawalRate: number;
}

const IncomeTableRow = ({ investment, withdrawalRate }: IProps): JSX.Element => (
    <TableRow className="font-bold text-xl">
        <TableCell background="bg-transparent">{formatPercentage(withdrawalRate)}</TableCell>
        <TableCell background="bg-transparent" className="text-right">
            {CurrencyFormatter.format(investment * withdrawalRate)}
        </TableCell>
    </TableRow>
);

export default IncomeTableRow;
