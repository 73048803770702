import { Skeleton } from '~/components';

const CardSkeleton = (): JSX.Element => (
    <div className="flex flex-col flex-grow">
        <Skeleton className="h-8 w-1/2" />
        <Skeleton className="flex-grow" style={{ minHeight: '40%' }} />
        <Skeleton className="h-8" />
        <Skeleton className="h-8" />
        <Skeleton className="h-8 w-3/4" />
    </div>
);

export default CardSkeleton;
