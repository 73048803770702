import { IExternalTeamMember, IExternalTeamMemberCreateRequest, IExternalTeamMemberUpdateRequest } from '@api';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AvatarInput, FormActions, SlideOver, TextInput, useAvatarInput } from '~/components';
import { trimString, yupToFormErrors } from '~/utils/yupUtils';

const mapFormDataToApi = (
    formData: IExternalTeamMemberFormData
): IExternalTeamMemberCreateRequest | IExternalTeamMemberUpdateRequest => ({
    ...formData,
    emailAddress: formData.emailAddress?.length ? formData.emailAddress : undefined,
});

interface IProps {
    teamMember?: IExternalTeamMember;
    isOpen: boolean;
    onClose: () => void;
    onSave: (
        request: IExternalTeamMemberCreateRequest | IExternalTeamMemberUpdateRequest,
        avatar: File | undefined
    ) => Promise<void>;
    title: string;
}

export type IExternalTeamMemberFormData = Pick<
    IExternalTeamMember,
    'firstName' | 'emailAddress' | 'lastName' | 'title'
>;

const getInitialValues = (teamMember?: IExternalTeamMember): IExternalTeamMemberFormData => {
    return {
        emailAddress: teamMember?.emailAddress,
        firstName: teamMember?.firstName || '',
        lastName: teamMember?.lastName || '',
        title: teamMember?.title || '',
    };
};
const schemaValidation = Yup.object().shape({
    emailAddress: Yup.string().label('Email').email().nullable(),
    firstName: Yup.string().transform(trimString).label('First Name').required(),
    lastName: Yup.string().transform(trimString).label('Last Name').required(),
    title: Yup.string().transform(trimString).label('Relationship').required(),
});

const ExternalTeamMemberForm = ({ teamMember, isOpen, onClose, onSave, title }: IProps): JSX.Element => {
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<IExternalTeamMemberFormData>(getInitialValues(teamMember));
    const [newAvatar, setNewAvatar] = useAvatarInput(isOpen);
    const handleSave = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        const request = mapFormDataToApi(formData);
        return onSave(request, newAvatar?.file);
    };
    const resetForm = (teamMember: IExternalTeamMember | undefined) => {
        setValidationErrors({});
        setFormData(getInitialValues(teamMember));
    };

    useEffect(() => resetForm(teamMember), [isOpen, teamMember]);

    return (
        <SlideOver
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={
                <FormActions onCancel={onClose} onSave={handleSave} setValidationErrors={setValidationErrors} />
            }
            title={title}
        >
            <div className="col">
                <TextInput
                    errorMessage={validationErrors.firstName}
                    label="First Name"
                    value={formData.firstName}
                    onChange={firstName => setFormData({ ...formData, firstName })}
                />
            </div>
            <div className="col">
                <TextInput
                    errorMessage={validationErrors.lastName}
                    label="Last Name"
                    value={formData.lastName}
                    onChange={lastName => setFormData({ ...formData, lastName })}
                />
            </div>
            <div className="col">
                <TextInput
                    errorMessage={validationErrors.title}
                    label="Relationship"
                    value={formData.title || ''}
                    onChange={title => setFormData({ ...formData, title })}
                />
            </div>
            <div className="col">
                <TextInput
                    errorMessage={validationErrors.emailAddress}
                    label="Email"
                    value={formData.emailAddress || ''}
                    onChange={emailAddress => setFormData({ ...formData, emailAddress })}
                />
            </div>
            <div className="col">
                <AvatarInput
                    errorMessage={validationErrors.avatar}
                    label="Photo"
                    src={newAvatar?.url || teamMember?.avatar}
                    onChange={setNewAvatar}
                />
            </div>
        </SlideOver>
    );
};

export default ExternalTeamMemberForm;
