import { IBusiness } from '@api';
import Card from '../Card';
import Header from '../Header';
import CommunicationCardContent from './CommunicationCardContent';

interface IProps {
    business: IBusiness;
}

const CommunicationCard = ({ business }: IProps): JSX.Element => {
    return (
        <Card padding>
            <Header text="Communication" />
            <CommunicationCardContent business={business} />
        </Card>
    );
};

export default CommunicationCard;
