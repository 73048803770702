import { IBizEquityPlace } from '@api';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

interface IProps {
    className: string;
    iconColorClassName?: string;
    place: IBizEquityPlace;
    lat: number;
    lng: number;
}

const height = 44;
const width = 33;
const iconSize = 20;
const popoverMaxWidth = 400;
const popoverMaxHeight = 52;
const popoverTopMargin = 4;
const iconMaskBaseUri = 'https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet';

const PlaceMarker = ({ className, iconColorClassName = 'text-information', place }: IProps): JSX.Element => {
    const [showInfo, setShowInfo] = useState(false);
    const mask = `url(${iconMaskBaseUri}.svg) no-repeat center / contain`;

    return (
        <>
            <div
                className={`absolute ${showInfo ? 'visible' : 'hidden'} text-center z-10`}
                style={{
                    width: `${popoverMaxWidth}px`,
                    left: `-${popoverMaxWidth / 2}px`,
                    top: `-${height + popoverMaxHeight + popoverTopMargin}px`,
                }}
            >
                <div className="bg-background1 rounded-lg px-3 py-3 inline-block">
                    <div className="text-lg font-bold truncate">{place.name}</div>
                </div>
            </div>
            <div
                className={`absolute ${className} cursor-pointer`}
                style={{ width: `${width}px`, left: `-${width / 2}px`, top: `-${height}px` }}
                onMouseEnter={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)}
            >
                <div
                    className="absolute bg-textDefault"
                    style={{
                        left: `${(33 - iconSize) / 2}px`,
                        top: '6px',
                        width: `${iconSize}px`,
                        height: `${iconSize}px`,
                        mask,
                        WebkitMask: mask,
                    }}
                ></div>
                <FontAwesomeIcon className={iconColorClassName} icon={faMapMarker} size="4x" />
            </div>
        </>
    );
};
export default PlaceMarker;
