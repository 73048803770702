import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CurrencyFormatter from '~/utils/currencyFormatter';

interface IProps {
    backgroundColorClass?: string;
    iconColorClass?: string;
    name: string;
    onClick: () => void;
    value?: number | null;
}

const ValuationMetric = ({
    backgroundColorClass = 'bg-background1',
    iconColorClass = 'text-textLink',
    name,
    onClick,
    value,
}: IProps): JSX.Element => (
    <div
        className={classNames(
            backgroundColorClass,
            'flex flex-col justify-center pl-8 pr-6 py-5 rounded-lg cursor-pointer'
        )}
        onClick={onClick}
    >
        <div className="flex justify-between">
            <div className="text-sm">{name}</div>
            <div className={iconColorClass}>
                <FontAwesomeIcon icon={faInfoCircle} />
            </div>
        </div>
        <div className="text-3xl">{CurrencyFormatter.format(value)}</div>
    </div>
);

export default ValuationMetric;
