import { IBizEquityBusiness } from '@api';
import { SeriesOptionsType } from 'highcharts';
import { getDataOverTime, getTotalDebtOverTime } from './chartUtils';
import MetricsChart from './MetricsChart';

interface IProps {
    bizEquityBusiness: IBizEquityBusiness;
}

const toChartSeries = (bizEquityBusiness: IBizEquityBusiness): SeriesOptionsType[] | null => {
    const statements = bizEquityBusiness.yearlyStatements;
    if (!statements) {
        return null;
    }

    const income = getDataOverTime(statements, 'income');
    const receivables = getDataOverTime(statements, 'accountsReceivables');
    const revenue = getDataOverTime(statements, 'revenue');
    const totalDebt = getTotalDebtOverTime(statements);

    if (!income || !receivables || !revenue || !totalDebt) {
        return null;
    }

    const data: SeriesOptionsType[] = [
        {
            name: 'Revenue',
            color: 'var(--color-info-E)',
            data: revenue,
            type: 'line',
        },
        {
            name: 'Total Debt',
            color: 'var(--color-info-B)',
            data: totalDebt,
            type: 'line',
        },
        {
            name: 'Income',
            color: 'var(--color-info-D)',
            data: income,
            type: 'line',
        },
        {
            name: 'Receivables',
            color: 'var(--color-info-G)',
            data: receivables,
            type: 'line',
        },
    ];
    return data;
};

const GrowthTrendsOfDriversChart = ({ bizEquityBusiness }: IProps): JSX.Element => {
    return (
        <MetricsChart
            description="This chart gives an indication of growth trends of the important drivers of the valuation. Please provide three years of Pretax Income, Revenue, and Accounts Receivables information to view."
            series={toChartSeries(bizEquityBusiness)}
            title="Growth Trends of Drivers of Valuation"
        />
    );
};

export default GrowthTrendsOfDriversChart;
