import { BrowserRouter, Route, Switch } from '~/routing';
import EmployeeUploadDelegateApp from './employeeUploadDelegate/EmployeeUploadDelegateApp';

const EmployeeUploadDelegateLayout = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/:businessId">
                    <EmployeeUploadDelegateApp />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default EmployeeUploadDelegateLayout;
