import { IProblemDetails, ITodo, ITodoCreateRequest, ITodoUpdateRequest, TodosApi } from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';
import { getCurrentUserQueryKey } from './user';

const todosApi = new TodosApi(undefined, config.api.baseUrl);
const myTodosQueryKey = getCurrentUserQueryKey('todos');
export const getTodosQueryKey = (businessId: string, ...rest: string[]): QueryKey =>
    getBusinessQueryKey(businessId, 'todos', ...(rest || []));

export const useAddTodo = (businessId: string): UseMutationResult<ITodo, IProblemDetails, ITodoCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<ITodo, IProblemDetails, ITodoCreateRequest>(
        request => todosApi.postTodo(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDeleteTodo = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        todoId => todosApi.deleteTodo(todoId, undefined).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useGetMyTodos = (): UseQueryResult<ITodo[], IProblemDetails> =>
    useQuery(myTodosQueryKey, () => todosApi.getTodos().then(response => response.data));

export const useUpdateTodo = (
    businessId: string,
    todoId: string
): UseMutationResult<void, IProblemDetails, ITodoUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, ITodoUpdateRequest>(
        request => todosApi.putTodo(todoId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
                queryClient.invalidateQueries(myTodosQueryKey);
            },
        }
    );

    return mutation;
};

export const useTodos = (businessId: string, enabled = true): UseQueryResult<ITodo[], IProblemDetails> =>
    useQuery(
        getTodosQueryKey(businessId),
        () => todosApi.getTodosForBusiness(businessId).then(response => response.data),
        { enabled: !!businessId && enabled }
    );

function invalidateBusiness(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getBusinessQueryKey(businessId));
}
