import { IBizEquityYearlyStatementUpdate } from '@api';
export interface IRowData {
    key: keyof IBizEquityYearlyStatementUpdate;
    displayName: string;
    infoContent: React.ReactNode;
}

export const assetYearRows: IRowData[] = [
    {
        displayName: 'Cash',
        infoContent: `This account should include cash and all cash equivalents such as checking and savings accounts, short term CD's, money market funds and even marketable securities which can be quickly liquidated.`,
        key: 'cash',
    },
    {
        displayName: 'Accounts Receivable',
        infoContent: `This is the amount of money owed to your company by clients who have been provided goods or services on credit.`,
        key: 'accountsReceivables',
    },
    {
        displayName: 'Inventory',
        infoContent: `Typically measured at original cost, this account should reflect office supplies, raw materials, work in progress and finished goods.`,
        key: 'inventory',
    },
    {
        displayName: 'Other Current Assets',
        infoContent: `This 'catchall' account typically includes items such as lease deposits, prepaid expenses and employee/officer loan receivables but may include accounts such as accrued revenue and deferred income tax.`,
        key: 'otherCurrentAssets',
    },
    {
        displayName: 'Fixed Assets',
        infoContent: (
            <>
                <span>{`Commonly referred to as 'furniture, fixtures and equipment' or 'property, plant and equipment', these long term assets are subject to ongoing depreciation expense. Most balance sheets will present their 'original cost' followed by 'accumulated depreciation' with the difference being 'book value'. For financial analysis purposes, book value should be entered in this line item.`}</span>
                <br />
                <br />
                <span>
                    To ensure correct calculations: If you{' '}
                    <b>
                        <i>own</i>
                    </b>{' '}
                    the property{' '}
                    <b>
                        <i>do not</i>
                    </b>{' '}
                    add the fair market value of the property to this field. It will be asked later in the Operations
                    Step.
                </span>
            </>
        ),
        key: 'fixedAssets',
    },
    {
        displayName: 'Intangible Assets',
        infoContent: `Most balance sheets will NOT include intangible assets based on GAAP rules. The common exception is when the subject company has been purchased via the 'asset sale' deal structure which requires the buyer and seller to agree on the 'allocation of purchase price'. Most such allocations will include value attributed to intangible assets such as goodwill, customer base, tradename or covenant not to compete. Similar to fixed assets, the balance entered here should be the 'book value' figure (original cost less accumulated amortization). Certain 'internally-generated' intangible assets may be capitalized and amortized, but this generally applies to advanced, high-tech-oriented businesses.`,
        key: 'intangibleAssets',
    },
];

export const incomeAndExpensesYearRows: IRowData[] = [
    {
        displayName: 'Revenue',
        infoContent: `The amount of money your business receives for selling a good or service.`,
        key: 'revenue',
    },
    {
        displayName: 'Pretax Income',
        infoContent: `This is the amount of money your business earns (revenue minus expenses) before deducting income taxes. Also known as pretax profit.`,
        key: 'income',
    },
    {
        displayName: 'Officer Compensation',
        infoContent: `This is the amount of actual compensation (wages, bonus payments, perks and other tax deductible discretionary payments on behalf of the primary owner-operator) paid to the primary owner-operator. Distributions (return of capital) should NOT be added here as they are already reflected in the pretax income entry above.`,
        key: 'officerCompensation',
    },
    {
        displayName: 'Interest Expense',
        infoContent: `This is the amount of interest expense paid by your company on various types of loans, credit card balances, etc.`,
        key: 'interestExpense',
    },
    {
        displayName: 'Non-Cash Expenses',
        infoContent: `These are expenses which did not require the use of cash in the current period. Customarily these items include only amortization and depreciation expense.`,
        key: 'nonCashExpenses',
    },
    {
        displayName: 'One-Time Losses',
        infoContent: `This does NOT include typical operations, sales & administrative expenses. Examples include: unusual & non-recurring expenses such as office renovations, losses from the sale of operating assets, and expenses not relating to the core operations of the business. Any amount entered will raise the amount of earnings and increase the company's value.`,
        key: 'oneTimeExpenses',
    },
    {
        displayName: 'One-Time Gains',
        infoContent: `This does NOT include gains from typical operations and sales. Examples include: revenue from unusual & non-recurring sales (such as the sale of a patent), gains from the sale of an operating asset (such as the sale of a delivery truck), and revenue not related to the core operations of the business (such as investment income). Any amount entered will lower the amount of earnings and decrease the company's value.`,
        key: 'oneTimeRevenues',
    },
];

export const liabilitiesYearRows: IRowData[] = [
    {
        displayName: 'Accounts Payable',
        infoContent: `This balance should reflect amounts currently owed to suppliers of all types. Amounts owed on credit cards could also be included here.`,
        key: 'accountsPayables',
    },
    {
        displayName: 'Short-Term Liabilities',
        infoContent: `Any financial obligation due within the next year which is not included in accounts payable. Common examples include credit card payables, line of credit balances and accrued liabilities related to payroll and taxes. This line item could also include 'current' amounts due to long term creditors, e.g. current portion (due over the next 12 months) of 10 year term loan from a bank.`,
        key: 'otherShortTermLiabilities',
    },
    {
        displayName: 'Bank Loans',
        infoContent: (
            <>
                This account represents the total principal balance outstanding on all loans made by banks or other
                financial institutions which possess a term of longer than one year.
                <br />
                <br />
                <em>
                    Any real estate related debt, such as mortgage balance, <b>should be excluded</b> here. It will be
                    asked later in the Operations Step
                </em>
            </>
        ),
        key: 'bankLoans',
    },
    {
        displayName: 'Long-Term Liabilities',
        infoContent: (
            <>
                <i>Other Long-Term Liabilities</i> may include real property mortgages with non-banks, obligations under
                long term capital leases, bonds payable and deferred income taxes. <br />
                In general, this category should include all long term liabilities other than bank loans. <br />
                <br />
                <em>
                    Any real estate related debt, such as mortgage balance, <b>should be excluded</b> here. It will be
                    asked later in the Operations Step
                </em>
            </>
        ),
        key: 'otherLongTermLiabilities',
    },
    {
        displayName: 'Contingent Liabilities',
        infoContent: `These are potential obligations which are dependent on the occurrence/non-occurrence of a certain event in the future. A common example would be a pending lawsuit which is likely to lead to legally enforceable financial payments owed by the company in the future. Other examples include potential warranty payments or payments related to the guarantee of a third party loan.`,
        key: 'contingentLiabilities',
    },
];

export enum Step {
    Assets = 'Assets',
    Growth = 'Growth',
    Income = 'Income',
    Liabilities = 'Liabilities',
    Operations = 'Operations',
}

interface IStep {
    description: string;
    displayName: string;
    step: Step;
}

export const steps: IStep[] = [
    {
        description: 'Enter as much income data as you have.',
        displayName: 'Income',
        step: Step.Income,
    },
    {
        description: "Tell us about the business' assets.",
        displayName: 'Assets',
        step: Step.Assets,
    },
    {
        description: 'What liabilities exist for the business?',
        displayName: 'Liabilities',
        step: Step.Liabilities,
    },
    {
        description: 'How is the business growing?',
        displayName: 'Growth',
        step: Step.Growth,
    },
    {
        description: 'Expand the details about operations.',
        displayName: 'Operations',
        step: Step.Operations,
    },
];
