import { faUndo } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button, DeleteConfirmationModal } from '~/components';
import { useDeleteEmployee, useEmployees } from '~/hooks';
import { Route, Switch, useHistory, useRouteMatch } from '~/routing';
import Employees from '../business/employees';
import EmployeesUpload from '../business/employees/EmployeesUpload';

interface IProps {
    businessId: string;
}

const EmployeeList = ({ businessId }: IProps): JSX.Element => {
    const history = useHistory();
    const match = useRouteMatch();
    const [deleteState, setDeleteState] = useState<{ isOpen: boolean; isSaving: boolean }>({
        isOpen: false,
        isSaving: false,
    });
    const { data: employees = [] } = useEmployees(businessId);
    const deleteEmployee = useDeleteEmployee(businessId);

    const onConfirmReset = async () => {
        setDeleteState({ ...deleteState, isSaving: true });
        const promises = employees.map(({ id }) => deleteEmployee.mutateAsync(id));
        await Promise.allSettled(promises);
        history.push(`${match.url}/Upload`);
        setDeleteState({ isOpen: false, isSaving: false });
    };

    return (
        <Switch>
            <Route path={`${match.path}/Upload`}>
                <h3 className="text-lg leading-6 font-medium mb-4">Employee List</h3>
                <EmployeesUpload businessId={businessId} />
            </Route>
            <Route>
                <>
                    <div className="flex justify-between mb-4">
                        <h3 className="text-lg leading-6 font-medium">Employee List</h3>
                        <Button
                            iconLeft={deleteState.isSaving ? undefined : faUndo}
                            text="Reset"
                            onClick={() => setDeleteState({ ...deleteState, isOpen: true })}
                            loading={deleteState.isSaving}
                        />
                    </div>
                    <Employees businessId={businessId} />
                    <DeleteConfirmationModal
                        onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                        onConfirm={onConfirmReset}
                        title="Reset Employees"
                        {...deleteState}
                    >
                        Are you sure you want to delete all current employees? This action cannot be undone.
                    </DeleteConfirmationModal>
                </>
            </Route>
        </Switch>
    );
};

export default EmployeeList;
