import { IDocument } from '@api';
import classnames from 'classnames';
import moment from 'moment';
import { toggleSelectedItem } from '~/redux/documentCenter';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { Link } from '~/routing';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';
import FolderIconTableView from './icons/FolderIconTableView';
import SelectionIcon from './icons/SelectionIcon';
import FolderDropdown from './ItemDropdown';
import { getFolderRoute } from './utils/routes';

interface IProps {
    item: IDocument;
}

const ItemFolderTableView = ({ item }: IProps): JSX.Element => {
    const { selectedItems } = useAppSelector(s => s.documentCenter);
    const isSelected = selectedItems[item.id] !== undefined;
    const dispatch = useAppDispatch();

    return (
        <tr
            className={classnames(
                'hover:bg-background2',
                { 'bg-background2': isSelected },
                { 'odd:bg-background1': !isSelected }
            )}
            onClick={() => dispatch(toggleSelectedItem(item))}
        >
            <td className="text-center">
                <SelectionIcon isSelected={isSelected} />
            </td>
            <td className="py-2 px-6">
                <DragAndDropUploadHandler folderId={item.id} className="flex items-center">
                    <Link
                        className="flex"
                        draggable={false}
                        onClick={e => e.stopPropagation()}
                        to={getFolderRoute(item.id)}
                    >
                        <FolderIconTableView color={item.color} icon={item.icon} />
                    </Link>
                    <Link draggable={false} onClick={e => e.stopPropagation()} to={getFolderRoute(item.id)}>
                        {item.name}
                    </Link>
                </DragAndDropUploadHandler>
            </td>
            <td className="align-middle py-2 px-6">
                {item.fileCount} {item.fileCount === 1 ? 'File' : 'Files'}
            </td>
            <td className="align-middle py-2 px-6">{moment(item.hierarchyUpdatedAt).fromNow()}</td>
            <td className="text-right p-2">
                <FolderDropdown item={item} />
            </td>
        </tr>
    );
};

export default ItemFolderTableView;
