import { IBusiness, IEmployee } from '@api';
import { Label } from '~/components';
import { NumericInput } from '~/components/NumericInput';
import { getAllocationState, ShareAllocationState } from '~/utils/sharesUtils';

interface IShareAllocationTextProps {
    business?: IBusiness;
    employees: IEmployee[];
    selectedEmployeeId?: string;
    shares?: number;
}

const ShareAllocationText = ({
    business,
    employees,
    selectedEmployeeId,
    shares,
}: IShareAllocationTextProps): JSX.Element => {
    if (!business || !selectedEmployeeId) return <span className="text-sm" />;

    const employeesWithDelta = employees
        .filter(e => e.id !== selectedEmployeeId)
        .concat({ id: selectedEmployeeId, shares } as IEmployee);

    const {
        outstandingShares,
        sharesAllocatedToEmployees,
        state: allocationState,
    } = getAllocationState(business, employeesWithDelta);

    return {
        [ShareAllocationState.NoSharesOutstanding]: (
            <span className="text-sm text-caution">Business has no outstanding shares.</span>
        ),
        [ShareAllocationState.TooManySharesAllocated]: (
            <span className="text-sm text-caution">{`Shares Allocated: ${sharesAllocatedToEmployees.toLocaleString()} / ${outstandingShares.toLocaleString()}`}</span>
        ),
        [ShareAllocationState.Valid]: <></>,
    }[allocationState];
};

interface IProps {
    business?: IBusiness;
    employees: IEmployee[];
    selectedEmployee?: Partial<IEmployee>;
    error: string;
    onChange: (value: number | null) => void;
    value: number | undefined;
}

const SharesInput = ({ business, employees, selectedEmployee, error, onChange, value }: IProps): JSX.Element => {
    return (
        <div>
            <div className="flex justify-between">
                <Label label="Shares" />
                <ShareAllocationText
                    business={business}
                    employees={employees}
                    selectedEmployeeId={selectedEmployee?.id}
                    shares={value}
                />
            </div>
            <NumericInput onChange={onChange} value={value} />
        </div>
    );
};

export default SharesInput;
