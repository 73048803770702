const isDefined = (num?: number): boolean => num !== null && num !== undefined;

const zeroRegex = /^-?0+\.?0*%$/;

export const formatPercentage = (value: number, options?: Intl.NumberFormatOptions): string => {
    const percentageFormat = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: isDefined(options?.minimumFractionDigits) ? options?.minimumFractionDigits : 0,
        maximumFractionDigits: isDefined(options?.maximumFractionDigits) ? options?.maximumFractionDigits : 2,
    });

    const result = percentageFormat.format(value);

    const isZero = zeroRegex.test(result);

    return isZero && result[0] === '-' ? result.slice(1) : result;
};
