import { IBizEquityYearlyStatementUpdate } from '@api';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrencyInput } from '~/components';
import InfoTooltip from './InfoTooltip';

interface IProps {
    formData: { [key: string]: IBizEquityYearlyStatementUpdate };
    infoContent: React.ReactNode;
    isRequired: boolean;
    name: string;
    propertyName: keyof IBizEquityYearlyStatementUpdate;
    validationErrors?: Record<string, string>;
    years: string[];
    setFormData: (formData: { [key: string]: IBizEquityYearlyStatementUpdate }) => void;
}

const FinancialRow = ({
    formData,
    infoContent,
    isRequired,
    name,
    propertyName,
    validationErrors,
    years,
    setFormData,
}: IProps): JSX.Element => (
    <>
        <div className="font-semibold flex flex-nowrap items-center mt-6 mb-1 md:mt-0 md:mb-0">
            {infoContent && <InfoTooltip>{infoContent}</InfoTooltip>}
            {name}
        </div>
        {years.map((year, index) => (
            <div key={`${year}_${propertyName}`} className="relative">
                {isRequired && index === 0 && (
                    <FontAwesomeIcon icon={faAsterisk} className="absolute text-error -left-4 top-3" size="xs" />
                )}
                <CurrencyInput
                    errorMessage={index === 0 ? validationErrors?.[propertyName] : undefined}
                    onChange={v => {
                        const updatedData = { ...formData };
                        updatedData[year][propertyName] =
                            v as unknown as IBizEquityYearlyStatementUpdate[keyof IBizEquityYearlyStatementUpdate];
                        setFormData(updatedData);
                    }}
                    value={(formData[year][propertyName] as unknown as number | null) ?? undefined}
                />
            </div>
        ))}
    </>
);

export default FinancialRow;
