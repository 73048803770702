import { IBusiness, IEmployee, IUserEntitlementType } from '@api';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { useState } from 'react';
import { Button, Can, DropdownMenu, ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import { ILocationState } from '~/pages/ILocationState';
import { Link, useLocation } from '~/routing';
import { IEditPageType } from '../../edit';
import EditEmployeeForm from '../../employees/EditEmployeeForm';
import EmployeesNoData from '../../employees/EmployeesNoData';
import CustomAgePreviewModal from './CustomAgePreviewModal';
import EditEmployeeRatingModal from './rating/EditEmployeeRatingModal';
import EmployeeSummarySlideOver from './rating/EmployeeSummarySlideOver';
import { hasRating } from './rating/utils';
import TeamValuesTabs from './TeamValuesTabs';

interface IProps {
    business: IBusiness;
}

const getAgePreviewTitle = (previewYears: number): string => {
    if (previewYears <= 0) {
        return 'Today';
    }

    return `In ${previewYears} Years`;
};

type DateOfBirth = string | null | undefined;

const adjustDateOfBirth = (dateOfBirth: DateOfBirth, agePreview: number): DateOfBirth => {
    if (!dateOfBirth) return dateOfBirth;

    return moment(dateOfBirth).subtract(agePreview, 'years').toISOString();
};

interface EditEmployeeState {
    employee?: IEmployee;
    isFormOpen?: boolean;
    isRatingModalOpen?: boolean;
    isSummaryOpen?: boolean;
}

const TeamValues = ({ business }: IProps): JSX.Element | null => {
    const businessSettingsState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Team Values',
    };
    const employeesQuery = useEmployees(business.id);
    const [agePreview, setAgePreview] = useState(0);
    const [showCustomAgeModal, setShowCustomAgeModal] = useState(false);
    const [editState, setEditState] = useState<EditEmployeeState>({});
    const isError = isTrueForAnyQuery('isError', employeesQuery);
    const isLoading = isTrueForAnyQuery('isLoading', employeesQuery);
    if (isLoading) return <LoadingIndicator />;
    if (isError) return <ErrorPage />;

    const { data: employees = [] } = employeesQuery;
    const employeesWithAdjustedDateOfBirth: IEmployee[] =
        employees.map(e => ({ ...e, dateOfBirth: adjustDateOfBirth(e.dateOfBirth, agePreview) })) ?? [];

    if (employees.length === 0) {
        return (
            <div className="p-10">
                <h2 className="mb-5 text-xl">Team Values</h2>
                <EmployeesNoData businessId={business.id} businessSettingsState={businessSettingsState} />
            </div>
        );
    }

    const onEditEmployee = (employee: IEmployee) => {
        if (hasRating(employee)) {
            setEditState({ isSummaryOpen: true, employee });
        } else {
            setEditState({ isRatingModalOpen: true, employee });
        }
    };

    return (
        <div className="p-10">
            <div className="flex justify-between content-center items-center">
                <h2 className="text-xl">Team Values</h2>
                <div className="flex items-center">
                    <Can hasEntitlement={IUserEntitlementType.EmployeeUpdate}>
                        <Link
                            to={{
                                pathname: `/${business.id}/Edit/${IEditPageType.Employees}`,
                                state: businessSettingsState,
                            }}
                        >
                            <Button className="mr-3" color="primary" iconLeft={faEdit} text="Edit Employee List" />
                        </Link>
                    </Can>
                    <DropdownMenu>
                        <DropdownMenu.Trigger
                            as={Button}
                            iconRight={faAngleDown}
                            refName="buttonRef"
                            text={getAgePreviewTitle(agePreview)}
                        />
                        <DropdownMenu.Item onClick={() => setAgePreview(0)}>{getAgePreviewTitle(0)}</DropdownMenu.Item>
                        <DropdownMenu.Item onClick={() => setAgePreview(3)}>{getAgePreviewTitle(3)}</DropdownMenu.Item>
                        <DropdownMenu.Item onClick={() => setAgePreview(5)}>{getAgePreviewTitle(5)}</DropdownMenu.Item>
                        <hr className="border-t border-background2 my-1" />
                        <DropdownMenu.Item onClick={() => setShowCustomAgeModal(true)}>Custom</DropdownMenu.Item>
                    </DropdownMenu>
                    <CustomAgePreviewModal
                        onClose={() => setShowCustomAgeModal(false)}
                        onSubmit={years => setAgePreview(years)}
                        open={showCustomAgeModal}
                    />
                </div>
            </div>
            <TeamValuesTabs
                business={business}
                employees={employeesWithAdjustedDateOfBirth}
                onEditEmployee={onEditEmployee}
            />
            <EditEmployeeRatingModal
                businessId={business.id}
                employee={editState.employee}
                isOpen={!!editState.isRatingModalOpen}
                onClose={() => setEditState({ ...editState, isRatingModalOpen: false })}
                onSuccess={employee => setEditState({ ...editState, employee, isRatingModalOpen: false })}
            />
            <EmployeeSummarySlideOver
                business={business}
                employee={editState.employee}
                employees={employees}
                isOpen={!!editState.isSummaryOpen}
                onClose={() => setEditState({ ...editState, isSummaryOpen: false })}
                onEditEmployee={() => setEditState({ ...editState, isFormOpen: true, isSummaryOpen: false })}
                onEditRating={() => setEditState({ ...editState, isRatingModalOpen: true })}
            />
            <EditEmployeeForm
                businessId={business.id}
                employee={editState.employee}
                isOpen={!!editState.isFormOpen}
                onClose={() => setEditState({ ...editState, isFormOpen: false })}
                onSuccess={() => undefined}
            />
        </div>
    );
};

export default TeamValues;
