/* tslint:disable */
/* eslint-disable */
/**
 * Business Center API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface IAdvisor
 */
export interface IAdvisor {
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    advisorFirmId: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    qualification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisor
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IAdvisorCreateRequest
 */
export interface IAdvisorCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IAdvisorCreateRequest
     */
    advisorFirmId: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorCreateRequest
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorCreateRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorCreateRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorCreateRequest
     */
    qualification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorCreateRequest
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IAdvisorFirm
 */
export interface IAdvisorFirm {
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirm
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirm
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirm
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirm
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirm
     */
    name: string;
    /**
     * 
     * @type {Array<IBusinessPageType>}
     * @memberof IAdvisorFirm
     */
    pages: Array<IBusinessPageType>;
    /**
     * 
     * @type {Array<IBusinessPageType>}
     * @memberof IAdvisorFirm
     */
    pagesExcluded: Array<IBusinessPageType>;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirm
     */
    partnerId: string;
    /**
     * 
     * @type {IBusinessSizeType}
     * @memberof IAdvisorFirm
     */
    size?: IBusinessSizeType;
    /**
     * 
     * @type {IStateOrProvinceType}
     * @memberof IAdvisorFirm
     */
    stateOrProvince?: IStateOrProvinceType;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirm
     */
    website?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IAdvisorFirm
     */
    yearFounded?: number | null;
}
/**
 * 
 * @export
 * @interface IAdvisorFirmCreateRequest
 */
export interface IAdvisorFirmCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmCreateRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmCreateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmCreateRequest
     */
    partnerId: string;
    /**
     * 
     * @type {IBusinessSizeType}
     * @memberof IAdvisorFirmCreateRequest
     */
    size?: IBusinessSizeType;
    /**
     * 
     * @type {IStateOrProvinceType}
     * @memberof IAdvisorFirmCreateRequest
     */
    stateOrProvince?: IStateOrProvinceType;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmCreateRequest
     */
    website?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IAdvisorFirmCreateRequest
     */
    yearFounded?: number | null;
}
/**
 * 
 * @export
 * @interface IAdvisorFirmSummary
 */
export interface IAdvisorFirmSummary {
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmSummary
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmSummary
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmSummary
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface IAdvisorFirmUpdateRequest
 */
export interface IAdvisorFirmUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmUpdateRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {Array<IBusinessPageType>}
     * @memberof IAdvisorFirmUpdateRequest
     */
    pages?: Array<IBusinessPageType> | null;
    /**
     * 
     * @type {Array<IBusinessPageType>}
     * @memberof IAdvisorFirmUpdateRequest
     */
    pagesExcluded?: Array<IBusinessPageType> | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmUpdateRequest
     */
    partnerId: string;
    /**
     * 
     * @type {IBusinessSizeType}
     * @memberof IAdvisorFirmUpdateRequest
     */
    size?: IBusinessSizeType;
    /**
     * 
     * @type {IStateOrProvinceType}
     * @memberof IAdvisorFirmUpdateRequest
     */
    stateOrProvince?: IStateOrProvinceType;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorFirmUpdateRequest
     */
    website?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IAdvisorFirmUpdateRequest
     */
    yearFounded?: number | null;
}
/**
 * 
 * @export
 * @interface IAdvisorUpdateRequest
 */
export interface IAdvisorUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IAdvisorUpdateRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorUpdateRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorUpdateRequest
     */
    qualification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IAdvisorUpdateRequest
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IAvatar
 */
export interface IAvatar {
    /**
     * 
     * @type {string}
     * @memberof IAvatar
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof IAvatar
     */
    url: string;
}
/**
 * 
 * @export
 * @interface IBizEquityBusiness
 */
export interface IBizEquityBusiness {
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    assetValuation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    businessOnline?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    businessStructure?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    changeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    country?: string | null;
    /**
     * 
     * @type {IBizEquityRank}
     * @memberof IBizEquityBusiness
     */
    countryRank?: IBizEquityRank;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    createDate?: string;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    ebitdaMarginGrowth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    employees?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    enterpriseValuation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    equityValuation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    firstName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityBusiness
     */
    hasCompletedDataEntry: boolean;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    inceptionYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    industry?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityBusiness
     */
    insuranceCovered?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityBusiness
     */
    intellectualProperty?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    liquidationValuation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    longitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    netAssetValuation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    numDataElements?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    operationsAssessment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    overallComparison?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    owner?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    ownerLeftImpact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    phone?: string | null;
    /**
     * 
     * @type {IBizEquityRank}
     * @memberof IBizEquityBusiness
     */
    postcodeRank?: IBizEquityRank;
    /**
     * 
     * @type {IBizEquityRealEstate}
     * @memberof IBizEquityBusiness
     */
    realEstate?: IBizEquityRealEstate;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBizEquityBusiness
     */
    reasons?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    recurringRevenue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    revenueGrowth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    taxForm?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusiness
     */
    topThreeCustomers?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    touchDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusiness
     */
    whitelabel?: string | null;
    /**
     * 
     * @type {{ [key: string]: IBizEquityYearlyStatement; }}
     * @memberof IBizEquityBusiness
     */
    yearlyStatements?: { [key: string]: IBizEquityYearlyStatement; } | null;
}
/**
 * 
 * @export
 * @interface IBizEquityBusinessConnectRequest
 */
export interface IBizEquityBusinessConnectRequest {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessConnectRequest
     */
    bizEquityBusinessId: string;
}
/**
 * 
 * @export
 * @interface IBizEquityBusinessConnectionStatus
 */
export interface IBizEquityBusinessConnectionStatus {
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityBusinessConnectionStatus
     */
    isConnected: boolean;
}
/**
 * 
 * @export
 * @interface IBizEquityBusinessCreateRequest
 */
export interface IBizEquityBusinessCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessCreateRequest
     */
    industry: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessCreateRequest
     */
    zip: string;
}
/**
 * 
 * @export
 * @interface IBizEquityBusinessDataEntryDelegateInvitationRequest
 */
export interface IBizEquityBusinessDataEntryDelegateInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessDataEntryDelegateInvitationRequest
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface IBizEquityBusinessSearchResultItem
 */
export interface IBizEquityBusinessSearchResultItem {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessSearchResultItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessSearchResultItem
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessSearchResultItem
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IBizEquityBusinessUpdateRequest
 */
export interface IBizEquityBusinessUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    ebitdaMarginGrowth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    hasCompletedDataEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    insuranceCovered?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    intellectualProperty?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    ownerLeftImpact?: string | null;
    /**
     * 
     * @type {IBizEquityRealEstateUpdate}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    realEstate?: IBizEquityRealEstateUpdate;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    recurringRevenue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    revenueGrowth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    topThreeCustomers?: number | null;
    /**
     * 
     * @type {{ [key: string]: IBizEquityYearlyStatementUpdate; }}
     * @memberof IBizEquityBusinessUpdateRequest
     */
    yearlyStatements?: { [key: string]: IBizEquityYearlyStatementUpdate; } | null;
}
/**
 * 
 * @export
 * @interface IBizEquityIndustry
 */
export interface IBizEquityIndustry {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityIndustry
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityIndustry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityIndustry
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IBizEquityKpi
 */
export interface IBizEquityKpi {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityKpi
     */
    comparison?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityKpi
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface IBizEquityLoginStatus
 */
export interface IBizEquityLoginStatus {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityLoginStatus
     */
    bizEquityDomain: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityLoginStatus
     */
    bizEquityOAuthRedirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityLoginStatus
     */
    bizEquitySubdomain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBizEquityLoginStatus
     */
    isLoggedIn: boolean;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityLoginStatus
     */
    loggedInAs?: string | null;
}
/**
 * 
 * @export
 * @interface IBizEquityPlace
 */
export interface IBizEquityPlace {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityPlace
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityPlace
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityPlace
     */
    longitude: number;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityPlace
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IBizEquityPostOAuthCodeRequest
 */
export interface IBizEquityPostOAuthCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityPostOAuthCodeRequest
     */
    authorizationCode?: string | null;
}
/**
 * 
 * @export
 * @interface IBizEquityRank
 */
export interface IBizEquityRank {
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRank
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRank
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface IBizEquityRealEstate
 */
export interface IBizEquityRealEstate {
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstate
     */
    area?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstate
     */
    monthlyRent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstate
     */
    mortgage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityRealEstate
     */
    ownership?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityRealEstate
     */
    ownershipParty?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstate
     */
    trueMarketLevelMonthlyRent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstate
     */
    value?: number | null;
}
/**
 * 
 * @export
 * @interface IBizEquityRealEstateUpdate
 */
export interface IBizEquityRealEstateUpdate {
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstateUpdate
     */
    area?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstateUpdate
     */
    monthlyRent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstateUpdate
     */
    mortgage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityRealEstateUpdate
     */
    ownership?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityRealEstateUpdate
     */
    ownershipParty?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstateUpdate
     */
    trueMarketLevelMonthlyRent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityRealEstateUpdate
     */
    value?: number | null;
}
/**
 * 
 * @export
 * @interface IBizEquityRequiredFields
 */
export interface IBizEquityRequiredFields {
    /**
     * 
     * @type {Array<string>}
     * @memberof IBizEquityRequiredFields
     */
    requiredFields: Array<string>;
}
/**
 * 
 * @export
 * @interface IBizEquityWhiteLabelUpdateRequest
 */
export interface IBizEquityWhiteLabelUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IBizEquityWhiteLabelUpdateRequest
     */
    advisorFirmId: string;
    /**
     * 
     * @type {string}
     * @memberof IBizEquityWhiteLabelUpdateRequest
     */
    subdomain: string;
}
/**
 * 
 * @export
 * @interface IBizEquityYearlyStatement
 */
export interface IBizEquityYearlyStatement {
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    accountsPayables?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    accountsReceivables?: number | null;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    accountsReceivablesToIncome?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    accountsReceivablesToRevenue?: IBizEquityKpi;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    bankLoans?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    cash?: number | null;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    cashToDebt?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    cashToRevenue?: IBizEquityKpi;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    contingentLiabilities?: number | null;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    debtToEquity?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    debtToIncome?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    debtToRevenue?: IBizEquityKpi;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    fixedAssets?: number | null;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    fixedAssetsToIncome?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    fixedAssetsToRevenue?: IBizEquityKpi;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    income?: number | null;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    incomeToEquity?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    incomeToRevenue?: IBizEquityKpi;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    intangibleAssets?: number | null;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    interestCoverage?: IBizEquityKpi;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    interestExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    inventory?: number | null;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    inventoryToIncome?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    inventoryToRevenue?: IBizEquityKpi;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    investments?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    nonCashExpenses?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    nonCurrentLiabilities?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    officerCompensation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    oneTimeExpenses?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    oneTimeRevenues?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    otherCurrentAssets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    otherLongTermLiabilities?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    otherNonCurrentAssets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    otherShortTermLiabilities?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatement
     */
    revenue?: number;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    revenueToFixedAssets?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    revenueToInventory?: IBizEquityKpi;
    /**
     * 
     * @type {IBizEquityKpi}
     * @memberof IBizEquityYearlyStatement
     */
    sdeMargin?: IBizEquityKpi;
}
/**
 * 
 * @export
 * @interface IBizEquityYearlyStatementUpdate
 */
export interface IBizEquityYearlyStatementUpdate {
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    accountsPayables?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    accountsReceivables?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    bankLoans?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    cash?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    contingentLiabilities?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    fixedAssets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    income?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    intangibleAssets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    interestExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    inventory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    nonCashExpenses?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    officerCompensation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    oneTimeExpenses?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    oneTimeRevenues?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    otherCurrentAssets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    otherLongTermLiabilities?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    otherShortTermLiabilities?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBizEquityYearlyStatementUpdate
     */
    revenue?: number | null;
}
/**
 * 
 * @export
 * @interface IBonusRightAggregatePaySummary
 */
export interface IBonusRightAggregatePaySummary {
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummary
     */
    baseValuation: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummary
     */
    percentProfitToShareholders: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummary
     */
    returnOnEquity: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummary
     */
    shareholderEquity: number;
    /**
     * 
     * @type {Array<IBonusRightAggregatePaySummaryYear>}
     * @memberof IBonusRightAggregatePaySummary
     */
    years: Array<IBonusRightAggregatePaySummaryYear>;
}
/**
 * 
 * @export
 * @interface IBonusRightAggregatePaySummaryYear
 */
export interface IBonusRightAggregatePaySummaryYear {
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    ebitda: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    formulaValue: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    grossSales: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    ltipRedemptions: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    ltipTotalValue: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    salaries: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    stips: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightAggregatePaySummaryYear
     */
    year: number;
}
/**
 * 
 * @export
 * @interface IBonusRightAssessmentResult
 */
export interface IBonusRightAssessmentResult {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IBonusRightAssessmentResult
     */
    answerMap: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof IBonusRightAssessmentResult
     */
    businessId: string;
}
/**
 * 
 * @export
 * @interface IBonusRightAssessmentSubmission
 */
export interface IBonusRightAssessmentSubmission {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IBonusRightAssessmentSubmission
     */
    answerMap: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof IBonusRightAssessmentSubmission
     */
    businessId: string;
}
/**
 * 
 * @export
 * @interface IBonusRightConnectionStatus
 */
export interface IBonusRightConnectionStatus {
    /**
     * 
     * @type {boolean}
     * @memberof IBonusRightConnectionStatus
     */
    isConnected: boolean;
}
/**
 * 
 * @export
 * @interface IBonusRightEmployeeRewards
 */
export interface IBonusRightEmployeeRewards {
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    age?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBonusRightEmployeeRewards
     */
    avatar?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    benefits?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    bonusRightEmployeeId: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    equity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBonusRightEmployeeRewards
     */
    grade: string;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    longTermIncentives?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBonusRightEmployeeRewards
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    retirement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    salary: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeRewards
     */
    shortTermIncentives?: number | null;
}
/**
 * 
 * @export
 * @interface IBonusRightEmployeeValueStatement
 */
export interface IBonusRightEmployeeValueStatement {
    /**
     * 
     * @type {string}
     * @memberof IBonusRightEmployeeValueStatement
     */
    employeeDisplayName?: string | null;
    /**
     * 
     * @type {Array<IBonusRightEmployeeValueStatementYear>}
     * @memberof IBonusRightEmployeeValueStatement
     */
    years?: Array<IBonusRightEmployeeValueStatementYear> | null;
}
/**
 * 
 * @export
 * @interface IBonusRightEmployeeValueStatementYear
 */
export interface IBonusRightEmployeeValueStatementYear {
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeValueStatementYear
     */
    ltipRedemption?: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeValueStatementYear
     */
    salary?: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeValueStatementYear
     */
    stipPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeValueStatementYear
     */
    unvestedValue?: number;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightEmployeeValueStatementYear
     */
    vestedValue?: number;
    /**
     * 
     * @type {string}
     * @memberof IBonusRightEmployeeValueStatementYear
     */
    year?: string | null;
}
/**
 * 
 * @export
 * @interface IBonusRightExecutiveBenefitAnalysis
 */
export interface IBonusRightExecutiveBenefitAnalysis {
    /**
     * 
     * @type {Array<IBonusRightExecutiveBenefitAnalysisEmployee>}
     * @memberof IBonusRightExecutiveBenefitAnalysis
     */
    employees: Array<IBonusRightExecutiveBenefitAnalysisEmployee>;
}
/**
 * 
 * @export
 * @interface IBonusRightExecutiveBenefitAnalysisEmployee
 */
export interface IBonusRightExecutiveBenefitAnalysisEmployee {
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    currentDeathBenefit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    currentLtdBenefit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    grade: string;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    longTermIncentive?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    salary?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    shortTermIncentive?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    targetDeathBenefit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    targetLtdBenefit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBonusRightExecutiveBenefitAnalysisEmployee
     */
    totalCashCompensation?: number;
}
/**
 * 
 * @export
 * @interface IBusiness
 */
export interface IBusiness {
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    advisorFirmId: string;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    id: string;
    /**
     * 
     * @type {IIndustryType}
     * @memberof IBusiness
     */
    industry?: IIndustryType;
    /**
     * 
     * @type {boolean}
     * @memberof IBusiness
     */
    isSampleCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IBusiness
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBusiness
     */
    longitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    name: string;
    /**
     * 
     * @type {Array<IBusinessPageType>}
     * @memberof IBusiness
     */
    pages: Array<IBusinessPageType>;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    primaryAdvisorId: string;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    primaryEmployeeId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBusiness
     */
    sharesOutstanding?: number | null;
    /**
     * 
     * @type {IBusinessSizeType}
     * @memberof IBusiness
     */
    size?: IBusinessSizeType;
    /**
     * 
     * @type {IStateOrProvinceType}
     * @memberof IBusiness
     */
    stateOrProvince?: IStateOrProvinceType;
    /**
     * 
     * @type {string}
     * @memberof IBusiness
     */
    website?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBusiness
     */
    yearFounded?: number | null;
}
/**
 * 
 * @export
 * @interface IBusinessCreateRequest
 */
export interface IBusinessCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IBusinessCreateRequest
     */
    advisorFirmId: string;
    /**
     * 
     * @type {string}
     * @memberof IBusinessCreateRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessCreateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {IIndustryType}
     * @memberof IBusinessCreateRequest
     */
    industry?: IIndustryType;
    /**
     * 
     * @type {string}
     * @memberof IBusinessCreateRequest
     */
    name: string;
    /**
     * 
     * @type {Array<IBusinessPageType>}
     * @memberof IBusinessCreateRequest
     */
    pages?: Array<IBusinessPageType> | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessCreateRequest
     */
    primaryAdvisorId: string;
    /**
     * 
     * @type {number}
     * @memberof IBusinessCreateRequest
     */
    sharesOutstanding?: number | null;
    /**
     * 
     * @type {IBusinessSizeType}
     * @memberof IBusinessCreateRequest
     */
    size?: IBusinessSizeType;
    /**
     * 
     * @type {IStateOrProvinceType}
     * @memberof IBusinessCreateRequest
     */
    stateOrProvince?: IStateOrProvinceType;
    /**
     * 
     * @type {number}
     * @memberof IBusinessCreateRequest
     */
    value?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessCreateRequest
     */
    website?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBusinessCreateRequest
     */
    yearFounded?: number | null;
}
/**
 * 
 * @export
 * @interface IBusinessEducationUpdateRequest
 */
export interface IBusinessEducationUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof IBusinessEducationUpdateRequest
     */
    educationResourceIds: Array<string>;
}
/**
 * 
 * @export
 * @interface IBusinessOwnerCreateRequest
 */
export interface IBusinessOwnerCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IBusinessOwnerCreateRequest
     */
    userId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IBusinessPageType {
    BizEquity = 'BizEquity',
    BonusRight = 'BonusRight',
    BusinessForecast = 'BusinessForecast',
    CorporateStrategies = 'CorporateStrategies',
    DocumentCenter = 'DocumentCenter',
    Education = 'Education',
    ExecutivePrograms = 'ExecutivePrograms',
    InnerZone = 'InnerZone',
    Observations = 'Observations',
    Purpose = 'Purpose',
    ScoreCard = 'ScoreCard',
    SuccessionPlan = 'SuccessionPlan',
    TeamValues = 'TeamValues'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum IBusinessSizeType {
    _1_10 = '_1_10',
    _11_50 = '_11_50',
    _51_200 = '_51_200',
    _201_500 = '_201_500',
    _501_1000 = '_501_1000',
    _1001_5000 = '_1001_5000',
    _5001_10000 = '_5001_10000',
    Over10000 = 'Over10000'
}

/**
 * 
 * @export
 * @interface IBusinessSummary
 */
export interface IBusinessSummary {
    /**
     * 
     * @type {string}
     * @memberof IBusinessSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IBusinessSummary
     */
    name: string;
    /**
     * 
     * @type {IEmployeeSummary}
     * @memberof IBusinessSummary
     */
    primaryEmployee?: IEmployeeSummary;
}
/**
 * 
 * @export
 * @interface IBusinessTeamMember
 */
export interface IBusinessTeamMember {
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMember
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMember
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMember
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMember
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMember
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMember
     */
    title?: string | null;
    /**
     * 
     * @type {IBusinessTeamMemberType}
     * @memberof IBusinessTeamMember
     */
    type: IBusinessTeamMemberType;
}
/**
 * 
 * @export
 * @interface IBusinessTeamMemberAdvisorCreateRequest
 */
export interface IBusinessTeamMemberAdvisorCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMemberAdvisorCreateRequest
     */
    advisorId: string;
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMemberAdvisorCreateRequest
     */
    businessId: string;
}
/**
 * 
 * @export
 * @interface IBusinessTeamMemberEmployeeCreateRequest
 */
export interface IBusinessTeamMemberEmployeeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IBusinessTeamMemberEmployeeCreateRequest
     */
    employeeId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IBusinessTeamMemberType {
    Advisor = 'Advisor',
    Employee = 'Employee',
    External = 'External'
}

/**
 * 
 * @export
 * @interface IBusinessUpdateRequest
 */
export interface IBusinessUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IBusinessUpdateRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {IIndustryType}
     * @memberof IBusinessUpdateRequest
     */
    industry?: IIndustryType;
    /**
     * 
     * @type {string}
     * @memberof IBusinessUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {Array<IBusinessPageType>}
     * @memberof IBusinessUpdateRequest
     */
    pages?: Array<IBusinessPageType> | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessUpdateRequest
     */
    primaryEmployeeId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBusinessUpdateRequest
     */
    sharesOutstanding?: number | null;
    /**
     * 
     * @type {IBusinessSizeType}
     * @memberof IBusinessUpdateRequest
     */
    size?: IBusinessSizeType;
    /**
     * 
     * @type {IStateOrProvinceType}
     * @memberof IBusinessUpdateRequest
     */
    stateOrProvince?: IStateOrProvinceType;
    /**
     * 
     * @type {number}
     * @memberof IBusinessUpdateRequest
     */
    value?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBusinessUpdateRequest
     */
    website?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBusinessUpdateRequest
     */
    yearFounded?: number | null;
}
/**
 * 
 * @export
 * @interface IBusinessValuation
 */
export interface IBusinessValuation {
    /**
     * 
     * @type {string}
     * @memberof IBusinessValuation
     */
    asOf: string;
    /**
     * 
     * @type {IBusinessValuationType}
     * @memberof IBusinessValuation
     */
    source: IBusinessValuationType;
    /**
     * 
     * @type {number}
     * @memberof IBusinessValuation
     */
    value: number;
}
/**
 * 
 * @export
 * @interface IBusinessValuationHistory
 */
export interface IBusinessValuationHistory {
    /**
     * 
     * @type {string}
     * @memberof IBusinessValuationHistory
     */
    asOf: string;
    /**
     * 
     * @type {string}
     * @memberof IBusinessValuationHistory
     */
    id: string;
    /**
     * 
     * @type {IBusinessValuationType}
     * @memberof IBusinessValuationHistory
     */
    source: IBusinessValuationType;
    /**
     * 
     * @type {number}
     * @memberof IBusinessValuationHistory
     */
    value: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IBusinessValuationType {
    ManualEntry = 'ManualEntry',
    BizEquity = 'BizEquity'
}

/**
 * 
 * @export
 * @interface IContactVisionLinkRequest
 */
export interface IContactVisionLinkRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof IContactVisionLinkRequest
     */
    advisorsToContact: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IContactVisionLinkRequest
     */
    answerMap: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof IContactVisionLinkRequest
     */
    businessId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IContactVisionLinkRequest
     */
    employeesToContact: Array<string>;
}
/**
 * 
 * @export
 * @interface ICreateBonusRightConnectionRequest
 */
export interface ICreateBonusRightConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ICreateBonusRightConnectionRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateBonusRightConnectionRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface IDocument
 */
export interface IDocument {
    /**
     * 
     * @type {IDocumentColorType}
     * @memberof IDocument
     */
    color?: IDocumentColorType;
    /**
     * 
     * @type {number}
     * @memberof IDocument
     */
    fileCount: number;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    hierarchyUpdatedAt: string;
    /**
     * 
     * @type {IDocumentIconType}
     * @memberof IDocument
     */
    icon?: IDocumentIconType;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof IDocument
     */
    isFolder: boolean;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IDocument
     */
    parentFolderId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IDocumentColorType {
    Blue = 'Blue',
    Gray = 'Gray',
    Orange = 'Orange',
    Purple = 'Purple',
    Red = 'Red',
    Teal = 'Teal',
    Yellow = 'Yellow'
}

/**
 * 
 * @export
 * @interface IDocumentDownloadLocation
 */
export interface IDocumentDownloadLocation {
    /**
     * 
     * @type {string}
     * @memberof IDocumentDownloadLocation
     */
    url: string;
}
/**
 * 
 * @export
 * @interface IDocumentEmployeeInvitationRequest
 */
export interface IDocumentEmployeeInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof IDocumentEmployeeInvitationRequest
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentEmployeeInvitationRequest
     */
    employeeId: string;
}
/**
 * 
 * @export
 * @interface IDocumentExternalTeamMemberInvitationRequest
 */
export interface IDocumentExternalTeamMemberInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof IDocumentExternalTeamMemberInvitationRequest
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentExternalTeamMemberInvitationRequest
     */
    externalTeamMemberId: string;
}
/**
 * 
 * @export
 * @interface IDocumentFolderCreateRequest
 */
export interface IDocumentFolderCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IDocumentFolderCreateRequest
     */
    businessId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentFolderCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentFolderCreateRequest
     */
    parentFolderId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IDocumentIconType {
    Award = 'Award',
    Database = 'Database',
    Feather = 'Feather',
    FileSignature = 'FileSignature',
    FlagUsa = 'FlagUsa',
    Hotel = 'Hotel',
    InvoiceDollar = 'InvoiceDollar',
    Medal = 'Medal',
    Other = 'Other',
    PieChart = 'PieChart',
    UmbrellaBeach = 'UmbrellaBeach',
    University = 'University'
}

/**
 * 
 * @export
 * @interface IDocumentList
 */
export interface IDocumentList {
    /**
     * 
     * @type {Array<IDocument>}
     * @memberof IDocumentList
     */
    documents: Array<IDocument>;
    /**
     * 
     * @type {string}
     * @memberof IDocumentList
     */
    folderId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentList
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IDocumentPathSegment
 */
export interface IDocumentPathSegment {
    /**
     * 
     * @type {string}
     * @memberof IDocumentPathSegment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentPathSegment
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IDocumentPermission
 */
export interface IDocumentPermission {
    /**
     * 
     * @type {string}
     * @memberof IDocumentPermission
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentPermission
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentPermission
     */
    personId: string;
}
/**
 * 
 * @export
 * @interface IDocumentUpdateRequest
 */
export interface IDocumentUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IDocumentUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IDocumentUpdateRequest
     */
    parentFolderId?: string | null;
}
/**
 * 
 * @export
 * @interface IEducation
 */
export interface IEducation {
    /**
     * 
     * @type {string}
     * @memberof IEducation
     */
    author?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEducation
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEducation
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof IEducation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IEducation
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IEducationDownloadLocation
 */
export interface IEducationDownloadLocation {
    /**
     * 
     * @type {string}
     * @memberof IEducationDownloadLocation
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof IEducationDownloadLocation
     */
    url: string;
}
/**
 * 
 * @export
 * @interface IEducationUpdateRequest
 */
export interface IEducationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IEducationUpdateRequest
     */
    author?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEducationUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEducationUpdateRequest
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IEmployee
 */
export interface IEmployee {
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    department?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    disabilityInsuranceCoverage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    familyRelativeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof IEmployee
     */
    isOwner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IEmployee
     */
    isPortalUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    lifeInsuranceCoverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    ratingGrowth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    ratingOperations?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    ratingProduct?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    ratingSales?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    ratingTeam?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    ratingTechnology?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    salary?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployee
     */
    shares?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployee
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IEmployeeCreateRequest
 */
export interface IEmployeeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    businessId: string;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    department?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    disabilityInsuranceCoverage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    familyRelativeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    firstName: string;
    /**
     * 
     * @type {boolean}
     * @memberof IEmployeeCreateRequest
     */
    isOwner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    lifeInsuranceCoverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    ratingGrowth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    ratingOperations?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    ratingProduct?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    ratingSales?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    ratingTeam?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    ratingTechnology?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    salary?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeCreateRequest
     */
    shares?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeCreateRequest
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IEmployeeFamily
 */
export interface IEmployeeFamily {
    /**
     * 
     * @type {Array<string>}
     * @memberof IEmployeeFamily
     */
    employeeIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeFamily
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IEmployeeSummary
 */
export interface IEmployeeSummary {
    /**
     * 
     * @type {string}
     * @memberof IEmployeeSummary
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeSummary
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeSummary
     */
    lastName: string;
}
/**
 * 
 * @export
 * @interface IEmployeeUpdateRequest
 */
export interface IEmployeeUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    department?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    disabilityInsuranceCoverage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    familyRelativeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    firstName: string;
    /**
     * 
     * @type {boolean}
     * @memberof IEmployeeUpdateRequest
     */
    isOwner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    lifeInsuranceCoverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    ratingGrowth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    ratingOperations?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    ratingProduct?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    ratingSales?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    ratingTeam?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    ratingTechnology?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    salary?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IEmployeeUpdateRequest
     */
    shares?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUpdateRequest
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IEmployeeUploadDelegateInvitationRequest
 */
export interface IEmployeeUploadDelegateInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUploadDelegateInvitationRequest
     */
    businessId: string;
    /**
     * 
     * @type {string}
     * @memberof IEmployeeUploadDelegateInvitationRequest
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface IEsop
 */
export interface IEsop {
    /**
     * 
     * @type {Array<string>}
     * @memberof IEsop
     */
    employees: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IEsop
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof IEsop
     */
    percentage: number;
}
/**
 * 
 * @export
 * @interface IEsopCreateRequest
 */
export interface IEsopCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IEsopCreateRequest
     */
    businessId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IEsopCreateRequest
     */
    employees: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IEsopCreateRequest
     */
    percentage: number;
}
/**
 * 
 * @export
 * @interface IEsopUpdateRequest
 */
export interface IEsopUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof IEsopUpdateRequest
     */
    employees: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IEsopUpdateRequest
     */
    percentage: number;
}
/**
 * 
 * @export
 * @interface IExternalTeamMember
 */
export interface IExternalTeamMember {
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMember
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMember
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMember
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMember
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMember
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMember
     */
    title: string;
}
/**
 * 
 * @export
 * @interface IExternalTeamMemberCreateRequest
 */
export interface IExternalTeamMemberCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberCreateRequest
     */
    businessId: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberCreateRequest
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberCreateRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberCreateRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberCreateRequest
     */
    title: string;
}
/**
 * 
 * @export
 * @interface IExternalTeamMemberUpdateRequest
 */
export interface IExternalTeamMemberUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberUpdateRequest
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberUpdateRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberUpdateRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IExternalTeamMemberUpdateRequest
     */
    title: string;
}
/**
 * 
 * @export
 * @interface IFamilyLimitedPartner
 */
export interface IFamilyLimitedPartner {
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartner
     */
    dollarAmount: number;
    /**
     * 
     * @type {string}
     * @memberof IFamilyLimitedPartner
     */
    familyRelativeId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IFamilyLimitedPartner
     */
    isGift: boolean;
}
/**
 * 
 * @export
 * @interface IFamilyLimitedPartnership
 */
export interface IFamilyLimitedPartnership {
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnership
     */
    discountForControl: number;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnership
     */
    discountForMarketability: number;
    /**
     * 
     * @type {string}
     * @memberof IFamilyLimitedPartnership
     */
    employeeId: string;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnership
     */
    generalPartnerInterest: number;
    /**
     * 
     * @type {string}
     * @memberof IFamilyLimitedPartnership
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnership
     */
    limitedPartnerInterest: number;
    /**
     * 
     * @type {Array<IFamilyLimitedPartner>}
     * @memberof IFamilyLimitedPartnership
     */
    partners: Array<IFamilyLimitedPartner>;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnership
     */
    shares: number;
}
/**
 * 
 * @export
 * @interface IFamilyLimitedPartnershipCreateRequest
 */
export interface IFamilyLimitedPartnershipCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipCreateRequest
     */
    discountForControl: number;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipCreateRequest
     */
    discountForMarketability: number;
    /**
     * 
     * @type {string}
     * @memberof IFamilyLimitedPartnershipCreateRequest
     */
    employeeId: string;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipCreateRequest
     */
    generalPartnerInterest: number;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipCreateRequest
     */
    limitedPartnerInterest: number;
    /**
     * 
     * @type {Array<IFamilyLimitedPartner>}
     * @memberof IFamilyLimitedPartnershipCreateRequest
     */
    partners: Array<IFamilyLimitedPartner>;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipCreateRequest
     */
    shares: number;
}
/**
 * 
 * @export
 * @interface IFamilyLimitedPartnershipUpdateRequest
 */
export interface IFamilyLimitedPartnershipUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipUpdateRequest
     */
    discountForControl: number;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipUpdateRequest
     */
    discountForMarketability: number;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipUpdateRequest
     */
    generalPartnerInterest: number;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipUpdateRequest
     */
    limitedPartnerInterest: number;
    /**
     * 
     * @type {Array<IFamilyLimitedPartner>}
     * @memberof IFamilyLimitedPartnershipUpdateRequest
     */
    partners: Array<IFamilyLimitedPartner>;
    /**
     * 
     * @type {number}
     * @memberof IFamilyLimitedPartnershipUpdateRequest
     */
    shares: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IIndustryType {
    Accounting = 'Accounting',
    AirlinesAviation = 'AirlinesAviation',
    AlternativeDisputeResolution = 'AlternativeDisputeResolution',
    AlternativeMedicine = 'AlternativeMedicine',
    Animation = 'Animation',
    ApparelAndFashion = 'ApparelAndFashion',
    ArchitectureAndPlanning = 'ArchitectureAndPlanning',
    ArtsAndCrafts = 'ArtsAndCrafts',
    Automotive = 'Automotive',
    AviationAndAerospace = 'AviationAndAerospace',
    Banking = 'Banking',
    Biotechnology = 'Biotechnology',
    BroadcastMedia = 'BroadcastMedia',
    BuildingMaterials = 'BuildingMaterials',
    BusinessSuppliesAndEquipment = 'BusinessSuppliesAndEquipment',
    CapitalMarkets = 'CapitalMarkets',
    Chemicals = 'Chemicals',
    CivicAndSocialOrganization = 'CivicAndSocialOrganization',
    CivilEngineering = 'CivilEngineering',
    CommercialRealEstate = 'CommercialRealEstate',
    ComputerAndNetworkSecurity = 'ComputerAndNetworkSecurity',
    ComputerGames = 'ComputerGames',
    ComputerHardware = 'ComputerHardware',
    ComputerNetworking = 'ComputerNetworking',
    ComputerSoftware = 'ComputerSoftware',
    Construction = 'Construction',
    ConsumerElectronics = 'ConsumerElectronics',
    ConsumerGoods = 'ConsumerGoods',
    ConsumerServices = 'ConsumerServices',
    Cosmetics = 'Cosmetics',
    Dairy = 'Dairy',
    DefenseAndSpace = 'DefenseAndSpace',
    Design = 'Design',
    EducationManagement = 'EducationManagement',
    ELearning = 'ELearning',
    ElectricalElectronicManufacturing = 'ElectricalElectronicManufacturing',
    Entertainment = 'Entertainment',
    EnvironmentalServices = 'EnvironmentalServices',
    EventsServices = 'EventsServices',
    ExecutiveOffice = 'ExecutiveOffice',
    FacilitiesServices = 'FacilitiesServices',
    Farming = 'Farming',
    FinancialServices = 'FinancialServices',
    FineArt = 'FineArt',
    Fishery = 'Fishery',
    FoodAndBeverages = 'FoodAndBeverages',
    FoodProduction = 'FoodProduction',
    FundRaising = 'FundRaising',
    Furniture = 'Furniture',
    GamblingAndCasinos = 'GamblingAndCasinos',
    GlassCeramicsAndConcrete = 'GlassCeramicsAndConcrete',
    GovernmentAdministration = 'GovernmentAdministration',
    GovernmentRelations = 'GovernmentRelations',
    GraphicDesign = 'GraphicDesign',
    HealthWellnessAndFitness = 'HealthWellnessAndFitness',
    HigherEducation = 'HigherEducation',
    HospitalAndHealthCare = 'HospitalAndHealthCare',
    Hospitality = 'Hospitality',
    HumanResources = 'HumanResources',
    ImportAndExport = 'ImportAndExport',
    IndividualAndFamilyServices = 'IndividualAndFamilyServices',
    IndustrialAutomation = 'IndustrialAutomation',
    InformationServices = 'InformationServices',
    InformationTechnologyAndServices = 'InformationTechnologyAndServices',
    Insurance = 'Insurance',
    InternationalAffairs = 'InternationalAffairs',
    InternationalTradeAndDevelopment = 'InternationalTradeAndDevelopment',
    Internet = 'Internet',
    InvestmentBanking = 'InvestmentBanking',
    InvestmentManagement = 'InvestmentManagement',
    Judiciary = 'Judiciary',
    LawEnforcement = 'LawEnforcement',
    LawPractice = 'LawPractice',
    LegalServices = 'LegalServices',
    LegislativeOffice = 'LegislativeOffice',
    LeisureTravelAndTourism = 'LeisureTravelAndTourism',
    Libraries = 'Libraries',
    LogisticsAndSupplyChain = 'LogisticsAndSupplyChain',
    LuxuryGoodsAndJewelry = 'LuxuryGoodsAndJewelry',
    Machinery = 'Machinery',
    ManagementConsulting = 'ManagementConsulting',
    Maritime = 'Maritime',
    MarketResearch = 'MarketResearch',
    MarketingAndAdvertising = 'MarketingAndAdvertising',
    MechanicalOrIndustrialEngineering = 'MechanicalOrIndustrialEngineering',
    MediaProduction = 'MediaProduction',
    MedicalDevices = 'MedicalDevices',
    MedicalPractice = 'MedicalPractice',
    MentalHealthCare = 'MentalHealthCare',
    Military = 'Military',
    MiningAndMetals = 'MiningAndMetals',
    MotionPicturesAndFilm = 'MotionPicturesAndFilm',
    MuseumsAndInstitutions = 'MuseumsAndInstitutions',
    Music = 'Music',
    Nanotechnology = 'Nanotechnology',
    Newspapers = 'Newspapers',
    NonProfitOrganizationManagement = 'NonProfitOrganizationManagement',
    OilAndEnergy = 'OilAndEnergy',
    OnlineMedia = 'OnlineMedia',
    OutsourcingOffshoring = 'OutsourcingOffshoring',
    PackageFreightDelivery = 'PackageFreightDelivery',
    PackagingAndContainers = 'PackagingAndContainers',
    PaperForestProducts = 'PaperForestProducts',
    PerformingArts = 'PerformingArts',
    Pharmaceuticals = 'Pharmaceuticals',
    Philanthropy = 'Philanthropy',
    Photography = 'Photography',
    Plastics = 'Plastics',
    PoliticalOrganization = 'PoliticalOrganization',
    PrimarySecondaryEducation = 'PrimarySecondaryEducation',
    Printing = 'Printing',
    ProfessionalTrainingAndCoaching = 'ProfessionalTrainingAndCoaching',
    ProgramDevelopment = 'ProgramDevelopment',
    PublicPolicy = 'PublicPolicy',
    PublicRelationsAndCommunications = 'PublicRelationsAndCommunications',
    PublicSafety = 'PublicSafety',
    Publishing = 'Publishing',
    RailroadManufacture = 'RailroadManufacture',
    Ranching = 'Ranching',
    RealEstate = 'RealEstate',
    RecreationalFacilitiesAndServices = 'RecreationalFacilitiesAndServices',
    ReligiousInstitutions = 'ReligiousInstitutions',
    RenewablesAndEnvironment = 'RenewablesAndEnvironment',
    Research = 'Research',
    Restaurants = 'Restaurants',
    Retail = 'Retail',
    SecurityAndInvestigations = 'SecurityAndInvestigations',
    Semiconductors = 'Semiconductors',
    Shipbuilding = 'Shipbuilding',
    SportingGoods = 'SportingGoods',
    Sports = 'Sports',
    StaffingAndRecruiting = 'StaffingAndRecruiting',
    Supermarkets = 'Supermarkets',
    Telecommunications = 'Telecommunications',
    Textiles = 'Textiles',
    ThinkTanks = 'ThinkTanks',
    Tobacco = 'Tobacco',
    TranslationAndLocalization = 'TranslationAndLocalization',
    TransportationTruckingRailroad = 'TransportationTruckingRailroad',
    Utilities = 'Utilities',
    VentureCapitalAndPrivateEquity = 'VentureCapitalAndPrivateEquity',
    Veterinary = 'Veterinary',
    Warehousing = 'Warehousing',
    Wholesale = 'Wholesale',
    WineAndSpirits = 'WineAndSpirits',
    Wireless = 'Wireless',
    WritingAndEditing = 'WritingAndEditing'
}

/**
 * 
 * @export
 * @interface IInnerZoneBusinessDetail
 */
export interface IInnerZoneBusinessDetail {
    /**
     * 
     * @type {Array<IInnerZoneEmployeeLink>}
     * @memberof IInnerZoneBusinessDetail
     */
    links: Array<IInnerZoneEmployeeLink>;
    /**
     * 
     * @type {Array<IInnerZoneEmployeeUser>}
     * @memberof IInnerZoneBusinessDetail
     */
    users: Array<IInnerZoneEmployeeUser>;
}
/**
 * 
 * @export
 * @interface IInnerZoneEmployeeDescriptions
 */
export interface IInnerZoneEmployeeDescriptions {
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeDescriptions
     */
    communication: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeDescriptions
     */
    personableTaskSection1: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeDescriptions
     */
    personableTaskSection2: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeDescriptions
     */
    subtleDirectSection1: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeDescriptions
     */
    subtleDirectSection2: string;
}
/**
 * 
 * @export
 * @interface IInnerZoneEmployeeLink
 */
export interface IInnerZoneEmployeeLink {
    /**
     * 
     * @type {Array<string>}
     * @memberof IInnerZoneEmployeeLink
     */
    employees: Array<string>;
    /**
     * 
     * @type {IInnerZoneEmployeeLinkType}
     * @memberof IInnerZoneEmployeeLink
     */
    type: IInnerZoneEmployeeLinkType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IInnerZoneEmployeeLinkType {
    Moderate = 'Moderate',
    Strongest = 'Strongest',
    Weakest = 'Weakest'
}

/**
 * 
 * @export
 * @interface IInnerZoneEmployeeProfile
 */
export interface IInnerZoneEmployeeProfile {
    /**
     * 
     * @type {IInnerZoneEmployeeDescriptions}
     * @memberof IInnerZoneEmployeeProfile
     */
    descriptions: IInnerZoneEmployeeDescriptions;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IInnerZoneEmployeeProfile
     */
    percentages: { [key: string]: number; };
    /**
     * 
     * @type {IInnerZoneEmployeeProfileType}
     * @memberof IInnerZoneEmployeeProfile
     */
    primaryType: IInnerZoneEmployeeProfileType;
    /**
     * 
     * @type {IInnerZoneEmployeeSecondaryStyles}
     * @memberof IInnerZoneEmployeeProfile
     */
    secondaryStyles: IInnerZoneEmployeeSecondaryStyles;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IInnerZoneEmployeeProfileType {
    BottomLiner = 'BottomLiner',
    BridgeBuilder = 'BridgeBuilder',
    Energizer = 'Energizer',
    Thinker = 'Thinker'
}

/**
 * 
 * @export
 * @interface IInnerZoneEmployeeSecondaryStyles
 */
export interface IInnerZoneEmployeeSecondaryStyles {
    /**
     * 
     * @type {number}
     * @memberof IInnerZoneEmployeeSecondaryStyles
     */
    direct: number;
    /**
     * 
     * @type {number}
     * @memberof IInnerZoneEmployeeSecondaryStyles
     */
    personable: number;
    /**
     * 
     * @type {number}
     * @memberof IInnerZoneEmployeeSecondaryStyles
     */
    subtle: number;
    /**
     * 
     * @type {number}
     * @memberof IInnerZoneEmployeeSecondaryStyles
     */
    task: number;
}
/**
 * 
 * @export
 * @interface IInnerZoneEmployeeUser
 */
export interface IInnerZoneEmployeeUser {
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    department?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    surveyDate?: string | null;
    /**
     * 
     * @type {IInnerZoneSurveyStatus}
     * @memberof IInnerZoneEmployeeUser
     */
    surveyStatus: IInnerZoneSurveyStatus;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneEmployeeUser
     */
    title?: string | null;
}
/**
 * 
 * @export
 * @interface IInnerZoneLoginRequest
 */
export interface IInnerZoneLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneLoginRequest
     */
    advisorFirmId: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneLoginRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneLoginRequest
     */
    username: string;
}
/**
 * 
 * @export
 * @interface IInnerZoneLoginStatus
 */
export interface IInnerZoneLoginStatus {
    /**
     * 
     * @type {boolean}
     * @memberof IInnerZoneLoginStatus
     */
    isLoggedIn: boolean;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneLoginStatus
     */
    loggedInAs?: string | null;
}
/**
 * 
 * @export
 * @interface IInnerZonePrimaryReport
 */
export interface IInnerZonePrimaryReport {
    /**
     * 
     * @type {Array<string>}
     * @memberof IInnerZonePrimaryReport
     */
    employeeIds: Array<string>;
}
/**
 * 
 * @export
 * @interface IInnerZonePrimaryReportSaveRequest
 */
export interface IInnerZonePrimaryReportSaveRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof IInnerZonePrimaryReportSaveRequest
     */
    employeeIds: Array<string>;
}
/**
 * 
 * @export
 * @interface IInnerZoneRelationshipSummary
 */
export interface IInnerZoneRelationshipSummary {
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneRelationshipSummary
     */
    summary: string;
}
/**
 * 
 * @export
 * @interface IInnerZoneSurvey
 */
export interface IInnerZoneSurvey {
    /**
     * 
     * @type {boolean}
     * @memberof IInnerZoneSurvey
     */
    isCompleted: boolean;
    /**
     * 
     * @type {Array<IInnerZoneSurveyQuestion>}
     * @memberof IInnerZoneSurvey
     */
    questions: Array<IInnerZoneSurveyQuestion>;
}
/**
 * 
 * @export
 * @interface IInnerZoneSurveyAnswer
 */
export interface IInnerZoneSurveyAnswer {
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneSurveyAnswer
     */
    answer: string;
    /**
     * 
     * @type {number}
     * @memberof IInnerZoneSurveyAnswer
     */
    questionId: number;
}
/**
 * 
 * @export
 * @interface IInnerZoneSurveyBusinessInvitationRequest
 */
export interface IInnerZoneSurveyBusinessInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneSurveyBusinessInvitationRequest
     */
    businessId: string;
    /**
     * 
     * @type {boolean}
     * @memberof IInnerZoneSurveyBusinessInvitationRequest
     */
    resendOnly?: boolean | null;
}
/**
 * 
 * @export
 * @interface IInnerZoneSurveyEmployeeInvitationRequest
 */
export interface IInnerZoneSurveyEmployeeInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneSurveyEmployeeInvitationRequest
     */
    employeeId: string;
}
/**
 * 
 * @export
 * @interface IInnerZoneSurveyQuestion
 */
export interface IInnerZoneSurveyQuestion {
    /**
     * 
     * @type {Array<string>}
     * @memberof IInnerZoneSurveyQuestion
     */
    answerOptions: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IInnerZoneSurveyQuestion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IInnerZoneSurveyQuestion
     */
    question: string;
}
/**
 * 
 * @export
 * @interface IInnerZoneSurveyResultsSaveRequest
 */
export interface IInnerZoneSurveyResultsSaveRequest {
    /**
     * 
     * @type {Array<IInnerZoneSurveyAnswer>}
     * @memberof IInnerZoneSurveyResultsSaveRequest
     */
    answers: Array<IInnerZoneSurveyAnswer>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IInnerZoneSurveyStatus {
    EmailPending = 'EmailPending',
    EmailSent = 'EmailSent',
    SurveyCompleted = 'SurveyCompleted'
}

/**
 * 
 * @export
 * @interface IMagicLinkResponse
 */
export interface IMagicLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof IMagicLinkResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof IMagicLinkResponse
     */
    expiresAt: string;
}
/**
 * 
 * @export
 * @interface IMe
 */
export interface IMe {
    /**
     * 
     * @type {string}
     * @memberof IMe
     */
    advisorFirmId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IMe
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IMe
     */
    businessId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IMe
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {Array<IUserEntitlementType>}
     * @memberof IMe
     */
    entitlements: Array<IUserEntitlementType>;
    /**
     * 
     * @type {string}
     * @memberof IMe
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IMe
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IMe
     */
    lastName: string;
    /**
     * 
     * @type {IUserRoleType}
     * @memberof IMe
     */
    role: IUserRoleType;
}
/**
 * 
 * @export
 * @interface INote
 */
export interface INote {
    /**
     * 
     * @type {string}
     * @memberof INote
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof INote
     */
    text: string;
    /**
     * 
     * @type {INoteType}
     * @memberof INote
     */
    type: INoteType;
    /**
     * 
     * @type {string}
     * @memberof INote
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface INoteCreateRequest
 */
export interface INoteCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof INoteCreateRequest
     */
    businessId: string;
    /**
     * 
     * @type {string}
     * @memberof INoteCreateRequest
     */
    text: string;
    /**
     * 
     * @type {INoteType}
     * @memberof INoteCreateRequest
     */
    type: INoteType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum INoteType {
    Objective = 'Objective',
    Observation = 'Observation'
}

/**
 * 
 * @export
 * @interface INoteUpdateRequest
 */
export interface INoteUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof INoteUpdateRequest
     */
    text: string;
}
/**
 * 
 * @export
 * @interface IProblemDetails
 */
export interface IProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof IProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProblemDetails
     */
    instance?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProblemDetails
     */
    type?: string | null;
}
/**
 * 
 * @export
 * @interface IRecapitalization
 */
export interface IRecapitalization {
    /**
     * 
     * @type {Array<IRecapitalizationEmployee>}
     * @memberof IRecapitalization
     */
    employees: Array<IRecapitalizationEmployee>;
    /**
     * 
     * @type {string}
     * @memberof IRecapitalization
     */
    id: string;
}
/**
 * 
 * @export
 * @interface IRecapitalizationCreateRequest
 */
export interface IRecapitalizationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof IRecapitalizationCreateRequest
     */
    businessId: string;
    /**
     * 
     * @type {Array<IRecapitalizationEmployee>}
     * @memberof IRecapitalizationCreateRequest
     */
    employees: Array<IRecapitalizationEmployee>;
}
/**
 * 
 * @export
 * @interface IRecapitalizationEmployee
 */
export interface IRecapitalizationEmployee {
    /**
     * 
     * @type {string}
     * @memberof IRecapitalizationEmployee
     */
    employeeId: string;
    /**
     * 
     * @type {number}
     * @memberof IRecapitalizationEmployee
     */
    percentage: number;
}
/**
 * 
 * @export
 * @interface IRecapitalizationUpdateRequest
 */
export interface IRecapitalizationUpdateRequest {
    /**
     * 
     * @type {Array<IRecapitalizationEmployee>}
     * @memberof IRecapitalizationUpdateRequest
     */
    employees: Array<IRecapitalizationEmployee>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IStateOrProvinceType {
    AL = 'AL',
    AK = 'AK',
    AS = 'AS',
    AZ = 'AZ',
    AR = 'AR',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DE = 'DE',
    DC = 'DC',
    FL = 'FL',
    GA = 'GA',
    GU = 'GU',
    HI = 'HI',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    IA = 'IA',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    ME = 'ME',
    MD = 'MD',
    MA = 'MA',
    MI = 'MI',
    MN = 'MN',
    MS = 'MS',
    MO = 'MO',
    MT = 'MT',
    NE = 'NE',
    NV = 'NV',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NY = 'NY',
    NC = 'NC',
    ND = 'ND',
    MP = 'MP',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    PR = 'PR',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VT = 'VT',
    VA = 'VA',
    VI = 'VI',
    WA = 'WA',
    WV = 'WV',
    WI = 'WI',
    WY = 'WY'
}

/**
 * 
 * @export
 * @interface ITermsOfServiceAgreementRequest
 */
export interface ITermsOfServiceAgreementRequest {
    /**
     * 
     * @type {string}
     * @memberof ITermsOfServiceAgreementRequest
     */
    termsOfServiceAgreementId: string;
}
/**
 * 
 * @export
 * @interface ITermsOfServiceAgreementStatus
 */
export interface ITermsOfServiceAgreementStatus {
    /**
     * 
     * @type {string}
     * @memberof ITermsOfServiceAgreementStatus
     */
    agreementId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITermsOfServiceAgreementStatus
     */
    content?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ITermsOfServiceAgreementStatus
     */
    needsAgreement: boolean;
}
/**
 * 
 * @export
 * @interface ITermsOfServiceAgreementUpdate
 */
export interface ITermsOfServiceAgreementUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof ITermsOfServiceAgreementUpdate
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface ITodo
 */
export interface ITodo {
    /**
     * 
     * @type {string}
     * @memberof ITodo
     */
    assigneeId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ITodo
     */
    completed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITodo
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ITodo
     */
    dueDate: string;
    /**
     * 
     * @type {string}
     * @memberof ITodo
     */
    id: string;
    /**
     * 
     * @type {ITodoPriorityType}
     * @memberof ITodo
     */
    priority: ITodoPriorityType;
    /**
     * 
     * @type {ITodoSystemActivityType}
     * @memberof ITodo
     */
    systemActivity?: ITodoSystemActivityType;
    /**
     * 
     * @type {string}
     * @memberof ITodo
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface ITodoCreateRequest
 */
export interface ITodoCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ITodoCreateRequest
     */
    assigneeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITodoCreateRequest
     */
    businessId: string;
    /**
     * 
     * @type {string}
     * @memberof ITodoCreateRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ITodoCreateRequest
     */
    dueDate: string;
    /**
     * 
     * @type {ITodoPriorityType}
     * @memberof ITodoCreateRequest
     */
    priority: ITodoPriorityType;
    /**
     * 
     * @type {ITodoSystemActivityType}
     * @memberof ITodoCreateRequest
     */
    systemActivity?: ITodoSystemActivityType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ITodoPriorityType {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ITodoSystemActivityType {
    BizEquityBusinessDataEntry = 'BizEquityBusinessDataEntry',
    DocumentUpload = 'DocumentUpload',
    EmployeeUpload = 'EmployeeUpload'
}

/**
 * 
 * @export
 * @interface ITodoUpdateRequest
 */
export interface ITodoUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ITodoUpdateRequest
     */
    assigneeId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ITodoUpdateRequest
     */
    completed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITodoUpdateRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ITodoUpdateRequest
     */
    dueDate: string;
    /**
     * 
     * @type {ITodoPriorityType}
     * @memberof ITodoUpdateRequest
     */
    priority: ITodoPriorityType;
}
/**
 * 
 * @export
 * @interface IUser
 */
export interface IUser {
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    lastName: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IUserEntitlementType {
    AdvisorFirmCreate = 'AdvisorFirmCreate',
    AdvisorFirmDelete = 'AdvisorFirmDelete',
    AdvisorFirmRead = 'AdvisorFirmRead',
    AdvisorFirmReadSummary = 'AdvisorFirmReadSummary',
    AdvisorFirmUpdate = 'AdvisorFirmUpdate',
    AdvisorCreate = 'AdvisorCreate',
    AdvisorDelete = 'AdvisorDelete',
    AdvisorRead = 'AdvisorRead',
    AdvisorUpdate = 'AdvisorUpdate',
    BizEquityBusinessCreate = 'BizEquityBusinessCreate',
    BizEquityBusinessDataEntryDelegateInvite = 'BizEquityBusinessDataEntryDelegateInvite',
    BizEquityBusinessRead = 'BizEquityBusinessRead',
    BizEquityBusinessUpdate = 'BizEquityBusinessUpdate',
    BizEquityBusinessAccessTokenWrite = 'BizEquityBusinessAccessTokenWrite',
    BizEquityWhiteLabelUpdate = 'BizEquityWhiteLabelUpdate',
    BonusRightBusinessAssessmentRead = 'BonusRightBusinessAssessmentRead',
    BonusRightBusinessAssessmentWrite = 'BonusRightBusinessAssessmentWrite',
    BonusRightBusinessConnectionRead = 'BonusRightBusinessConnectionRead',
    BonusRightBusinessConnectionWrite = 'BonusRightBusinessConnectionWrite',
    BonusRightBusinessContactRead = 'BonusRightBusinessContactRead',
    BonusRightBusinessContactWrite = 'BonusRightBusinessContactWrite',
    BonusRightBusinessReportsRead = 'BonusRightBusinessReportsRead',
    BonusRightEmployeeReportsRead = 'BonusRightEmployeeReportsRead',
    BusinessCreate = 'BusinessCreate',
    BusinessDelete = 'BusinessDelete',
    BusinessPresentation = 'BusinessPresentation',
    BusinessRead = 'BusinessRead',
    BusinessUpdate = 'BusinessUpdate',
    BusinessOwnerCreate = 'BusinessOwnerCreate',
    BusinessOwnerDelete = 'BusinessOwnerDelete',
    BusinessOwnerRead = 'BusinessOwnerRead',
    BusinessTeamMemberCreate = 'BusinessTeamMemberCreate',
    BusinessTeamMemberDelete = 'BusinessTeamMemberDelete',
    BusinessTeamMemberRead = 'BusinessTeamMemberRead',
    DocumentCreate = 'DocumentCreate',
    DocumentDelete = 'DocumentDelete',
    DocumentRead = 'DocumentRead',
    DocumentShare = 'DocumentShare',
    DocumentUpdate = 'DocumentUpdate',
    DocumentPermissionCreate = 'DocumentPermissionCreate',
    DocumentPermissionDelete = 'DocumentPermissionDelete',
    DocumentPermissionRead = 'DocumentPermissionRead',
    DocumentPermissionUpdate = 'DocumentPermissionUpdate',
    EducationCreate = 'EducationCreate',
    EducationDelete = 'EducationDelete',
    EducationRead = 'EducationRead',
    EducationUpdate = 'EducationUpdate',
    EmployeeCreate = 'EmployeeCreate',
    EmployeeDelete = 'EmployeeDelete',
    EmployeeRead = 'EmployeeRead',
    EmployeeUpdate = 'EmployeeUpdate',
    EmployeeUploadDelegateInvite = 'EmployeeUploadDelegateInvite',
    EsopCreate = 'EsopCreate',
    EsopDelete = 'EsopDelete',
    EsopRead = 'EsopRead',
    EsopUpdate = 'EsopUpdate',
    ExternalTeamMemberCreate = 'ExternalTeamMemberCreate',
    ExternalTeamMemberDelete = 'ExternalTeamMemberDelete',
    ExternalTeamMemberRead = 'ExternalTeamMemberRead',
    ExternalTeamMemberUpdate = 'ExternalTeamMemberUpdate',
    EvaluationCreate = 'EvaluationCreate',
    EvaluationRead = 'EvaluationRead',
    FamilyLimitedPartnershipCreate = 'FamilyLimitedPartnershipCreate',
    FamilyLimitedPartnershipDelete = 'FamilyLimitedPartnershipDelete',
    FamilyLimitedPartnershipRead = 'FamilyLimitedPartnershipRead',
    FamilyLimitedPartnershipUpdate = 'FamilyLimitedPartnershipUpdate',
    InnerZoneBusinessConnectionRead = 'InnerZoneBusinessConnectionRead',
    InnerZoneBusinessConnectionWrite = 'InnerZoneBusinessConnectionWrite',
    InnerZoneBusinessRead = 'InnerZoneBusinessRead',
    InnerZoneBusinessReportsWrite = 'InnerZoneBusinessReportsWrite',
    InnerZoneProfileRead = 'InnerZoneProfileRead',
    InnerZoneSurveyInvite = 'InnerZoneSurveyInvite',
    InnerZoneSurveyRead = 'InnerZoneSurveyRead',
    InnerZoneSurveyResultsWrite = 'InnerZoneSurveyResultsWrite',
    NoteCreate = 'NoteCreate',
    NoteDelete = 'NoteDelete',
    NoteRead = 'NoteRead',
    NoteUpdate = 'NoteUpdate',
    PlacesRead = 'PlacesRead',
    RecapitalizationCreate = 'RecapitalizationCreate',
    RecapitalizationDelete = 'RecapitalizationDelete',
    RecapitalizationRead = 'RecapitalizationRead',
    RecapitalizationUpdate = 'RecapitalizationUpdate',
    TermsOfServiceAgreementRead = 'TermsOfServiceAgreementRead',
    TermsOfServiceAgreementWrite = 'TermsOfServiceAgreementWrite',
    TodoCreate = 'TodoCreate',
    TodoDelete = 'TodoDelete',
    TodoRead = 'TodoRead',
    TodoUpdate = 'TodoUpdate',
    UserAvatarWrite = 'UserAvatarWrite',
    UserRead = 'UserRead'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum IUserRoleType {
    Admin = 'Admin',
    Advisor = 'Advisor',
    Anonymous = 'Anonymous',
    BizEquityDataEntryDelegate = 'BizEquityDataEntryDelegate',
    BusinessOwner = 'BusinessOwner',
    DocumentUploadDelegate = 'DocumentUploadDelegate',
    EmployeeUploadDelegate = 'EmployeeUploadDelegate',
    InnerZoneSurveyTaker = 'InnerZoneSurveyTaker'
}

/**
 * 
 * @export
 * @interface IVisionLinkContactRecord
 */
export interface IVisionLinkContactRecord {
    /**
     * 
     * @type {string}
     * @memberof IVisionLinkContactRecord
     */
    emailSentAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof IVisionLinkContactRecord
     */
    recordExists: boolean;
    /**
     * 
     * @type {Array<IVisionLinkContactUserRecord>}
     * @memberof IVisionLinkContactRecord
     */
    usersToContact: Array<IVisionLinkContactUserRecord>;
}
/**
 * 
 * @export
 * @interface IVisionLinkContactUserRecord
 */
export interface IVisionLinkContactUserRecord {
    /**
     * 
     * @type {string}
     * @memberof IVisionLinkContactUserRecord
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof IVisionLinkContactUserRecord
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IVisionLinkContactUserRecord
     */
    logId: string;
}

/**
 * AdvisorFirmsApi - axios parameter creator
 * @export
 */
export const AdvisorFirmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisorFirm: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdvisorFirm', 'id', id)
            const localVarPath = `/api/AdvisorFirms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorFirm: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdvisorFirm', 'id', id)
            const localVarPath = `/api/AdvisorFirms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorFirmSummary: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdvisorFirmSummary', 'id', id)
            const localVarPath = `/api/AdvisorFirms/{id}/Summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorFirms: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdvisorFirms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IAdvisorFirmCreateRequest} [iAdvisorFirmCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdvisorFirm: async (apiVersion?: string, iAdvisorFirmCreateRequest?: IAdvisorFirmCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdvisorFirms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAdvisorFirmCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IAdvisorFirmUpdateRequest} [iAdvisorFirmUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdvisorFirm: async (id: string, apiVersion?: string, iAdvisorFirmUpdateRequest?: IAdvisorFirmUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdvisorFirm', 'id', id)
            const localVarPath = `/api/AdvisorFirms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAdvisorFirmUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdvisorFirmAvatar: async (id: string, file: any, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setAdvisorFirmAvatar', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('setAdvisorFirmAvatar', 'file', file)
            const localVarPath = `/api/AdvisorFirms/{id}/Avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvisorFirmsApi - functional programming interface
 * @export
 */
export const AdvisorFirmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvisorFirmsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdvisorFirm(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdvisorFirm(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisorFirm(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdvisorFirm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisorFirm(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisorFirmSummary(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdvisorFirmSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisorFirmSummary(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisorFirms(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAdvisorFirm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisorFirms(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IAdvisorFirmCreateRequest} [iAdvisorFirmCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdvisorFirm(apiVersion?: string, iAdvisorFirmCreateRequest?: IAdvisorFirmCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdvisorFirm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdvisorFirm(apiVersion, iAdvisorFirmCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IAdvisorFirmUpdateRequest} [iAdvisorFirmUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdvisorFirm(id: string, apiVersion?: string, iAdvisorFirmUpdateRequest?: IAdvisorFirmUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdvisorFirm(id, apiVersion, iAdvisorFirmUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAdvisorFirmAvatar(id: string, file: any, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAvatar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAdvisorFirmAvatar(id, file, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvisorFirmsApi - factory interface
 * @export
 */
export const AdvisorFirmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvisorFirmsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisorFirm(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdvisorFirm(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorFirm(id: string, apiVersion?: string, options?: any): AxiosPromise<IAdvisorFirm> {
            return localVarFp.getAdvisorFirm(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorFirmSummary(id: string, apiVersion?: string, options?: any): AxiosPromise<IAdvisorFirmSummary> {
            return localVarFp.getAdvisorFirmSummary(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorFirms(apiVersion?: string, options?: any): AxiosPromise<Array<IAdvisorFirm>> {
            return localVarFp.getAdvisorFirms(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IAdvisorFirmCreateRequest} [iAdvisorFirmCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdvisorFirm(apiVersion?: string, iAdvisorFirmCreateRequest?: IAdvisorFirmCreateRequest, options?: any): AxiosPromise<IAdvisorFirm> {
            return localVarFp.postAdvisorFirm(apiVersion, iAdvisorFirmCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IAdvisorFirmUpdateRequest} [iAdvisorFirmUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdvisorFirm(id: string, apiVersion?: string, iAdvisorFirmUpdateRequest?: IAdvisorFirmUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putAdvisorFirm(id, apiVersion, iAdvisorFirmUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdvisorFirmAvatar(id: string, file: any, apiVersion?: string, options?: any): AxiosPromise<IAvatar> {
            return localVarFp.setAdvisorFirmAvatar(id, file, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvisorFirmsApi - object-oriented interface
 * @export
 * @class AdvisorFirmsApi
 * @extends {BaseAPI}
 */
export class AdvisorFirmsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorFirmsApi
     */
    public deleteAdvisorFirm(id: string, apiVersion?: string, options?: any) {
        return AdvisorFirmsApiFp(this.configuration).deleteAdvisorFirm(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorFirmsApi
     */
    public getAdvisorFirm(id: string, apiVersion?: string, options?: any) {
        return AdvisorFirmsApiFp(this.configuration).getAdvisorFirm(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorFirmsApi
     */
    public getAdvisorFirmSummary(id: string, apiVersion?: string, options?: any) {
        return AdvisorFirmsApiFp(this.configuration).getAdvisorFirmSummary(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorFirmsApi
     */
    public getAdvisorFirms(apiVersion?: string, options?: any) {
        return AdvisorFirmsApiFp(this.configuration).getAdvisorFirms(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IAdvisorFirmCreateRequest} [iAdvisorFirmCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorFirmsApi
     */
    public postAdvisorFirm(apiVersion?: string, iAdvisorFirmCreateRequest?: IAdvisorFirmCreateRequest, options?: any) {
        return AdvisorFirmsApiFp(this.configuration).postAdvisorFirm(apiVersion, iAdvisorFirmCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IAdvisorFirmUpdateRequest} [iAdvisorFirmUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorFirmsApi
     */
    public putAdvisorFirm(id: string, apiVersion?: string, iAdvisorFirmUpdateRequest?: IAdvisorFirmUpdateRequest, options?: any) {
        return AdvisorFirmsApiFp(this.configuration).putAdvisorFirm(id, apiVersion, iAdvisorFirmUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} file 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorFirmsApi
     */
    public setAdvisorFirmAvatar(id: string, file: any, apiVersion?: string, options?: any) {
        return AdvisorFirmsApiFp(this.configuration).setAdvisorFirmAvatar(id, file, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvisorsApi - axios parameter creator
 * @export
 */
export const AdvisorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisor: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdvisor', 'id', id)
            const localVarPath = `/api/Advisors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisor: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdvisor', 'id', id)
            const localVarPath = `/api/Advisors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} advisorFirmId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorsByFirmId: async (advisorFirmId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorFirmId' is not null or undefined
            assertParamExists('getAdvisorsByFirmId', 'advisorFirmId', advisorFirmId)
            const localVarPath = `/api/Firms/{advisorFirmId}/Advisors`
                .replace(`{${"advisorFirmId"}}`, encodeURIComponent(String(advisorFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IAdvisorCreateRequest} [iAdvisorCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdvisor: async (apiVersion?: string, iAdvisorCreateRequest?: IAdvisorCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Advisors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAdvisorCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IAdvisorUpdateRequest} [iAdvisorUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdvisor: async (id: string, apiVersion?: string, iAdvisorUpdateRequest?: IAdvisorUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAdvisor', 'id', id)
            const localVarPath = `/api/Advisors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAdvisorUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdvisorAvatar: async (id: string, file: any, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setAdvisorAvatar', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('setAdvisorAvatar', 'file', file)
            const localVarPath = `/api/Advisors/{id}/Avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvisorsApi - functional programming interface
 * @export
 */
export const AdvisorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvisorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdvisor(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdvisor(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisor(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdvisor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisor(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} advisorFirmId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvisorsByFirmId(advisorFirmId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAdvisor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvisorsByFirmId(advisorFirmId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IAdvisorCreateRequest} [iAdvisorCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdvisor(apiVersion?: string, iAdvisorCreateRequest?: IAdvisorCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAdvisor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdvisor(apiVersion, iAdvisorCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IAdvisorUpdateRequest} [iAdvisorUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAdvisor(id: string, apiVersion?: string, iAdvisorUpdateRequest?: IAdvisorUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAdvisor(id, apiVersion, iAdvisorUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAdvisorAvatar(id: string, file: any, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAvatar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAdvisorAvatar(id, file, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvisorsApi - factory interface
 * @export
 */
export const AdvisorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvisorsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisor(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdvisor(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisor(id: string, apiVersion?: string, options?: any): AxiosPromise<IAdvisor> {
            return localVarFp.getAdvisor(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} advisorFirmId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvisorsByFirmId(advisorFirmId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IAdvisor>> {
            return localVarFp.getAdvisorsByFirmId(advisorFirmId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IAdvisorCreateRequest} [iAdvisorCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdvisor(apiVersion?: string, iAdvisorCreateRequest?: IAdvisorCreateRequest, options?: any): AxiosPromise<IAdvisor> {
            return localVarFp.postAdvisor(apiVersion, iAdvisorCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IAdvisorUpdateRequest} [iAdvisorUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAdvisor(id: string, apiVersion?: string, iAdvisorUpdateRequest?: IAdvisorUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putAdvisor(id, apiVersion, iAdvisorUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdvisorAvatar(id: string, file: any, apiVersion?: string, options?: any): AxiosPromise<IAvatar> {
            return localVarFp.setAdvisorAvatar(id, file, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvisorsApi - object-oriented interface
 * @export
 * @class AdvisorsApi
 * @extends {BaseAPI}
 */
export class AdvisorsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorsApi
     */
    public deleteAdvisor(id: string, apiVersion?: string, options?: any) {
        return AdvisorsApiFp(this.configuration).deleteAdvisor(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorsApi
     */
    public getAdvisor(id: string, apiVersion?: string, options?: any) {
        return AdvisorsApiFp(this.configuration).getAdvisor(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} advisorFirmId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorsApi
     */
    public getAdvisorsByFirmId(advisorFirmId: string, apiVersion?: string, options?: any) {
        return AdvisorsApiFp(this.configuration).getAdvisorsByFirmId(advisorFirmId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IAdvisorCreateRequest} [iAdvisorCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorsApi
     */
    public postAdvisor(apiVersion?: string, iAdvisorCreateRequest?: IAdvisorCreateRequest, options?: any) {
        return AdvisorsApiFp(this.configuration).postAdvisor(apiVersion, iAdvisorCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IAdvisorUpdateRequest} [iAdvisorUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorsApi
     */
    public putAdvisor(id: string, apiVersion?: string, iAdvisorUpdateRequest?: IAdvisorUpdateRequest, options?: any) {
        return AdvisorsApiFp(this.configuration).putAdvisor(id, apiVersion, iAdvisorUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} file 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisorsApi
     */
    public setAdvisorAvatar(id: string, file: any, apiVersion?: string, options?: any) {
        return AdvisorsApiFp(this.configuration).setAdvisorAvatar(id, file, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BizEquityBusinessesApi - axios parameter creator
 * @export
 */
export const BizEquityBusinessesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessConnectRequest} [iBizEquityBusinessConnectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectBizEquityBusiness: async (businessId: string, apiVersion?: string, iBizEquityBusinessConnectRequest?: IBizEquityBusinessConnectRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('connectBizEquityBusiness', 'businessId', businessId)
            const localVarPath = `/api/BizEquity/Businesses/{businessId}/Connection`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBizEquityBusinessConnectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessCreateRequest} [iBizEquityBusinessCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBizEquityBusiness: async (businessId: string, apiVersion?: string, iBizEquityBusinessCreateRequest?: IBizEquityBusinessCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('createBizEquityBusiness', 'businessId', businessId)
            const localVarPath = `/api/BizEquity/Businesses/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBizEquityBusinessCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessDataEntryDelegateInvitationRequest} [iBizEquityBusinessDataEntryDelegateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitationForBizEquityDataEntryDelegate: async (businessId: string, apiVersion?: string, iBizEquityBusinessDataEntryDelegateInvitationRequest?: IBizEquityBusinessDataEntryDelegateInvitationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('createInvitationForBizEquityDataEntryDelegate', 'businessId', businessId)
            const localVarPath = `/api/BizEquity/Businesses/{businessId}/DataEntryDelegate`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBizEquityBusinessDataEntryDelegateInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBizEquityBusiness', 'businessId', businessId)
            const localVarPath = `/api/BizEquity/Businesses/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityBusinessConnectionStatus: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBizEquityBusinessConnectionStatus', 'businessId', businessId)
            const localVarPath = `/api/BizEquity/Businesses/{businessId}/ConnectionStatus`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBizEquityBusinesses: async (search?: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BizEquity/Businesses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessUpdateRequest} [iBizEquityBusinessUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBizEquityBusiness: async (businessId: string, apiVersion?: string, iBizEquityBusinessUpdateRequest?: IBizEquityBusinessUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('updateBizEquityBusiness', 'businessId', businessId)
            const localVarPath = `/api/BizEquity/Businesses/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBizEquityBusinessUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BizEquityBusinessesApi - functional programming interface
 * @export
 */
export const BizEquityBusinessesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BizEquityBusinessesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessConnectRequest} [iBizEquityBusinessConnectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessConnectRequest?: IBizEquityBusinessConnectRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessConnectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessCreateRequest} [iBizEquityBusinessCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessCreateRequest?: IBizEquityBusinessCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessDataEntryDelegateInvitationRequest} [iBizEquityBusinessDataEntryDelegateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitationForBizEquityDataEntryDelegate(businessId: string, apiVersion?: string, iBizEquityBusinessDataEntryDelegateInvitationRequest?: IBizEquityBusinessDataEntryDelegateInvitationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitationForBizEquityDataEntryDelegate(businessId, apiVersion, iBizEquityBusinessDataEntryDelegateInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBizEquityBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBizEquityBusiness>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBizEquityBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBizEquityBusinessConnectionStatus(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBizEquityBusinessConnectionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBizEquityBusinessConnectionStatus(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBizEquityBusinesses(search?: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBizEquityBusinessSearchResultItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBizEquityBusinesses(search, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessUpdateRequest} [iBizEquityBusinessUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessUpdateRequest?: IBizEquityBusinessUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BizEquityBusinessesApi - factory interface
 * @export
 */
export const BizEquityBusinessesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BizEquityBusinessesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessConnectRequest} [iBizEquityBusinessConnectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessConnectRequest?: IBizEquityBusinessConnectRequest, options?: any): AxiosPromise<void> {
            return localVarFp.connectBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessConnectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessCreateRequest} [iBizEquityBusinessCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessCreateRequest?: IBizEquityBusinessCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessDataEntryDelegateInvitationRequest} [iBizEquityBusinessDataEntryDelegateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitationForBizEquityDataEntryDelegate(businessId: string, apiVersion?: string, iBizEquityBusinessDataEntryDelegateInvitationRequest?: IBizEquityBusinessDataEntryDelegateInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createInvitationForBizEquityDataEntryDelegate(businessId, apiVersion, iBizEquityBusinessDataEntryDelegateInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBizEquityBusiness> {
            return localVarFp.getBizEquityBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityBusinessConnectionStatus(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBizEquityBusinessConnectionStatus> {
            return localVarFp.getBizEquityBusinessConnectionStatus(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBizEquityBusinesses(search?: string, apiVersion?: string, options?: any): AxiosPromise<Array<IBizEquityBusinessSearchResultItem>> {
            return localVarFp.searchBizEquityBusinesses(search, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBizEquityBusinessUpdateRequest} [iBizEquityBusinessUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessUpdateRequest?: IBizEquityBusinessUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BizEquityBusinessesApi - object-oriented interface
 * @export
 * @class BizEquityBusinessesApi
 * @extends {BaseAPI}
 */
export class BizEquityBusinessesApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {IBizEquityBusinessConnectRequest} [iBizEquityBusinessConnectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityBusinessesApi
     */
    public connectBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessConnectRequest?: IBizEquityBusinessConnectRequest, options?: any) {
        return BizEquityBusinessesApiFp(this.configuration).connectBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessConnectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {IBizEquityBusinessCreateRequest} [iBizEquityBusinessCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityBusinessesApi
     */
    public createBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessCreateRequest?: IBizEquityBusinessCreateRequest, options?: any) {
        return BizEquityBusinessesApiFp(this.configuration).createBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {IBizEquityBusinessDataEntryDelegateInvitationRequest} [iBizEquityBusinessDataEntryDelegateInvitationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityBusinessesApi
     */
    public createInvitationForBizEquityDataEntryDelegate(businessId: string, apiVersion?: string, iBizEquityBusinessDataEntryDelegateInvitationRequest?: IBizEquityBusinessDataEntryDelegateInvitationRequest, options?: any) {
        return BizEquityBusinessesApiFp(this.configuration).createInvitationForBizEquityDataEntryDelegate(businessId, apiVersion, iBizEquityBusinessDataEntryDelegateInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityBusinessesApi
     */
    public getBizEquityBusiness(businessId: string, apiVersion?: string, options?: any) {
        return BizEquityBusinessesApiFp(this.configuration).getBizEquityBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityBusinessesApi
     */
    public getBizEquityBusinessConnectionStatus(businessId: string, apiVersion?: string, options?: any) {
        return BizEquityBusinessesApiFp(this.configuration).getBizEquityBusinessConnectionStatus(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityBusinessesApi
     */
    public searchBizEquityBusinesses(search?: string, apiVersion?: string, options?: any) {
        return BizEquityBusinessesApiFp(this.configuration).searchBizEquityBusinesses(search, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {IBizEquityBusinessUpdateRequest} [iBizEquityBusinessUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityBusinessesApi
     */
    public updateBizEquityBusiness(businessId: string, apiVersion?: string, iBizEquityBusinessUpdateRequest?: IBizEquityBusinessUpdateRequest, options?: any) {
        return BizEquityBusinessesApiFp(this.configuration).updateBizEquityBusiness(businessId, apiVersion, iBizEquityBusinessUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BizEquityIndustriesApi - axios parameter creator
 * @export
 */
export const BizEquityIndustriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBizEquityIndustries: async (search?: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BizEquity/Industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BizEquityIndustriesApi - functional programming interface
 * @export
 */
export const BizEquityIndustriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BizEquityIndustriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBizEquityIndustries(search?: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBizEquityIndustry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBizEquityIndustries(search, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BizEquityIndustriesApi - factory interface
 * @export
 */
export const BizEquityIndustriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BizEquityIndustriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBizEquityIndustries(search?: string, apiVersion?: string, options?: any): AxiosPromise<Array<IBizEquityIndustry>> {
            return localVarFp.searchBizEquityIndustries(search, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BizEquityIndustriesApi - object-oriented interface
 * @export
 * @class BizEquityIndustriesApi
 * @extends {BaseAPI}
 */
export class BizEquityIndustriesApi extends BaseAPI {
    /**
     * 
     * @param {string} [search] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityIndustriesApi
     */
    public searchBizEquityIndustries(search?: string, apiVersion?: string, options?: any) {
        return BizEquityIndustriesApiFp(this.configuration).searchBizEquityIndustries(search, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BizEquityPlacesApi - axios parameter creator
 * @export
 */
export const BizEquityPlacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [industryId] 
         * @param {number} [northEastLat] 
         * @param {number} [northEastLong] 
         * @param {number} [southWestLat] 
         * @param {number} [southWestLong] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBizEquityPlaces: async (industryId?: string, northEastLat?: number, northEastLong?: number, southWestLat?: number, southWestLong?: number, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BizEquity/Places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (industryId !== undefined) {
                localVarQueryParameter['industryId'] = industryId;
            }

            if (northEastLat !== undefined) {
                localVarQueryParameter['northEastLat'] = northEastLat;
            }

            if (northEastLong !== undefined) {
                localVarQueryParameter['northEastLong'] = northEastLong;
            }

            if (southWestLat !== undefined) {
                localVarQueryParameter['southWestLat'] = southWestLat;
            }

            if (southWestLong !== undefined) {
                localVarQueryParameter['southWestLong'] = southWestLong;
            }

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BizEquityPlacesApi - functional programming interface
 * @export
 */
export const BizEquityPlacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BizEquityPlacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [industryId] 
         * @param {number} [northEastLat] 
         * @param {number} [northEastLong] 
         * @param {number} [southWestLat] 
         * @param {number} [southWestLong] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBizEquityPlaces(industryId?: string, northEastLat?: number, northEastLong?: number, southWestLat?: number, southWestLong?: number, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBizEquityPlace>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBizEquityPlaces(industryId, northEastLat, northEastLong, southWestLat, southWestLong, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BizEquityPlacesApi - factory interface
 * @export
 */
export const BizEquityPlacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BizEquityPlacesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [industryId] 
         * @param {number} [northEastLat] 
         * @param {number} [northEastLong] 
         * @param {number} [southWestLat] 
         * @param {number} [southWestLong] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBizEquityPlaces(industryId?: string, northEastLat?: number, northEastLong?: number, southWestLat?: number, southWestLong?: number, apiVersion?: string, options?: any): AxiosPromise<Array<IBizEquityPlace>> {
            return localVarFp.searchBizEquityPlaces(industryId, northEastLat, northEastLong, southWestLat, southWestLong, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BizEquityPlacesApi - object-oriented interface
 * @export
 * @class BizEquityPlacesApi
 * @extends {BaseAPI}
 */
export class BizEquityPlacesApi extends BaseAPI {
    /**
     * 
     * @param {string} [industryId] 
     * @param {number} [northEastLat] 
     * @param {number} [northEastLong] 
     * @param {number} [southWestLat] 
     * @param {number} [southWestLong] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityPlacesApi
     */
    public searchBizEquityPlaces(industryId?: string, northEastLat?: number, northEastLong?: number, southWestLat?: number, southWestLong?: number, apiVersion?: string, options?: any) {
        return BizEquityPlacesApiFp(this.configuration).searchBizEquityPlaces(industryId, northEastLat, northEastLong, southWestLat, southWestLong, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BizEquityRequiredFieldsApi - axios parameter creator
 * @export
 */
export const BizEquityRequiredFieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityRequiredFields: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BizEquity/RequiredFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BizEquityRequiredFieldsApi - functional programming interface
 * @export
 */
export const BizEquityRequiredFieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BizEquityRequiredFieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBizEquityRequiredFields(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBizEquityRequiredFields>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBizEquityRequiredFields(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BizEquityRequiredFieldsApi - factory interface
 * @export
 */
export const BizEquityRequiredFieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BizEquityRequiredFieldsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityRequiredFields(apiVersion?: string, options?: any): AxiosPromise<IBizEquityRequiredFields> {
            return localVarFp.getBizEquityRequiredFields(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BizEquityRequiredFieldsApi - object-oriented interface
 * @export
 * @class BizEquityRequiredFieldsApi
 * @extends {BaseAPI}
 */
export class BizEquityRequiredFieldsApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityRequiredFieldsApi
     */
    public getBizEquityRequiredFields(apiVersion?: string, options?: any) {
        return BizEquityRequiredFieldsApiFp(this.configuration).getBizEquityRequiredFields(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BizEquitySettingsApi - axios parameter creator
 * @export
 */
export const BizEquitySettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBizEquityWhiteLabelUpdateRequest} [iBizEquityWhiteLabelUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhiteLabel: async (apiVersion?: string, iBizEquityWhiteLabelUpdateRequest?: IBizEquityWhiteLabelUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BizEquity/Settings/WhiteLabel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBizEquityWhiteLabelUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BizEquitySettingsApi - functional programming interface
 * @export
 */
export const BizEquitySettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BizEquitySettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBizEquityWhiteLabelUpdateRequest} [iBizEquityWhiteLabelUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWhiteLabel(apiVersion?: string, iBizEquityWhiteLabelUpdateRequest?: IBizEquityWhiteLabelUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWhiteLabel(apiVersion, iBizEquityWhiteLabelUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BizEquitySettingsApi - factory interface
 * @export
 */
export const BizEquitySettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BizEquitySettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBizEquityWhiteLabelUpdateRequest} [iBizEquityWhiteLabelUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhiteLabel(apiVersion?: string, iBizEquityWhiteLabelUpdateRequest?: IBizEquityWhiteLabelUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWhiteLabel(apiVersion, iBizEquityWhiteLabelUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BizEquitySettingsApi - object-oriented interface
 * @export
 * @class BizEquitySettingsApi
 * @extends {BaseAPI}
 */
export class BizEquitySettingsApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IBizEquityWhiteLabelUpdateRequest} [iBizEquityWhiteLabelUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquitySettingsApi
     */
    public updateWhiteLabel(apiVersion?: string, iBizEquityWhiteLabelUpdateRequest?: IBizEquityWhiteLabelUpdateRequest, options?: any) {
        return BizEquitySettingsApiFp(this.configuration).updateWhiteLabel(apiVersion, iBizEquityWhiteLabelUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BizEquityUserApi - axios parameter creator
 * @export
 */
export const BizEquityUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityLoginStatus: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BizEquity/User/LoginStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBizEquityPostOAuthCodeRequest} [iBizEquityPostOAuthCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBizEquityOAuthCode: async (apiVersion?: string, iBizEquityPostOAuthCodeRequest?: IBizEquityPostOAuthCodeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BizEquity/User/OAuthCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBizEquityPostOAuthCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BizEquityUserApi - functional programming interface
 * @export
 */
export const BizEquityUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BizEquityUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBizEquityLoginStatus(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBizEquityLoginStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBizEquityLoginStatus(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBizEquityPostOAuthCodeRequest} [iBizEquityPostOAuthCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBizEquityOAuthCode(apiVersion?: string, iBizEquityPostOAuthCodeRequest?: IBizEquityPostOAuthCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBizEquityOAuthCode(apiVersion, iBizEquityPostOAuthCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BizEquityUserApi - factory interface
 * @export
 */
export const BizEquityUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BizEquityUserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBizEquityLoginStatus(apiVersion?: string, options?: any): AxiosPromise<IBizEquityLoginStatus> {
            return localVarFp.getBizEquityLoginStatus(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBizEquityPostOAuthCodeRequest} [iBizEquityPostOAuthCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBizEquityOAuthCode(apiVersion?: string, iBizEquityPostOAuthCodeRequest?: IBizEquityPostOAuthCodeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postBizEquityOAuthCode(apiVersion, iBizEquityPostOAuthCodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BizEquityUserApi - object-oriented interface
 * @export
 * @class BizEquityUserApi
 * @extends {BaseAPI}
 */
export class BizEquityUserApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityUserApi
     */
    public getBizEquityLoginStatus(apiVersion?: string, options?: any) {
        return BizEquityUserApiFp(this.configuration).getBizEquityLoginStatus(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IBizEquityPostOAuthCodeRequest} [iBizEquityPostOAuthCodeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BizEquityUserApi
     */
    public postBizEquityOAuthCode(apiVersion?: string, iBizEquityPostOAuthCodeRequest?: IBizEquityPostOAuthCodeRequest, options?: any) {
        return BizEquityUserApiFp(this.configuration).postBizEquityOAuthCode(apiVersion, iBizEquityPostOAuthCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BonusRightAssessmentApi - axios parameter creator
 * @export
 */
export const BonusRightAssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightAssessmentResult: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBonusRightAssessmentResult', 'businessId', businessId)
            const localVarPath = `/api/BonusRight/Assessment/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBonusRightAssessmentSubmission} [iBonusRightAssessmentSubmission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBonusRightAssessment: async (apiVersion?: string, iBonusRightAssessmentSubmission?: IBonusRightAssessmentSubmission, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BonusRight/Assessment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBonusRightAssessmentSubmission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BonusRightAssessmentApi - functional programming interface
 * @export
 */
export const BonusRightAssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BonusRightAssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusRightAssessmentResult(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBonusRightAssessmentResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusRightAssessmentResult(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBonusRightAssessmentSubmission} [iBonusRightAssessmentSubmission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitBonusRightAssessment(apiVersion?: string, iBonusRightAssessmentSubmission?: IBonusRightAssessmentSubmission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitBonusRightAssessment(apiVersion, iBonusRightAssessmentSubmission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BonusRightAssessmentApi - factory interface
 * @export
 */
export const BonusRightAssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BonusRightAssessmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightAssessmentResult(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBonusRightAssessmentResult> {
            return localVarFp.getBonusRightAssessmentResult(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBonusRightAssessmentSubmission} [iBonusRightAssessmentSubmission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBonusRightAssessment(apiVersion?: string, iBonusRightAssessmentSubmission?: IBonusRightAssessmentSubmission, options?: any): AxiosPromise<void> {
            return localVarFp.submitBonusRightAssessment(apiVersion, iBonusRightAssessmentSubmission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BonusRightAssessmentApi - object-oriented interface
 * @export
 * @class BonusRightAssessmentApi
 * @extends {BaseAPI}
 */
export class BonusRightAssessmentApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightAssessmentApi
     */
    public getBonusRightAssessmentResult(businessId: string, apiVersion?: string, options?: any) {
        return BonusRightAssessmentApiFp(this.configuration).getBonusRightAssessmentResult(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IBonusRightAssessmentSubmission} [iBonusRightAssessmentSubmission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightAssessmentApi
     */
    public submitBonusRightAssessment(apiVersion?: string, iBonusRightAssessmentSubmission?: IBonusRightAssessmentSubmission, options?: any) {
        return BonusRightAssessmentApiFp(this.configuration).submitBonusRightAssessment(apiVersion, iBonusRightAssessmentSubmission, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BonusRightConnectionApi - axios parameter creator
 * @export
 */
export const BonusRightConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {ICreateBonusRightConnectionRequest} [iCreateBonusRightConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBonusRightConnection: async (businessId: string, apiVersion?: string, iCreateBonusRightConnectionRequest?: ICreateBonusRightConnectionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('createBonusRightConnection', 'businessId', businessId)
            const localVarPath = `/api/BonusRight/{businessId}/BonusRightConnection`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCreateBonusRightConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBonusRightConnection: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('deleteBonusRightConnection', 'businessId', businessId)
            const localVarPath = `/api/BonusRight/{businessId}/BonusRightConnection`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightConnectionStatus: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBonusRightConnectionStatus', 'businessId', businessId)
            const localVarPath = `/api/BonusRight/{businessId}/ConnectionStatus`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BonusRightConnectionApi - functional programming interface
 * @export
 */
export const BonusRightConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BonusRightConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {ICreateBonusRightConnectionRequest} [iCreateBonusRightConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBonusRightConnection(businessId: string, apiVersion?: string, iCreateBonusRightConnectionRequest?: ICreateBonusRightConnectionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBonusRightConnectionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBonusRightConnection(businessId, apiVersion, iCreateBonusRightConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBonusRightConnection(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBonusRightConnectionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBonusRightConnection(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusRightConnectionStatus(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBonusRightConnectionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusRightConnectionStatus(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BonusRightConnectionApi - factory interface
 * @export
 */
export const BonusRightConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BonusRightConnectionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {ICreateBonusRightConnectionRequest} [iCreateBonusRightConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBonusRightConnection(businessId: string, apiVersion?: string, iCreateBonusRightConnectionRequest?: ICreateBonusRightConnectionRequest, options?: any): AxiosPromise<IBonusRightConnectionStatus> {
            return localVarFp.createBonusRightConnection(businessId, apiVersion, iCreateBonusRightConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBonusRightConnection(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBonusRightConnectionStatus> {
            return localVarFp.deleteBonusRightConnection(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightConnectionStatus(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBonusRightConnectionStatus> {
            return localVarFp.getBonusRightConnectionStatus(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BonusRightConnectionApi - object-oriented interface
 * @export
 * @class BonusRightConnectionApi
 * @extends {BaseAPI}
 */
export class BonusRightConnectionApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {ICreateBonusRightConnectionRequest} [iCreateBonusRightConnectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightConnectionApi
     */
    public createBonusRightConnection(businessId: string, apiVersion?: string, iCreateBonusRightConnectionRequest?: ICreateBonusRightConnectionRequest, options?: any) {
        return BonusRightConnectionApiFp(this.configuration).createBonusRightConnection(businessId, apiVersion, iCreateBonusRightConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightConnectionApi
     */
    public deleteBonusRightConnection(businessId: string, apiVersion?: string, options?: any) {
        return BonusRightConnectionApiFp(this.configuration).deleteBonusRightConnection(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightConnectionApi
     */
    public getBonusRightConnectionStatus(businessId: string, apiVersion?: string, options?: any) {
        return BonusRightConnectionApiFp(this.configuration).getBonusRightConnectionStatus(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BonusRightEmployeeRewardsApi - axios parameter creator
 * @export
 */
export const BonusRightEmployeeRewardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightAggregatePaySummary: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBonusRightAggregatePaySummary', 'businessId', businessId)
            const localVarPath = `/api/BonusRightEmployeeRewards/{businessId}/AggregatePaySummary`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {number} bonusRightEmployeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightEmployeeValueStatement: async (businessId: string, bonusRightEmployeeId: number, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBonusRightEmployeeValueStatement', 'businessId', businessId)
            // verify required parameter 'bonusRightEmployeeId' is not null or undefined
            assertParamExists('getBonusRightEmployeeValueStatement', 'bonusRightEmployeeId', bonusRightEmployeeId)
            const localVarPath = `/api/BonusRightEmployeeRewards/{businessId}/Employees/{bonusRightEmployeeId}/ValueStatement`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"bonusRightEmployeeId"}}`, encodeURIComponent(String(bonusRightEmployeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightExecutiveBenefitAnalysis: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBonusRightExecutiveBenefitAnalysis', 'businessId', businessId)
            const localVarPath = `/api/BonusRightEmployeeRewards/{businessId}/ExecutiveBenefitAnalysis`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightTotalRewardsAssessment: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBonusRightTotalRewardsAssessment', 'businessId', businessId)
            const localVarPath = `/api/BonusRightEmployeeRewards/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BonusRightEmployeeRewardsApi - functional programming interface
 * @export
 */
export const BonusRightEmployeeRewardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BonusRightEmployeeRewardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusRightAggregatePaySummary(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBonusRightAggregatePaySummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusRightAggregatePaySummary(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {number} bonusRightEmployeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusRightEmployeeValueStatement(businessId: string, bonusRightEmployeeId: number, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBonusRightEmployeeValueStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusRightEmployeeValueStatement(businessId, bonusRightEmployeeId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusRightExecutiveBenefitAnalysis(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBonusRightExecutiveBenefitAnalysis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusRightExecutiveBenefitAnalysis(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusRightTotalRewardsAssessment(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBonusRightEmployeeRewards>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBonusRightTotalRewardsAssessment(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BonusRightEmployeeRewardsApi - factory interface
 * @export
 */
export const BonusRightEmployeeRewardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BonusRightEmployeeRewardsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightAggregatePaySummary(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBonusRightAggregatePaySummary> {
            return localVarFp.getBonusRightAggregatePaySummary(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {number} bonusRightEmployeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightEmployeeValueStatement(businessId: string, bonusRightEmployeeId: number, apiVersion?: string, options?: any): AxiosPromise<IBonusRightEmployeeValueStatement> {
            return localVarFp.getBonusRightEmployeeValueStatement(businessId, bonusRightEmployeeId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightExecutiveBenefitAnalysis(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBonusRightExecutiveBenefitAnalysis> {
            return localVarFp.getBonusRightExecutiveBenefitAnalysis(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusRightTotalRewardsAssessment(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IBonusRightEmployeeRewards>> {
            return localVarFp.getBonusRightTotalRewardsAssessment(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BonusRightEmployeeRewardsApi - object-oriented interface
 * @export
 * @class BonusRightEmployeeRewardsApi
 * @extends {BaseAPI}
 */
export class BonusRightEmployeeRewardsApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightEmployeeRewardsApi
     */
    public getBonusRightAggregatePaySummary(businessId: string, apiVersion?: string, options?: any) {
        return BonusRightEmployeeRewardsApiFp(this.configuration).getBonusRightAggregatePaySummary(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {number} bonusRightEmployeeId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightEmployeeRewardsApi
     */
    public getBonusRightEmployeeValueStatement(businessId: string, bonusRightEmployeeId: number, apiVersion?: string, options?: any) {
        return BonusRightEmployeeRewardsApiFp(this.configuration).getBonusRightEmployeeValueStatement(businessId, bonusRightEmployeeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightEmployeeRewardsApi
     */
    public getBonusRightExecutiveBenefitAnalysis(businessId: string, apiVersion?: string, options?: any) {
        return BonusRightEmployeeRewardsApiFp(this.configuration).getBonusRightExecutiveBenefitAnalysis(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BonusRightEmployeeRewardsApi
     */
    public getBonusRightTotalRewardsAssessment(businessId: string, apiVersion?: string, options?: any) {
        return BonusRightEmployeeRewardsApiFp(this.configuration).getBonusRightTotalRewardsAssessment(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessEducationApi - axios parameter creator
 * @export
 */
export const BusinessEducationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessEducation: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBusinessEducation', 'businessId', businessId)
            const localVarPath = `/api/BusinessEducation/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBusinessEducationUpdateRequest} [iBusinessEducationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBusinessEducation: async (businessId: string, apiVersion?: string, iBusinessEducationUpdateRequest?: IBusinessEducationUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('putBusinessEducation', 'businessId', businessId)
            const localVarPath = `/api/BusinessEducation/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBusinessEducationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessEducationApi - functional programming interface
 * @export
 */
export const BusinessEducationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessEducationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessEducation(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IEducation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessEducation(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBusinessEducationUpdateRequest} [iBusinessEducationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBusinessEducation(businessId: string, apiVersion?: string, iBusinessEducationUpdateRequest?: IBusinessEducationUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBusinessEducation(businessId, apiVersion, iBusinessEducationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessEducationApi - factory interface
 * @export
 */
export const BusinessEducationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessEducationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessEducation(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IEducation>> {
            return localVarFp.getBusinessEducation(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IBusinessEducationUpdateRequest} [iBusinessEducationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBusinessEducation(businessId: string, apiVersion?: string, iBusinessEducationUpdateRequest?: IBusinessEducationUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putBusinessEducation(businessId, apiVersion, iBusinessEducationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessEducationApi - object-oriented interface
 * @export
 * @class BusinessEducationApi
 * @extends {BaseAPI}
 */
export class BusinessEducationApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessEducationApi
     */
    public getBusinessEducation(businessId: string, apiVersion?: string, options?: any) {
        return BusinessEducationApiFp(this.configuration).getBusinessEducation(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {IBusinessEducationUpdateRequest} [iBusinessEducationUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessEducationApi
     */
    public putBusinessEducation(businessId: string, apiVersion?: string, iBusinessEducationUpdateRequest?: IBusinessEducationUpdateRequest, options?: any) {
        return BusinessEducationApiFp(this.configuration).putBusinessEducation(businessId, apiVersion, iBusinessEducationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessOwnersApi - axios parameter creator
 * @export
 */
export const BusinessOwnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessOwnerCreateRequest} [iBusinessOwnerCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessOwner: async (apiVersion?: string, iBusinessOwnerCreateRequest?: IBusinessOwnerCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessOwners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBusinessOwnerCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusinessOwner: async (userId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteBusinessOwner', 'userId', userId)
            const localVarPath = `/api/BusinessOwners/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessOwners: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBusinessOwners', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/BusinessOwners`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessOwnersApi - functional programming interface
 * @export
 */
export const BusinessOwnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessOwnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessOwnerCreateRequest} [iBusinessOwnerCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBusinessOwner(apiVersion?: string, iBusinessOwnerCreateRequest?: IBusinessOwnerCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBusinessOwner(apiVersion, iBusinessOwnerCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBusinessOwner(userId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusinessOwner(userId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessOwners(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessOwners(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessOwnersApi - factory interface
 * @export
 */
export const BusinessOwnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessOwnersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessOwnerCreateRequest} [iBusinessOwnerCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessOwner(apiVersion?: string, iBusinessOwnerCreateRequest?: IBusinessOwnerCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createBusinessOwner(apiVersion, iBusinessOwnerCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusinessOwner(userId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBusinessOwner(userId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessOwners(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IUser>> {
            return localVarFp.getBusinessOwners(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessOwnersApi - object-oriented interface
 * @export
 * @class BusinessOwnersApi
 * @extends {BaseAPI}
 */
export class BusinessOwnersApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IBusinessOwnerCreateRequest} [iBusinessOwnerCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnersApi
     */
    public createBusinessOwner(apiVersion?: string, iBusinessOwnerCreateRequest?: IBusinessOwnerCreateRequest, options?: any) {
        return BusinessOwnersApiFp(this.configuration).createBusinessOwner(apiVersion, iBusinessOwnerCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnersApi
     */
    public deleteBusinessOwner(userId: string, apiVersion?: string, options?: any) {
        return BusinessOwnersApiFp(this.configuration).deleteBusinessOwner(userId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnersApi
     */
    public getBusinessOwners(businessId: string, apiVersion?: string, options?: any) {
        return BusinessOwnersApiFp(this.configuration).getBusinessOwners(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessTeamMembersApi - axios parameter creator
 * @export
 */
export const BusinessTeamMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessTeamMemberAdvisorCreateRequest} [iBusinessTeamMemberAdvisorCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvisorTeamMember: async (apiVersion?: string, iBusinessTeamMemberAdvisorCreateRequest?: IBusinessTeamMemberAdvisorCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessTeamMembers/Advisor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBusinessTeamMemberAdvisorCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessTeamMemberEmployeeCreateRequest} [iBusinessTeamMemberEmployeeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployeeTeamMember: async (apiVersion?: string, iBusinessTeamMemberEmployeeCreateRequest?: IBusinessTeamMemberEmployeeCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessTeamMembers/Employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBusinessTeamMemberEmployeeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} advisorId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisorTeamMember: async (businessId: string, advisorId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('deleteAdvisorTeamMember', 'businessId', businessId)
            // verify required parameter 'advisorId' is not null or undefined
            assertParamExists('deleteAdvisorTeamMember', 'advisorId', advisorId)
            const localVarPath = `/api/BusinessTeamMembers/{businessId}/Advisor/{advisorId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"advisorId"}}`, encodeURIComponent(String(advisorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployeeTeamMember: async (employeeId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteEmployeeTeamMember', 'employeeId', employeeId)
            const localVarPath = `/api/BusinessTeamMembers/Employee/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessTeamMembers: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBusinessTeamMembers', 'businessId', businessId)
            const localVarPath = `/api/BusinessTeamMembers/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessTeamMembersApi - functional programming interface
 * @export
 */
export const BusinessTeamMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessTeamMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessTeamMemberAdvisorCreateRequest} [iBusinessTeamMemberAdvisorCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdvisorTeamMember(apiVersion?: string, iBusinessTeamMemberAdvisorCreateRequest?: IBusinessTeamMemberAdvisorCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdvisorTeamMember(apiVersion, iBusinessTeamMemberAdvisorCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessTeamMemberEmployeeCreateRequest} [iBusinessTeamMemberEmployeeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployeeTeamMember(apiVersion?: string, iBusinessTeamMemberEmployeeCreateRequest?: IBusinessTeamMemberEmployeeCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployeeTeamMember(apiVersion, iBusinessTeamMemberEmployeeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} advisorId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdvisorTeamMember(businessId: string, advisorId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdvisorTeamMember(businessId, advisorId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployeeTeamMember(employeeId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployeeTeamMember(employeeId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessTeamMembers(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBusinessTeamMember>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessTeamMembers(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessTeamMembersApi - factory interface
 * @export
 */
export const BusinessTeamMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessTeamMembersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessTeamMemberAdvisorCreateRequest} [iBusinessTeamMemberAdvisorCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvisorTeamMember(apiVersion?: string, iBusinessTeamMemberAdvisorCreateRequest?: IBusinessTeamMemberAdvisorCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createAdvisorTeamMember(apiVersion, iBusinessTeamMemberAdvisorCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessTeamMemberEmployeeCreateRequest} [iBusinessTeamMemberEmployeeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployeeTeamMember(apiVersion?: string, iBusinessTeamMemberEmployeeCreateRequest?: IBusinessTeamMemberEmployeeCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createEmployeeTeamMember(apiVersion, iBusinessTeamMemberEmployeeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} advisorId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvisorTeamMember(businessId: string, advisorId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdvisorTeamMember(businessId, advisorId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployeeTeamMember(employeeId: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmployeeTeamMember(employeeId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessTeamMembers(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IBusinessTeamMember>> {
            return localVarFp.getBusinessTeamMembers(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessTeamMembersApi - object-oriented interface
 * @export
 * @class BusinessTeamMembersApi
 * @extends {BaseAPI}
 */
export class BusinessTeamMembersApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IBusinessTeamMemberAdvisorCreateRequest} [iBusinessTeamMemberAdvisorCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTeamMembersApi
     */
    public createAdvisorTeamMember(apiVersion?: string, iBusinessTeamMemberAdvisorCreateRequest?: IBusinessTeamMemberAdvisorCreateRequest, options?: any) {
        return BusinessTeamMembersApiFp(this.configuration).createAdvisorTeamMember(apiVersion, iBusinessTeamMemberAdvisorCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IBusinessTeamMemberEmployeeCreateRequest} [iBusinessTeamMemberEmployeeCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTeamMembersApi
     */
    public createEmployeeTeamMember(apiVersion?: string, iBusinessTeamMemberEmployeeCreateRequest?: IBusinessTeamMemberEmployeeCreateRequest, options?: any) {
        return BusinessTeamMembersApiFp(this.configuration).createEmployeeTeamMember(apiVersion, iBusinessTeamMemberEmployeeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} advisorId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTeamMembersApi
     */
    public deleteAdvisorTeamMember(businessId: string, advisorId: string, apiVersion?: string, options?: any) {
        return BusinessTeamMembersApiFp(this.configuration).deleteAdvisorTeamMember(businessId, advisorId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTeamMembersApi
     */
    public deleteEmployeeTeamMember(employeeId: string, apiVersion?: string, options?: any) {
        return BusinessTeamMembersApiFp(this.configuration).deleteEmployeeTeamMember(employeeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessTeamMembersApi
     */
    public getBusinessTeamMembers(businessId: string, apiVersion?: string, options?: any) {
        return BusinessTeamMembersApiFp(this.configuration).getBusinessTeamMembers(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessValuationsApi - axios parameter creator
 * @export
 */
export const BusinessValuationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessValuation: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBusinessValuation', 'businessId', businessId)
            const localVarPath = `/api/BusinessValuations/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getHistoryForBusiness', 'businessId', businessId)
            const localVarPath = `/api/BusinessValuations/{businessId}/History`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotsForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getSnapshotsForBusiness', 'businessId', businessId)
            const localVarPath = `/api/BusinessValuations/{businessId}/Snapshots`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessValuationsApi - functional programming interface
 * @export
 */
export const BusinessValuationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessValuationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessValuation(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBusinessValuation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessValuation(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBusinessValuationHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotsForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBusinessValuation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnapshotsForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessValuationsApi - factory interface
 * @export
 */
export const BusinessValuationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessValuationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessValuation(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IBusinessValuation> {
            return localVarFp.getBusinessValuation(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IBusinessValuationHistory>> {
            return localVarFp.getHistoryForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotsForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IBusinessValuation>> {
            return localVarFp.getSnapshotsForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessValuationsApi - object-oriented interface
 * @export
 * @class BusinessValuationsApi
 * @extends {BaseAPI}
 */
export class BusinessValuationsApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessValuationsApi
     */
    public getBusinessValuation(businessId: string, apiVersion?: string, options?: any) {
        return BusinessValuationsApiFp(this.configuration).getBusinessValuation(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessValuationsApi
     */
    public getHistoryForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return BusinessValuationsApiFp(this.configuration).getHistoryForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessValuationsApi
     */
    public getSnapshotsForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return BusinessValuationsApiFp(this.configuration).getSnapshotsForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessesApi - axios parameter creator
 * @export
 */
export const BusinessesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSampleCase: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Businesses/SampleCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBusiness', 'id', id)
            const localVarPath = `/api/Businesses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusiness: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBusiness', 'id', id)
            const localVarPath = `/api/Businesses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinesses: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Businesses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessCreateRequest} [iBusinessCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBusiness: async (apiVersion?: string, iBusinessCreateRequest?: IBusinessCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Businesses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBusinessCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IBusinessUpdateRequest} [iBusinessUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBusiness: async (id: string, apiVersion?: string, iBusinessUpdateRequest?: IBusinessUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putBusiness', 'id', id)
            const localVarPath = `/api/Businesses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBusinessUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBusinessAvatar: async (id: string, file: any, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setBusinessAvatar', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('setBusinessAvatar', 'file', file)
            const localVarPath = `/api/Businesses/{id}/Avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBusinessLocation: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setBusinessLocation', 'id', id)
            const localVarPath = `/api/Businesses/{id}/Location`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessesApi - functional programming interface
 * @export
 */
export const BusinessesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSampleCase(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSampleCase(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBusiness(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusiness(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusiness(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBusiness>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusiness(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinesses(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBusinessSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinesses(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessCreateRequest} [iBusinessCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBusiness(apiVersion?: string, iBusinessCreateRequest?: IBusinessCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBusiness>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBusiness(apiVersion, iBusinessCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IBusinessUpdateRequest} [iBusinessUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBusiness(id: string, apiVersion?: string, iBusinessUpdateRequest?: IBusinessUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBusiness(id, apiVersion, iBusinessUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBusinessAvatar(id: string, file: any, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAvatar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBusinessAvatar(id, file, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBusinessLocation(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBusinessLocation(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessesApi - factory interface
 * @export
 */
export const BusinessesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSampleCase(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.createSampleCase(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBusiness(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusiness(id: string, apiVersion?: string, options?: any): AxiosPromise<IBusiness> {
            return localVarFp.getBusiness(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinesses(apiVersion?: string, options?: any): AxiosPromise<Array<IBusinessSummary>> {
            return localVarFp.getBusinesses(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IBusinessCreateRequest} [iBusinessCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBusiness(apiVersion?: string, iBusinessCreateRequest?: IBusinessCreateRequest, options?: any): AxiosPromise<IBusiness> {
            return localVarFp.postBusiness(apiVersion, iBusinessCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IBusinessUpdateRequest} [iBusinessUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBusiness(id: string, apiVersion?: string, iBusinessUpdateRequest?: IBusinessUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putBusiness(id, apiVersion, iBusinessUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBusinessAvatar(id: string, file: any, apiVersion?: string, options?: any): AxiosPromise<IAvatar> {
            return localVarFp.setBusinessAvatar(id, file, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBusinessLocation(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setBusinessLocation(id, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessesApi - object-oriented interface
 * @export
 * @class BusinessesApi
 * @extends {BaseAPI}
 */
export class BusinessesApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public createSampleCase(apiVersion?: string, options?: any) {
        return BusinessesApiFp(this.configuration).createSampleCase(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public deleteBusiness(id: string, apiVersion?: string, options?: any) {
        return BusinessesApiFp(this.configuration).deleteBusiness(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public getBusiness(id: string, apiVersion?: string, options?: any) {
        return BusinessesApiFp(this.configuration).getBusiness(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public getBusinesses(apiVersion?: string, options?: any) {
        return BusinessesApiFp(this.configuration).getBusinesses(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IBusinessCreateRequest} [iBusinessCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public postBusiness(apiVersion?: string, iBusinessCreateRequest?: IBusinessCreateRequest, options?: any) {
        return BusinessesApiFp(this.configuration).postBusiness(apiVersion, iBusinessCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IBusinessUpdateRequest} [iBusinessUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public putBusiness(id: string, apiVersion?: string, iBusinessUpdateRequest?: IBusinessUpdateRequest, options?: any) {
        return BusinessesApiFp(this.configuration).putBusiness(id, apiVersion, iBusinessUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} file 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public setBusinessAvatar(id: string, file: any, apiVersion?: string, options?: any) {
        return BusinessesApiFp(this.configuration).setBusinessAvatar(id, file, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApi
     */
    public setBusinessLocation(id: string, apiVersion?: string, options?: any) {
        return BusinessesApiFp(this.configuration).setBusinessLocation(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentPermissionsApi - axios parameter creator
 * @export
 */
export const DocumentPermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentEmployeeInvitationRequest} [iDocumentEmployeeInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentInvitationForEmployee: async (apiVersion?: string, iDocumentEmployeeInvitationRequest?: IDocumentEmployeeInvitationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentPermissions/EmployeeInvitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDocumentEmployeeInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentExternalTeamMemberInvitationRequest} [iDocumentExternalTeamMemberInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentInvitationForExternalTeamMember: async (apiVersion?: string, iDocumentExternalTeamMemberInvitationRequest?: IDocumentExternalTeamMemberInvitationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentPermissions/ExternalTeamMemberInvitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDocumentExternalTeamMemberInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentPermission: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocumentPermission', 'id', id)
            const localVarPath = `/api/DocumentPermissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPermission: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentPermission', 'id', id)
            const localVarPath = `/api/DocumentPermissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPermissionsForDocument: async (documentId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentPermissionsForDocument', 'documentId', documentId)
            const localVarPath = `/api/DocumentPermissions/{documentId}/Permissions`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentPermission} [iDocumentPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocumentPermission: async (apiVersion?: string, iDocumentPermission?: IDocumentPermission, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDocumentPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IDocumentPermission} [iDocumentPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocumentPermission: async (id: string, apiVersion?: string, iDocumentPermission?: IDocumentPermission, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDocumentPermission', 'id', id)
            const localVarPath = `/api/DocumentPermissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDocumentPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentPermissionsApi - functional programming interface
 * @export
 */
export const DocumentPermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentPermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentEmployeeInvitationRequest} [iDocumentEmployeeInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentInvitationForEmployee(apiVersion?: string, iDocumentEmployeeInvitationRequest?: IDocumentEmployeeInvitationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentInvitationForEmployee(apiVersion, iDocumentEmployeeInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentExternalTeamMemberInvitationRequest} [iDocumentExternalTeamMemberInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentInvitationForExternalTeamMember(apiVersion?: string, iDocumentExternalTeamMemberInvitationRequest?: IDocumentExternalTeamMemberInvitationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentInvitationForExternalTeamMember(apiVersion, iDocumentExternalTeamMemberInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentPermission(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentPermission(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPermission(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocumentPermission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentPermission(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPermissionsForDocument(documentId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IDocumentPermission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentPermissionsForDocument(documentId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentPermission} [iDocumentPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDocumentPermission(apiVersion?: string, iDocumentPermission?: IDocumentPermission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocumentPermission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDocumentPermission(apiVersion, iDocumentPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IDocumentPermission} [iDocumentPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDocumentPermission(id: string, apiVersion?: string, iDocumentPermission?: IDocumentPermission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDocumentPermission(id, apiVersion, iDocumentPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentPermissionsApi - factory interface
 * @export
 */
export const DocumentPermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentPermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentEmployeeInvitationRequest} [iDocumentEmployeeInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentInvitationForEmployee(apiVersion?: string, iDocumentEmployeeInvitationRequest?: IDocumentEmployeeInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createDocumentInvitationForEmployee(apiVersion, iDocumentEmployeeInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentExternalTeamMemberInvitationRequest} [iDocumentExternalTeamMemberInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentInvitationForExternalTeamMember(apiVersion?: string, iDocumentExternalTeamMemberInvitationRequest?: IDocumentExternalTeamMemberInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createDocumentInvitationForExternalTeamMember(apiVersion, iDocumentExternalTeamMemberInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentPermission(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocumentPermission(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPermission(id: string, apiVersion?: string, options?: any): AxiosPromise<IDocumentPermission> {
            return localVarFp.getDocumentPermission(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPermissionsForDocument(documentId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IDocumentPermission>> {
            return localVarFp.getDocumentPermissionsForDocument(documentId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentPermission} [iDocumentPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocumentPermission(apiVersion?: string, iDocumentPermission?: IDocumentPermission, options?: any): AxiosPromise<IDocumentPermission> {
            return localVarFp.postDocumentPermission(apiVersion, iDocumentPermission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IDocumentPermission} [iDocumentPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocumentPermission(id: string, apiVersion?: string, iDocumentPermission?: IDocumentPermission, options?: any): AxiosPromise<void> {
            return localVarFp.putDocumentPermission(id, apiVersion, iDocumentPermission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentPermissionsApi - object-oriented interface
 * @export
 * @class DocumentPermissionsApi
 * @extends {BaseAPI}
 */
export class DocumentPermissionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IDocumentEmployeeInvitationRequest} [iDocumentEmployeeInvitationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentPermissionsApi
     */
    public createDocumentInvitationForEmployee(apiVersion?: string, iDocumentEmployeeInvitationRequest?: IDocumentEmployeeInvitationRequest, options?: any) {
        return DocumentPermissionsApiFp(this.configuration).createDocumentInvitationForEmployee(apiVersion, iDocumentEmployeeInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IDocumentExternalTeamMemberInvitationRequest} [iDocumentExternalTeamMemberInvitationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentPermissionsApi
     */
    public createDocumentInvitationForExternalTeamMember(apiVersion?: string, iDocumentExternalTeamMemberInvitationRequest?: IDocumentExternalTeamMemberInvitationRequest, options?: any) {
        return DocumentPermissionsApiFp(this.configuration).createDocumentInvitationForExternalTeamMember(apiVersion, iDocumentExternalTeamMemberInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentPermissionsApi
     */
    public deleteDocumentPermission(id: string, apiVersion?: string, options?: any) {
        return DocumentPermissionsApiFp(this.configuration).deleteDocumentPermission(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentPermissionsApi
     */
    public getDocumentPermission(id: string, apiVersion?: string, options?: any) {
        return DocumentPermissionsApiFp(this.configuration).getDocumentPermission(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentPermissionsApi
     */
    public getDocumentPermissionsForDocument(documentId: string, apiVersion?: string, options?: any) {
        return DocumentPermissionsApiFp(this.configuration).getDocumentPermissionsForDocument(documentId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IDocumentPermission} [iDocumentPermission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentPermissionsApi
     */
    public postDocumentPermission(apiVersion?: string, iDocumentPermission?: IDocumentPermission, options?: any) {
        return DocumentPermissionsApiFp(this.configuration).postDocumentPermission(apiVersion, iDocumentPermission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IDocumentPermission} [iDocumentPermission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentPermissionsApi
     */
    public putDocumentPermission(id: string, apiVersion?: string, iDocumentPermission?: IDocumentPermission, options?: any) {
        return DocumentPermissionsApiFp(this.configuration).putDocumentPermission(id, apiVersion, iDocumentPermission, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentFolderCreateRequest} [iDocumentFolderCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: async (apiVersion?: string, iDocumentFolderCreateRequest?: IDocumentFolderCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Documents/Folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDocumentFolderCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocument', 'id', id)
            const localVarPath = `/api/Documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadDocument', 'id', id)
            const localVarPath = `/api/Documents/{id}/Download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocument', 'id', id)
            const localVarPath = `/api/Documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPath: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentPath', 'id', id)
            const localVarPath = `/api/Documents/{id}/Path`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [folderId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (businessId: string, folderId?: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getDocuments', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/Documents`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} parentFolderId 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument: async (businessId: string, parentFolderId: string, file: any, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('postDocument', 'businessId', businessId)
            // verify required parameter 'parentFolderId' is not null or undefined
            assertParamExists('postDocument', 'parentFolderId', parentFolderId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postDocument', 'file', file)
            const localVarPath = `/api/Documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


            if (businessId !== undefined) { 
                localVarFormParams.append('BusinessId', businessId as any);
            }
    
            if (parentFolderId !== undefined) { 
                localVarFormParams.append('ParentFolderId', parentFolderId as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IDocumentUpdateRequest} [iDocumentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument: async (id: string, apiVersion?: string, iDocumentUpdateRequest?: IDocumentUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDocument', 'id', id)
            const localVarPath = `/api/Documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iDocumentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentFolderCreateRequest} [iDocumentFolderCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolder(apiVersion?: string, iDocumentFolderCreateRequest?: IDocumentFolderCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(apiVersion, iDocumentFolderCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocument(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocumentDownloadLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocument(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPath(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IDocumentPathSegment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentPath(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [folderId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(businessId: string, folderId?: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocumentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(businessId, folderId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} parentFolderId 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDocument(businessId: string, parentFolderId: string, file: any, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDocument(businessId, parentFolderId, file, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IDocumentUpdateRequest} [iDocumentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDocument(id: string, apiVersion?: string, iDocumentUpdateRequest?: IDocumentUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDocument(id, apiVersion, iDocumentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IDocumentFolderCreateRequest} [iDocumentFolderCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(apiVersion?: string, iDocumentFolderCreateRequest?: IDocumentFolderCreateRequest, options?: any): AxiosPromise<IDocument> {
            return localVarFp.createFolder(apiVersion, iDocumentFolderCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument(id: string, apiVersion?: string, options?: any): AxiosPromise<IDocumentDownloadLocation> {
            return localVarFp.downloadDocument(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(id: string, apiVersion?: string, options?: any): AxiosPromise<IDocument> {
            return localVarFp.getDocument(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPath(id: string, apiVersion?: string, options?: any): AxiosPromise<Array<IDocumentPathSegment>> {
            return localVarFp.getDocumentPath(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [folderId] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(businessId: string, folderId?: string, apiVersion?: string, options?: any): AxiosPromise<IDocumentList> {
            return localVarFp.getDocuments(businessId, folderId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} parentFolderId 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument(businessId: string, parentFolderId: string, file: any, apiVersion?: string, options?: any): AxiosPromise<IDocument> {
            return localVarFp.postDocument(businessId, parentFolderId, file, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IDocumentUpdateRequest} [iDocumentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument(id: string, apiVersion?: string, iDocumentUpdateRequest?: IDocumentUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putDocument(id, apiVersion, iDocumentUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IDocumentFolderCreateRequest} [iDocumentFolderCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public createFolder(apiVersion?: string, iDocumentFolderCreateRequest?: IDocumentFolderCreateRequest, options?: any) {
        return DocumentsApiFp(this.configuration).createFolder(apiVersion, iDocumentFolderCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public deleteDocument(id: string, apiVersion?: string, options?: any) {
        return DocumentsApiFp(this.configuration).deleteDocument(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public downloadDocument(id: string, apiVersion?: string, options?: any) {
        return DocumentsApiFp(this.configuration).downloadDocument(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocument(id: string, apiVersion?: string, options?: any) {
        return DocumentsApiFp(this.configuration).getDocument(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocumentPath(id: string, apiVersion?: string, options?: any) {
        return DocumentsApiFp(this.configuration).getDocumentPath(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [folderId] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocuments(businessId: string, folderId?: string, apiVersion?: string, options?: any) {
        return DocumentsApiFp(this.configuration).getDocuments(businessId, folderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} parentFolderId 
     * @param {any} file 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public postDocument(businessId: string, parentFolderId: string, file: any, apiVersion?: string, options?: any) {
        return DocumentsApiFp(this.configuration).postDocument(businessId, parentFolderId, file, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IDocumentUpdateRequest} [iDocumentUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public putDocument(id: string, apiVersion?: string, iDocumentUpdateRequest?: IDocumentUpdateRequest, options?: any) {
        return DocumentsApiFp(this.configuration).putDocument(id, apiVersion, iDocumentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EducationApi - axios parameter creator
 * @export
 */
export const EducationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEducation: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEducation', 'id', id)
            const localVarPath = `/api/Education/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadLocation: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDownloadLocation', 'id', id)
            const localVarPath = `/api/Education/{id}/Download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducation: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEducation', 'id', id)
            const localVarPath = `/api/Education/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} advisorId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducationForAdvisor: async (advisorId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorId' is not null or undefined
            assertParamExists('getEducationForAdvisor', 'advisorId', advisorId)
            const localVarPath = `/api/Advisors/{advisorId}/Education`
                .replace(`{${"advisorId"}}`, encodeURIComponent(String(advisorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} advisorId 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {string} [author] 
         * @param {string} [description] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEducation: async (advisorId: string, file: any, apiVersion?: string, author?: string, description?: string, title?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorId' is not null or undefined
            assertParamExists('postEducation', 'advisorId', advisorId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postEducation', 'file', file)
            const localVarPath = `/api/Education`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


            if (advisorId !== undefined) { 
                localVarFormParams.append('AdvisorId', advisorId as any);
            }
    
            if (author !== undefined) { 
                localVarFormParams.append('Author', author as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEducationUpdateRequest} [iEducationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEducation: async (id: string, apiVersion?: string, iEducationUpdateRequest?: IEducationUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putEducation', 'id', id)
            const localVarPath = `/api/Education/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEducationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationApi - functional programming interface
 * @export
 */
export const EducationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEducation(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEducation(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadLocation(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEducationDownloadLocation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadLocation(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducation(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEducation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEducation(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} advisorId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducationForAdvisor(advisorId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IEducation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEducationForAdvisor(advisorId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} advisorId 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {string} [author] 
         * @param {string} [description] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEducation(advisorId: string, file: any, apiVersion?: string, author?: string, description?: string, title?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEducation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEducation(advisorId, file, apiVersion, author, description, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEducationUpdateRequest} [iEducationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEducation(id: string, apiVersion?: string, iEducationUpdateRequest?: IEducationUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEducation(id, apiVersion, iEducationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EducationApi - factory interface
 * @export
 */
export const EducationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEducation(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEducation(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadLocation(id: string, apiVersion?: string, options?: any): AxiosPromise<IEducationDownloadLocation> {
            return localVarFp.getDownloadLocation(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducation(id: string, apiVersion?: string, options?: any): AxiosPromise<IEducation> {
            return localVarFp.getEducation(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} advisorId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducationForAdvisor(advisorId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IEducation>> {
            return localVarFp.getEducationForAdvisor(advisorId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} advisorId 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {string} [author] 
         * @param {string} [description] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEducation(advisorId: string, file: any, apiVersion?: string, author?: string, description?: string, title?: string, options?: any): AxiosPromise<IEducation> {
            return localVarFp.postEducation(advisorId, file, apiVersion, author, description, title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEducationUpdateRequest} [iEducationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEducation(id: string, apiVersion?: string, iEducationUpdateRequest?: IEducationUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putEducation(id, apiVersion, iEducationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationApi - object-oriented interface
 * @export
 * @class EducationApi
 * @extends {BaseAPI}
 */
export class EducationApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public deleteEducation(id: string, apiVersion?: string, options?: any) {
        return EducationApiFp(this.configuration).deleteEducation(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public getDownloadLocation(id: string, apiVersion?: string, options?: any) {
        return EducationApiFp(this.configuration).getDownloadLocation(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public getEducation(id: string, apiVersion?: string, options?: any) {
        return EducationApiFp(this.configuration).getEducation(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} advisorId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public getEducationForAdvisor(advisorId: string, apiVersion?: string, options?: any) {
        return EducationApiFp(this.configuration).getEducationForAdvisor(advisorId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} advisorId 
     * @param {any} file 
     * @param {string} [apiVersion] 
     * @param {string} [author] 
     * @param {string} [description] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public postEducation(advisorId: string, file: any, apiVersion?: string, author?: string, description?: string, title?: string, options?: any) {
        return EducationApiFp(this.configuration).postEducation(advisorId, file, apiVersion, author, description, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IEducationUpdateRequest} [iEducationUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public putEducation(id: string, apiVersion?: string, iEducationUpdateRequest?: IEducationUpdateRequest, options?: any) {
        return EducationApiFp(this.configuration).putEducation(id, apiVersion, iEducationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeesApi - axios parameter creator
 * @export
 */
export const EmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEmployeeUploadDelegateInvitationRequest} [iEmployeeUploadDelegateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitationForEmployeeUploadDelegate: async (apiVersion?: string, iEmployeeUploadDelegateInvitationRequest?: IEmployeeUploadDelegateInvitationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employees/UploadDelegateInvitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEmployeeUploadDelegateInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmployee', 'id', id)
            const localVarPath = `/api/Employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployee: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmployee', 'id', id)
            const localVarPath = `/api/Employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeFamiliesForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getEmployeeFamiliesForBusiness', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/Families`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getEmployeesForBusiness', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/Employees`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEmployeeCreateRequest} [iEmployeeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmployee: async (apiVersion?: string, iEmployeeCreateRequest?: IEmployeeCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEmployeeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEmployeeUpdateRequest} [iEmployeeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmployee: async (id: string, apiVersion?: string, iEmployeeUpdateRequest?: IEmployeeUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putEmployee', 'id', id)
            const localVarPath = `/api/Employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEmployeeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeAvatar: async (id: string, file: any, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setEmployeeAvatar', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('setEmployeeAvatar', 'file', file)
            const localVarPath = `/api/Employees/{id}/Avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeesApi - functional programming interface
 * @export
 */
export const EmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEmployeeUploadDelegateInvitationRequest} [iEmployeeUploadDelegateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitationForEmployeeUploadDelegate(apiVersion?: string, iEmployeeUploadDelegateInvitationRequest?: IEmployeeUploadDelegateInvitationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitationForEmployeeUploadDelegate(apiVersion, iEmployeeUploadDelegateInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployee(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployee(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployee(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEmployee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployee(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeFamiliesForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IEmployeeFamily>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeFamiliesForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeesForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IEmployee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeesForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEmployeeCreateRequest} [iEmployeeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEmployee(apiVersion?: string, iEmployeeCreateRequest?: IEmployeeCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEmployee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEmployee(apiVersion, iEmployeeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEmployeeUpdateRequest} [iEmployeeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEmployee(id: string, apiVersion?: string, iEmployeeUpdateRequest?: IEmployeeUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEmployee(id, apiVersion, iEmployeeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEmployeeAvatar(id: string, file: any, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAvatar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEmployeeAvatar(id, file, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeesApi - factory interface
 * @export
 */
export const EmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEmployeeUploadDelegateInvitationRequest} [iEmployeeUploadDelegateInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitationForEmployeeUploadDelegate(apiVersion?: string, iEmployeeUploadDelegateInvitationRequest?: IEmployeeUploadDelegateInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createInvitationForEmployeeUploadDelegate(apiVersion, iEmployeeUploadDelegateInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployee(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmployee(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployee(id: string, apiVersion?: string, options?: any): AxiosPromise<IEmployee> {
            return localVarFp.getEmployee(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeFamiliesForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IEmployeeFamily>> {
            return localVarFp.getEmployeeFamiliesForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IEmployee>> {
            return localVarFp.getEmployeesForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEmployeeCreateRequest} [iEmployeeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEmployee(apiVersion?: string, iEmployeeCreateRequest?: IEmployeeCreateRequest, options?: any): AxiosPromise<IEmployee> {
            return localVarFp.postEmployee(apiVersion, iEmployeeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEmployeeUpdateRequest} [iEmployeeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmployee(id: string, apiVersion?: string, iEmployeeUpdateRequest?: IEmployeeUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putEmployee(id, apiVersion, iEmployeeUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeAvatar(id: string, file: any, apiVersion?: string, options?: any): AxiosPromise<IAvatar> {
            return localVarFp.setEmployeeAvatar(id, file, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeesApi - object-oriented interface
 * @export
 * @class EmployeesApi
 * @extends {BaseAPI}
 */
export class EmployeesApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IEmployeeUploadDelegateInvitationRequest} [iEmployeeUploadDelegateInvitationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public createInvitationForEmployeeUploadDelegate(apiVersion?: string, iEmployeeUploadDelegateInvitationRequest?: IEmployeeUploadDelegateInvitationRequest, options?: any) {
        return EmployeesApiFp(this.configuration).createInvitationForEmployeeUploadDelegate(apiVersion, iEmployeeUploadDelegateInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public deleteEmployee(id: string, apiVersion?: string, options?: any) {
        return EmployeesApiFp(this.configuration).deleteEmployee(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getEmployee(id: string, apiVersion?: string, options?: any) {
        return EmployeesApiFp(this.configuration).getEmployee(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getEmployeeFamiliesForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return EmployeesApiFp(this.configuration).getEmployeeFamiliesForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getEmployeesForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return EmployeesApiFp(this.configuration).getEmployeesForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IEmployeeCreateRequest} [iEmployeeCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public postEmployee(apiVersion?: string, iEmployeeCreateRequest?: IEmployeeCreateRequest, options?: any) {
        return EmployeesApiFp(this.configuration).postEmployee(apiVersion, iEmployeeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IEmployeeUpdateRequest} [iEmployeeUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public putEmployee(id: string, apiVersion?: string, iEmployeeUpdateRequest?: IEmployeeUpdateRequest, options?: any) {
        return EmployeesApiFp(this.configuration).putEmployee(id, apiVersion, iEmployeeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} file 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public setEmployeeAvatar(id: string, file: any, apiVersion?: string, options?: any) {
        return EmployeesApiFp(this.configuration).setEmployeeAvatar(id, file, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EsopsApi - axios parameter creator
 * @export
 */
export const EsopsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEsop: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEsop', 'id', id)
            const localVarPath = `/api/Esops/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsop: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEsop', 'id', id)
            const localVarPath = `/api/Esops/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsopsForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getEsopsForBusiness', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/Esops`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEsopCreateRequest} [iEsopCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEsop: async (apiVersion?: string, iEsopCreateRequest?: IEsopCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Esops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEsopCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEsopUpdateRequest} [iEsopUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEsop: async (id: string, apiVersion?: string, iEsopUpdateRequest?: IEsopUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putEsop', 'id', id)
            const localVarPath = `/api/Esops/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEsopUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EsopsApi - functional programming interface
 * @export
 */
export const EsopsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EsopsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEsop(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEsop(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEsop(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEsop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEsop(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEsopsForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEsop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEsopsForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEsopCreateRequest} [iEsopCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEsop(apiVersion?: string, iEsopCreateRequest?: IEsopCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEsop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEsop(apiVersion, iEsopCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEsopUpdateRequest} [iEsopUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEsop(id: string, apiVersion?: string, iEsopUpdateRequest?: IEsopUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEsop(id, apiVersion, iEsopUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EsopsApi - factory interface
 * @export
 */
export const EsopsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EsopsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEsop(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEsop(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsop(id: string, apiVersion?: string, options?: any): AxiosPromise<IEsop> {
            return localVarFp.getEsop(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsopsForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IEsop> {
            return localVarFp.getEsopsForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IEsopCreateRequest} [iEsopCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEsop(apiVersion?: string, iEsopCreateRequest?: IEsopCreateRequest, options?: any): AxiosPromise<IEsop> {
            return localVarFp.postEsop(apiVersion, iEsopCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IEsopUpdateRequest} [iEsopUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEsop(id: string, apiVersion?: string, iEsopUpdateRequest?: IEsopUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putEsop(id, apiVersion, iEsopUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EsopsApi - object-oriented interface
 * @export
 * @class EsopsApi
 * @extends {BaseAPI}
 */
export class EsopsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EsopsApi
     */
    public deleteEsop(id: string, apiVersion?: string, options?: any) {
        return EsopsApiFp(this.configuration).deleteEsop(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EsopsApi
     */
    public getEsop(id: string, apiVersion?: string, options?: any) {
        return EsopsApiFp(this.configuration).getEsop(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EsopsApi
     */
    public getEsopsForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return EsopsApiFp(this.configuration).getEsopsForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IEsopCreateRequest} [iEsopCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EsopsApi
     */
    public postEsop(apiVersion?: string, iEsopCreateRequest?: IEsopCreateRequest, options?: any) {
        return EsopsApiFp(this.configuration).postEsop(apiVersion, iEsopCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IEsopUpdateRequest} [iEsopUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EsopsApi
     */
    public putEsop(id: string, apiVersion?: string, iEsopUpdateRequest?: IEsopUpdateRequest, options?: any) {
        return EsopsApiFp(this.configuration).putEsop(id, apiVersion, iEsopUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalTeamMembersApi - axios parameter creator
 * @export
 */
export const ExternalTeamMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalTeamMember: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExternalTeamMember', 'id', id)
            const localVarPath = `/api/ExternalTeamMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalTeamMember: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExternalTeamMember', 'id', id)
            const localVarPath = `/api/ExternalTeamMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalTeamMembersForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getExternalTeamMembersForBusiness', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/ExternalTeamMembers`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IExternalTeamMemberCreateRequest} [iExternalTeamMemberCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalTeamMember: async (apiVersion?: string, iExternalTeamMemberCreateRequest?: IExternalTeamMemberCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalTeamMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iExternalTeamMemberCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IExternalTeamMemberUpdateRequest} [iExternalTeamMemberUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternalTeamMember: async (id: string, apiVersion?: string, iExternalTeamMemberUpdateRequest?: IExternalTeamMemberUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putExternalTeamMember', 'id', id)
            const localVarPath = `/api/ExternalTeamMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iExternalTeamMemberUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberAvatar: async (id: string, file: any, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setTeamMemberAvatar', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('setTeamMemberAvatar', 'file', file)
            const localVarPath = `/api/ExternalTeamMembers/{id}/Avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalTeamMembersApi - functional programming interface
 * @export
 */
export const ExternalTeamMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalTeamMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExternalTeamMember(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExternalTeamMember(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalTeamMember(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExternalTeamMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalTeamMember(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalTeamMembersForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IExternalTeamMember>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalTeamMembersForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IExternalTeamMemberCreateRequest} [iExternalTeamMemberCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExternalTeamMember(apiVersion?: string, iExternalTeamMemberCreateRequest?: IExternalTeamMemberCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExternalTeamMember>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExternalTeamMember(apiVersion, iExternalTeamMemberCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IExternalTeamMemberUpdateRequest} [iExternalTeamMemberUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putExternalTeamMember(id: string, apiVersion?: string, iExternalTeamMemberUpdateRequest?: IExternalTeamMemberUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putExternalTeamMember(id, apiVersion, iExternalTeamMemberUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamMemberAvatar(id: string, file: any, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAvatar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamMemberAvatar(id, file, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalTeamMembersApi - factory interface
 * @export
 */
export const ExternalTeamMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalTeamMembersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalTeamMember(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteExternalTeamMember(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalTeamMember(id: string, apiVersion?: string, options?: any): AxiosPromise<IExternalTeamMember> {
            return localVarFp.getExternalTeamMember(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalTeamMembersForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<IExternalTeamMember>> {
            return localVarFp.getExternalTeamMembersForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IExternalTeamMemberCreateRequest} [iExternalTeamMemberCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExternalTeamMember(apiVersion?: string, iExternalTeamMemberCreateRequest?: IExternalTeamMemberCreateRequest, options?: any): AxiosPromise<IExternalTeamMember> {
            return localVarFp.postExternalTeamMember(apiVersion, iExternalTeamMemberCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IExternalTeamMemberUpdateRequest} [iExternalTeamMemberUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExternalTeamMember(id: string, apiVersion?: string, iExternalTeamMemberUpdateRequest?: IExternalTeamMemberUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putExternalTeamMember(id, apiVersion, iExternalTeamMemberUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} file 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamMemberAvatar(id: string, file: any, apiVersion?: string, options?: any): AxiosPromise<IAvatar> {
            return localVarFp.setTeamMemberAvatar(id, file, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalTeamMembersApi - object-oriented interface
 * @export
 * @class ExternalTeamMembersApi
 * @extends {BaseAPI}
 */
export class ExternalTeamMembersApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalTeamMembersApi
     */
    public deleteExternalTeamMember(id: string, apiVersion?: string, options?: any) {
        return ExternalTeamMembersApiFp(this.configuration).deleteExternalTeamMember(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalTeamMembersApi
     */
    public getExternalTeamMember(id: string, apiVersion?: string, options?: any) {
        return ExternalTeamMembersApiFp(this.configuration).getExternalTeamMember(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalTeamMembersApi
     */
    public getExternalTeamMembersForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return ExternalTeamMembersApiFp(this.configuration).getExternalTeamMembersForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IExternalTeamMemberCreateRequest} [iExternalTeamMemberCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalTeamMembersApi
     */
    public postExternalTeamMember(apiVersion?: string, iExternalTeamMemberCreateRequest?: IExternalTeamMemberCreateRequest, options?: any) {
        return ExternalTeamMembersApiFp(this.configuration).postExternalTeamMember(apiVersion, iExternalTeamMemberCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IExternalTeamMemberUpdateRequest} [iExternalTeamMemberUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalTeamMembersApi
     */
    public putExternalTeamMember(id: string, apiVersion?: string, iExternalTeamMemberUpdateRequest?: IExternalTeamMemberUpdateRequest, options?: any) {
        return ExternalTeamMembersApiFp(this.configuration).putExternalTeamMember(id, apiVersion, iExternalTeamMemberUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} file 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalTeamMembersApi
     */
    public setTeamMemberAvatar(id: string, file: any, apiVersion?: string, options?: any) {
        return ExternalTeamMembersApiFp(this.configuration).setTeamMemberAvatar(id, file, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FamilyLimitedPartnershipsApi - axios parameter creator
 * @export
 */
export const FamilyLimitedPartnershipsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFamilyLimitedPartnership: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFamilyLimitedPartnership', 'id', id)
            const localVarPath = `/api/FamilyLimitedPartnerships/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFamilyLimitedPartnership: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFamilyLimitedPartnership', 'id', id)
            const localVarPath = `/api/FamilyLimitedPartnerships/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForEmployee: async (employeeId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getForEmployee', 'employeeId', employeeId)
            const localVarPath = `/api/Employees/{employeeId}/FamilyLimitedPartnerships`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IFamilyLimitedPartnershipCreateRequest} [iFamilyLimitedPartnershipCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFamilyLimitedPartnership: async (apiVersion?: string, iFamilyLimitedPartnershipCreateRequest?: IFamilyLimitedPartnershipCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FamilyLimitedPartnerships`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iFamilyLimitedPartnershipCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IFamilyLimitedPartnershipUpdateRequest} [iFamilyLimitedPartnershipUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFamilyLimitedPartnership: async (id: string, apiVersion?: string, iFamilyLimitedPartnershipUpdateRequest?: IFamilyLimitedPartnershipUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putFamilyLimitedPartnership', 'id', id)
            const localVarPath = `/api/FamilyLimitedPartnerships/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iFamilyLimitedPartnershipUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FamilyLimitedPartnershipsApi - functional programming interface
 * @export
 */
export const FamilyLimitedPartnershipsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FamilyLimitedPartnershipsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFamilyLimitedPartnership(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFamilyLimitedPartnership(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFamilyLimitedPartnership(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFamilyLimitedPartnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFamilyLimitedPartnership(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForEmployee(employeeId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFamilyLimitedPartnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForEmployee(employeeId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IFamilyLimitedPartnershipCreateRequest} [iFamilyLimitedPartnershipCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFamilyLimitedPartnership(apiVersion?: string, iFamilyLimitedPartnershipCreateRequest?: IFamilyLimitedPartnershipCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFamilyLimitedPartnership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFamilyLimitedPartnership(apiVersion, iFamilyLimitedPartnershipCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IFamilyLimitedPartnershipUpdateRequest} [iFamilyLimitedPartnershipUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFamilyLimitedPartnership(id: string, apiVersion?: string, iFamilyLimitedPartnershipUpdateRequest?: IFamilyLimitedPartnershipUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFamilyLimitedPartnership(id, apiVersion, iFamilyLimitedPartnershipUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FamilyLimitedPartnershipsApi - factory interface
 * @export
 */
export const FamilyLimitedPartnershipsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FamilyLimitedPartnershipsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFamilyLimitedPartnership(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFamilyLimitedPartnership(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFamilyLimitedPartnership(id: string, apiVersion?: string, options?: any): AxiosPromise<IFamilyLimitedPartnership> {
            return localVarFp.getFamilyLimitedPartnership(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForEmployee(employeeId: string, apiVersion?: string, options?: any): AxiosPromise<IFamilyLimitedPartnership> {
            return localVarFp.getForEmployee(employeeId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IFamilyLimitedPartnershipCreateRequest} [iFamilyLimitedPartnershipCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFamilyLimitedPartnership(apiVersion?: string, iFamilyLimitedPartnershipCreateRequest?: IFamilyLimitedPartnershipCreateRequest, options?: any): AxiosPromise<IFamilyLimitedPartnership> {
            return localVarFp.postFamilyLimitedPartnership(apiVersion, iFamilyLimitedPartnershipCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IFamilyLimitedPartnershipUpdateRequest} [iFamilyLimitedPartnershipUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFamilyLimitedPartnership(id: string, apiVersion?: string, iFamilyLimitedPartnershipUpdateRequest?: IFamilyLimitedPartnershipUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putFamilyLimitedPartnership(id, apiVersion, iFamilyLimitedPartnershipUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FamilyLimitedPartnershipsApi - object-oriented interface
 * @export
 * @class FamilyLimitedPartnershipsApi
 * @extends {BaseAPI}
 */
export class FamilyLimitedPartnershipsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyLimitedPartnershipsApi
     */
    public deleteFamilyLimitedPartnership(id: string, apiVersion?: string, options?: any) {
        return FamilyLimitedPartnershipsApiFp(this.configuration).deleteFamilyLimitedPartnership(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyLimitedPartnershipsApi
     */
    public getFamilyLimitedPartnership(id: string, apiVersion?: string, options?: any) {
        return FamilyLimitedPartnershipsApiFp(this.configuration).getFamilyLimitedPartnership(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyLimitedPartnershipsApi
     */
    public getForEmployee(employeeId: string, apiVersion?: string, options?: any) {
        return FamilyLimitedPartnershipsApiFp(this.configuration).getForEmployee(employeeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IFamilyLimitedPartnershipCreateRequest} [iFamilyLimitedPartnershipCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyLimitedPartnershipsApi
     */
    public postFamilyLimitedPartnership(apiVersion?: string, iFamilyLimitedPartnershipCreateRequest?: IFamilyLimitedPartnershipCreateRequest, options?: any) {
        return FamilyLimitedPartnershipsApiFp(this.configuration).postFamilyLimitedPartnership(apiVersion, iFamilyLimitedPartnershipCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IFamilyLimitedPartnershipUpdateRequest} [iFamilyLimitedPartnershipUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyLimitedPartnershipsApi
     */
    public putFamilyLimitedPartnership(id: string, apiVersion?: string, iFamilyLimitedPartnershipUpdateRequest?: IFamilyLimitedPartnershipUpdateRequest, options?: any) {
        return FamilyLimitedPartnershipsApiFp(this.configuration).putFamilyLimitedPartnership(id, apiVersion, iFamilyLimitedPartnershipUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InnerZoneCommunicationApi - axios parameter creator
 * @export
 */
export const InnerZoneCommunicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserInnerZoneEmployeeProfile: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InnerZone/Communication/Profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneBusinessDetail: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getInnerZoneBusinessDetail', 'businessId', businessId)
            const localVarPath = `/api/InnerZone/Communication/Businesses/{businessId}/Detail`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneEmployeeProfile: async (employeeId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getInnerZoneEmployeeProfile', 'employeeId', employeeId)
            const localVarPath = `/api/InnerZone/Communication/Employees/{employeeId}/Profile`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} comparisonEmployeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneEmployeeRelationshipSummary: async (employeeId: string, comparisonEmployeeId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getInnerZoneEmployeeRelationshipSummary', 'employeeId', employeeId)
            // verify required parameter 'comparisonEmployeeId' is not null or undefined
            assertParamExists('getInnerZoneEmployeeRelationshipSummary', 'comparisonEmployeeId', comparisonEmployeeId)
            const localVarPath = `/api/InnerZone/Communication/Employees/{employeeId}/RelationshipSummary`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (comparisonEmployeeId !== undefined) {
                localVarQueryParameter['comparisonEmployeeId'] = comparisonEmployeeId;
            }

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZonePrimaryReport: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getInnerZonePrimaryReport', 'businessId', businessId)
            const localVarPath = `/api/InnerZone/Communication/Businesses/{businessId}/PrimaryReport`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IInnerZonePrimaryReportSaveRequest} [iInnerZonePrimaryReportSaveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInnerZonePrimaryReport: async (businessId: string, apiVersion?: string, iInnerZonePrimaryReportSaveRequest?: IInnerZonePrimaryReportSaveRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('setInnerZonePrimaryReport', 'businessId', businessId)
            const localVarPath = `/api/InnerZone/Communication/Businesses/{businessId}/PrimaryReport`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInnerZonePrimaryReportSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InnerZoneCommunicationApi - functional programming interface
 * @export
 */
export const InnerZoneCommunicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InnerZoneCommunicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserInnerZoneEmployeeProfile(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInnerZoneEmployeeProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserInnerZoneEmployeeProfile(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnerZoneBusinessDetail(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInnerZoneBusinessDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnerZoneBusinessDetail(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnerZoneEmployeeProfile(employeeId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInnerZoneEmployeeProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnerZoneEmployeeProfile(employeeId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} comparisonEmployeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnerZoneEmployeeRelationshipSummary(employeeId: string, comparisonEmployeeId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInnerZoneRelationshipSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnerZoneEmployeeRelationshipSummary(employeeId, comparisonEmployeeId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnerZonePrimaryReport(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInnerZonePrimaryReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnerZonePrimaryReport(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IInnerZonePrimaryReportSaveRequest} [iInnerZonePrimaryReportSaveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInnerZonePrimaryReport(businessId: string, apiVersion?: string, iInnerZonePrimaryReportSaveRequest?: IInnerZonePrimaryReportSaveRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInnerZonePrimaryReport(businessId, apiVersion, iInnerZonePrimaryReportSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InnerZoneCommunicationApi - factory interface
 * @export
 */
export const InnerZoneCommunicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InnerZoneCommunicationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserInnerZoneEmployeeProfile(apiVersion?: string, options?: any): AxiosPromise<IInnerZoneEmployeeProfile> {
            return localVarFp.getCurrentUserInnerZoneEmployeeProfile(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneBusinessDetail(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IInnerZoneBusinessDetail> {
            return localVarFp.getInnerZoneBusinessDetail(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneEmployeeProfile(employeeId: string, apiVersion?: string, options?: any): AxiosPromise<IInnerZoneEmployeeProfile> {
            return localVarFp.getInnerZoneEmployeeProfile(employeeId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} comparisonEmployeeId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneEmployeeRelationshipSummary(employeeId: string, comparisonEmployeeId: string, apiVersion?: string, options?: any): AxiosPromise<IInnerZoneRelationshipSummary> {
            return localVarFp.getInnerZoneEmployeeRelationshipSummary(employeeId, comparisonEmployeeId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZonePrimaryReport(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IInnerZonePrimaryReport> {
            return localVarFp.getInnerZonePrimaryReport(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {IInnerZonePrimaryReportSaveRequest} [iInnerZonePrimaryReportSaveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInnerZonePrimaryReport(businessId: string, apiVersion?: string, iInnerZonePrimaryReportSaveRequest?: IInnerZonePrimaryReportSaveRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setInnerZonePrimaryReport(businessId, apiVersion, iInnerZonePrimaryReportSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InnerZoneCommunicationApi - object-oriented interface
 * @export
 * @class InnerZoneCommunicationApi
 * @extends {BaseAPI}
 */
export class InnerZoneCommunicationApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneCommunicationApi
     */
    public getCurrentUserInnerZoneEmployeeProfile(apiVersion?: string, options?: any) {
        return InnerZoneCommunicationApiFp(this.configuration).getCurrentUserInnerZoneEmployeeProfile(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneCommunicationApi
     */
    public getInnerZoneBusinessDetail(businessId: string, apiVersion?: string, options?: any) {
        return InnerZoneCommunicationApiFp(this.configuration).getInnerZoneBusinessDetail(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneCommunicationApi
     */
    public getInnerZoneEmployeeProfile(employeeId: string, apiVersion?: string, options?: any) {
        return InnerZoneCommunicationApiFp(this.configuration).getInnerZoneEmployeeProfile(employeeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {string} comparisonEmployeeId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneCommunicationApi
     */
    public getInnerZoneEmployeeRelationshipSummary(employeeId: string, comparisonEmployeeId: string, apiVersion?: string, options?: any) {
        return InnerZoneCommunicationApiFp(this.configuration).getInnerZoneEmployeeRelationshipSummary(employeeId, comparisonEmployeeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneCommunicationApi
     */
    public getInnerZonePrimaryReport(businessId: string, apiVersion?: string, options?: any) {
        return InnerZoneCommunicationApiFp(this.configuration).getInnerZonePrimaryReport(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {IInnerZonePrimaryReportSaveRequest} [iInnerZonePrimaryReportSaveRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneCommunicationApi
     */
    public setInnerZonePrimaryReport(businessId: string, apiVersion?: string, iInnerZonePrimaryReportSaveRequest?: IInnerZonePrimaryReportSaveRequest, options?: any) {
        return InnerZoneCommunicationApiFp(this.configuration).setInnerZonePrimaryReport(businessId, apiVersion, iInnerZonePrimaryReportSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InnerZoneSettingsApi - axios parameter creator
 * @export
 */
export const InnerZoneSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneLoginStatus: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InnerZone/Settings/LoginStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneLoginRequest} [iInnerZoneLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInnerZoneLogin: async (apiVersion?: string, iInnerZoneLoginRequest?: IInnerZoneLoginRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InnerZone/Settings/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInnerZoneLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InnerZoneSettingsApi - functional programming interface
 * @export
 */
export const InnerZoneSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InnerZoneSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnerZoneLoginStatus(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInnerZoneLoginStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnerZoneLoginStatus(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneLoginRequest} [iInnerZoneLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInnerZoneLogin(apiVersion?: string, iInnerZoneLoginRequest?: IInnerZoneLoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInnerZoneLogin(apiVersion, iInnerZoneLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InnerZoneSettingsApi - factory interface
 * @export
 */
export const InnerZoneSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InnerZoneSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneLoginStatus(apiVersion?: string, options?: any): AxiosPromise<IInnerZoneLoginStatus> {
            return localVarFp.getInnerZoneLoginStatus(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneLoginRequest} [iInnerZoneLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInnerZoneLogin(apiVersion?: string, iInnerZoneLoginRequest?: IInnerZoneLoginRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postInnerZoneLogin(apiVersion, iInnerZoneLoginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InnerZoneSettingsApi - object-oriented interface
 * @export
 * @class InnerZoneSettingsApi
 * @extends {BaseAPI}
 */
export class InnerZoneSettingsApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneSettingsApi
     */
    public getInnerZoneLoginStatus(apiVersion?: string, options?: any) {
        return InnerZoneSettingsApiFp(this.configuration).getInnerZoneLoginStatus(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IInnerZoneLoginRequest} [iInnerZoneLoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneSettingsApi
     */
    public postInnerZoneLogin(apiVersion?: string, iInnerZoneLoginRequest?: IInnerZoneLoginRequest, options?: any) {
        return InnerZoneSettingsApiFp(this.configuration).postInnerZoneLogin(apiVersion, iInnerZoneLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InnerZoneSurveyApi - axios parameter creator
 * @export
 */
export const InnerZoneSurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneSurvey: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InnerZone/Survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyResultsSaveRequest} [iInnerZoneSurveyResultsSaveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResults: async (apiVersion?: string, iInnerZoneSurveyResultsSaveRequest?: IInnerZoneSurveyResultsSaveRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InnerZone/Survey/Results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInnerZoneSurveyResultsSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InnerZoneSurveyApi - functional programming interface
 * @export
 */
export const InnerZoneSurveyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InnerZoneSurveyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnerZoneSurvey(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInnerZoneSurvey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnerZoneSurvey(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyResultsSaveRequest} [iInnerZoneSurveyResultsSaveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setResults(apiVersion?: string, iInnerZoneSurveyResultsSaveRequest?: IInnerZoneSurveyResultsSaveRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setResults(apiVersion, iInnerZoneSurveyResultsSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InnerZoneSurveyApi - factory interface
 * @export
 */
export const InnerZoneSurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InnerZoneSurveyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerZoneSurvey(apiVersion?: string, options?: any): AxiosPromise<IInnerZoneSurvey> {
            return localVarFp.getInnerZoneSurvey(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyResultsSaveRequest} [iInnerZoneSurveyResultsSaveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResults(apiVersion?: string, iInnerZoneSurveyResultsSaveRequest?: IInnerZoneSurveyResultsSaveRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setResults(apiVersion, iInnerZoneSurveyResultsSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InnerZoneSurveyApi - object-oriented interface
 * @export
 * @class InnerZoneSurveyApi
 * @extends {BaseAPI}
 */
export class InnerZoneSurveyApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneSurveyApi
     */
    public getInnerZoneSurvey(apiVersion?: string, options?: any) {
        return InnerZoneSurveyApiFp(this.configuration).getInnerZoneSurvey(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IInnerZoneSurveyResultsSaveRequest} [iInnerZoneSurveyResultsSaveRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneSurveyApi
     */
    public setResults(apiVersion?: string, iInnerZoneSurveyResultsSaveRequest?: IInnerZoneSurveyResultsSaveRequest, options?: any) {
        return InnerZoneSurveyApiFp(this.configuration).setResults(apiVersion, iInnerZoneSurveyResultsSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InnerZoneSurveyInvitationApi - axios parameter creator
 * @export
 */
export const InnerZoneSurveyInvitationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyBusinessInvitationRequest} [iInnerZoneSurveyBusinessInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBusinessInvitation: async (apiVersion?: string, iInnerZoneSurveyBusinessInvitationRequest?: IInnerZoneSurveyBusinessInvitationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InnerZone/Survey/Invitation/Business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInnerZoneSurveyBusinessInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyEmployeeInvitationRequest} [iInnerZoneSurveyEmployeeInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmployeeInvitation: async (apiVersion?: string, iInnerZoneSurveyEmployeeInvitationRequest?: IInnerZoneSurveyEmployeeInvitationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InnerZone/Survey/Invitation/Employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInnerZoneSurveyEmployeeInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InnerZoneSurveyInvitationApi - functional programming interface
 * @export
 */
export const InnerZoneSurveyInvitationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InnerZoneSurveyInvitationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyBusinessInvitationRequest} [iInnerZoneSurveyBusinessInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendBusinessInvitation(apiVersion?: string, iInnerZoneSurveyBusinessInvitationRequest?: IInnerZoneSurveyBusinessInvitationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendBusinessInvitation(apiVersion, iInnerZoneSurveyBusinessInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyEmployeeInvitationRequest} [iInnerZoneSurveyEmployeeInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmployeeInvitation(apiVersion?: string, iInnerZoneSurveyEmployeeInvitationRequest?: IInnerZoneSurveyEmployeeInvitationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmployeeInvitation(apiVersion, iInnerZoneSurveyEmployeeInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InnerZoneSurveyInvitationApi - factory interface
 * @export
 */
export const InnerZoneSurveyInvitationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InnerZoneSurveyInvitationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyBusinessInvitationRequest} [iInnerZoneSurveyBusinessInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBusinessInvitation(apiVersion?: string, iInnerZoneSurveyBusinessInvitationRequest?: IInnerZoneSurveyBusinessInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendBusinessInvitation(apiVersion, iInnerZoneSurveyBusinessInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IInnerZoneSurveyEmployeeInvitationRequest} [iInnerZoneSurveyEmployeeInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmployeeInvitation(apiVersion?: string, iInnerZoneSurveyEmployeeInvitationRequest?: IInnerZoneSurveyEmployeeInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmployeeInvitation(apiVersion, iInnerZoneSurveyEmployeeInvitationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InnerZoneSurveyInvitationApi - object-oriented interface
 * @export
 * @class InnerZoneSurveyInvitationApi
 * @extends {BaseAPI}
 */
export class InnerZoneSurveyInvitationApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IInnerZoneSurveyBusinessInvitationRequest} [iInnerZoneSurveyBusinessInvitationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneSurveyInvitationApi
     */
    public sendBusinessInvitation(apiVersion?: string, iInnerZoneSurveyBusinessInvitationRequest?: IInnerZoneSurveyBusinessInvitationRequest, options?: any) {
        return InnerZoneSurveyInvitationApiFp(this.configuration).sendBusinessInvitation(apiVersion, iInnerZoneSurveyBusinessInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IInnerZoneSurveyEmployeeInvitationRequest} [iInnerZoneSurveyEmployeeInvitationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerZoneSurveyInvitationApi
     */
    public sendEmployeeInvitation(apiVersion?: string, iInnerZoneSurveyEmployeeInvitationRequest?: IInnerZoneSurveyEmployeeInvitationRequest, options?: any) {
        return InnerZoneSurveyInvitationApiFp(this.configuration).sendEmployeeInvitation(apiVersion, iInnerZoneSurveyEmployeeInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MagicLinkApi - axios parameter creator
 * @export
 */
export const MagicLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccessToken', 'id', id)
            const localVarPath = `/api/MagicLink/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagicLinkApi - functional programming interface
 * @export
 */
export const MagicLinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MagicLinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessToken(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IMagicLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessToken(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MagicLinkApi - factory interface
 * @export
 */
export const MagicLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MagicLinkApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken(id: string, apiVersion?: string, options?: any): AxiosPromise<IMagicLinkResponse> {
            return localVarFp.getAccessToken(id, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagicLinkApi - object-oriented interface
 * @export
 * @class MagicLinkApi
 * @extends {BaseAPI}
 */
export class MagicLinkApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagicLinkApi
     */
    public getAccessToken(id: string, apiVersion?: string, options?: any) {
        return MagicLinkApiFp(this.configuration).getAccessToken(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotesApi - axios parameter creator
 * @export
 */
export const NotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNote', 'id', id)
            const localVarPath = `/api/Notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNote', 'id', id)
            const localVarPath = `/api/Notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getNotesForBusiness', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/Notes`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {INoteCreateRequest} [iNoteCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNote: async (apiVersion?: string, iNoteCreateRequest?: INoteCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iNoteCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {INoteUpdateRequest} [iNoteUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNote: async (id: string, apiVersion?: string, iNoteUpdateRequest?: INoteUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putNote', 'id', id)
            const localVarPath = `/api/Notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iNoteUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotesApi - functional programming interface
 * @export
 */
export const NotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNote(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNote(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotesForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<INote>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotesForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {INoteCreateRequest} [iNoteCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNote(apiVersion?: string, iNoteCreateRequest?: INoteCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNote(apiVersion, iNoteCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {INoteUpdateRequest} [iNoteUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNote(id: string, apiVersion?: string, iNoteUpdateRequest?: INoteUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNote(id, apiVersion, iNoteUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotesApi - factory interface
 * @export
 */
export const NotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNote(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote(id: string, apiVersion?: string, options?: any): AxiosPromise<INote> {
            return localVarFp.getNote(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<INote>> {
            return localVarFp.getNotesForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {INoteCreateRequest} [iNoteCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNote(apiVersion?: string, iNoteCreateRequest?: INoteCreateRequest, options?: any): AxiosPromise<INote> {
            return localVarFp.postNote(apiVersion, iNoteCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {INoteUpdateRequest} [iNoteUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNote(id: string, apiVersion?: string, iNoteUpdateRequest?: INoteUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putNote(id, apiVersion, iNoteUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotesApi - object-oriented interface
 * @export
 * @class NotesApi
 * @extends {BaseAPI}
 */
export class NotesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public deleteNote(id: string, apiVersion?: string, options?: any) {
        return NotesApiFp(this.configuration).deleteNote(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getNote(id: string, apiVersion?: string, options?: any) {
        return NotesApiFp(this.configuration).getNote(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public getNotesForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return NotesApiFp(this.configuration).getNotesForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {INoteCreateRequest} [iNoteCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public postNote(apiVersion?: string, iNoteCreateRequest?: INoteCreateRequest, options?: any) {
        return NotesApiFp(this.configuration).postNote(apiVersion, iNoteCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {INoteUpdateRequest} [iNoteUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public putNote(id: string, apiVersion?: string, iNoteUpdateRequest?: INoteUpdateRequest, options?: any) {
        return NotesApiFp(this.configuration).putNote(id, apiVersion, iNoteUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecapitalizationsApi - axios parameter creator
 * @export
 */
export const RecapitalizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecapitalization: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRecapitalization', 'id', id)
            const localVarPath = `/api/Recapitalizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapitalization: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecapitalization', 'id', id)
            const localVarPath = `/api/Recapitalizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapitalizationForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getRecapitalizationForBusiness', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/Recapitalizations`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IRecapitalizationCreateRequest} [iRecapitalizationCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRecapitalization: async (apiVersion?: string, iRecapitalizationCreateRequest?: IRecapitalizationCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Recapitalizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRecapitalizationCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IRecapitalizationUpdateRequest} [iRecapitalizationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRecapitalization: async (id: string, apiVersion?: string, iRecapitalizationUpdateRequest?: IRecapitalizationUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putRecapitalization', 'id', id)
            const localVarPath = `/api/Recapitalizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRecapitalizationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecapitalizationsApi - functional programming interface
 * @export
 */
export const RecapitalizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecapitalizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecapitalization(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecapitalization(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecapitalization(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRecapitalization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecapitalization(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecapitalizationForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRecapitalization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecapitalizationForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IRecapitalizationCreateRequest} [iRecapitalizationCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRecapitalization(apiVersion?: string, iRecapitalizationCreateRequest?: IRecapitalizationCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRecapitalization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRecapitalization(apiVersion, iRecapitalizationCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IRecapitalizationUpdateRequest} [iRecapitalizationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRecapitalization(id: string, apiVersion?: string, iRecapitalizationUpdateRequest?: IRecapitalizationUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRecapitalization(id, apiVersion, iRecapitalizationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecapitalizationsApi - factory interface
 * @export
 */
export const RecapitalizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecapitalizationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecapitalization(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRecapitalization(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapitalization(id: string, apiVersion?: string, options?: any): AxiosPromise<IRecapitalization> {
            return localVarFp.getRecapitalization(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecapitalizationForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IRecapitalization> {
            return localVarFp.getRecapitalizationForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IRecapitalizationCreateRequest} [iRecapitalizationCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRecapitalization(apiVersion?: string, iRecapitalizationCreateRequest?: IRecapitalizationCreateRequest, options?: any): AxiosPromise<IRecapitalization> {
            return localVarFp.postRecapitalization(apiVersion, iRecapitalizationCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {IRecapitalizationUpdateRequest} [iRecapitalizationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRecapitalization(id: string, apiVersion?: string, iRecapitalizationUpdateRequest?: IRecapitalizationUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putRecapitalization(id, apiVersion, iRecapitalizationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecapitalizationsApi - object-oriented interface
 * @export
 * @class RecapitalizationsApi
 * @extends {BaseAPI}
 */
export class RecapitalizationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapitalizationsApi
     */
    public deleteRecapitalization(id: string, apiVersion?: string, options?: any) {
        return RecapitalizationsApiFp(this.configuration).deleteRecapitalization(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapitalizationsApi
     */
    public getRecapitalization(id: string, apiVersion?: string, options?: any) {
        return RecapitalizationsApiFp(this.configuration).getRecapitalization(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapitalizationsApi
     */
    public getRecapitalizationForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return RecapitalizationsApiFp(this.configuration).getRecapitalizationForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IRecapitalizationCreateRequest} [iRecapitalizationCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapitalizationsApi
     */
    public postRecapitalization(apiVersion?: string, iRecapitalizationCreateRequest?: IRecapitalizationCreateRequest, options?: any) {
        return RecapitalizationsApiFp(this.configuration).postRecapitalization(apiVersion, iRecapitalizationCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {IRecapitalizationUpdateRequest} [iRecapitalizationUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecapitalizationsApi
     */
    public putRecapitalization(id: string, apiVersion?: string, iRecapitalizationUpdateRequest?: IRecapitalizationUpdateRequest, options?: any) {
        return RecapitalizationsApiFp(this.configuration).putRecapitalization(id, apiVersion, iRecapitalizationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TermsOfServiceAgreementsApi - axios parameter creator
 * @export
 */
export const TermsOfServiceAgreementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {ITermsOfServiceAgreementRequest} [iTermsOfServiceAgreementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToTermsOfServiceAgreement: async (apiVersion?: string, iTermsOfServiceAgreementRequest?: ITermsOfServiceAgreementRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/TermsOfServiceAgreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTermsOfServiceAgreementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsOfServiceAgreementStatus: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/TermsOfServiceAgreements/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermsOfServiceAgreementsApi - functional programming interface
 * @export
 */
export const TermsOfServiceAgreementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TermsOfServiceAgreementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {ITermsOfServiceAgreementRequest} [iTermsOfServiceAgreementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreeToTermsOfServiceAgreement(apiVersion?: string, iTermsOfServiceAgreementRequest?: ITermsOfServiceAgreementRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITermsOfServiceAgreementUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreeToTermsOfServiceAgreement(apiVersion, iTermsOfServiceAgreementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermsOfServiceAgreementStatus(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITermsOfServiceAgreementStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermsOfServiceAgreementStatus(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TermsOfServiceAgreementsApi - factory interface
 * @export
 */
export const TermsOfServiceAgreementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TermsOfServiceAgreementsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {ITermsOfServiceAgreementRequest} [iTermsOfServiceAgreementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToTermsOfServiceAgreement(apiVersion?: string, iTermsOfServiceAgreementRequest?: ITermsOfServiceAgreementRequest, options?: any): AxiosPromise<ITermsOfServiceAgreementUpdate> {
            return localVarFp.agreeToTermsOfServiceAgreement(apiVersion, iTermsOfServiceAgreementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsOfServiceAgreementStatus(apiVersion?: string, options?: any): AxiosPromise<ITermsOfServiceAgreementStatus> {
            return localVarFp.getTermsOfServiceAgreementStatus(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TermsOfServiceAgreementsApi - object-oriented interface
 * @export
 * @class TermsOfServiceAgreementsApi
 * @extends {BaseAPI}
 */
export class TermsOfServiceAgreementsApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {ITermsOfServiceAgreementRequest} [iTermsOfServiceAgreementRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsOfServiceAgreementsApi
     */
    public agreeToTermsOfServiceAgreement(apiVersion?: string, iTermsOfServiceAgreementRequest?: ITermsOfServiceAgreementRequest, options?: any) {
        return TermsOfServiceAgreementsApiFp(this.configuration).agreeToTermsOfServiceAgreement(apiVersion, iTermsOfServiceAgreementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsOfServiceAgreementsApi
     */
    public getTermsOfServiceAgreementStatus(apiVersion?: string, options?: any) {
        return TermsOfServiceAgreementsApiFp(this.configuration).getTermsOfServiceAgreementStatus(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TodosApi - axios parameter creator
 * @export
 */
export const TodosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTodo: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTodo', 'id', id)
            const localVarPath = `/api/Todos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodo: async (id: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTodo', 'id', id)
            const localVarPath = `/api/Todos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodos: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Todos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodosForBusiness: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getTodosForBusiness', 'businessId', businessId)
            const localVarPath = `/api/Businesses/{businessId}/Todos`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {ITodoCreateRequest} [iTodoCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTodo: async (apiVersion?: string, iTodoCreateRequest?: ITodoCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Todos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTodoCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {ITodoUpdateRequest} [iTodoUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTodo: async (id: string, apiVersion?: string, iTodoUpdateRequest?: ITodoUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTodo', 'id', id)
            const localVarPath = `/api/Todos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTodoUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TodosApi - functional programming interface
 * @export
 */
export const TodosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TodosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTodo(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTodo(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodo(id: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITodo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodo(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodos(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITodo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodos(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodosForBusiness(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITodo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodosForBusiness(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {ITodoCreateRequest} [iTodoCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTodo(apiVersion?: string, iTodoCreateRequest?: ITodoCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITodo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTodo(apiVersion, iTodoCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {ITodoUpdateRequest} [iTodoUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTodo(id: string, apiVersion?: string, iTodoUpdateRequest?: ITodoUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTodo(id, apiVersion, iTodoUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TodosApi - factory interface
 * @export
 */
export const TodosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TodosApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTodo(id: string, apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTodo(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodo(id: string, apiVersion?: string, options?: any): AxiosPromise<ITodo> {
            return localVarFp.getTodo(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodos(apiVersion?: string, options?: any): AxiosPromise<Array<ITodo>> {
            return localVarFp.getTodos(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodosForBusiness(businessId: string, apiVersion?: string, options?: any): AxiosPromise<Array<ITodo>> {
            return localVarFp.getTodosForBusiness(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {ITodoCreateRequest} [iTodoCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTodo(apiVersion?: string, iTodoCreateRequest?: ITodoCreateRequest, options?: any): AxiosPromise<ITodo> {
            return localVarFp.postTodo(apiVersion, iTodoCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [apiVersion] 
         * @param {ITodoUpdateRequest} [iTodoUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTodo(id: string, apiVersion?: string, iTodoUpdateRequest?: ITodoUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putTodo(id, apiVersion, iTodoUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TodosApi - object-oriented interface
 * @export
 * @class TodosApi
 * @extends {BaseAPI}
 */
export class TodosApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public deleteTodo(id: string, apiVersion?: string, options?: any) {
        return TodosApiFp(this.configuration).deleteTodo(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public getTodo(id: string, apiVersion?: string, options?: any) {
        return TodosApiFp(this.configuration).getTodo(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public getTodos(apiVersion?: string, options?: any) {
        return TodosApiFp(this.configuration).getTodos(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public getTodosForBusiness(businessId: string, apiVersion?: string, options?: any) {
        return TodosApiFp(this.configuration).getTodosForBusiness(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {ITodoCreateRequest} [iTodoCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public postTodo(apiVersion?: string, iTodoCreateRequest?: ITodoCreateRequest, options?: any) {
        return TodosApiFp(this.configuration).postTodo(apiVersion, iTodoCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [apiVersion] 
     * @param {ITodoUpdateRequest} [iTodoUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public putTodo(id: string, apiVersion?: string, iTodoUpdateRequest?: ITodoUpdateRequest, options?: any) {
        return TodosApiFp(this.configuration).putTodo(id, apiVersion, iTodoUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/Me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IMe>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(apiVersion?: string, options?: any): AxiosPromise<IMe> {
            return localVarFp.getMe(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMe(apiVersion?: string, options?: any) {
        return UsersApiFp(this.configuration).getMe(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VisionLinkContactApi - axios parameter creator
 * @export
 */
export const VisionLinkContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisionLinkContactRecord: async (businessId: string, apiVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getVisionLinkContactRecord', 'businessId', businessId)
            const localVarPath = `/api/VisionLink/Contact/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IContactVisionLinkRequest} [iContactVisionLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContactVisionLink: async (apiVersion?: string, iContactVisionLinkRequest?: IContactVisionLinkRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/VisionLink/Contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined && apiVersion !== null) {
                localVarHeaderParameter['api-version'] = String(apiVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iContactVisionLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisionLinkContactApi - functional programming interface
 * @export
 */
export const VisionLinkContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisionLinkContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisionLinkContactRecord(businessId: string, apiVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IVisionLinkContactRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisionLinkContactRecord(businessId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IContactVisionLinkRequest} [iContactVisionLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContactVisionLink(apiVersion?: string, iContactVisionLinkRequest?: IContactVisionLinkRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContactVisionLink(apiVersion, iContactVisionLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisionLinkContactApi - factory interface
 * @export
 */
export const VisionLinkContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisionLinkContactApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisionLinkContactRecord(businessId: string, apiVersion?: string, options?: any): AxiosPromise<IVisionLinkContactRecord> {
            return localVarFp.getVisionLinkContactRecord(businessId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {IContactVisionLinkRequest} [iContactVisionLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContactVisionLink(apiVersion?: string, iContactVisionLinkRequest?: IContactVisionLinkRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postContactVisionLink(apiVersion, iContactVisionLinkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisionLinkContactApi - object-oriented interface
 * @export
 * @class VisionLinkContactApi
 * @extends {BaseAPI}
 */
export class VisionLinkContactApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisionLinkContactApi
     */
    public getVisionLinkContactRecord(businessId: string, apiVersion?: string, options?: any) {
        return VisionLinkContactApiFp(this.configuration).getVisionLinkContactRecord(businessId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {IContactVisionLinkRequest} [iContactVisionLinkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisionLinkContactApi
     */
    public postContactVisionLink(apiVersion?: string, iContactVisionLinkRequest?: IContactVisionLinkRequest, options?: any) {
        return VisionLinkContactApiFp(this.configuration).postContactVisionLink(apiVersion, iContactVisionLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


