import { IInnerZoneEmployeeProfileType } from '@api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { profileTypeColor, profileTypeDisplay, profileTypeIcon } from './innerZoneEnumUtils';

interface IProps {
    percentage: number;
    profileType: IInnerZoneEmployeeProfileType;
}

const ProfileTypePercentageCard = ({ percentage, profileType }: IProps): JSX.Element => (
    <div className="rounded bg-background1 py-3 text-center shadow">
        <FontAwesomeIcon
            className={`text-${profileTypeColor[profileType]}`}
            icon={profileTypeIcon[profileType]}
            size="lg"
        />
        <div className="text-3xl leading-9 font-medium mb-1">{percentage}%</div>
        <div className="text-xs leading-none">{profileTypeDisplay[profileType]}</div>
    </div>
);

export default ProfileTypePercentageCard;
