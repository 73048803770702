import { faCloudUpload, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button } from '~/components';
import { Link, Route, Switch, useRouteMatch } from '~/routing';
import Employees from '../../employees';
import AddEmployeeForm from '../../employees/AddEmployeeForm';
import EmployeesUpload from '../../employees/EmployeesUpload';

interface IProps {
    businessId: string;
}

const EmployeeList = ({ businessId }: IProps): JSX.Element => {
    const [addState, setAddState] = useState({ isOpen: false });
    const match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/Upload`}>
                <h3 className="text-lg leading-6 font-medium mb-4">Employee List</h3>
                <EmployeesUpload businessId={businessId} />
            </Route>
            <Route>
                <>
                    <div className="flex justify-between mb-4">
                        <h3 className="text-lg leading-6 font-medium">Employee List</h3>
                        <div className="self-center space-x-3">
                            <Button
                                iconLeft={faPlusCircle}
                                text="Add New"
                                onClick={() => setAddState({ ...addState, isOpen: true })}
                            />
                            <Link to={`${match.url}/Upload`}>
                                <Button iconLeft={faCloudUpload} text="Bulk Import" />
                            </Link>
                        </div>
                    </div>
                    <Employees businessId={businessId} />
                    <AddEmployeeForm
                        businessId={businessId}
                        onClose={() => setAddState({ ...addState, isOpen: false })}
                        {...addState}
                    />
                </>
            </Route>
        </Switch>
    );
};

export default EmployeeList;
