import { IBonusRightEmployeeValueStatementYear, IBusiness, IEmployee } from '@api';
import { SeriesOptionsType } from 'highcharts';
import {
    LoadingIndicator,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import StackedAreaChart from '~/components/charts/StackedAreaChart';
import { useBonusRightEmployeeValueStatement } from '~/hooks/bonusRightEmployeeRewards';
import { getBonusRightSampleEmployeeValueStatement } from '~/sampleData';
import CurrencyFormatter from '~/utils/currencyFormatter';
import BonusRightErrorPage from '../../BonusRightErrorPage';
import CurrencyTableCell from '../../CurrencyTableCell';

interface IProps {
    bonusRightEmployeeId: number;
    business: IBusiness;
    employees: IEmployee[];
    onClose: () => void;
}

const EmployeeValueStatementModal = ({
    bonusRightEmployeeId,
    business,
    employees,
    onClose,
}: IProps): JSX.Element | null => {
    const {
        isError,
        isLoading,
        data: evs = getBonusRightSampleEmployeeValueStatement(
            employees,
            bonusRightEmployeeId,
            business.isSampleCase ?? false
        ),
    } = useBonusRightEmployeeValueStatement(business.id, bonusRightEmployeeId, !business.isSampleCase);
    if (isError) return <BonusRightErrorPage business={business} reportName="Employee Value Statement" />;
    if (isLoading) return <LoadingIndicator />;
    if (!evs.years) return null;
    const { years } = evs;

    return (
        <Modal
            maxWidth="7xl"
            open={bonusRightEmployeeId !== undefined}
            setOpen={onClose}
            title="Employee Value Statement"
        >
            <div className="flex flex-col items-center p-6 space-y-4">
                <div className="flex max-w-6xl space-x-5 w-full">
                    {renderTopLevelItem('Salary', years.map(y => y.salary).reduce(reducer))}
                    {renderTopLevelItem('Short-Term Incentives', years.map(y => y.stipPayment).reduce(reducer))}
                    {renderTopLevelItem('Long-Term Incentives', years.map(y => y.ltipRedemption).reduce(reducer))}
                </div>
                <div className="max-w-6xl w-full">
                    <StackedAreaChart
                        height={260}
                        plotOptions={{ series: { color: 'transparent' } }}
                        series={mapChartData(years)}
                        xAxis={{ allowDecimals: false }}
                    />
                </div>
                <div className="max-w-6xl w-full">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell />
                                {years?.map(y => (
                                    <TableHeaderCell key={y.year}>{y.year}</TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderRow('Salary', years, y => y.salary)}
                            {renderRow('STIP Payment', years, y => y.stipPayment)}
                            {renderRow('LTIP Redemption', years, y => y.ltipRedemption)}
                            {renderRow('Vested Value', years, y => y.vestedValue)}
                            {renderRow('Unvested Value', years, y => y.unvestedValue)}
                            {renderRow(
                                'Total Plan Value',
                                years,
                                y =>
                                    number(y.salary) +
                                    number(y.stipPayment) +
                                    number(y.ltipRedemption) +
                                    number(y.vestedValue) +
                                    number(y.unvestedValue),
                                'bg-background1'
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </Modal>
    );
};

const number = (n: number | undefined) => n ?? 0;

const reducer = (a: number | undefined, i: number | undefined) => number(a) + number(i);

const renderTopLevelItem = (label: string, value: number | undefined) => (
    <div className="bg-background1 flex-grow px-4 py-5 rounded-lg">
        <h3>Total {label}</h3>
        <p className="font-semibold text-3xl">{CurrencyFormatter.format(value)}</p>
    </div>
);

const renderRow = (
    label: string,
    years: IBonusRightEmployeeValueStatementYear[],
    accessor: (year: IBonusRightEmployeeValueStatementYear) => number | undefined,
    background = 'bg-background'
) => (
    <TableRow>
        <TableCell background={background}>{label}</TableCell>
        {years?.map(y => (
            <CurrencyTableCell background={background} key={y.year} value={accessor(y)} />
        ))}
    </TableRow>
);

type ChartAreas = Pick<IBonusRightEmployeeValueStatementYear, 'salary' | 'stipPayment' | 'ltipRedemption'>;
const chartAreaKeys: Array<keyof ChartAreas> = ['ltipRedemption', 'stipPayment', 'salary'];
const chartAreaColors: Record<keyof ChartAreas, `--color-${string}`> = {
    ltipRedemption: '--color-blue',
    salary: '--color-green',
    stipPayment: '--color-yellow',
};

const mapChartData = (years: IBonusRightEmployeeValueStatementYear[]): SeriesOptionsType[] => {
    return chartAreaKeys.map<SeriesOptionsType>(key => ({
        name: key,
        data: years.map(y => [parseInt(y.year ?? '0', 10), number(y[key])]),
        type: 'area',
        fillColor: {
            linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
            },
            stops: [
                [0, `var(${chartAreaColors[key]}-500)`],
                [1, `var(${chartAreaColors[key]}-600)`],
            ],
        },
    }));
};

export default EmployeeValueStatementModal;
