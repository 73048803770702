import { IBusiness, IBusinessPageType } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import { useBonusRightConnectionStatus } from '~/hooks/bonusRightConnection';
import BusinessForecast from './businessForecast';
import UnconnectedPage from './unconnected';

interface IProps {
    business: IBusiness;
}

const BusinessForecastPage = ({ business }: IProps): JSX.Element => {
    const employeesQuery = useEmployees(business.id);
    const connectionStatusQuery = useBonusRightConnectionStatus(
        business.id,
        !business.isSampleCase && !employeesQuery.isLoading
    );
    const isLoading = isTrueForAnyQuery('isLoading', connectionStatusQuery, employeesQuery);
    const isError = isTrueForAnyQuery('isError', connectionStatusQuery, employeesQuery);
    if (isLoading) return <LoadingIndicator />;
    if (isError) return <ErrorPage />;

    const { data: connectionStatus = { isConnected: business.isSampleCase } } = connectionStatusQuery;

    return connectionStatus.isConnected ? (
        <BusinessForecast business={business} employees={employeesQuery.data ?? []} />
    ) : (
        <UnconnectedPage business={business} businessPageType={IBusinessPageType.BusinessForecast} />
    );
};

export default BusinessForecastPage;
