import { IBusinessTeamMember, ITodo } from '@api';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useState } from 'react';
import { Button, Checkbox } from '~/components';
import { useUpdateTodo } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import EditTodoForm from './EditTodoForm';
import { priorityColorMap } from './priorityColorMap';

interface ITodoItemProps {
    footer?: React.ReactNode;
    tail?: React.ReactNode;
    todo: ITodo;
}

const getColor = (todo: ITodo): string => {
    if (todo.completed) return 'affirmation';
    const color = priorityColorMap[todo.priority];
    if (color) return color;

    throw new Error('Unmapped item color');
};

const getShortMonth = (dateString: string) =>
    new Date(dateString).toLocaleString('default', { month: 'short', timeZone: 'UTC' });
const getTwoDigitDay = (dateString: string) =>
    new Date(dateString).toLocaleString('default', { day: '2-digit', timeZone: 'UTC' });

export const TodoItem = ({ footer, tail, todo }: ITodoItemProps): JSX.Element => {
    const color = getColor(todo);

    return (
        <div className="flex shadow-sm rounded-md bg-background2 mt-4 border border-l-0 border-divider">
            <div
                className={classNames(`bg-${color}`, 'flex-shrink-0 flex items-center justify-center w-1 rounded-l-md')}
            />
            <div className="px-6 py-4 w-full">
                <div className="flex">
                    <div className="text-center">
                        <div className="font-medium uppercase tracking-wide">{getShortMonth(todo.dueDate)}</div>
                        <div className="text-3xl font-bold tracking-wide">{getTwoDigitDay(todo.dueDate)}</div>
                    </div>
                    <div className="mx-6 flex-grow line-clamp-2 leading-relaxed" title={todo.description}>
                        {todo.description}
                    </div>
                    {tail}
                </div>
                {footer}
            </div>
        </div>
    );
};

interface ICompletableTodoItemProps {
    businessId: string;
    footer?: React.ReactNode;
    todo: ITodo;
}

export const CompletableTodoItem = ({ footer, businessId, todo }: ICompletableTodoItemProps): JSX.Element => {
    const updateTodo = useUpdateTodo(businessId, todo.id);
    const onCompleted = () => {
        updateTodo.mutateAsync({
            ...todo,
            completed: !todo.completed,
        });
    };

    return (
        <TodoItem
            todo={todo}
            tail={<Checkbox checked={todo.completed} onChange={onCompleted} value="completed" />}
            footer={footer}
        />
    );
};

interface IEditableTodoItemProps {
    assigneeName?: string;
    businessId: string;
    todo: ITodo;
}

export const EditableTodoItem = ({ assigneeName, businessId, todo }: IEditableTodoItemProps): JSX.Element => {
    const [editIsOpen, setEditIsOpen] = useState(false);

    return (
        <>
            <CompletableTodoItem
                businessId={businessId}
                todo={todo}
                footer={
                    <div className="flex justify-between mt-3">
                        <div className="truncated">{assigneeName || 'Unassigned'}</div>
                        {!todo.completed && (
                            <Button
                                color="secondary"
                                iconLeft={faEdit}
                                size="sm"
                                text="Edit"
                                onClick={() => setEditIsOpen(true)}
                            />
                        )}
                    </div>
                }
            />
            <EditTodoForm
                businessId={businessId}
                isOpen={editIsOpen}
                onClose={() => setEditIsOpen(false)}
                todo={todo}
            />
        </>
    );
};

interface ITodoItemSetProps {
    businessId: string;
    label: string;
    teamMembers: IBusinessTeamMember[];
    todos: ITodo[];
}

const TodoItemSet = (props: ITodoItemSetProps): JSX.Element | null => {
    const { label, teamMembers, todos } = props;
    if (todos?.length === 0) {
        return null;
    }
    const color = getColor(todos[0]);

    return (
        <>
            {label && <div className={`text-${color} pt-4`}>{label}</div>}
            {todos.map(todo => {
                const assignee = teamMembers.find(t => t.id === todo.assigneeId);
                const assigneeName = assignee ? NameFormatter.getName(assignee) : undefined;

                return <EditableTodoItem key={todo.id} assigneeName={assigneeName} todo={todo} {...props} />;
            })}
        </>
    );
};

export default TodoItemSet;
