import { IBusiness } from '@api';
import {
    isTrueForAnyQuery,
    useBoundingClientRect,
    useInnerZoneBusinessDetail,
    useInnerZonePrimaryReport,
} from '~/hooks';
import CommunicationChart from '../../innerZone/CommunicationChart';
import UserSurveyProgress from '../../innerZone/UserSurveyProgress';
import CardSkeleton from '../CardSkeleton';
import InnerZoneError from './InnerZoneError';

interface IProps {
    business: IBusiness;
}

const CommunicationCardContent = ({ business }: IProps): JSX.Element => {
    const [chartContainerRect, chartContainerRef] = useBoundingClientRect();
    const innerZoneBusinessDetailQuery = useInnerZoneBusinessDetail(business.id);
    const innerZonePrimaryReportQuery = useInnerZonePrimaryReport(business.id);
    const isLoading = isTrueForAnyQuery('isLoading', innerZoneBusinessDetailQuery, innerZonePrimaryReportQuery);
    const isError = isTrueForAnyQuery('isError', innerZoneBusinessDetailQuery, innerZonePrimaryReportQuery);

    if (isError) return <InnerZoneError error={innerZoneBusinessDetailQuery.error || undefined} />;
    if (isLoading || !innerZoneBusinessDetailQuery.data || !innerZonePrimaryReportQuery.data) return <CardSkeleton />;

    return (
        <div className="flex flex-col flex-grow">
            <UserSurveyProgress employeeUsers={innerZoneBusinessDetailQuery.data.users} />
            <div className="flex flex-grow items-center mt-4 pointer-events-none" ref={chartContainerRef}>
                <CommunicationChart
                    avatarOnly
                    employeeLinks={innerZoneBusinessDetailQuery.data.links}
                    employeeUsers={[
                        ...innerZoneBusinessDetailQuery.data.users.filter(
                            u => u.employeeId === business.primaryEmployeeId
                        ),
                        ...innerZoneBusinessDetailQuery.data.users.filter(u =>
                            innerZonePrimaryReportQuery.data.employeeIds.some(id => id === u.employeeId)
                        ),
                    ]}
                    height={chartContainerRect?.height || 0}
                    width={chartContainerRect?.width || 0}
                    onEmployeeUserClick={() => null}
                    onLinkClick={() => null}
                />
            </div>
        </div>
    );
};

export default CommunicationCardContent;
